import React from 'react'
import { makeStyles } from '@material-ui/styles';
import TableClients from '../ExistingCaseTables/TableClients';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';


const useStyles = makeStyles({
  appMain: {
    paddingLeft: '50px',
    paddingRight:'50px',
    padding:'0px'
  }
})

function ExistingCases() {
    const classes = useStyles();
  return (
    <>
    <div className="home">
    <Navigation />
    
    <div className="homeContainer">
      <Navbar />
    <div className={classes.appMain}>
        
         <TableClients />
        
    </div>
    </div>
    </div>
   
    </>
  )
}

export default ExistingCases