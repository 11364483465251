import React,{useContext, useEffect, useState} from 'react'
import './UserNavigation.scss'
import LOGO from './LFH.svg';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { AuthContext } from '../../context/AuthContext';
import Axios from "axios"; 

const UserNavigation = ()=>{

const [open,setOpen] = useState(false);
const { currentUser,logout } = useContext(AuthContext);
const [data, setData] = useState([]);


const handleClick = (e)=>{
  e.preventDefault();
  setOpen(!open);
}

const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [openDropdown, setOpenDropdown] = useState(false);

const handleDropdownToggle = () => {
  setOpenDropdown(!openDropdown);
};
const loadData = async () => {
  const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
  setData(response.data);
}; 

useEffect(() =>{
  loadData();
}, []); 

 



  return (
      <div className='headerUser'>

          <div className={classNames('navbar', { 'scrolled': isScrolled })}>

            <div className='logo'>
              <img src={LOGO} alt="logo" />
            </div>


            <div
              className={open ? "nav-items active" : "nav-items"}
            >
              <span>
                <a href='/index' className={isScrolled ? 'scrolled' : ''}>Home</a>
              </span>
           
              <span>
                <a href='/invoices' className={isScrolled ? 'scrolled' : ''}>Invoices</a>
              </span> 
              
              <span>
                <a href='/reports' className={isScrolled ? 'scrolled' : ''}>Reports</a>
              </span>

              <span>
                <a href='/bring_ups' className={isScrolled ? 'scrolled' : ''}>Bring Ups</a>
              </span>

              <span>
                <a href='/communications' className={isScrolled ? 'scrolled' : ''}><EmailIcon /></a>
              </span>

              <span> 
              <button className='bg-red-800'>
                  <a href='/NewCases'>Add Case</a>
              </button>
            </span>

              <span onClick={handleDropdownToggle}>
              {data.map((val,index) =>{
                return(
                  <div  key={val.id}>             
                  <div>
                  {currentUser.fullname === val.fullname && 
                    <img src={val.imageUrl} alt=''/>
                    }
                  </div>
                 
                  </div>
                )
              })}
              <div className={classNames('dropdown', { 'active': openDropdown })}>

                  <div className='TopDrop'>
                    <h4>{currentUser.fullname}</h4>
                    {data.map((val,index) =>{
                      return(
                        <div  key={val.id}>             
                        <div>
                        {currentUser.fullname === val.fullname && 
                          <img src={val.imageUrl} alt=''/>
                          }
                        </div>
                       
                        </div>
                      )
                    })}
                  </div>
                  <div className='ListingDropdown'>

                    <a href='/settings'>
                    {data.map((val,index) =>{
                      return(
                        <div  key={val.id}>             
                        <div>
                        {currentUser.fullname === val.fullname && 
                          <a href={`/update_user/${val.id}`} style={{display:'flex',gap:'3px'}}> 
                            Settings 
                          </a>
                          }
                        </div>
                       
                        </div>
                      )
                    })}
                    </a> 

                    <a href='/user_leave'>Leave request</a>
                    <button>
                      <a href='/Auth'>Logout</a>
                    </button> 
                  </div>
              </div>
            </span>
              

           

          
          

            </div>
            <div className="hamburger">
                  <span><MenuIcon style={{fontSize:'25px'}} onClick={handleClick}/></span>
              </div>
          </div>
      </div>
  );
}

export default UserNavigation;
