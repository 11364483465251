import React, { useEffect, useState } from 'react'
import axios from 'axios';
import '../pages/home.scss'
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';
import Box from '@mui/material/Box';
import moment from 'moment';
import InboxIcon from '@mui/icons-material/Inbox';
import Axios from 'axios'
import SendIcon from '@mui/icons-material/Send';
import { Modal } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import person from './person.jpg'
import './Message.scss'
import ReplyIcon from '@mui/icons-material/Reply';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import './SuggestionsMessage.css'
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

function Message() {

    const [view, setView] = useState([]);
    const {id} = useParams();

    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions/oneMessage/${id}`);
        setView(res.data);
      } catch (err) {
        console.log(err);
      }
    }; 
    fetchData();
  }, [id]);

  const [suggestions,setSuggestions] = useState([]);
    const [messages,setMessages] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions?q=${query}`);
          setSuggestions(res.data);
        };
        if (query.length === 0 || query.length > 2) fetchData();
      }, [query]);

      useEffect(() => {
        const fetchData = async () => {
          const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions/messages?q=${query}`);
          setMessages(res.data);
        };
        if (query.length === 0 || query.length > 2) fetchData();
      }, [query]);


      const getText = (html) =>{
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - suggestions.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reply,setReply] = React.useState(false);
  const handleReply = () => setReply(true);
  const handleCloseReply = () => setReply(false);

  const [forward,setForward] = React.useState(false);
  const handleForward = () => setForward(true)
  const handleCloseForward = () => setForward(false)



  const { currentUser,currentFirm } = useContext(AuthContext);



  const history = useNavigate();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleChangeCheck = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };




  const [filingdate,setFilingDate] = [moment().format("YYYY-MM-DD hh:mm:ss")];
  const [fullname,setFullName] =  [currentUser.fullname || currentFirm.firmname]

 


  const [data, setData] = useState([]);

  const loadData = async () => {
    const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
    setData(response.data);
  }; 

  useEffect(() =>{
    loadData();
  }, []); 


  const [Receiver,SetReceiver] = useState('');
  const [Subject,setSubject] = useState('');
  const [Message,setMessage] = useState('');
  const [sender,setSender] =  [currentUser.fullname || currentFirm.firmname];


  const forwardMessage = () =>{
    axios.put(process.env.REACT_APP_API_ADDRESS + `api/suggestions/${id}`, 
    {Receiver:Receiver}).then(() => {
    });

      toast.success("The message has been forwarded") 
    }

  const ReplyMessage = () =>{
    axios.put(process.env.REACT_APP_API_ADDRESS + `api/suggestions/${id}`, 
    {Subject:Subject,Message:Message}).then(() => {
      });

        toast.success("Reply sent.") 
      }

      const deletePersonalRecord = (id) =>{
        if(window.confirm("Are you sure you want to delete this message?")){
          axios.delete(process.env.REACT_APP_API_ADDRESS + `api/suggestions/${id}`);
          toast.success("Message deleted successfully");
          setTimeout(() => history('/Suggestions'), 200)
        }
       } 


  return (

    <div className="home">
    <Sidebar />
    
    <div className="homeContainer">
      <Navbar />
      <div style={{marginLeft:'50px',marginRight:'50px',marginTop:'50px',display:'flex'}}>

      <div className='Messagebar'>
        <span style={{fontSize:'25px'}}>Hmail</span>

        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',gap:'1rem'}}>
          <button className='MailButtonInbox'>
          <InboxIcon />
          <div style={{display:'flex'}}>
          <span>
            <div className="counter">
            {messages.map((val) =>{
              return(
                <div>
                <Link to={'/Suggestions'} style={{textDecoration:'none'}}>
                  <span style={{fontWeight:'bold'}}>
                  {currentUser.fullname === val.Receiver && 
                    <span>Inbox  {val.messages} </span>
                  }
                  {currentFirm.firmname === val.Receiver && 
                    <span>Inbox {val.messages} </span>
                  }
                  </span>
                </Link>
                  
                </div>
              )
            })}
            </div>
          </span>
          </div>
          </button>
          
          <button className='MailButton'><SendIcon /><span style={{paddingLeft:'10px'}}>Sent messages</span></button>
        </div>
       
      </div>


      <div>

      <div className='Inboxbar'>
      <div style={{display:'flex',gap:'2rem'}}>
        <Link to={'/Suggestions'}>
          <button className='ButtonInbox'><ArrowBackIcon /></button>
        </Link>
        <button className='ButtonInbox'><ArchiveIcon /></button>
        <button className='ButtonInbox' onClick={() => deletePersonalRecord(view.id)}><DeleteIcon /></button>
      </div>
       
        <span> <a style={{textDecoration:'none'}} href={`/users/${view.fileId}`}> <button className='GoTocase'> Go To Case Details</button></a> </span>
      </div>

      <div className='message'>

      <div className='subjectline'>

      <span>Subject: {view.Subject}</span>
      <div className='messagebuttons'>
      

      </div>
      

      </div>

      <hr style={{height:'1px'}}/>

      <div className='messagehead'>

            <div className='headeravatar'>
               <div><img src={person} alt="" /></div> 
               <div><span>{view.sender}</span></div> 
            </div>

            <div>
                <span>{moment(view.DateSent).format('Do MMMM YYYY')}</span>
            </div>
            
      </div>

      <div className='messageBody'>
            <span>{getText(view.Message)}</span>
      </div>

  

      <div className='messageBottom'>
            <span>Attachments <b>.</b> </span>
      </div>

      <hr style={{height:'1px'}}/>






      <div className='messagebuttons'>
      <button onClick={handleReply}><ReplyIcon />Reply</button>
      <Modal 
            open={reply}
            onClose={handleCloseReply}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

           
            <Box sx={style}>
                <span style={{color:'blue',textAlign:'center',fontSize:'15px'}}>Reply to Message</span>

                <div>

 
              <div className='mb-3' style={{marginTop:'10px'}}>
                <label htmlFor='Subject'> Subject: </label>
                <input
                className='form-control' 
                id="Subject"
                name="Subject"
                required
                value={Subject} 
                onChange={(e) =>{
                  setSubject(e.target.value);
              }}
                />
              </div>

              <div className='mb-3' style={{marginTop:'10px'}}>
                <label htmlFor='Message'> Message </label>
                <textarea
                rows={5}
                className='form-control' 
                id="Message"
                name="Message"
                required
                value={Message} 
                onChange={(e) =>{
                  setMessage(e.target.value);
              }}
                />
              </div>

              <div className="mb-3 mt-3" style={{display:'none'}}>
            <lable htmlFor='Sender'>Sender</lable>
            <input 
              type='text'
              className="form-control"
              name="sender"
              id="sender"
              value={sender || ""}
              onChange={(e) =>{
                setSender(e.target.value);
              }}
            />
          </div>

              
              <div>
              <label htmlFor='attachfile' style={{display:'flex',gap:'10px'}}>
              <input 
                type='checkbox'
                value={isSubscribed}
                onChange={handleChangeCheck}
                id='attachfile'
                name='attachfile'
              />
              Attach File (Optional)
              </label>

              {isSubscribed && 
                <div style={{marginTop:'10px'}}>
                  <input 
                    className='form-control'
                    type='file'
                  />
                </div>
              
              }


              </div>

              <div style={{display:'flex',justifyContent:'center'}}>
                <button onClick={ReplyMessage} style={{borderRadius:'5px',padding:'10px 20px',color:'white',backgroundColor:'#02b159'}}>Reply</button>
              </div>
              
                      
            
              </div>
            </Box>


          </Modal>

          

      <button onClick={handleForward}><ShortcutIcon /> Forward</button>
      <Modal 
      open={forward}
      onClose={handleCloseForward}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

     
      <Box sx={style}>
          <span style={{color:'blue',textAlign:'center',fontSize:'15px'}}>Forward Message</span>

          <div>

          <div className='mb-3 mt-3'>
          <label htmlFor='Receivers' style={{marginTop:'10px'}}> To: </label>
          <select
          className='form-control' 
          id="Receivers"
          name="Receivers"
          required
          value={Receiver} 
          onChange={(e) =>{
            SetReceiver(e.target.value);
        }}
        >
          <option>select...</option>
          
          {data.map((val) =>{
            return( 
              
                <option>{val.fullname}</option>
                
            )
          })}
          
        </select>
        </div>

        
      
        
        <div style={{display:'flex',justifyContent:'center'}}>
            <button onClick={forwardMessage} style={{borderRadius:'5px',padding:'10px 20px',color:'white',backgroundColor:'#02b159'}}>Forward</button>
        </div>
                
      
        </div>
      </Box>


    </Modal>
      </div>
      <hr style={{height:'1px'}}/>

     

      </div>

      </div>

      

      </div>
    </div>
    </div>
  )
}

export default Message