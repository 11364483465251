import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import '../context/Auth.scss';
import Login from './Login.png';

export const Auth = () => {
  const history = useNavigate();

  const [staff, setStaff] = useState({
    username: '',
    password: '',
  });

  const [lawfirm, setLawfirm] = useState({
    username: '',
    password: '', 
  });

  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setStaff((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(staff);
      setTimeout(() => history('/Index'), 100);
    } catch (err) {
      setError(err.response.data);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className='Auth'>
        <div className='background-overlay'>
          <div className='LoginPage'>
            <div className='Form'>
              <div className='businessName'>
                <span>LawFirms <strong>Hub</strong></span>
              </div>

              <div className='Entry'>
                <label htmlFor='userName'>Username <span>*</span></label>
                <input
                  type='text'
                  placeholder='Username (email)'
                  id='username'
                  name='username'
                  onChange={handleChange}
                />
              </div>

              <div className='Entry'>
                <label htmlFor='password'>Password <span>*</span></label>
                <div className='PasswordShow'>
                  <input
                    placeholder='Password'
                    type={passwordShown ? 'text' : 'password'}
                    id='password'
                    name='password'
                    onChange={handleChange}
                  />
                  <button type='button' onClick={togglePassword}>
                    {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </button>
                </div>
              </div>

              <div className='SubmitButton'>
                <button onClick={handleSubmit}>Login</button>
              </div>

              <div className='errors'>
                {error && <p>{error}</p>}
              </div>
            </div>

            <div className='LoginImage'>
              <img src={Login} alt=''/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
