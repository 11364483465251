import React from 'react';
import './Ceo.scss';
import Profile from './profile.jpeg'
import NavigationOne from '../NavigationOne';
import moment from 'moment';

const Ceo = () => {
    const year = moment().format("YYYY")
  return (
    <div>
        <NavigationOne />
        <div className='MainProfile'>
            <div className='Content'>
                <select>
                    <option>Explore Content</option>
                </select>
            </div>
            <div className='ImageHeader'>
                <img src={Profile} alt=''/>

                <div className='CompanyDesc'>
                    <h1>S&M Softwares Inc.</h1>

                    <div className='Company'>
                        <div className='Border'></div>
                        <div className='Desc'>
                            <span>Company</span>
                            <a href='/#'>S&M Softwares Inc.</a>
                        </div>
                    </div>

                    <div className='Company'>
                        <div className='Border'></div>
                        <div className='Desc'>
                            <span>Title</span>
                            <span>ceo & founder</span>
                        </div>
                    </div>

                    <div className='Company'>
                        <div className='Border'></div>
                        <div className='Desc'>
                            <span>location</span>
                            <p>Nairobi, Kenya</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='BodyCeo'>
                <h1>Profile Summary</h1>

                <p>
                Samuel Wanjiru is a highly educated and experienced individual 
                in the field of software development and statistics. He is the 
                founder and CEO of S&M Software, a company that is based in Nairobi. 
                Samuel has a Bachelor of Science degree in Mathematics and Computer Science, 
                which has given him a strong foundation in both programming and mathematical 
                concepts. He has also completed a Master's degree in Applied Statistics, 
                which has further enhanced his knowledge and skills in data analysis and interpretation.
                </p>
                <p> 
                Samuel's professional experience in the software industry has helped him to develop 
                expertise in various programming languages and software tools. He has a deep 
                understanding of software development methodologies, project management, and 
                quality assurance practices. Samuel has also worked on several data analysis 
                projects, which have involved designing and implementing statistical models 
                and conducting complex data analyses.
                </p>
                <p>
                S&M Softwares, Samuel's brainchild, is a renowned tech 
                firm that has developed innovative software solutions 
                such as LawfirmsHub and Kilimo Erevo. LawfirmsHub is a 
                top-of-the-line law firm management system that provides 
                cutting-edge solutions to the legal industry. It simplifies 
                case management, document handling, billing, and invoicing, 
                and helps law firms streamline their processes and enhance 
                their overall efficiency.
                </p>
                <p>
                Kilimo Erevo is another groundbreaking software developed 
                by S&M Softwares, which is designed to address the pressing 
                global issues of climate change and food security. 
                The software utilizes big data analytics to generate 
                actionable insights and provide policy recommendations 
                that can help governments and organizations make informed 
                decisions to mitigate climate change and ensure food security.
                </p>
                <p>
                As the CEO of S&M Software, Samuel brings his wealth of knowledge 
                and expertise to the company. He is committed to providing 
                high-quality software solutions to clients, which are tailored 
                to their specific needs. Samuel is a strong leader who motivates 
                and inspires his team to deliver exceptional results. With his 
                strong technical skills, business acumen, and leadership abilities, 
                Samuel is poised to take S&M Software to new heights of success.
                </p>
            </div>

            <div className='Landingfooter'>
            <span>Get In Touch with us today</span>
            <button><a href='/#'>Lets Talk</a></button>
            <small> &copy; {year} <a href='/#'>S&M Software Inc.</a> All rights reserved.</small>
            </div>


        </div>
    </div>
  )
}

export default Ceo
