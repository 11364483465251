import React, { useContext, useState } from 'react';
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from '@mui/icons-material/Menu';
import './Navigation.scss';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Navigation = () => {

  const { currentUser,logout } = useContext(AuthContext);
  const [firmname] =[currentUser.firmname || currentUser.fullname];
  const firmAccountName = firmname.slice(0, firmname.indexOf(" ", firmname.indexOf(" ") + 1));


  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [activeTab, setActiveTab] = useState('home');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const toggleSidebarMinimized = () => {
    setIsSidebarMinimized(!isSidebarMinimized);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  return (
    <div>

    <div className={`sidebarNavigation ${isSidebarOpen ? '' : 'closed'} ${isSidebarMinimized ? 'minimized' : ''}`}>

      <div className="toggle-button" onClick={toggleSidebarMinimized} style={{display:'flex',gap:'20px'}}>
        <MenuIcon />
        <div className="sidebar-header">
          <div className="logo" style={{fontWeight:'bold'}}>  </div>
        </div>
      </div>

      <div className="sidebar-content">

        <div className="sidebar-tabs">

        <div>

        <div className={`sidebar-tab ${activeTab === 'Dashboard' ? 'active' : ''}`} onClick={() => handleTabClick('Dashboard')}>
            <a href='/index'>
              <img alt=''/>
            </a>
          </div> 

          <div className={`sidebar-tab ${activeTab === 'Dashboard' ? 'active' : ''}`} onClick={() => handleTabClick('Dashboard')}>
            <a href='/index'>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </a>
          </div> 

        <div className={`sidebar-tab ${activeTab === 'HumanResource' ? 'active' : ''}`} onClick={() => handleTabClick('HumanResource')}>
          <a href='/Profile'>
          <AccountCircleIcon  className="icon"/>
          <span>My profile</span>
          </a>
        </div>
        </div>

          <div className="sidebar-tab">
              <span style={{display:'flex'}}>
              <ExitToAppIcon className="icon" />
              
              {currentUser.role === 'Normal User' && 
              <Link className='link' to='/'>
              {currentUser && currentUser.username ? (
                <span onClick={logout}>Logout</span>
              ) : (
                <Link className='link' to="/">
                Login
                </Link>
              )}
             
              </Link> 
              }

              {currentUser.role === 'Admin' && 
              <Link to='/' style={{ textDecoration: "none" }}>
              {currentUser && currentUser.id ? (
                <span onClick={logout}>Logout</span>
              ) : ( 
                  <Link className='link' to="/">  
                    Login
                  </Link>
                )}
                </Link>
                }


            </span>
          </div>

        </div>
      </div>
    </div>
    </div>
  );
};

export default Navigation;
