import Navbar from "../Navigation/Navbar";
import Navigation from "../Components/NavOfficial/Navigation";
import { MainPage } from "../Components/CaseRegistrations/MainPage";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import UserNavigation from "../NormalUser/UserNavigation/UserNavigation";



function NewCases() {  

  const { currentUser } = useContext(AuthContext);

  return (
    <>
    <div className="home">
    {currentUser.role === 'Admin' && (
      <Navigation />
      )}
    <div className="homeContainer">
    {currentUser.role === 'Admin' && (
      <Navbar />
    )}
    {currentUser.role === 'Normal User' && (
      <UserNavigation />
    )}
    <div>
      <MainPage  />
    </div> 
    
    </div>
    </div>
    </>
  );
}

export default NewCases;