import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAu0qmZsy9zSzn3ZbTiaxIsKisrFQG3UZw",
  authDomain: "lawfirmshubdocs.firebaseapp.com",
  projectId: "lawfirmshubdocs",
  storageBucket: "lawfirmshubdocs.appspot.com",
  messagingSenderId: "744450329470",
  appId: "1:744450329470:web:d2ff70d5fd1c6f2e3f412f",
  measurementId: "G-VGYKKTTE7Q"
};


const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);