import { React, useContext } from "react";
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Home from "./pages";
import InstructionsForm from "./pages/InstructionsForm";
import NewCases from "./pages/NewCases";
import ExistingCases from "./pages/ExistingCases";
// import Navbar from "./NavigationBars/NavBar";
import Navbar from "./Navigation/Navbar";
import Calender from "./Calender/CalenderSelf/Calender";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from "./users/User";
import { PageMain } from "./TrialReg/Main";
import { ViewTasks } from "./pages/ViewTasks";
import { IssueTask } from "./Task Pane/IssueTask";
import Schedule from "./PaymentSchedule/Schedule";
import { Auth } from "./pages/Auth";
import { Requisitions } from "./pages/Requisitions";
import { Staffmembers } from "./pages/Staffmembers";
import { StaffRegistration } from "./Staffmembers/StaffRegistration";
import { RequisitionForm } from "./PaymentSchedule/RequisitionForm";
import { Leave } from "./pages/Leave";
import { LeaveForm } from "./Staffmembers/LeaveForm";
import TaskHome from "./pages/TaskHome";
import { RequisitionsAdmin } from "./pages/RequisitionsAdmin";
import { LeaveApproval } from "./pages/LeaveApproval";
import { StaffTaskUpdate } from "./Task Pane/StaffTaskUpdate";
import { CauseList } from "./pages/CauseList";
import { TodayCauselist } from "./causelistStyle/TodayCauselist";
import { Help } from "./Help/Help";
import { LawFirms } from "./LawFirms/LawFirms";
import { LawAuth } from "./pages/LawAuth";
import UsageManuals from "./Help/UsageManuals";
import SingleRequisition from "./PaymentSchedule/SingleRequisition";
import SeeTask from "./SeeTask/SeeTask";
import './pages/home.scss'


import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from "react-router-dom";
import UpdateUser from "./Staffmembers/UpdateUser";
import Sidebar from "./Navigation/Sidebar";
import { AuthContext } from "./context/AuthContext";

const ProtectedRoute = ({ user, children }) => {
  const { currentUser,currentFirm } = useContext(AuthContext);
  if (!currentFirm.role) {
    return <Navigate to="/Auth" replace />;
  }

  return children;
};

const RequireAuth = ({ children }) => {
  const { currentUser,currentFirm } = useContext(AuthContext);

  return currentFirm.role || currentUser.role ? children : <Navigate to="/Auth" />;
  
};



const Layout = () =>{ 
 


  return(
    <>
      <ToastContainer position="top-center" />
      
     {/** <Navbar /> */}
   <div className="home">
   <Sidebar />
   
   <div className="homeContainer">
   <Navbar />
   <div>
   
   <Outlet />
   </div>
   </div>
   </div>


      
    </>
  )
};



const router = createBrowserRouter([
  {
    path: "/",
    element:<Auth />,
  },
  {
    path:'/Auth',
    element:<Auth />
  },
  {
    path:'/UsageManuals',
    element:<UsageManuals />
  },
  
 
  {
    path: "/",
    element: <Layout />,
    
    children:[
   
      {
        path:'/index',
        element: <ProtectedRoute><Home /></ProtectedRoute>
      },
    
      {
        path:'/ViewTasks',
        element:<ViewTasks />
      },
      {
        path:'/causelist',
        element:<CauseList />
      },
      {
        path:'/TodayCauselist',
        element:<TodayCauselist />
      },
     
      {
        path:'/InstructionsForm',
        element:<InstructionsForm />
      },
      {
        path:'/NewCases',
        element:<NewCases/>
      },
      {
        path:'/ExistingCases',
        element:<ExistingCases />
      },
      {
        path:'/Calender',
        element:<Calender />
      },
      {
        path:'/users/:id',
        element:<User />
      },
      
      {
        path:'/update/:id',
        element:<PageMain />
      },
      {
        path:'/requisition/:id',
        element:<SingleRequisition />
      },
      {
        path:'/taskview/:id',
        element:<SeeTask />
      },
      {
        path:'/Updaterequisition/:id',
        element:<RequisitionForm />
      },
     
      {
        path:'/IssueTask',
        element:<IssueTask />
      },
      {
        path:'/updateTask/:id',
        element:<IssueTask  /> 
      },
      {
        path:'/StaffTaskUpdate/:id',
        element:<StaffTaskUpdate /> 
      },

      {
        path:'/schedule',
        element:<Schedule />
      },
      {
        path:'/updateSchedule/:id',
        element:<Schedule />
      },
      {
        path:'/RequisitionsAdmin',
        element:<RequisitionsAdmin />
      },
      {
        path:'/Requisitions',
        element:<Requisitions />
      },
      {
        path:'/RequisitionForm',
        element:<RequisitionForm />
      },
      {
        path:'/Staffmembers',
        element:<Staffmembers />
      },
      {
        path:'/StaffRegistration',
        element:<StaffRegistration />
      },
      {
        path:'/updateUser/:id',
        element:<UpdateUser />
      },
      {
        path:'/Leave',
        element:<Leave />
      },
      {
        path:'/LeaveApproval',
        element:<LeaveApproval />
      },

      {
        path:'/UpdateLeave/:id',
        element:<LeaveForm /> 
      },
      {
        path:'/LeaveForm',
        element:<LeaveForm />
      },
      {
        path:'/TaskHome',
        element:<TaskHome />
      },
   
    ]
  },

  {
    path:'/Help',
    element:<Help />
  },
  {
    path:'/register_account',
    element:<LawFirms />
  }
 
]);



function App() {


 


  return (
    <>
    <div className="App">
   
      <RouterProvider router={router} />
    
      
    </div>
    </>
  );
}



export default App;
