import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import { StepOne } from './StepOne';
import './StylesHere/MainPage.scss'

export const MainPage = () => {
  const { currentUser } = useContext(AuthContext);

  const regdate = moment().format("YYYY-MM-DD hh:mm:ss");
  const regby = currentUser.fullname || currentUser.firmname;
  const firmEmail = currentUser.FirmEmail;
  const lawfirmaccount = currentUser.firmname;
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [clientInfo, setClientInfo] = useState({
    CourtRank: '',
    CourtStation: '',
    CourtDivision: '',
    PartyType: '',
    CaseType: '',
    ClientStatus: '',
    IdNumber: '',
    FullName: '',
    Gender: '',
    parent: '',
    parentminor: '',
    firstadmin: '',
    secondadmin: '',
    Insurance: '',
    Mobile1: '',
    Mobile2: '',
    ChaserName: '',
    residence: '',
    summary: '',
    fileref: '',
    status: '',
    defenceappearance: '',
    defencefirm: '',
    defenceemail: '',
    Location: '',
    Street: '',
    defendant: '',
    secondddefendant: '',
    purpose: '',
  });

  const { CourtStation, CaseType, ClientStatus, FullName, Gender, IdNumber, fileref, status } = clientInfo;
  const [steps, setSteps] = useState(1);
  const { id } = useParams();

  const handleDocumentChange = (documentName) => {
    const updatedSelectedDocuments = [...selectedDocuments];
    const index = updatedSelectedDocuments.indexOf(documentName);

    if (index > -1) {
      updatedSelectedDocuments.splice(index, 1);
    } else {
      updatedSelectedDocuments.push(documentName);
    }

    setSelectedDocuments(updatedSelectedDocuments);
  };

  const nextStep = () => {
    setSteps(steps + 1);
  };

  const previousStep = () => {
    setSteps(steps - 1);
  };

  const handleOnChange = (e) => {
    const {name, value} = e.target
    setClientInfo({...clientInfo,[name]:value})
  };

  const history = useNavigate();

  const handleSubmit = (e) => {
   

    const updatedClientInfo = {
      ...clientInfo,
      regdate: regdate,
      regby: regby,
      firmEmail:firmEmail,
      lawfirmaccount: lawfirmaccount,
      selectedDocuments: selectedDocuments.join(','),
    };

    if (!CourtStation) {
      toast.error("All values are required");
    } else {
      if (!id) {
        axios
          .post(process.env.REACT_APP_API_ADDRESS + "api/newcases", updatedClientInfo)
          .then(() => {
            setClientInfo({
              CourtRank: '',
              CourtStation: '',
              CourtDivision: '',
              PartyType: '',
              ClientStatus: '',
              CaseType: '',
              selectedDocuments: '',
              IdNumber: '',
              fullname: '',
              Gender: '',
              parent: '',
              parentminor: '',
              firstadmin: '',
              secondadmin: '',
              Insurance: '',
              residence: '',
              ChaserName: '',
              Mobile1: '',
              Mobile2: '',
              summary: '',
              fileref: '',
              status: '',
              defenceappearance: '',
              defencefirm: '',
              defenceemail: '',
              Location: '',
              Street: '',
              defendant: '',
              secondddefendant: '',
              regdate: '',
              regby: '',
              lawfirmaccount: '',
              firmEmail:'',
            });
          })
          .catch((err) => toast.error(err.response.data));
        toast.success("Case Details added successfully");
      } else {
        axios
          .put(process.env.REACT_APP_API_ADDRESS + `api/newcases/${id}`, updatedClientInfo)
          .then(() => {
            setClientInfo({
              CourtRank: '',
              CourtStation: '',
              CourtDivision: '',
              PartyType: '',
              ClientStatus: '',
              CaseType: '',
              selectedDocuments: '',
              IdNumber: '',
              fullname: '',
              Gender: '',
              parent: '',
              parentminor: '',
              firstadmin: '',
              secondadmin: '',
              Insurance: '',
              residence: '',
              ChaserName: '',
              Mobile1: '',
              Mobile2: '',
              summary: '',
              fileref: '',
              status: '',
              defenceappearance: '',
              defencefirm: '',
              defenceemail: '',
              Location: '',
              Street: '',
              defendant: '',
              secondddefendant: '',
              regdate: '',
              regby: '',
              lawfirmaccount: '',
              firmEmail:'',
            });
          })
          .catch((err) => toast.error(err.response.data));
        toast.success("Case Details updated successfully");
      }

      setTimeout(() => history('/index'), 700);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/newcases/${id}`);
        setClientInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  const multiStepForm = () => {
    switch (steps) {
      case 1:
        return (
          <StepOne
            clientInfo={clientInfo}
            handleOnChange={handleOnChange}
            handleDocumentChange = {handleDocumentChange}
            selectedDocuments = {selectedDocuments}
            nextStep={nextStep} 
            id={id}
          />
        );
      case 2:
        return (
          <StepTwo
            clientInfo={clientInfo}
            nextStep={nextStep}
            previousStep={previousStep}
            handleOnChange={handleOnChange}
            id={id}
          />
        );
      case 3:
        return (
          <StepThree
            clientInfo={clientInfo}
            previousStep={previousStep}
            nextStep={nextStep}
            handleOnChange={handleOnChange}
            id={id}
          />
        );
      case 4:
        return (
          <StepFour
            clientInfo={clientInfo}
            previousStep={previousStep}
            nextStep={nextStep}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            id={id}
          />
        );
      default:
        return null;
    }
  };

  return (

        <div className='MainPage'>{multiStepForm()}</div>
  
  );
};
