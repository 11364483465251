import React, { useContext, useEffect, useRef, useState } from 'react'
import {useReactToPrint} from 'react-to-print'
import '../causelistStyle/causelist.css'
import '../ExistingCaseTables/TableClient1.css'
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Navbar from '../Navigation/Navbar';
import Sidebar from '../Navigation/Sidebar';
import { AuthContext } from '../context/AuthContext';
import Navigation from '../Components/NavOfficial/Navigation';



export const CauseList = () => {

    const Monday  = moment().add(-1,'days').format('dddd');
    const Tuesday  = moment().add(0,'days').format('dddd');
    const Wednesday  = moment().add(1,'days').format('dddd');
    const Thursday  = moment().add(2,'days').format('dddd');
    const Friday  = moment().add(3,'days').format('dddd');

    const TheDate  = moment().add(1,'days').format('dddd, Do MMMM YYYY');
    const { currentUser,currentFirm } = useContext(AuthContext);


    const componentRef = useRef();
    
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Cause List'
    });

  
    const [Activity,setActivity] = useState([]);
    const loadData = async () => {
        const response = await axios.get(process.env.REACT_APP_API_ADDRESS + "api/EventsFile/Day3");
        setActivity(response.data);
      }; 
    
      useEffect(() =>{
        loadData();
      }, []);



  return (
        <>
        <div className="home">
        <Navigation />
    
        <div className="homeContainer">
        <Navbar />
        <div style={{display:'flex',flexDirection:'row',gap:'1rem',marginBottom:'10px',justifyContent:'center',marginTop:'20PX'}}>
        
        <TableContainer component={Paper} >
        <Table sx={{ minWidth: 400 }} aria-label="custom pagination table" className='table'>
        <TableHead className='head'>
        <TableRow className='rows' style={{display:'flex',justifyContent:'center'}}>

          {/* Monday */}
        <TableCell className="tableCell rows">
        <Link to={'/Monday'}>
            <button className='causelistButton'>
                {Monday}
            </button>
        </Link>
        </TableCell>

        {/* Tuesday */}
        <TableCell className="tableCell rows">
            <Link to={'/TodayCauselist'}>
                <button type="button" className='causelistButton'>
                    {Tuesday}
                </button>
            </Link>
        </TableCell>
        
          {/* Wednesday */}
        <TableCell className="tableCell rows">
            <button type="button" className='causelistButton'  onClick={handlePrint}>
                {Wednesday}
            </button>
        </TableCell>

          {/* Thursday */}
        <TableCell className="tableCell rows">
            <Link to={'/Thursday'}>
                <button className='causelistButton'>
                    {Thursday}
                </button>
            </Link>
        </TableCell>

          {/* Friday */}
        <TableCell className="tableCell rows">
            <Link to={'/Friday'}>
                <button className='causelistButton'>
                    {Friday}
                </button>
            </Link>
        </TableCell>

        </TableRow>
        </TableHead>

        </Table>
        </TableContainer>


        </div>
        <div ref={componentRef} className="card">
        <div className='causelist'>
            <div>
                <h4>Magistrate Court Commercial</h4>
                <h5>Cause List</h5>
                <p>..................</p>
                <h5>Matters on {TheDate}</h5>
            </div>
            <div style={{paddingLeft:'50px',paddingRight:'50px'}}>

            <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
              <TableHead className='head'>
                <TableRow className='rows'>
                        <TableCell className="tableCell rows">#</TableCell>
                        <TableCell className="tableCell rows">File Reference</TableCell>
                        <TableCell className="tableCell rows">Activity</TableCell>
                        <TableCell className="tableCell rows">Actioned To</TableCell>
                        <TableCell className="tableCell rows">Citation</TableCell>
                    </TableRow>
                    </TableHead>
                    
                     
                   
                    <TableBody>
                    {Activity.length === 0 ? (
                        <TableRow className='rows' >
                            <td colSpan={4}>
                                <span style={{textAlign:'right'}}>No matters available in tomorrow's Causelist</span>
                            </td>
                        </TableRow>
                    ): (
                        Activity.map((val,index) =>{
                            return(   
                                <TableRow key={val.id} className='rows'> 
                                {currentFirm.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{index + 1}</TableCell>
                                }
                                {currentUser.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{index + 1}</TableCell>
                                }
                                {currentFirm.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.fileid}</TableCell>
                                }
                                {currentUser.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.fileid}</TableCell>
                                }
                                {currentFirm.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.eventFile}</TableCell>
                                }
                                {currentUser.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.eventFile}</TableCell>
                                }
    
                                {currentFirm.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.actionTo}</TableCell>
                                }
                                {currentUser.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.actionTo}</TableCell>
                                }
    
                                {currentFirm.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.clientname} vs {val.Defendant}</TableCell>   
                                }
                                {currentUser.firmname === val.lawfirmaccount &&
                                    <TableCell className="tableCell rows">{val.clientname} vs {val.Defendant}</TableCell>   
                                }
    
                                    
                                </TableRow>
                                
                            )
                        })
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
    
        </div>
        </div>
        </div>
        </div>
        
        
        </>
  )
}
