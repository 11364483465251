import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import '../ExistingCaseTables/TableClient1.css'
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Axios from 'axios';
import moment from 'moment';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import '../Styles/Search.css'

import Paper from '@mui/material/Paper';


import '../ExistingCaseTables/table.scss'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




function InstructionsForm() {

  const { currentUser,currentFirm } = useContext(AuthContext);
  const [scheduleData,setScheduleData] = useState([]);
  const [query, setQuery] = useState("");
  
  useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/schedules?q=${query}`);
      setScheduleData(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scheduleData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  return (
    <>

    <div> 
    <div className="home">
    <Sidebar />
    
    <div className="homeContainer">
      <Navbar />
    {currentFirm.role === 'Admin' &&
    <div style={{marginLeft:'20px',marginRight:'20px',marginTop:'20px'}}>
      <p style={{backgroundColor:'#dff0d8',color:'#241842',fontWeight:'400', width:'fit-content'}}>
      <b>Payment Schedule:</b> This page is only to be used purely for file payment follow ups. 
      If an advocate or represenative of 
      Waiganjo Wachira Advocate goes into a an agreement with the defendants <b>eg.</b> 
      one whose motor vehicle has been attached 
      by an auctioneer, then the terms and period of 
      payment should be clearly registered in the table below. 
      This will go along way into ensuring that matters are followed 
      up in a timely fashion if and when the agreement is not
      met.
      <strong>Clearly indicate the file number, decretal sum, breakdown descrition of the payment schedule, date of 
      agreement and the official date of payment commencement.</strong>
      </p>
    </div> }

    <div style={{marginLeft:'20px',marginRight:'20px',marginTop:'20px'}}>
      <input 
      className='serachInput'
      type='search'
      placeholder='Search...'
      onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />

      {currentFirm.role === 'Admin' && <Link to={'/schedule'} className='Link'>
        <button className='buttonlink'>Record Agreement</button>
      </Link> }

    </div>
    <div style={{marginLeft:'20px',marginRight:'20px',marginTop:'20px'}}>
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
      <TableHead className='head'>
        <TableRow className='rows'>
                <TableCell className="tableCell rows">#</TableCell>
                <TableCell className="tableCell rows">File Ref</TableCell>
                <TableCell className="tableCell rows">Decretal Sum</TableCell>
                <TableCell className="tableCell rows">Payment Terms</TableCell>
                <TableCell className="tableCell rows">Commencement Date</TableCell>
                <TableCell className="tableCell rows">Age</TableCell>
                <TableCell className="tableCell rows">Status</TableCell>
                {currentFirm.role === 'Admin' &&    <TableCell className="tableCell rows">Action</TableCell> } 
                </TableRow>
                </TableHead>
                <TableBody>
                {(rowsPerPage > 0
                  ? scheduleData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : scheduleData
                ).map((val,index) =>{
                  return(
                    <TableRow key={val.id} className='rows'> 
                      <TableCell className="tableCell rows">{index + 1}</TableCell>
                      <TableCell className="tableCell rows">{val.filereference}</TableCell>
                      <TableCell className="tableCell rows">{val.decretal}</TableCell>
                      <TableCell className="tableCell rows">{val.terms}</TableCell>
                      <TableCell className="tableCell rows">{moment(val.agreementDate).format('Do-MMMM-YY')}</TableCell>
                      <TableCell className="tableCell rows">{moment(val.agreementDate).fromNow()}</TableCell>
                      <TableCell className="tableCell rows">
                      <span className={`status2 ${val.scheduleStatus}`}>{val.scheduleStatus}</span>
                      </TableCell>

                    {currentFirm.role === 'Admin' &&   <TableCell className="tableCell rows">
                        <Link to={`/updateSchedule/${val.id}`}><EditIcon /></Link>
                      </TableCell>
                    }
                    </TableRow>
                  )
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}


                </TableBody>

                <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={4}
                    count={scheduleData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
              </Table>
            </TableContainer>
    
    </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default InstructionsForm