import React, { useEffect, useState } from 'react'
import './IssueTask.css'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import  Axios  from 'axios';
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';

export const IssueTask = () => {

  const { currentUser,currentFirm } = useContext(AuthContext);

  const [taskInfo,setTaskInfo] = useState({
    name:'',
    taskname:'',
    description:'',
    dateissued:'',
    status:'', 
    sender:''
  })

  const {status} = taskInfo;

  const handleOnChange = (e) =>{
    const {name, value} = e.target
    setTaskInfo({...taskInfo,[name]:value})
  }

  const history = useNavigate();
  const {id} = useParams();

  const handleSubmit = (e) =>{

    if(!status ){
      toast.error("All values are required")
    }else{
      if(!id){
        axios.post(process.env.REACT_APP_API_ADDRESS + "api/tasks", 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",dateissued:"",status:"",sender:''})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task added successfully")
      }else{
        axios.put(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`, 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",dateissued:"",status:"",sender:""})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task updated successfully")
      }
      
      setTimeout(() => history('/index'),700); 
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`);
        setTaskInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  


  const [data, setData] = useState([]);

  const loadData = async () => {
    const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
    setData(response.data);
  }; 

  useEffect(() =>{
    loadData();
  }, []); 


  return (
    <>

    <div className="home">
    <Sidebar />

    <div className="homeContainer">
    <Navbar />
    
    <div className='main'>
    <div className='file-card'>

    
    {currentFirm.role === 'Admin' && 
    <div className='mb-3' style={{marginTop:'10px'}}>
    <label htmlFor='name'> Employee Name </label>
    
    <select
    className='form-control' 
    id="name"
    name="name"
    required
    value={taskInfo.name || " "}
    onChange={handleOnChange}
  >
    <option>select...</option>
    
    {data.map((val) =>{
      return( 
          <option>{val.fullname}</option>
      )
    })}
    
  </select>
  </div>
    }

{currentFirm.role === 'Admin' && 
<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='taskname'> Task Name </label>
<select
className='form-control' 
id="taskname"
name="taskname"
required
value={taskInfo.taskname || " "}
onChange={handleOnChange}
>
<option>select...</option>
<option>Submissions</option>
<option>Application</option>
<option>Plaint</option>
<option>Replying Affidavit</option>
<option>Ad Litem</option>
</select>
</div>
  }
{currentFirm.role === 'Admin' && 
<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='description'> Task Description </label>
<textarea
rows={5}
className='form-control' 
id="description"
name="description"
required
value={taskInfo.description || " "}
onChange={handleOnChange}
/>
</div>
}

{currentFirm.role === 'Admin' && 
<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='dateissued'> Date issued </label>
<input
type='datetime-local'
className='form-control' 
id="dateissued"
name="dateissued"

required
value={taskInfo.dateissued || " "}
onChange={handleOnChange}
/>
</div>
}

<div className='mb-2' style={{marginTop:'10px'}}>
<label htmlFor='status'> Status </label>
<select
className='form-control' 
id="status"
name="status"
required
value={taskInfo.status || " "}
onChange={handleOnChange}
>
<option>select...</option>
{currentFirm.role === 'Admin' &&  <option>Pending</option> }
<option>Completed</option>
{currentFirm.role === 'Admin' &&  <option>Recalled</option> }
</select>
</div>

{currentFirm.role === 'Admin' && 
<div className='mb-5'  style={{marginTop:'10px'}}>
<label htmlFor='sender'> Task Sender </label>
<select
className='form-control' 
id="sender"
name="sender"
required
value={taskInfo.sender || " "}
onChange={handleOnChange}
>
<option>select...</option>
<option>Patrick Waiganjo</option>
<option>James Wachira</option>
</select>
</div>
}
 
<br />

<div className='float-center'>
      <input type="submit" onClick={() => handleSubmit()} className="btn btn-primary btn-block" value={id ? "Update Task" : "Submit Task"} />
</div>

  </div>
  </div>
  </div>
  </div>
    </>
  )
}
