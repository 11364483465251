import React from 'react'
import './StylesHere/AnimatedText.scss'
import './StylesHere/StepFour.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StepFour = ({clientInfo,previousStep,handleOnChange,id,handleSubmit}) => {
  return (
    <> 

    <div className="animated-text" style={{
        display:'flex',
        flexDirection:'row',
        gap:'50px',
        alignItems:'center',
        marginBottom:'20px'
      }}> 
      <h2 className='circle'> Step 4 </h2>
    </div>
  
  <div className='StepFour'>
  
  <div className='CourtRank'>
  
  <div className='CourtRankselect'>
  <label htmlFor='defendant'> 1st Defendant name  </label>
  <input 
  type='text' 
  name='defendant' 
  id='defendant' 
  value={clientInfo.defendant || ""} 
  placeholder='John Doe And Elon Face'
  onChange={handleOnChange}
  />
  </div>
  
  <div className='CourtRankselect'>
  <label htmlFor='2nddefendant'> 2nd Defendant name (Optional) </label>
  <input 
  type='text' 
  name='secondddefendant' 
  id='secondddefendant' 
  value={clientInfo.secondddefendant || ""} 
  placeholder='John Doe And Elon Face'
  onChange={handleOnChange}
  />
  </div>
  </div>
  
  <div className='CourtRank'>
  <div className='CourtRankselect'>
  <label htmlFor='defenceappearance'> Defence Appearance </label>
  <select
  name='defenceappearance' 
  id='defenceappearance' 
  value={clientInfo.defenceappearance || ""}
  onChange={handleOnChange}
  >
  <option>Select...</option>
  <option>None</option>
  <option>Yes </option>
  </select>
  </div>
  
  {clientInfo.defenceappearance === "Yes" && 
  <div className='CourtRankselect'>
  <label htmlFor='defencefirm'> Defence Firm Name  </label>
  <input 
  type='text' 
  name='defencefirm' 
  id='defencefirm' 
  value={clientInfo.defencefirm || ""} 
  placeholder='Enter Defence Firm Name'
  onChange={handleOnChange}
  />
  </div>
  }
  </div>
  
  <div className='CourtRank'>
  {clientInfo.defenceappearance === "Yes" && 
  <div className='CourtRankselect'>
  <label htmlFor='Location'> Defence Firm Location  </label>
  
  <input 
  type='text' 
  name='Location' 
  id='Location' 
  value={clientInfo.Location || ""} 
  placeholder='A Towers, 15th floor, west wing'
  onChange={handleOnChange}
  />
  </div>
  }
  
  {clientInfo.defenceappearance === "Yes" &&  
  <div className='CourtRankselect'>
  <label htmlFor='Street'> Defence Firm Street  </label>
  
  <input 
  type='text' 
  name='Street'  
  id='Street' 
  value={clientInfo.Street || ""} 
  placeholder='Monrovia street'
  onChange={handleOnChange}
  />
  </div>
  }
  </div>
  
  <div className='CourtRank'>
    {clientInfo.defenceappearance === "Yes" && 
    <div className='CourtRankselect'>
    <label htmlFor='defenceemail'> Defence Firm Email  </label>
    
    <input 
    type='text' 
    name='defenceemail' 
    id='defenceemail' 
    value={clientInfo.defenceemail || ""} 
    placeholder='info@wwlaw.com'
    onChange={handleOnChange}
    />
    </div>
    }
    
    <div className='CourtRankselect'>
    <label htmlFor='caseNumber'> Case Number </label>
    
    <input 
    type='text' 
    name='caseNumber' 
    id='caseNumber' 
    value={clientInfo.caseNumber || ""} 
    placeholder='E001 or 001'
    onChange={handleOnChange}
    />
    </div>
  </div>
  
  <div className='CourtRank'>
    <div className='CourtRankselect'>
    <label htmlFor='caseYear'> Case Year </label>
    <select
    name='caseYear' 
    id='caseYear' 
    value={clientInfo.caseYear || ""} 
    onChange={handleOnChange}
    >
    <option>Select...</option>
    <option>2024</option>
    <option>2023</option>
    <option>2022</option>
    <option>2021</option>
    <option>2020</option>
    <option>2019</option>
    <option>2018</option>
    <option>2017</option> 
    <option>2016</option>
    <option>2015</option>
    <option>2014</option>
    <option>2013</option>
    <option>2012</option>
    <option>2011</option>
    <option>2010</option>
    </select>
    </div>
    
    <div className='CourtRankselect'>
    <label htmlFor='status'> Status </label>
    <select 
    name='status' 
    id='status' 
    value={clientInfo.status || ""}
    onChange={handleOnChange}
    >
    <option>Select...</option>
    <option>Active</option>
    <option> Closed </option>
    <option> Archived </option>
    </select>
    </div>
  </div>
  
  </div>
    <div className='float-start'>
      <button 
      className='bg-green-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-green-500 duration-500'
      onClick={() => previousStep()}
      ><ArrowBackIcon /></button>
    </div>
   
   <div className='float-end'>
   <br />
   <input 
    type="submit" 
    onClick={() => handleSubmit()} 
    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
    value={id ? "Update Case details" : "Submit"} 
   />
   </div>
     </>
  )
}
 
export default StepFour