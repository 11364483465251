import React, { useState } from 'react'
import './LandingPage.scss'
import LOGO from './ASSETS/LFH.svg'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import './DropdownMenu.css'
import LegalCompass from './ASSETS/LegalCompass.png'
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';




function NavigationOne() {

  return (
    <div className='landingHome'>

    {/* 
    <div> 
    <Box sx={{ width: '100%' }}>
    <Collapse in={open}>
      <Alert
      variant="filled" 
      severity="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
      Hello there! Site is under progressive development to enhance user experience. Exciting changes coming!
      </Alert>
    </Collapse>
   
  </Box>
  </div>
    */}

    <div className='Contacts'>

    <div className='PartA'>
      <span><CallIcon /> +254 742548359/+254 111356555</span>
      <span><EmailIcon /> info@lawfirmshub.com</span>
      <span><LocationOnIcon /> Nairobi, Kenya</span>
    </div>

    <div className='PartB'>
      <span className='item'><TwitterIcon /></span>
      <span className='item'><FacebookOutlinedIcon /></span>
      <span className='item'><InstagramIcon /></span>
      <span className='item'><YouTubeIcon /></span>
    </div> 

    </div>

    <div className='navA'>
        <span><a href='/#'>Clients</a></span>
        <span><a href='/Docs'>Docs</a></span>
        <span><a href='/Help'>Support</a></span>
        <span><a href='/#'>Careers</a></span>
        <span><a href='/#'>Reports</a></span>
        <span><button><a href='/Bot'>CounselGenie</a></button></span>
        <span><a href='/Auth'>Log In</a></span>
       
        

    </div> 

    <div className='navB'> 
        <div className='CompanyName'>
            <img src={LOGO} alt='' />
        </div>
        <div className='Bnavigation'>
            <span><a href='/#'>Why Choose Us</a></span>
            
            <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <span {...bindTrigger(popupState)} className='dropbutton'>
                  Features <i className="fa fa-caret-down"></i>
                </span>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography sx={{ p: 2 }}></Typography>
                </Popover>
              </div>
            )}
          </PopupState>
            <span><a href='/#'>Categories</a></span>
            <span><a href='/pricing'>Pricing</a></span> 
            {/** 
            <div>
              <div className="dropdown">

                <span className='dropbutton'>
                  Community <i className="fa fa-caret-down"></i>
                </span>

               <div className="dropdown-content">

                <div className='Community'>
                  <div className='CommunityA'>
                    <div>
                      <h5>Try CounselGenie</h5>
                      <p>An AI providing legal counsel to you.</p>
                      <button style={{
                        backgroundColor:'white',
                        color:'black',
                        padding:'10px 20px',
                        borderRadius:'10px',
                        fontWeight:'500'
                      }}>Ask It Today</button>
                    </div>
                    <div>
                      <img style={{
                        width:'150px',
                        height:'130px',
                        border:'1px solid transparent',
                        borderRadius:'5px'
                      }} src={LegalCompass} alt=''/>
                    </div>
                    
                  </div>

                  <div className='CommunityB'>
                    <h6>COMMUNITY</h6>
                    <p><a href='/#'>Overview</a></p>
                    <p><a href='/#'>Q&A</a></p>
                    <p><a href='/#'>Customer Community</a></p>
                  </div>

                  <div className='CommunityC'>
                  <h6 style={{
                    color:'grey'
                  }}>ENGAGE WITH US</h6>
                    
                      <p><a href='/#'>Find a Lawyer</a></p>
                      <p><a href='/#'>Newsletter</a></p>
                      <p><a href='/#'>Events</a></p>
                      <button className='ReportButton'><a href='/#'>Report negligence</a></button>
                       
                  </div>

                </div>
               
               </div>

              </div>
             
            
            </div>
                  */}
                  
            <span><button><a href='/LFHCare'>LFHCare</a></button></span>
            <span>
                <button> 
                <a href='/register_account'>Sign Up</a>
                </button>
            </span>
            
        </div>
    </div>


    </div>
  )
}

export default NavigationOne