import React from 'react'
import NavigationOne from '../NavigationOne'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  return (
    <div>
        <NavigationOne />

        <div className='Policy'>
            <div className='headerPolicy'>
                <h1>Legal & Compliance Center</h1>
                <span>Our convenient one-stop shop for all things legal, compliance, and trust.</span>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy