import React from 'react'
import UserNavigation from '../UserNavigation/UserNavigation'
import './UserView.scss'
import { UserSummary } from '../UserWidgets/UserSummary'
import UserCaseView from '../UserCaseView/UserCaseView'
import TripsSummary from './TripsSummary'

const UserView = () => {
  return (
    <div>
        <UserNavigation />

        <div className='UserView'>
            <UserSummary />
            <UserCaseView />
        </div>
    </div>
  )
}

export default UserView