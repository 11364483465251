import React from 'react'
import NavigationOne from '../LandingPage/NavigationOne'
import './Usage.scss'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCardIcon from '@mui/icons-material/AddCard';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PolicyIcon from '@mui/icons-material/Policy';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const UsageManuals = () => {
  return (
    <>
      <div>

      <div style={{marginBottom:'20px'}}>
        <NavigationOne />
      </div>
        

        <div className='Usage'>
          <div className='Introduction'>
            <span> Docs Home </span>
            <h1> Welcome to Law Firms Hub Docs </h1>
            <div className='buttons'>
            <button>Getting Started with Law Firms Hub</button>
            <button>Quick Answer Guides</button>
            </div>
          </div>

          <div className='GuideDetails'>
            <h3>Product Guides</h3>

            <div className='Products'> 
              <span><a href='#/'> <ManageAccountsIcon className='icon'/> Accounts</a></span>
              <span><a href='/pricing'> <AddCardIcon className='icon'/> Billing</a></span>
              <span><a href='#/'> <RocketLaunchIcon className='icon'/> Get Started</a></span>
              <span><a href='#/'> <PolicyIcon className='icon'/> Policy</a></span>
              <span><a href='#/'> <CloudSyncIcon className='icon'/> Backups</a></span>
              <span><a href='#/'> <CloudDoneIcon className='icon'/> Storage</a></span>
              <span><a href='#/'> <VerifiedUserIcon className='icon'/> Security</a></span>
            </div>


          </div>
         
        </div>
      </div>
    </>
  )
}

export default UsageManuals