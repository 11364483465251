import React from 'react'
import './StylesHere/StepOne.scss'
import './StylesHere/AnimatedText.scss'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const StepOne = ({clientInfo,selectedDocuments,nextStep,handleOnChange,handleDocumentChange,id}) => {

  return ( 
      <>  
      
     <div className='MainStations'>

      <div 
        className="animated-text" 
        style={{
            display:'flex',
            flexDirection:'row',
            gap:'50px',
            alignItems:'center',
            marginBottom:'20px'
        }}
      > 
      <h2 className='circle'> Step 1 </h2>
      </div>
      
      <div className='CourtRank'>
        <div className='CourtRankselect'>
            <label htmlFor='CourtRank'> Select Court Rank </label>
            <select
                id="CourtRank"
                name="CourtRank"
                value={clientInfo.CourtRank || ""}
                onChange={handleOnChange}
            >
                <option value=''>Select rank...</option>
                <option value="High court">High court</option>
                <option value='Magistrate court'>Magistrate court</option>
                <option value='Small claims court'>Small claims court</option>
            </select>
        </div>

        <div  className='CourtRankselect'>
        <label htmlFor='CourtStation'>Court Station</label>
        <select
        id="CourtStation"
        name="CourtStation"
        value={clientInfo.CourtStation || ""}
        onChange={handleOnChange}
        
    >
        <option>Select...</option>
        <option>MILIMANI Commercial Magistrate Court</option>
        <option>MILIMANI Magistrate Court</option>
        <option>Mavoko Magistrate Court</option>
        <option>Kajiado Magistrate Court</option>
        <option>RUIRU Magistrate Court</option>
        <option>Kilungu Magistrate Court</option>
        <option>Ngong Magistrate Court</option>
        <option>Murang'a Magistrate Court</option>
        <option>KANGEMA Magistrate Court</option>
        <option>Kikuyu Magistrate Court</option>
        <option>ENGINEER Magistrate Court</option>
        <option>THIKA Magistrate Court</option>
        <option>NAROK Magistrate Court</option>
        <option>OTHAYA Magistrate Court</option>
        <option>WANGURU Magistrate Court</option>
        <option>KERUGOYA Magistrate Court</option>
        <option>BARICHO Magistrate Court</option>
        <option>TAWA Magistrate Court</option>
        <option>MACHAKOS Magistrate Court</option>
        <option>Nyeri Magistrate Court</option>
        <option>Kitui Magistrate Court</option>
        <option>NYAHURURU Magistrate Court</option>
        <option>NANYUKI Magistrate Court</option>
        <option>LIMURU Magistrate Court</option>
        <option>EMBU Magistrate Court</option>
        <option>MWINGI Magistrate Court</option>
        <option>GATUNDU Magistrate Court</option>
        <option>KARATINA Magistrate Court</option>
        <option>Mutomo Magistrate Court</option>
        <option>Naivasha Magistrate Court</option>
        <option>Kiambu Magistrate Court</option>
        <option>Githunguri Magistrate Court</option>
        <option>Kandara Magistrate Court</option>
        <option>Kenol Magistrate Court</option>
        <option>Molo Magistrate Court</option>
        <option>Nakuru Magistrate Court</option>
        <option>Kithimani Magistrate Court</option>
        <option>Kyuso Magistrate Court</option>
        <option>MAKUENI Magistrate Court</option>
        <option>MAKINDU Magistrate Court</option>
        <option>KANGUNDO Magistrate Court</option>
        <option>WUNDANYI Magistrate Court</option>
        <option>MASENO Magistrate Court</option>
        <option>MANDERA Magistrate Court</option>
        <option>KALOLENI Magistrate Court</option>
        <option>MOMBASA Magistrate Court</option>
        <option>VIHIGA Magistrate Court</option>
    </select>
        </div>

      </div>
     


    {clientInfo.CourtRank === "Magistrate court" && (
    <div className='CourtRank'>

      <div className='CourtRankselect'>
        <label htmlFor='CourtDivision'> Court Division </label>
        <select
            name='CourtDivision' 
            id='CourtDivision' 
            value={clientInfo.CourtDivision || ""}
            onChange={handleOnChange}
        >
            <option>Select...</option>
            <option>MAGISTRATE COURT COMMERCIAL</option>
            <option>Commercial Suit Division</option>
            <option>Magistrate Court Family</option>
        </select>
    </div>

      <div className='CourtRankselect'>
        <label htmlFor='PartyType'>Your Party</label>
        <select
        name='PartyType' 
        id='PartyType' 
        value={clientInfo.PartyType || ""}
        onChange={handleOnChange}
        >
            <option>Select...</option>
            <option>Plaintiff</option>
            <option>Claimant</option>
            <option>Responsent</option>
            <option>Defendant</option>
        </select>
    </div>


    </div>
    )}

    <div className='CourtRank'>

    {clientInfo.PartType !== '' && (
    <div className='CourtRankselect'>
        <label htmlFor='ClientStatus'> Client Status </label>
        <select
        className='form-control' 
        name='ClientStatus' 
        id='ClientStatus' 
        value={clientInfo.ClientStatus} 
        onChange={handleOnChange}
        >
        <option>Select...</option>
        <option>Minor</option>
        <option>Adult</option>
        <option>Deceased</option>
        </select>
      
    </div>
    )}

    {clientInfo.ClientStatus !== "" && (
      <div className='CourtRankselect'>
        <label htmlFor='CaseType'> Select Case Type </label>
        <select
            className='form-control' 
            name='CaseType' 
            id='CaseType' 
            value={clientInfo.CaseType || ""}
            onChange={handleOnChange}
        >
            <option>Select...</option>
            <option>Personal Injury (Running Down)</option>
            <option>Personal Injury (Work Injury)</option>
            <option>Environment and Land Cases</option>
            <option>Employment and Labour Cases</option>
            <option>Election Petitions</option>
            <option>Defamation (Libel / Slander)</option>
            <option>Medical Negligence</option>
            <option>Debt Collection Cases</option>
            <option>Divorce and Separation</option>
            <option>Succession or Inheritance Cases</option>
        </select>   
      </div>
    )}
  
  </div>

  {clientInfo.CaseType !== "" && 
  <div className='DocumentsProvided'>
 

  <div className='ListingHeader'>
   <h6>Tick the necessary documents</h6>
  </div> 

   
   <div className='DocumentsTick'>
   {clientInfo.CaseType === 'Personal Injury (Running Down)' &&
    <div className='TickDoc'>
        <input
            className="form-check-input"
            id="policeabstract"
            name="policeabstract"
            type="checkbox"
            value="policeabstract"
            checked={selectedDocuments.includes('policeabstract') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('policeabstract'))}
            onChange={() => handleDocumentChange('policeabstract')}
        />
        <label htmlFor="policeabstract">Police Abstract</label>
    </div>
  }

  {clientInfo.CaseType === 'Personal Injury (Running Down)' &&
    <div >
        {clientInfo.ClientStatus !== 'Deceased' &&
            <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="medicalsummary"
            name="medicalsummary"
            value="medicalsummary"
            checked={selectedDocuments?.includes('medicalsummary') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('medicalsummary'))}
            onChange={() => handleDocumentChange('medicalsummary')}
            />
            <label htmlFor='medicalsummary'> Copy of Medical Summary </label>
            </div>
        }
    </div>
  }

  {clientInfo.CaseType === 'Personal Injury (Running Down)' &&
        <div>
        {clientInfo.ClientStatus !== 'Deceased' &&
            <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="xray"
            name="xray"
            value="xray"
            checked={selectedDocuments?.includes('xray') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('xray'))}
            onChange={() => handleDocumentChange('xray')}
            />
            <label htmlFor='xray'> Xrays </label>
            </div>
        }
        </div>
 
  }

{clientInfo.CaseType === 'Personal Injury (Running Down)' &&
    
        <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="receipts"
            name="receipts"
            value="receipts"
            checked={selectedDocuments?.includes('receipts') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('receipts'))}
            onChange={() => handleDocumentChange('receipts')}
            />
            <label htmlFor='receipts' > Copies of Receipts </label>
        </div>
    
        }
  
            <div className='TickDoc'>
                <input
                className="form-check-input"
                type="checkbox"
                id="idcopy"
                name="idcopy"
                value="idcopy"
                checked={selectedDocuments?.includes('idcopy') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('idcopy'))}
                onChange={() => handleDocumentChange('idcopy')}
                />
                <label htmlFor='idcopy' > Copy(s) of National Identity Card </label>
            </div>
    

{clientInfo.CaseType === 'Personal Injury (Running Down)' &&
    <div>
        {clientInfo.ClientStatus !== 'Deceased' &&
            <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="p3"
            name="p3"
            value="Verified P3"
            checked={selectedDocuments?.includes('p3') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('p3'))}
            onChange={() => handleDocumentChange('p3')}
            />
            <label htmlFor='p3'>Copy of p3</label>
        </div>
        }
    </div>
}

{clientInfo.ClientStatus === 'Deceased' &&
    
        <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="burialpermit"
            name="burialpermit"
            value="burialpermit"
            checked={selectedDocuments?.includes('burialpermit') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('burialpermit'))}
            onChange={() => handleDocumentChange('burialpermit')}
            />
            <label htmlFor='burialpermit' > Burial Permit </label>
        </div>
 
}

    {clientInfo.ClientStatus === 'Deceased' &&
      
            <div className='TickDoc'>
                <input
                className="form-check-input"
                type="checkbox"
                id="deathCert"
                name="deathCert"
                value="deathCert"
                checked={selectedDocuments?.includes('deathCert') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('deathCert'))}
                onChange={() => handleDocumentChange('deathCert')}
                />
                <label htmlFor='deathCert' > Death Certificate </label>
            </div>
        
    }

    {clientInfo.ClientStatus === 'Deceased' &&(
       
            <div className='TickDoc'>
                <input
                className="form-check-input"
                type="checkbox"
                id="chiefsletter"
                name="chiefsletter"
                value="chiefsletter"
                checked={selectedDocuments?.includes('chiefsletter') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('chiefsletter'))}
                onChange={() => handleDocumentChange('chiefsletter')}
                />
                <label htmlFor='chiefsletter' > Chief's letter </label>
            </div>
        
    )}

{clientInfo.CaseType === 'Divorce' &&
   
        <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="marriagecert"
            name="marriagecert"
            value="marriagecert"
            checked={selectedDocuments?.includes('marriagecert') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('marriagecert'))}
            onChange={() => handleDocumentChange('marriagecert')}
            />
            <label htmlFor='marriagecert' > Marriage certificate </label>
        </div>

}

{clientInfo.ClientStatus === 'Minor' &&
    
        <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="birthcertificate" 
            name="birthcertificate"
            value="birthcertificate"
            checked={selectedDocuments?.includes('birthcertificate') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('birthcertificate'))}
            onChange={() => handleDocumentChange('birthcertificate')}
            />
            <label htmlFor='birthcertificate'> Birth Certificate </label>
        </div>
    
}


{clientInfo.CaseType === 'Employment' &&
    
        <div className='TickDoc'>
            <input
            className="form-check-input"
            type="checkbox"
            id="Terminationletter"
            name="Terminationletter"
            value="Terminationletter"
            checked={selectedDocuments?.includes('Terminationletter') || (clientInfo.selectedDocuments && clientInfo.selectedDocuments.includes('Terminationletter'))}
            onChange={() => handleDocumentChange('Terminationletter')}
            />
            <label htmlFor='Terminationletter'> Termination letter </label>
        </div>
 
}

</div>


</div>

  }


  <div className='mt-4' style={{display:'flex',justifyContent:'center'}}>
    <button 
    onClick={() => nextStep()}
    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
    >
      <ArrowForwardIcon />
    </button>
  </div>
 
  </div>
  </>
  )
}
