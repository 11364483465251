import React, { useContext, useEffect, useState } from 'react';
import moment from "moment";
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import './Styles/ExpenseAccounts.scss'
import { RequisitionsAdmin } from '../../pages/RequisitionsAdmin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import './Styles/CashIn.scss'
import './Styles/CashOut.scss'
import { Requisitions } from '../../pages/Requisitions';
import { AuthContext } from '../../context/AuthContext';
import { RequisitionTabs } from '../../Components/RequisitionTabs/RequisitionTabs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ExpenseAccounts = () => {

  const current_time = moment().format("hh:mm a, Do MMMM");

  const { currentUser } = useContext(AuthContext); 

  const { id } = useParams();

  const [total, setTotal] = useState([]);
  const [paid, setPaid] = useState([]);
  const [balance, setBalance] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [totalResponse, paidResponse] = await Promise.all([
          Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Total'),
          Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Paid'),
        ]);

        const totalData = totalResponse.data;
        const paidData = paidResponse.data;

        setTotal(totalData);
        setPaid(paidData);

        const calculatedBalance = totalData.map((totalItem) => {
          const matchingPaidItem = paidData.find((paidItem) => paidItem.id === totalItem.id);

          if (matchingPaidItem) {
            return {
              id: totalItem.id,
              balanceAmount: totalItem.Totalamount - matchingPaidItem.PaidAmount,
            };
          }
          return {
            id: totalItem.id,
            balanceAmount: totalItem.Totalamount,
          };
        });

        setBalance(calculatedBalance);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const [openCredit, setOpenCredit] = React.useState(false);

  const handleClickOpenCredit = () => {
    setOpenCredit(true);
  };

  const handleCloseCredit = () => {
    setOpenCredit(false);
  };

  const [openDebit, setOpenDebit] = React.useState(false);

  const handleClickOpenDebit = () => {
    setOpenDebit(true);
  };

  const handleCloseDebit = () => {
    setOpenDebit(false);
  };

  const [openCashIn, setOpenCashIn] = React.useState(false);

  const handleClickOpenCashIn = () => {
    setOpenCashIn(true);
  };

  const handleCloseCashIn = () => {
    setOpenCashIn(false);
  };

  const [openCashOut, setOpenCashOut] = React.useState(false);

  const handleClickOpenCashOut = () => {
    setOpenCashOut(true);
  };

  const handleCloseCashOut = () => {
    setOpenCashOut(false);
  };

  return (
    <div className='ExpenseAccounts'>
      <div className='TopPartExpenses'>
        
        <div className='itemExpense'>
          <h2>Total Amount Requested</h2>
          <div className='amountExpense'>
            {total.map((item) => (
              <span key={item.id}>KE {item.Totalamount.toLocaleString()}.00 /=</span>
            ))}
          </div>
        </div>
        <div className='itemExpense'>
          <h2>Paid Amount Paid</h2>
          <div className='amountExpense'>
            {paid.map((item) => (
              <span key={item.id}>KE {item.PaidAmount.toLocaleString()}.00 /=</span>
            ))}
          </div>
        </div>
        <div className='itemExpense'>
          <h2>Total Amount Pending </h2>
          <div className='amountExpense'>
            {balance.map((item) => (
              <span key={item.id}>KE {item.balanceAmount.toLocaleString()}.00 /=</span>
            ))}
          </div>
        </div>
      </div>
      {currentUser.role === 'Admin' && (
      <div className='CashCreditDebit'>
            <div className='Credit'><button onClick={handleClickOpenCredit}>+ Credit Cash Into Firm</button></div>
            <div className='Debit'><button onClick={handleClickOpenDebit}>- Debit Cash from Firm</button></div>
           
      </div>
      )}
      <div>
                 {/* Credit Dialog */}
           
            <div>
            <Dialog
            open={openCredit}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCredit}
            aria-describedby="alert-dialog-slide-description"
             >
             <DialogTitle>{"Cash In"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div className='CashIn'>
                    <div className='CreditTop'>
                        <div className='CrediEntry'>
                            <label htmlFor='category'>Category</label>
                            <select
                            id='category'
                            name='category'
                            >
                                <option>Select Category</option>
                            </select>
                        </div>
                        <div  className='CrediEntry'>
                            <button onClick={handleClickOpenCashIn}>+ Add </button>
                        </div>
                      </div>

                      <div className='CreditCenter'>
                        <label htmlFor='Name'>Name</label>
                        <input 
                            name='Name'
                            id='Name'
                        />
                      </div>

                      <div className='CreditTop'>
                      <div className='CrediEntry'>
                          <label htmlFor='Amount'>Amount</label>
                          <input 
                            name='Amount'
                            id='Amount'
                          />
                      </div>
                      <div  className='CrediEntry'>
                          <label htmlFor='Date'>Date</label>
                          <input 
                            type='date'
                            name='Date'
                            id='Date'
                          />
                      </div>
                    </div>

                      </div>  
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseCredit}>Cancel</Button>
                <Button onClick={handleCloseCredit}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
     

            {/* debit Dialog */}
    
        <div>
            <Dialog
            open={openDebit}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDebit}
            aria-describedby="alert-dialog-slide-description"
                >
                <DialogTitle>{"Cash Out"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <DialogContentText id="alert-dialog-slide-description">
                    <div className='CashIn'>
                    <div className='CreditTop'>
                        <div className='CrediEntry'>
                            <label htmlFor='category'>Category</label>
                            <select
                            id='category'
                            name='category'
                            >
                                <option>Select Category</option>
                            </select>
                        </div>
                        <div  className='CrediEntry'>
                            <button onClick={handleClickOpenCashOut}>+ Add </button>
                        </div>
                      </div>

                      <div className='CreditCenter'>
                        <label htmlFor='Name'>Name</label>
                        <input 
                            name='Name'
                            id='Name'
                        />
                      </div>

                      <div className='CreditTop'>
                      <div className='CrediEntry'>
                          <label htmlFor='Amount'>Amount</label>
                          <input 
                            name='Amount'
                            id='Amount'
                          />
                      </div>
                      <div  className='CrediEntry'>
                          <label htmlFor='Date'>Date</label>
                          <input 
                            type='date'
                            name='Date'
                            id='Date'
                          />
                      </div>
                    </div>

                      </div>  
                    </DialogContentText>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDebit}>Cancel</Button>
                <Button onClick={handleCloseDebit}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
            
     
        <div>
            <Dialog
            open={openCashIn}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCashIn}
            aria-describedby="alert-dialog-slide-description"
            >
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <div className='CashIn'>
                        <div className='AddCategory'>
                            <div className='CatgoryAdd'>
                                <label htmlFor='CategoryName'>Add Category</label>
                                <input 
                                    name='CategoryName'
                                    id='CategoryName'
                                    placeholder='eg. Legal fee,Loan,Contribution etc'
                                />
                            </div>
                        </div>
                    </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseCashIn}>CANCEL</Button>
                <Button onClick={handleCloseCashIn}>SAVE NOW</Button>
                </DialogActions>
            </Dialog>
        </div>

        <div>
            <Dialog
            open={openCashOut}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCashOut}
            aria-describedby="alert-dialog-slide-description"
                >
                
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <div className='CashIn'>
                    <div className='AddCategory'>
                        <div className='CatgoryAdd'>
                            <label htmlFor='CategoryName'>Add Category</label>
                            <input 
                                name='CategoryName'
                                id='CategoryName'
                                placeholder='eg. Personal use etc'
                            />
                        </div>
                    </div>
                </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseCashOut}>CANCEL</Button>
                <Button onClick={handleCloseCashOut}>SAVE NOW</Button>
                </DialogActions>
            </Dialog>
        </div>
      
      </div>
      <div className='MiddleSection'>
        {currentUser.role === "Admin" && (
          <RequisitionsAdmin />
        )}
     
        {currentUser.role === 'Normal User' && 
        <div style={{width:'auto'}}>  
        <RequisitionTabs />
        </div>
        }
        
      </div>
    </div>
  );
};

export default ExpenseAccounts;
