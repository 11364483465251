import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';

import './Button.css';
import '../Styles/Search.css';
import './table.scss';

import { AuthContext } from '../context/AuthContext';

function TableClients() {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]); 
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
 
  const lawfirmaccount = currentUser?.firmname || currentUser?.firmname;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`${process.env.REACT_APP_API_ADDRESS}api/newcases/CaseDetails/${lawfirmaccount}?q=${query}`, {
          params: {
            page: currentPage,
            pageSize: pageSize,
          },
        });

        const formattedData = res.data.map((item, index) => ({
          ...item,
          count: index + 1 + (currentPage - 1) * pageSize,
        }));

        setData(formattedData);
      } catch (error) {
        console.log(error);
      }
    };

    if (query.length === 0 || query.length > 2) {
      fetchData();
    }
  }, [query, currentPage, pageSize, lawfirmaccount]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const history = useNavigate();

  const deletePersonalRecord = (id) => {
    if (window.confirm('Are you sure you want to delete the client details?')) {
      Axios.delete(`${process.env.REACT_APP_API_ADDRESS}api/newcases/${id}`);
      toast.success('Client record deleted successfully');
      setTimeout(() => history('/ExistingCases'), 500);
    }
  };

  return (
    <>
      <div
        className="searchCases"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '20px',
          paddingTop: '20px',
        }}
      >
        <input
          style={{
            border: 'none',
            outline: 'none',
            background: 'rgba(194, 187, 187, 0.404)',
            borderRadius: '10px',
            padding: '10px 15px',
            fontWeight: '500',
          }}
          type="search"
          placeholder="Search..."
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />

        <button
          style={{
            backgroundColor: '#02b159',
            padding: '5px 20px',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            fontWeight: '500',
          }}
        >
          <Link to="/NewCases" style={{ textDecoration: 'none', color: 'white' }}>
            <AddIcon /> File Case
          </Link>
        </button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table">
          <TableHead className="head">
            <TableRow className="rows">
              <TableCell className="tableCell rows">#</TableCell>
              <TableCell className="tableCell rows">File Ref</TableCell>
              <TableCell className="tableCell rows">Case No</TableCell>
              <TableCell className="tableCell rows">Citation</TableCell>
              
              <TableCell className="tableCell rows">Reg Date</TableCell>
              <TableCell className="tableCell rows">Status</TableCell>
              <TableCell className="tableCell rows">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((val) => (
              <TableRow key={val.id} className="rows">
                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">{val.count}</TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">{val.fileref}</TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">{val.caseNumber}/{val.caseYear}</TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">{val.FullName}</TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">{moment(val.regdate).format('Do-MMMM-YYYY')}</TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell className="tableCell rows">
                    <span className={`status2 ${val.status}`}>{val.status}</span>
                  </TableCell>
                )}

                {(currentUser.firmname === val.lawfirmaccount) && (
                  <TableCell>
                  
                    <Link to={`/update/${val.id}`}>
                      <button className="btnone btn-edit"><EditIcon style={{ fontSize: '16px' }} /></button>
                    </Link>

                    <Link to={`/users/${val.id}_${val.FullName}`}>
                      <button className="btnone btn-view"><VisibilityIcon style={{ fontSize: '16px' }} /></button>
                    </Link>

                    {currentUser.role === 'Admin' && (
                      <button className="btnone btn-delete" onClick={() => deletePersonalRecord(val.id)}>
                        <DeleteIcon style={{ fontSize: '16px' }} />
                      </button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            style={{
              border: 'none',
              padding: '10px 15px',
            }}
          >
            Previous
          </button>

          <span>{currentPage}</span>

          <button
            onClick={handleNextPage}
            disabled={data.length < pageSize}
            style={{
              border: 'none',
              padding: '10px 15px',
            }}
          >
            Next
          </button>
        </div>
      </TableContainer>
      <br />
    </>
  );
}

export default TableClients;
