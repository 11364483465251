import React, { useContext, useEffect, useState } from 'react'
import Axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../../Navigation/Sidebar';
import Navbar from '../../Navigation/Navbar';
import moment from 'moment';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import './MatterNotification.scss'
import Navigation from '../NavOfficial/Navigation';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };



const MatterNotifications = () => {

    const [Dormant, setDormant] = useState([]);
    const current_time = moment().format("YYYY")
    const { currentUser } = useContext(AuthContext);

    const loadData = async () => {
    const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/EventsFile/dormant");
    setDormant(response.data);
    }; 

    useEffect(() =>{
    loadData();
    }, []); 

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Dormant.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  



  return (
    <div className="home">
    <Navigation />
    
    <div className="homeContainer">
      <Navbar />
    <div className='MatterNotification'>


    <TableContainer component={Paper} >
    <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>

    <TableHead className='head'>
        <TableRow className='rows'>
            <TableCell className="tableCell rows">#</TableCell>
            <TableCell className="tableCell rows">Citation</TableCell>
            <TableCell className="tableCell rows">File Reference</TableCell>
            <TableCell className="tableCell rows">Status</TableCell>
            <TableCell className="tableCell rows">Action</TableCell>
            
        </TableRow>

        </TableHead>
        <TableBody>
        {(rowsPerPage > 0
            ? Dormant.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : Dormant
          ).map((val,index) =>{
            return(
                <TableRow key={val.id} className='rows'>
                {currentUser.firmname === val.lawfirmaccount &&
                    <TableCell className="tableCell rows">{index + 1}</TableCell>
                }
                {currentUser.firmname === val.lawfirmaccount &&
                    <TableCell className="tableCell rows">{val.clientname}</TableCell>
                }
                {currentUser.firmname === val.lawfirmaccount &&
                    <TableCell className="tableCell rows">{val.fileid}</TableCell>
                }
                {currentUser.firmname === val.lawfirmaccount &&
                <TableCell className="tableCell rows">
                    <span style={{color:'red',fontWeight:'bold'}}>Dormant</span>
                  </TableCell>
                }

                


                {currentUser.firmname === val.lawfirmaccount &&
                    <TableCell className="tableCell rows">
                        <a href={`/users/${val.EventsId}`}>
                            <button className='btnone btn-view'>Go To Case Details</button> 
                        </a>
                    </TableCell>
                }


                </TableRow>
             
            )
          })}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}

        </TableBody>
        <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={4}
            count={Dormant.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
        </Table>
        </TableContainer>
  
    </div>
    </div>
    </div>
  )
}

export default MatterNotifications