import React from 'react'
import './StylesHere/AnimatedText.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './StylesHere/StepThree.scss'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const StepThree = ({clientInfo,previousStep,handleOnChange,nextStep}) => {
  return (
    <> 
    <div className="animated-text" style={{
      display:'flex',
      flexDirection:'row',
      gap:'50px',
      alignItems:'center',
      marginBottom:'20px'
    }}> 
    <h2 className='circle'> Step 3 </h2>
    </div>
  
  
  <div className="StepThree">
 
  <div className='Contacts'>

      <div className='ContactsForm'>
        <label htmlFor='Mobile1'> Mobile contact one </label>
        <input 
        name='Mobile1' 
        id='Mobile1'  
        value={clientInfo.Mobile1 || ""} 
        placeholder='+254'
        onChange={handleOnChange}
        
        />
    </div>
  
      <div className='ContactsForm'>
        <label htmlFor='Mobile2'> Mobile contact Two </label>
        <input 
        name='Mobile2' 
        id='Mobile2' 
        value={clientInfo.Mobile2 || ""} 
        placeholder='+254'
        onChange={handleOnChange}
        
        />
      </div>
  
  </div>
  

  
  <div className='ReactQuill'>
  <label htmlFor='casesummary'>
    Case Summary: Particulars of suit.
  </label>
  <div className="editorContainer">
    <ReactQuill
      theme="snow" // You can choose a theme based on your preference
      modules={{
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'link',
        'image',
      ]}
      placeholder='Enumerate the case details'
      value={clientInfo.summary || ""}
      onChange={(value) => handleOnChange({ target: { name: 'summary', value } })}
      style={{ height: '150px' }}
    />
  </div>
  </div>

  
    <div className='summaryOne' style={{display:'none'}}>
      <label htmlFor='regdate'> Date of Registration </label>
      <input  
      className='form-control' 
      name='regdate' 
      id='regdate' 
      value={clientInfo.regdate}  
      onChange={handleOnChange}
      />
    </div>
  
  
    <div className='FileRef'>
      <label htmlFor='fileref'> Issued File Reference  </label>
      <input 
      type='text' 
      name='fileref' 
      id='fileref' 
      value={clientInfo.fileref || ""} 
      placeholder='eg. RD100, GEN100 , IC100'
      onChange={handleOnChange}
      />
    </div>
  
    </div>
  

  
    <div className='float-start'>
      <button 
        className='bg-green-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-green-500 duration-500'
        onClick={() => previousStep()}
      >
      <ArrowBackIcon/>
      </button>
    </div>
  
    <div className='float-end'>
      <button 
        className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
        onClick={() => nextStep()}
      >
        <ArrowForwardIcon />
      </button>
    </div>
    </>
  ) 
}

export default StepThree