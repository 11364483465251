import React, { useContext } from 'react'
import './index.css'
import { RunningDown } from '../Analytics/RunningDown';
import { General } from '../Analytics/General';
import { Employment } from '../Analytics/Employment';
import { WorkInjury } from '../Analytics/WorkInjury';
import { Divorce } from '../Analytics/Divorce';
import Total from '../Analytics/TotalCases';
import Navbar from '../Navigation/Navbar';
import './home.scss'
import moment from 'moment';
import Navigation from '../Components/NavOfficial/Navigation';
import MainNav from '../MainComponents/MainNav/MainNav';
import SideAnalytics from '../MainComponents/MainNav/SideAnalytics';
import { AuthContext } from '../context/AuthContext';
import UserNavigation from '../NormalUser/UserNavigation/UserNavigation';
import UserView from '../NormalUser/UserView/UserView';





const Home = () => {

  const current_time = moment().format("YYYY")
  const { currentUser,logout } = useContext(AuthContext);

  const userNow = currentUser.fullname;

  console.log('user:',userNow)


  return ( 
    <>
    <div className="home">
    {currentUser.role === 'Admin' && (
    <Navigation />
    )}
     
    <div className="homeContainer">
    {currentUser.role === 'Admin' && (
      <Navbar />
    )}

    {currentUser.role === 'Admin' && (
      <div className='widgets'>
        <Total  />
        <RunningDown />
        <General />
        <Employment />
        <Divorce />
        <WorkInjury />
      </div>
    )}

    {currentUser.role === 'Normal User' && (
      <UserView />
    )}
    
      <div> 

      {currentUser.role === 'Admin' && (  
      <div className='MainBody'> 
        <MainNav />
        {/** 
        <SideAnalytics />
        */}
      </div>
      )}
        
    </div>
    
    </div>
    </div>
   

 

    
    </>
  )
}

export default Home