import React from 'react'
import './StylesHere/AnimatedText.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './StylesHere/StepThree.scss'

export const StepThree = ({CaseType,ClientStatus,Mobile1,setMobile1,Mobile2,setMobile2,ChaserMobile,setChaserMobile,policeabstract,setPoliceabsract,setChiefsletter,medicalsummary,setMedicalsummary,xray,setXray,receipts,setReceipts,idcopy,setIdcopy,p3,setP3,burialpermit,setBurialpermit,marriagecert,setMarriagecert,birthcertificate,setBirthcertificate,Terminationletter,setTerminationletter,setDeathCert,summary,setSummary,regdate,setRegdate,fileref,setFileref,MedicalExpenses,setMedicalExpenses,FutureMedical,setFutureMedical,perviousStep,handleOnChange,nextStep}) => {
  return (
    <>
  <div className="animated-text" style={{
    display:'flex',
    flexDirection:'row',
    gap:'50px',
    alignItems:'center',
    marginBottom:'20px'
  }}> 
  <h2 className='circle'> Step 3 </h2>
  <div>
  <h1>Client Connections: Bridge the gap, build trust, conquer legal realms!</h1>
  </div>
  </div>


<div className="StepThree">

<div className='Contacts'>

    <div className='Contact1'>
      <label htmlFor='Mobile1'> Mobile contact one </label>
      <input 
      className='form-control' 
      name='Mobile1' 
      id='Mobile1'  
      value={Mobile1 || ""} 
      placeholder='Enter Phone number(0700..)'
      onChange={(e) =>{
        setMobile1(e.target.value);
    }}
      
      />
  </div>

    <div className='Contact1'>
      <label htmlFor='Mobile2'> Mobile contact Two </label>
      <input 
      className='form-control' 
      name='Mobile2' 
      id='Mobile2' 
      value={Mobile2 || ""} 
      placeholder='0700...'
      onChange={(e) =>{
        setMobile2(e.target.value);
    }}
      
      />
    </div>

</div>

<div className='Summarys'>

  <div className='summaryOne' style={{marginBottom:'20px'}}>
    <div className="editorContainer">
    <label htmlFor='casesummary'>
    Case Summary: Particulars of suit.</label>
    <ReactQuill 
    style={{ height: '100px',width:'800px'}}
    className="col-md-12"
    placeholder='Enumerate the case details' 
    name="summary"
    id="summary"
    value={summary || ""}
    onChange={(value) => {
      setSummary(value);
    }}
    />
    </div>
  </div>


  <div className='summaryOne' style={{display:'none'}}>
    <label htmlFor='regdate'> Date of Registration </label>
    <input  
    className='form-control' 
    name='regdate' 
    id='regdate' 
    value={regdate} 
    onChange={(e) =>{
      setRegdate(e.target.value);
    }}
    />
  </div>


  <div className='summaryOne' >
    <label htmlFor='fileref'> Issued File Reference  </label>
    <input 
    type='text' 
    className='form-control' 
    name='fileref' 
    id='fileref' 
    value={fileref || ""} 
    placeholder='eg. RD100, GEN100 , IC100'
    onChange={(e) =>{
      setFileref(e.target.value);
    }}
    />
  </div>

  </div>

</div>


  <div className='float-start'>
    <button 
    type='button' 
    className='btn btn-dark' 
    onClick={() => perviousStep()}
    style={{width:'200px'}}
    >
    Back
    </button>
  </div>

  <div className='float-end'>
    <button 
    type='button' 
    className='btn btn-primary' 
    onClick={() => nextStep()}
    style={{width:'200px'}}
    >
    Next
    </button>
  </div>
    </>
  )
}
