import React from 'react';
import NavigationOne from '../NavigationOne';
import './LFHCare.scss'
import LFHCARE from './LFHCARE.jpeg'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './LfhForm.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid green',
  boxShadow: 24,
  height:'auto',
  p: 4,
};

const LFHCare = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openLawyer, setOpenLawyer] = React.useState(false);
  const handleOpenLawyer = () => setOpenLawyer(true);
  const handleCloseLawyer = () => setOpenLawyer(false);

  return (
    <div>
        <NavigationOne />
        <div className='LFHCare'>
        <div className='LFHMain'>

            <div className='Care'>
            <div className='CareText'>
            <h2>LFHCare</h2>
            <h5>Committed to ensuring equal access to justice for all.</h5>
            <div className='FindaLawyer'>

            <div>
            <button onClick={handleOpen}>Register To Find a Lawyer</button>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" 
            >
            <Box sx={style} style={{borderRadius:'10px'}}>
            
                <form className='FormCare'>
                  <div className='mt-3 mb-1'>
                    <label htmlFor='FullName'>Full Name</label>
                    <input 
                      className='form-control'
                      placeholder='Your Name / jina lako'
                    />
                  </div>
                  <div className='mb-1'>
                    <label htmlFor='PhoneNumber'>Phone Number</label>
                    <input 
                      className='form-control'
                      name='PhoneNumber'
                      id='PhoneNumber'
                      placeholder='Your Mobile Number / nambari ya simu'
                    />
                  </div>
                  <div className='mb-1'>
                    <label htmlFor='Email'>Email (Optional)</label>
                    <input 
                      className='form-control'
                      name='Email'
                      id='Email'
                      type='email'
                      placeholder='Your Email Address / barua pepe'
                    />
                  </div>
                  <div className='mb-1'>
                    <label htmlFor='Location'>Your Location</label>
                    <input 
                      className='form-control'
                      name='Location'
                      id='Location'
                      placeholder='Your area of residence / eneo lako'
                    />
                  </div>
                  <div className='mb-1'>
                    <label htmlFor='problem'>Describe Your Legal Issue in the Field Below / Elezea shida yako ya kisheria</label>
                    <textarea
                      rows={4} 
                      className='form-control'
                      name='problem'
                      id='problem'
                    />
                  </div>

                  <div style={{display:'flex',justifyContent:'space-evenly',gap:'20px'}}>
                    <button onClick={handleClose} style={{
                      backgroundColor:'red',
                      borderRadius:'10px',
                      padding:'10px 20px',
                      color:'white',
                      fontWeight:'500'
                    }}>Cancel/Ghairi</button>

                    <button style={{
                      backgroundColor:'#02b159',
                      borderRadius:'10px',
                      padding:'10px 20px',
                      color:'white',
                      fontWeight:'500'
                    }}>Submit/Wasilisha</button>
                  </div>
                </form>
            </Box>
            </Modal>
            </div>


            <div>
            <button onClick={handleOpenLawyer}>Advocate's Subscription</button>
            <Modal
            open={openLawyer}
            onClose={handleCloseLawyer}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" 
            >
            <Box sx={style} style={{borderRadius:'10px'}}>
            
                <form className='FormCare'>
                  <div className='mt-3 mb-1'>
                    <label htmlFor='FullName'>Your Name</label>
                    <input 
                      className='form-control'
                      type='text'
                      name='FullName'
                      id='FullName'
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='practicingNumber'>Practicing Number</label>
                    <input 
                      className='form-control'
                      type='text'
                      name='practicingNumber'
                      id='practicingNumber'
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='MobileNumber'>Phone Number</label>
                    <input 
                      className='form-control'
                      type='text'
                      name='MobileNumber'
                      id='MobileNumber'
                    />
                  </div>

                  <div className='mb-1'>
                  <label htmlFor='emailAddress'>Email </label>
                  <input 
                    className='form-control'
                    type='text'
                    name='emailAddress'
                    id='emailAddress'
                  />
                </div>

                <div className='mb-1'>
                <label htmlFor='Location'>Location</label>
                <input 
                  className='form-control'
                  type='text'
                  name='Location'
                  id='Location'
                />
                </div>

                <div className='mb-1'>
                  <label htmlFor='PracticeArea'>Area of practice</label>
                  <select 
                  className='form-control'
                  id="PracticeArea"
                  name='PracticeArea'
                  >
                    <option>Select an option</option>
                    <option>Civil Litigation</option>
                    <option>Criminal Law</option>
                    <option>Conveyancing</option>
                    <option>Corporate Law</option>
                    <option>Intellectual Property Law</option>
                    <option>Family Law</option>
                    <option>Labour Law</option>
                    <option>Tax Law</option>
                    <option>Environmental Law</option>
                    <option>Human Rights Law</option>
                  </select>
                </div>

                <div style={{display:'flex',justifyContent:'space-evenly',gap:'20px'}}>
                <button onClick={handleCloseLawyer} style={{
                  backgroundColor:'red',
                  borderRadius:'10px',
                  padding:'10px 20px',
                  color:'white',
                  fontWeight:'500'
                }}>Cancel</button>

                <button style={{
                  backgroundColor:'#02b159',
                  borderRadius:'10px',
                  padding:'10px 20px',
                  color:'white',
                  fontWeight:'500'
                }}>Submit</button>
              </div>

                </form>
            </Box>
            </Modal>
            </div>


            </div>
            </div>
            <div className='CareImg'>
             <img src={LFHCARE} alt=''/>
            </div>
            </div>
           

          
        </div>
        <div className='LFHBody'>
            <h6>At LawFirmsHub, we believe that access to justice is a 
            fundamental right for everyone, especially for those who 
            are vulnerable and lack privilege.</h6> 
            <h6> We care deeply about 
            ensuring that justice is not delayed or denied, and that's 
            why we are proud to introduce LFHCare - a revolutionary 
            initiative aimed at connecting you to the best advocates in 
            town to expedite your cases in no time.</h6>
            <h6>So, whether you're 
            struggling with legal issues or just need a helping hand, 
            watch out for our social media postings to learn more about 
            LFHCare and how we can help you get the justice you deserve!</h6>
            <h5>
            Remember, justice delayed is justice denied, but with LFHCare, 
            you can rest assured that justice is always within your reach.</h5>
        </div>
    </div>
    </div>
  )
}

export default LFHCare;