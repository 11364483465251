import React, { useContext, useEffect, useState } from 'react'
import GavelIcon from '@mui/icons-material/Gavel';
import './Styles/Cards.css'
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { AuthContext } from '../context/AuthContext';

export const Employment = () => {

    const { currentUser } = useContext(AuthContext);
    const {id} = useParams();
    const [Employment, setEmployment] = useState([]);

    useEffect (() =>{
        Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/statistics/Employment').then((response) =>{
            setEmployment(response.data)
        });
      },[id]);

  return (
    <>
    <div className='widget'>
    <div className='left'>
    <span className='title'>Employment cases</span>
    
    
    {Employment.map((val) =>{
        return(  
          <>

          {currentUser?.firmname === val.lawfirmaccount && 
          <span className='counter'> 
          {val.Employment.toLocaleString()}
          </span>
          }

          </>
      
       
        )
      })} 

    </div>

    </div>
    </>
  )
}
