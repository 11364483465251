import React, { useContext, useEffect, useState } from 'react'
import CarCrashIcon from '@mui/icons-material/CarCrash';
import { useParams } from 'react-router-dom';
import './Styles/Cards.css'
import Axios from 'axios';
import { AuthContext } from '../context/AuthContext';

export const RunningDown = () => {

    const { currentUser } = useContext(AuthContext);
    const {id} = useParams();
    const [runningDown, setRunningDown] = useState([]);

    useEffect (() =>{
        Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/statistics/RunningDown').then((response) =>{
            setRunningDown(response.data)
        });
      },[id]);
 

  return ( 
    <>
    <div className='widget'>

    <div className='left'>
        
        {runningDown.map((val) =>{
            return(  

              <div 
                className='bg-green-600 p-2 text-white rounded-md hidden lg:block sm:block md:block'
                style={{height:'100px'}}
              >
              <span className='title'>RTA cases</span>
              {currentUser?.firmname === val.lawfirmaccount && 
                <span className='counter'> 
                {val.RunningDown.toLocaleString()}
                </span>
              }
              </div>

            )
          })}
        
    </div>

    </div>
    </>
  )
}
