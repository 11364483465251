import React, { useState } from 'react'
import { useContext } from 'react'
import './StylesHere/AnimatedText.scss'
import './StylesHere/Step2.scss'
import './StylesHere/DialogForm.scss'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer'; 
import { AuthContext } from '../../context/AuthContext';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StepTwo = ({clientInfo,nextStep,previousStep,handleOnChange}) => {

  const [open, setOpen] = React.useState(false);
  const [error,setError] = useState(null) 
  const [loading, setLoading] = useState(false);
 
  const handleCancel = () => {
    handleClose();
  };


  const { checkuser } = useContext(AuthContext);

  const handleCheck = async (e) => { 
    e.preventDefault();
    setLoading(true);
    try {
      await checkuser(clientInfo);
    } catch (err) {
      setError(err.response.data); 
    }finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };



  return ( 
    <div className='StepTwo'>

      <div 
          className="animated-text" 
          style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
          
            alignItems:'center',
            marginBottom:'20px'
          }}
        > 
          <h2 className='circle'> Step 2 </h2>
          <button 
            onClick={handleClickOpen}
            className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
          >
          Add Case Party
          </button>
      </div>

      
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          
        >

        <DialogTitle>{"Use this form to check if the client is registered"}</DialogTitle>

        <DialogContent  className='DialogFormDetails'>
          <DialogContentText className='dialogContentText' id="alert-dialog-slide-description">

           
            <div className='CheckMate'>
                <label htmlFor='IdNumber'> National Identity Number  </label>
                <input 
                  type='number' 
                  name='IdNumber' 
                  id='IdNumber' 
                  placeholder='Enter IdNumber'
                  value={clientInfo.IdNumber || ""} 
                  onChange={handleOnChange}
                />
                <button  
                  className='bg-green-500 text-white font-[Poppins] py-2 px-6 rounded hover:bg-indigo-400 duration-500'
                  onClick={handleCheck}
                  >
                  {loading ? 'Checking...' : 'Check'}
                </button>
                {error && <p style={{fontWeight:'bold',color:'red'}} className='pAuth'>{error} </p>}
            </div>
    
            {error === 'Client not registered. Continue with registration' &&  
            <div className='AllInputs'>
              <label htmlFor='FullName'> Full Name </label>
              <input 
              type='text' 
              name='FullName' 
              id='FullName' 
              placeholder="Enter client's full name"
              value={clientInfo.FullName || ""} 
              onChange={handleOnChange}
              />
            </div>
            }
    
            {/** Minor entries */}
            {error === 'Client not registered. Continue with registration' &&  
            <div className='MinorDetails'>
            {clientInfo.ClientStatus === 'Minor' && 
            <div className='AllInputs' >
              <label htmlFor='parent'> Full Name of Next Friend </label>
              <input 
                type='text' 
                name='parent' 
                id='parent' 
                placeholder="Enter parent's name"
                value={clientInfo.parent || ""} 
                onChange={handleOnChange}
              />
            </div>
            }
            {clientInfo.ClientStatus === 'Minor' && 
              <div className='AllInputs'>
              <label htmlFor='parentminor'> Relationship to minor </label>
                <select
                  id="parentminor"
                  name="parentminor"
                  value={clientInfo.parentminor || ""} 
                  onChange={handleOnChange}
                >
                  <option>Select...</option>
                  <option>Mother</option>
                  <option>Father</option>
                  <option>Brother</option>
                  <option>Sister</option>
                  <option>Grand Mother</option>
                  <option>Grand Father</option>
                </select>
              </div>
            }
            </div>
            }
    
            {/** Deceased */}

            {error === 'Client not registered. Continue with registration' &&  
            <div className='MinorDetails'>
              {clientInfo.ClientStatus === 'Deceased' && 
                <div className='AllInputs'>
                  <label htmlFor='firstadmin'> 1st Admin Full Name </label>
                  <input 
                    type='text' 
                    name='firstadmin' 
                    id='firstadmin' 
                    placeholder="Enter 1st Admin name"
                    value={clientInfo.firstadmin || ""} 
                    onChange={handleOnChange}
                  />
                </div>
              }
        
              {clientInfo.ClientStatus === 'Deceased' && 
                <div className='AllInputs mt-2'>
                  <label htmlFor='secondadmin'> 2nd Admin Full Name </label>
                  <input 
                    type='text' 
                    name='secondadmin' 
                    id='secondadmin' 
                    placeholder="Enter 1st Admin name"
                    value={clientInfo.secondadmin || ""} 
                    onChange={handleOnChange}
                  />
                </div>
              }
            </div>
            }
    
            
          
              {error === 'Client not registered. Continue with registration' &&  
                <div className='AllInputs'>
                  <label htmlFor='Gender'> Gender </label>
                  <select
                    id="Gender"
                    name="Gender"
                    value={clientInfo.Gender || ""} 
                    onChange={handleOnChange}
                  >
                    <option>Select...</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Nonbinary</option>
                  </select>
                </div>
              }
    
            <div>
    
            {error === 'Client not registered. Continue with registration' &&  
            <div className='AllInputs'>
              <label htmlFor='residence'>Residence</label>
              <input 
                type='text' 
                className='form-control' 
                name='residence' 
                id='residence' 
                value={clientInfo.residence || ""} 
                placeholder='Enter area of residence'
                onChange={handleOnChange}
              />
            </div>
            }
    
          {error === 'Client not registered. Continue with registration' &&  
          <div>
          {clientInfo.CaseType === 'Personal Injury (Running Down)' &&
          <div className='AllInputs mt-2'>
            <label htmlFor='Insurance'> Insurance (Blamed).  </label>
            <input 
              type='text' 
              className='form-control' 
              name='Insurance' 
              id='Insurance' 
              value={clientInfo.Insurance || ""} 
              placeholder='Enter the insurance'
              onChange={handleOnChange}
            />
          </div>
          }
        </div> 
        }
    
        {error === 'Client not registered. Continue with registration' &&  
        <div>
          {clientInfo.CaseType === 'Personal Injury (Running Down)' &&
            <div className='AllInputs mt-2'>
              <label htmlFor='ChaserName'>Chaser</label>
              <input 
                type='text' 
                className='form-control' 
                name='ChaserName' 
                id='ChaserName' 
                value={clientInfo.ChaserName || ""} 
                placeholder='Enter Chaser name'
                onChange={handleOnChange}
              />
            </div>
          }
        </div>
    
        }
            </div>
    
    
            
    
          </DialogContentText>
    
        </DialogContent>
    
        <DialogActions>
    
    
          <button
            onClick={handleCancel}
            className='bg-green-500 text-white font-[Poppins] py-2 px-6 rounded md:ml-8 hover:bg-indigo-500 duration-500'
          >
            Continue
          </button>
        </DialogActions>
      
        </Dialog>

    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" id="table-to-export-mpesa">
        <TableHead>
          <TableRow>
            <TableCell>Full name</TableCell>
            <TableCell>ID number</TableCell>
            <TableCell>Residence</TableCell>
            <TableCell>Chaser</TableCell>
            <TableCell>Insurance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  
              
              <TableRow>
                <TableCell>{clientInfo.FullName}</TableCell>
                <TableCell >{clientInfo.IdNumber}</TableCell>
                <TableCell>{clientInfo.residence}</TableCell>
                <TableCell>{clientInfo.ChaserName}</TableCell>
                <TableCell>{clientInfo.Insurance}</TableCell>
              </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>

  </Paper>

    <div style={{marginTop:"50px"}}>
    {error === 'Client not registered. Continue with registration' && 
    <div className='float-end'>
    <button 
    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
    onClick={() => nextStep()}
    ><ArrowForwardIcon /></button>
    </div>
    }
    <div className='float-start'  >
    <button 
    className='bg-green-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-green-500 duration-500'
    onClick={() => previousStep()}
    ><ArrowBackIcon /></button>
    </div>
</div>
    </div>
  )
}

export default StepTwo