import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';

export const StaffRegistration = () => {
  
  const { currentUser } = useContext(AuthContext); 
  const [iduser, setIdUser] = [currentUser.id]
  const [fullname,setFullName] = useState([]);
  const [username, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');  
  const [phone, setPhone] = useState(''); 
  const history = useNavigate();
  const [lawfirmaccount,setLawFirmAccount] =  [currentUser.firmname || currentUser.firmname]
 

  const SubmitEvent = () =>{
    axios.post(process.env.REACT_APP_API_ADDRESS + 'api/Auth/register', 
    {fullname:fullname,username:username,role:role,password:password,
      phone:phone,iduser:iduser,lawfirmaccount:lawfirmaccount,
  }).then(() => {
    
  });
  toast.success("User added successfully")
  setTimeout(() => history('/index'),700);
  };






  return (
    <>
    <div className="home">
    <Navigation />

    <div className="homeContainer">
    <Navbar />
    <div className='main'>
    <div className='file-card'>
    <h5 style={{textAlign:'center', color:'red'}}> Update Account Details</h5>
   
        <div style={{display:'none'}}>
        <label htmlFor='lawfirmaccount'>Law Firm Account</label>
        <input 
        className='form-control'
        name='lawfirmaccount'
        id='lawfirmaccount' 
        value={lawfirmaccount} 
        onChange={(e) =>{
          setLawFirmAccount(e.target.value);
      }}
        />
       </div>

    <div className='mb-3' style={{marginTop:'10px'}}>
    <label htmlFor='fullname'> Full Name </label>
    <input
    className='form-control' 
    id="fullname"
    name="fullname"
    required
    value={fullname || " "}
    onChange={(e) =>{
      setFullName(e.target.value);
  }}
  />
  
  </div>
    


<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='username'> User name (email)  </label>
<input
className='form-control' 
type='email'
id="username"
name="username"
required
value={username || ""}
onChange={(e) =>{
  setUserName(e.target.value);
}}
/>

</div>


<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='phone'> Phone Number </label>
<input
className='form-control' 
type='phone'
id="phone"
name="phone"
required
value={phone || ""}
onChange={(e) =>{
  setPhone(e.target.value);
}}
/>

</div>
  
{currentUser.role === 'Admin' && 
<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='role'>  Role </label>
<select
className='form-control' 
id="role"
name="role"
value={role || ""}
onChange={(e) =>{
  setRole(e.target.value);
}}
>
<option>Select...</option>
<option>Normal User</option>
<option>Admin</option>
</select>
</div>
}




<div className='mb-3' style={{marginTop:'10px'}}>
<label htmlFor='password'> Password  </label>
<input
type='password'
className='form-control' 
id="password"
name="password"
value={password || ""}
onChange={(e) =>{
  setPassword(e.target.value);
}}
/>
</div>

<div className='mb-3' style={{marginTop:'10px',display:'none'}}>
<label htmlFor='iduser'>User Id</label>
<input 
type='number'
className='form-control'
name='iduser'
id='iduser'
value={iduser || ""}
onChange={(e) =>{
  setIdUser(e.target.value);
}}
/>
</div>


<br />

<div className='float-center'>
      <input type="submit" onClick={SubmitEvent} className="btn btn-primary btn-block" value={"Submit User"} />
</div>

  </div>
  </div>
  </div>
  </div>
    </>
  )
}
