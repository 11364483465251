import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Navigation from '../../Components/NavOfficial/Navigation';
import Navbar from '../../Navigation/Navbar';
import './Appointnments.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

const Appointments = () => {
    const { currentUser } = useContext(AuthContext);
    const [appointments, setAppointments] = useState([]);
    const lawfirm_email = currentUser.FirmEmail;
    const [message, setMessage] = useState('');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect (() =>{
        axios.get(process.env.REACT_APP_API_ADDRESS + `api/appointments/${lawfirm_email}`).then((response) =>{
            setAppointments(response.data);
            setMessage(response.data.message);
        });
      },[lawfirm_email]);


  return (
    <div className="home">
    <Navigation />
     
    <div className="homeContainer">
      <Navbar />
      <div className='Appointments'>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.client_name}</TableCell>
                    <TableCell>{moment(row.appointment_date).format('Do-MMMM-YYYY')}</TableCell>
                    <TableCell>{row.appointment_time}</TableCell>
                    <TableCell>{row.client_mobile}</TableCell>
                    <TableCell><span style={{color:'orangered',fontWeight:'bold'}}>SMS Sent</span></TableCell>
                    <TableCell><span style={{color:'white',backgroundColor:'#02b159',padding:'5px 5px',borderRadius:'5px',border:'none',cursor:'pointer'}}>view</span></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={appointments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    </div>
    

  )
}

export default Appointments;