import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from '../context/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment'
import '../ExistingCaseTables/TableClient1.css'
import VisibilityIcon from '@mui/icons-material/Visibility';


import '../ExistingCaseTables/table.scss'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

 
const TaskHome = () => { 
  const [tasks, setTasks] = useState([]);
  const { currentUser } = useContext(AuthContext);


  const [query, setQuery] = useState("");


  
  useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks?q=${query}`);
    setTasks(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);



  const getText = (html) =>{
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tasks.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <div className="home">
    <Sidebar />

    <div className="homeContainer">
    <Navbar />
    <div style={{paddingLeft:'80px',paddingRight:'80px'}}>
    <div style={{textAlign:'center',marginTop:'20px'}}> 
      <h5 style={{backgroundColor:'#dff0d8',color:'#241842',fontWeight:'400', width:'fit-content'}}>Kindly forward your files to the task sender after completing the assigned task.</h5>
    </div>
    <input 
    className='serachInput'
    type='search'
    placeholder='Search...'
    onChange={(e) => setQuery(e.target.value.toLowerCase())}
    />
    <TableContainer component={Paper} >
    <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
      <TableHead className='head'>
        <TableRow className='rows'>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>#</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>name</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>taskname</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>description</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>dateissued</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>From</TableCell>
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>status</TableCell>
            
            <TableCell className="tableCell rows" style={{textAlign:'center'}}>Action</TableCell>
            
        </TableRow>
 
        </TableHead>
        <tbody>
    
    {tasks.map((val,index) =>{
      return(
        <TableRow key={val.id} className='rows'> 
        
            {currentUser.fullname === val.name &&  <TableCell className="tableCell rows">{index + 1}</TableCell> }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.name}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.taskname}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{getText(val.description)}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{moment(val.dateissued).fromNow()}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.sender}</TableCell>  }
            {currentUser.fullname === val.name &&    
                <TableCell className="tableCell rows">
                    <span className={`status2 ${val.status}`}>{val.status}</span>
                </TableCell> 
            }
            
      
    {currentUser.fullname === val.name && 
         <TableCell className="tableCell rows">

       {/* {val.status !== 'Recalled' && 
            <Link to={`/StaffTaskUpdate/${val.id}`}>
               <button className='btnone btn-edit'><EditIcon style={{fontSize:'16px'}}/>EDIT</button> 
            </Link>
     } */} 
          <Link to={`/taskview/${val.id}`}>
              <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
          </Link>  
     </TableCell>
    }
    
        </TableRow>
      )
    })}

    </tbody>
    </Table>
    </TableContainer>
    </div>
    </div>
    </div>
  );
};

export default TaskHome;