import React, { useContext, useEffect, useRef, useState } from 'react'
import './Settings.scss'
import UserNavigation from '../../UserNavigation/UserNavigation'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';




export const Settings = () => {

    const { id } = useParams();
  
    const { currentUser } = useContext(AuthContext); 
    const lawfirmaccount =  [currentUser?.firmname || currentUser?.firmname]
    const iduser = currentUser?.id
    const [gender, setGender] = useState('');
    const [account_status,setAccountStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    console.log('file name:', selectedFile);
    const imagePreviewRef = useRef(null);
    const history = useNavigate();


    const [UserInformation, setUserInformation] = useState({
        fullname:'',
        username:'',
        role:'',
        password:'',
        phone:'',
        jobCategory:'',
        gender:'',
        account_status:'',
        salary:'',
        id_number:'',
        bank_account_number:'',
        kra_pin:'',
        bank_details:''
      });

      const { username } = UserInformation;

      const handleFileChange = (event) => {
        const { files } = event.target;
    
        if (files.length > 0) {
            const file = files[0];
            setSelectedFile(file);
    
            // Read the file and set it as the source of the image preview
            const reader = new FileReader();
            reader.onload = (e) => {
               
                imagePreviewRef.current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
            // Clear the image preview when no file is selected
            imagePreviewRef.current.src = '';
        }
    };

    

    const handleGenderChange = (event) => {
      setGender(event.target.value);
    };

    const handleStatusChange = (event) =>{
        setAccountStatus(event.target.value);
    };

      const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUserInformation({ ...UserInformation, [name]: value });
      }

      const handleSubmit = (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('selectedFile', selectedFile);
    
        const updatedUserInfo = {
            ...UserInformation,
            lawfirmaccount: lawfirmaccount,
            iduser: iduser,
            gender: gender,
            account_status: account_status,
        };
    
        Object.entries(updatedUserInfo).forEach(([key, value]) => {
            formData.append(key, value);
        });
    
        if (!username) {
            alert('All values are required');
        } else {
            if (!id) {
                axios
                    .post(process.env.REACT_APP_API_ADDRESS + 'api/Auth/register', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Set content type to FormData
                        },
                    })
                    .then(() => {
                        setUserInformation({});
                    })
                    .catch((err) => alert(err.response.data));
                alert('Case Details added successfully');
            } else {
                axios
                    .put(process.env.REACT_APP_API_ADDRESS + `api/Auth/updateUser/${id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Set content type to FormData
                        },
                    })
                    .then(() => {
                        setUserInformation({
                            fullname: '',
                            username: '',
                            role: '',
                            password: '',
                            phone: '',
                            jobCategory: '',
                            gender: '',
                            account_status: '',
                            salary: '',
                            id_number: '',
                            bank_account_number: '',
                            kra_pin: '',
                            bank_details: '',
                        });
                        toast.success('Update Done successfully', { position: 'top-right' });
                    })
                    .catch((err) => {
                        toast.error(err.response.data, { position: 'top-right' });
                    });
            }
    
            setTimeout(() => history('/index'), 700);
        }
    };
    


    const handleImageDelete = () => {
        setSelectedFile(null);
        imagePreviewRef.current.src = '';
      };



      useEffect(() => { 
        const fetchData = async () => {
          try {
            const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/Auth/user/${id}`); 
            setUserInformation(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [id]);  
    
      
     


  return (
    <div>
    <UserNavigation />

        <div className='Settings'>
            

           <div className='SettingForm'>
            <div className='ImageSettings'>
                <img src={UserInformation.imageUrl} ref={imagePreviewRef} alt=''/>
                <div className='input-wrapper_image'>

                    <label htmlFor='selectedFile' className='custom-file-input'> <EditIcon /></label>  
                    <button onClick={handleImageDelete}><DeleteIcon /></button>

                    <input 
                        type='file' 
                        id='selectedFile'
                        name='selectedFile'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
             
                </div>

            </div>

            <div className='SettingEntryView'>

            <div className='SettingsPartA'>
               
                    <div className='input-wrapper'>
                        <input 
                            type='text' 
                            id='fullname' 
                            name='fullname' 
                            placeholder='Enter your full name'
                            value={UserInformation.fullname || ""}
                            onChange={handleOnChange}
                        /> 
                        <label htmlFor='fullname'>Name</label>
                    </div>

                    <div className='input-wrapper'>
                        <input 
                            type='text' 
                            id='username' 
                            name='username' 
                            placeholder='Enter your user@name' 
                            value={UserInformation.username || " "} 
                            onChange={handleOnChange}
                        />
                        <label htmlFor='username'>Username</label>
                    </div>

                    <div className='input-wrapper'>
                        <input 
                            type='number' 
                            id='phone' 
                            name='phone' 
                            placeholder='Enter your mobile money number' 
                            value={UserInformation.phone || " "} 
                            onChange={handleOnChange}
                        />
                        <label htmlFor='phone'>Mpesa mobile</label>
                    </div>

                    
                    <div className='input-wrapper'>
                        <input 
                        type='number' 
                        id='id_number' 
                        name='id_number' 
                        placeholder='Enter your national id'
                        value={UserInformation.id_number || " "}
                        onChange={handleOnChange}
                        />
                        <label htmlFor='id_number'>ID number</label>
                    </div>

                    <div className='input-wrapper'>
                        <input 
                        type='number' 
                        id='bank_account_number' 
                        name='bank_account_number' 
                        placeholder='Enter your bank account number'
                        value={UserInformation.bank_account_number || " "}
                        onChange={handleOnChange}
                        />
                        <label htmlFor='bank_account_number'>Bank account number</label>
                    </div>

                    <div className='input-wrapper'>
                        <input 
                        type='text' 
                        id='bank_details' 
                        name='bank_details' 
                        placeholder='Enter bank name and branch'
                        value={UserInformation.bank_details || " "}
                        onChange={handleOnChange}
                        />
                        <label htmlFor='bank_details'>Bank name & branch</label>
                    </div>
              
            </div>

            <div className='SettingsPartB'>

                <div className='two-input-wrapper'>

                    <span>Gender</span>

                    <div className='Radios'>
                     
                    <div className='radioInput'>
                    <label>
                        <input 
                        type='radio' 
                        id='gender' 
                        name='gender' 
                        value='male' 
                        checked={gender === 'male'} 
                        onChange={handleGenderChange}/>
                        Male
                    </label>
                    </div>

                    <div className='radioInput'>
                    <label>
                        <input 
                        type='radio' 
                        id='gender' 
                        name='gender' 
                        value='female' 
                        checked={gender === 'female'} 
                        onChange={handleGenderChange}
                        />
                        Female
                    </label>
                    </div>

                    </div>
            
                </div>

                <div className='two-input-wrapper'>

                    <span>Account status</span>

                    <div className='Radios'>
                    
                    <div className='radioInput'>
                    <label>
                        <input 
                        type='radio' 
                        id='account_status' 
                        name='account_status' 
                        value='active' 
                        checked={account_status === 'active'} 
                        onChange={handleStatusChange}
                        />
                        Active
                    </label>
                    </div>

                    <div className='radioInput'>
                    <label>
                        <input 
                        type='radio' 
                        id='account_status' 
                        name='account_status' 
                        value='inactive' 
                        checked={account_status === 'inactive'} 
                        onChange={handleStatusChange}
                        />
                        Inactive
                    </label>
                    </div>

                    </div>
        
                </div>

                <div className='SettingsSelect'>
                    <label htmlFor='jobCategory'>Job category</label>
                    <select 
                        id='jobCategory' 
                        name="jobCategory" 
                        className="custom-select"
                        value={UserInformation.jobCategory || " "}
                        onChange={handleOnChange}
                    >
                        <option value=''>Select...</option>
                        <optgroup label="Partners">
                            <option value="Partner A">Partner A</option>
                            <option value="Partner B">Partner B</option>
                        </optgroup>
                        <optgroup label="Staff">
                            <option value="Advocate">Associate</option>
                            <option value="IT administrator">IT administrator</option>
                            <option value="secretary">Secretary</option>
                            <option value="pupil">Pupil</option>
                            <option value="clerk">Clerk</option>
                            <option value="caterer">Caterer & cleaner</option>
                            <option value="Process server">Process server</option>
                        </optgroup>
                    </select>
                </div>

                <div className='input-wrapper'>
                    <input 
                    type='number' 
                    id='salary' 
                    name='salary' 
                    placeholder='KES 0.00'
                    value={UserInformation.salary || " "}
                    onChange={handleOnChange}
                    />
                    <label htmlFor='salary'>Salary</label>
                </div>

                <div className='input-wrapper'>
                    <input 
                    type='text' 
                    id='kra_pin' 
                    name='kra_pin' 
                    placeholder='Enter your KRA pin'
                    value={UserInformation.kra_pin || " "}
                    onChange={handleOnChange}
                    />
                    <label htmlFor='kra_pin'>KRA pin</label>
                </div>

                <div className='input-wrapper'>
                    <input 
                    type='password' 
                    id='password' 
                    name='password' 
                    placeholder='Enter new password'
                    value={UserInformation.password}
                    onChange={handleOnChange}
                    />
                    <label htmlFor='password'>Password</label>
                </div>

             
            </div>

            </div>

            <div className='settingsButton'>
                <input type='button' value={id? 'Update user':'Submit user'} onClick={handleSubmit}/>
            </div>

            </div>
        </div>

    </div>
  )
}
