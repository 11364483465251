import React from 'react'
import 'react-quill/dist/quill.snow.css';
import './StylesHere/AnimatedText.scss'
import './StylesHere/StepFour.scss'

export const StepFour = ({clientInfo,CaseType,ClientStatus,clientcategory,defendant,setDefendant,secondddefendant,setSeconddefendant,motorOne,setMotorOne,motor,setMotor,defenceappearance,setDefenceappearance,defencefirm,setDefencefirm,Location,setLocation,Street,setStreet,defenceemail,setDefenceemail,caseNumber,setCaseNumber,caseYear,setCaseYear,regby,setRegby,status,setStatus,perviousStep,handleSubmit,id,handleOnChange,nextStep}) => {


  return (
  <>
  <div className="animated-text" style={{
    display:'flex',
    flexDirection:'row',
    gap:'50px',
    alignItems:'center',
    marginBottom:'20px'
  }}> 
  <h2 className='circle'> Step 4 </h2>
  <div>
  <h1> Finally, unveil the Opposition: Decode their puzzle, conquer legal battles!</h1>
  </div>
  </div>

<div className='StepFour'>

<div className='DefenceNames'>
<div className='mb-3'>
<label htmlFor='defendant'> 1st Defendant name  </label>
<input 
type='text' 
className='form-control' 
name='defendant' 
id='defendant' 
value={defendant || ""} 
placeholder='John Doe And Elon Face'
onChange={(e) =>{
  setDefendant(e.target.value);
}}
/>
</div>

<div className='mb-3'>
<label htmlFor='2nddefendant'> 2nd Defendant name (Optional) </label>
<input 
type='text' 
className='form-control' 
name='secondddefendant' 
id='secondddefendant' 
value={secondddefendant || ""} 
placeholder='John Doe And Elon Face'
onChange={(e) =>{
  setSeconddefendant(e.target.value);
}}
/>
</div>
</div>

<div className='perticularDetails'> 

<div className='mb-3'>
{clientcategory === 'Passenger' &&
<div>
<label htmlFor='motorOne'> Reg No of motor vehicle / motor cycle the client was travelling in. </label>
<input 
type='text' 
className='form-control' 
name='motorOne' 
id='motorOne' 
value={motorOne || ""} 
placeholder='KDA 001A / KMDA 001B'
onChange={(e) =>{
  setMotorOne(e.target.value);
}}
/>
</div>
}
</div>

<div className='mb-3'>
{clientcategory === 'Rider' &&
<div>
<label htmlFor='motorOne'> Reg No of motor cycle the client was riding. </label>
<input 
type='text' 
className='form-control' 
name='motorOne' 
id='motorOne' 
value={motorOne || ""} 
placeholder='KDA 001A / KMDA 001B'
onChange={(e) =>{
  setMotorOne(e.target.value);
}}
/>
</div>
}
</div>

<div className='mb-3'>
{CaseType === 'Running Down' &&
<div>
<label htmlFor='motor'> Reg No of blamed motor vehicle / motor cycle  </label>
<input 
type='text' 
className='form-control' 
name='motor' 
id='motor' 
value={motor || ""}   
placeholder='KDA 001A / KMDA 001B'
onChange={(e) =>{
  setMotor(e.target.value);
}}
/>
</div>
}
</div>

<div className='mb-3'>
<label htmlFor='defenceappearance'> Defence Appearance </label>
<select
className='form-control' 
name='defenceappearance' 
id='defenceappearance' 
value={defenceappearance || ""}
onChange={(e) =>{
  setDefenceappearance(e.target.value);
}}
>
<option>Select...</option>
<option>None</option>
<option>Yes </option>
</select>
</div>

</div>

{defenceappearance === "Yes" && 
<div className='mb-3'>
<label htmlFor='defencefirm'> Defence Firm Name  </label>
<input 
type='text' 
className='form-control' 
name='defencefirm' 
id='defencefirm' 
value={defencefirm || ""} 
placeholder='Enter Defence Firm Name'
onChange={(e) =>{
  setDefencefirm(e.target.value);
}}
/>
</div>
}


{defenceappearance === "Yes" && 
<div className='mb-3'>
<label htmlFor='Location'> Defence Firm Location  </label>

<input 
type='text' 
className='form-control' 
name='Location' 
id='Location' 
value={Location || ""} 
placeholder='A Towers, 15th floor, west wing'
onChange={(e) =>{
  setLocation(e.target.value);
}}
/>
</div>
}

{defenceappearance === "Yes" &&  
<div className='mb-3'>
<label htmlFor='Street'> Defence Firm Street  </label>

<input 
type='text' 
className='form-control' 
name='Street'  
id='Street' 
value={Street || ""} 
placeholder='Monrovia street'
onChange={(e) =>{
  setStreet(e.target.value);
}}
/>
</div>
}

{defenceappearance === "Yes" && 
<div className='mb-3'>
<label htmlFor='defenceemail'> Defence Firm Email  </label>

<input 
type='text' 
className='form-control' 
name='defenceemail' 
id='defenceemail' 
value={defenceemail || ""} 
placeholder='info@wwlaw.com'
onChange={(e) =>{
  setDefenceemail(e.target.value);
}}
/>
</div>
}

<div className='mb-3'>
<label htmlFor='caseNumber'> Case Number </label>

<input 
type='text' 
className='form-control' 
name='caseNumber' 
id='caseNumber' 
value={caseNumber || ""} 
placeholder='E001 or 001'
onChange={(e) =>{
  setCaseNumber(e.target.value);
}}
/>
</div>

<div className='mb-3'>
<label htmlFor='caseYear'> Case Year </label>
<select
className='form-control' 
name='caseYear' 
id='caseYear' 
value={caseYear || ""} 
onChange={(e) =>{
  setCaseYear(e.target.value);
}}
>
<option>Select...</option>
<option>2023</option>
<option>2022</option>
<option>2021</option>
<option>2020</option>
<option>2019</option>
<option>2018</option>
<option>2017</option> 
<option>2016</option>
<option>2015</option>
<option>2014</option>
<option>2013</option>
<option>2012</option>
<option>2011</option>
<option>2010</option>
</select>
</div>

<div className='mb-3' style={{display:'none'}}>
<label htmlFor='regby'> Registered By  </label>

<input 
type='text' 
className='form-control' 
name='regby' 
id='regby' 
value={regby.split(' ')[0] || ""} 
placeholder='Enter registering authority'
onChange={(e) =>{
  setRegby(e.target.value);
}}
/>
</div>

<div className='mb-2'>
<label htmlFor='status'> Status </label>
<select
className='form-control' 
name='status' 
id='status' 
value={status || ""}
onChange={(e) =>{
  setStatus(e.target.value);
}}
>
<option>Select...</option>
<option>Active</option>
<option> Closed </option>
<option> Archived </option>
</select>
</div>

</div>
  <div className='float-start'>
    <button 
    type='button' 
    className='btn btn-dark' 
    onClick={() => perviousStep()}
    style={{width:'200px'}}
    >Back</button>
  </div>
 
 <div className='float-end'>
 <br />
 <input type="submit" onClick={() => handleSubmit()} className="btn btn-primary" value={id ? "Update Case details" : "Confirm & Submit"} />
 </div>
   </>
  )
}
