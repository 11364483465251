import React, { useContext, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './Lawfirm.scss'
import { toast } from 'react-toastify';
import axios from 'axios'
import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import NavigationOne from '../LandingPage/NavigationOne';
import GoogleIcon from '@mui/icons-material/Google';

export const LawFirms = () => {

    const { currentUser,logout } = useContext(AuthContext);

    const [lawfirm, setLawfirm] = useState({
        firmname:'',
        username:'',
        phone1:'',
        postal:'',
        location:'',
        password:'',
        confirmpassword:'',
        role:'Admin'
    })
    const {firmname} = lawfirm;

    const handleOnChange = (e) =>{
        const {name, value} = e.target
        setLawfirm({...lawfirm,[name]:value})
      }
      const history = useNavigate();
      const {id} = useParams();

      const handleSubmit = (e) =>{

        if(!firmname ){
          toast.error("All values are required")
        }else{
          if(!id){
            axios.post(process.env.REACT_APP_API_ADDRESS + "api/lawfirmaccount", 
          lawfirm).then(() =>{
            setLawfirm({firmname:'',username:'',phone1:'',postal:'',location:'',password:'',confirmpassword:'',role:''})
          }).catch((err) => toast.err(err.response.data));
          toast.success("User added successfully")
          }else{
            axios.put(process.env.REACT_APP_API_ADDRESS + `api/lawfirmaccount/${id}`, 
          lawfirm).then(() =>{
            setLawfirm({firmname:'',username:'',phone1:'',postal:'',location:'',password:'',confirmpassword:''})
          }).catch((err) => toast.err(err.response.data));
          toast.success("Account created successfully. Continue to login")
          }

          setTimeout(() => history('/Auth'),700);

        }
      }

  return (
    <>  
    <ToastContainer position="top-center" />
    <NavigationOne />
        <div className='lawfirmbody'> 

        <h1>Create your account</h1>

        <div className='bodyRegistration'>
        
        <div className='Another'>
          <h4>Sign up using another provider</h4>

          <div className='buttons'>
               <button><GoogleIcon className='icon'/>Sign up with Google</button>
          </div>
        </div>

        <div className='Border'>
        
        </div>
      
        <div>  
        <form>
        <div>
        
        <h4>Sign up with email</h4>
        </div>
        <div className='lawfirm'>
            <div className='mb-2'>
                <label htmlFor='firmname'>Law Firm Name</label>
                <input 
                    className='form-control'
                    type='text'
                    id='firmname'
                    name='firmname'
                    value={lawfirm.firmname || ""}
                    onChange={handleOnChange}

                />
            </div>
            <div className='mb-2'>
            <label htmlFor='username'>Email</label>
            <input 
                className='form-control'
                type='text'
                id='username'
                name='username'
                value={lawfirm.username || ""}
                onChange={handleOnChange}

            />
        </div>

        <div className='mb-2'>
        <label htmlFor='phone1'>Mobile Number</label>
        <input 
            className='form-control'
            type='phone'
            id='phone1'
            name='phone1'
            value={lawfirm.phone1 || ""}
            onChange={handleOnChange}

        />
        </div>

        <div className='mb-2'>
        <label htmlFor='postal'>Postal Adress </label>
        <input 
            className='form-control'
            type='text'
            id='postal'
            name='postal'
            value={lawfirm.postal || ""}
            onChange={handleOnChange}
        />
        </div>

        <div className='mb-2'>
        <label htmlFor='location'>Physical Location</label>
        <input 
            className='form-control'
            type='text'
            id='location'
            name='location'
            value={lawfirm.location || ""}
            onChange={handleOnChange}
        />
        </div>

        <div className='mb-2'>
        <label htmlFor='password'>Password  </label>
        <input 
            className='form-control'
            type='password'
            id='password'
            name='password'
            value={lawfirm.password || ""}
            onChange={handleOnChange}
        />
        </div>

        <div className='mb-2'>
        <label htmlFor='confirmpassword'>Confirm Password  </label>
        <input 
            className='form-control'
            type='password'
            id='confirmpassword'
            name='confirmpassword'
            value={lawfirm.confirmpassword || ""}
            onChange={handleOnChange}
        />
        </div>

        <div className='mb-2' style={{display:'none'}}>
          <label htmlFor='role'>Role</label> 
          <input 
          className='form-control'
          type='text'
          id='role'
          name='role'
          value={lawfirm.role}
          onChange={handleOnChange}
          />     
        </div>

        <div className='buttonsLink'>
            <h4 onClick={() => handleSubmit()}>Register</h4>  
        </div>
       
        </div>
        </form>
        </div>
        </div>
              <p>By providing your email address or using a single sign-on provider to create an account, you agree to our <br /> <a href='#/'>Terms of Service</a> and that you have reviewed our <a href='#/'>Privacy Policy</a> and <a href='#/'>Cookie Policy</a>.</p>

              <div  className='lawbutton'>
              <span>Already have an account?</span> 
              <a href="/Auth">
                Log in.
              </a>
              
            
            </div>
        </div>
    </>
  )
}
