import React, { useEffect, useState } from 'react'
import './IssueTask.css'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export const StaffTaskUpdate = () => {

  const { currentUser,currentFirm } = useContext(AuthContext);

  const [taskInfo,setTaskInfo] = useState({
    name:'',
    taskname:'',
    description:'',
    dateissued:'',
    status:'',
    sender:''
  })

  const {status} = taskInfo;

  const handleOnChange = (e) =>{
    const {name, value} = e.target
    setTaskInfo({...taskInfo,[name]:value})
  }

  const history = useNavigate();
  const {id} = useParams();

  const handleSubmit = (e) =>{

    if(!status ){
      toast.error("All values are required")
    }else{
      if(!id){
        axios.post(process.env.REACT_APP_API_ADDRESS + "api/tasks", 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",dateissued:"",status:"",sender:''})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task added successfully")
      }else{
        axios.put(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`, 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",status:"",sender:""})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task updated successfully")
      }
      
      setTimeout(() => history('/index'),700);
    }
  }


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`);
        setTaskInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);



  return (
    <>
    <div className='main'>
    <div className='file-card'>


<div className='mb-2' style={{marginTop:'10px'}}>
<label htmlFor='status'> Status </label>
<select
className='form-control' 
id="status"
name="status"
required
value={taskInfo.status || " "}
onChange={handleOnChange}
>
<option>select...</option>
{currentFirm.role === 'Admin' &&  <option>Pending</option> }
<option>Completed</option>
{currentFirm.role === 'Admin' &&  <option>Recalled</option> }
</select>
</div>



<br />

<div className='float-center'>
      <input type="submit" onClick={() => handleSubmit()} className="btn btn-primary btn-block" value={id ? "Update Task" : "Submit Task"} />
</div>

  </div>
  </div>
    </>
  )
}
