import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import './UserCaseView.scss'


const UserCaseView = () => {

    const [CaseStations, setCaseStations] = useState([]); 
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleInputChange = (event) => {
      setSearchQuery(event.target.value);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/stations?q=${searchQuery}`)
          .then((response) => {
            setCaseStations(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, [searchQuery]);


  return ( 
    <div className='UserCaseView'>
  
    <Paper sx={{ width: '100%', overflow: 'scroll' }}>
    <div className='StationSearch'>
    <input 
      placeholder='Search station...'
      onChange={handleInputChange}
      value={searchQuery}
    />
  </div>
    <TableContainer  sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Station</TableCell>
            <TableCell>All</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Closed</TableCell>
            <TableCell>Archived</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {CaseStations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((Stations,index) =>{
            return(
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell  >
                    <Link to={`/cases/${Stations.CourtStation}`} style={{textDecoration:'none',color:'blue',fontWeight:'bold'}}>{Stations.CourtStation}</Link>
                </TableCell>

                <TableCell>{Stations.caseCount}</TableCell>
                <TableCell>{Stations.active}</TableCell>
                <TableCell>{Stations.closed}</TableCell>
                <TableCell>{Stations.archived}</TableCell>
                
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    
    </TableContainer>
    <TablePagination
    rowsPerPageOptions={[10, 25, 100]}
    component="div"
    count={CaseStations.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
  </Paper>
    </div>
  ) 
}

export default UserCaseView