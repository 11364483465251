import { React, useContext } from "react";
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Home from "./pages";
import InstructionsForm from "./pages/InstructionsForm";
import NewCases from "./pages/NewCases";
import NewCasesUpdate from "./pages/NewCaseUpdate";
import ExistingCases from "./pages/ExistingCases";
// import Navbar from "./NavigationBars/NavBar";
import Navbar from "./Navigation/Navbar"; 
import Calender from "./Calender/CalenderSelf/Calender";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from "./users/User";
import { PageMain } from "./TrialReg/Main";
import { ViewTasks } from "./pages/ViewTasks";
import { IssueTask } from "./Task Pane/IssueTask";
import Schedule from "./PaymentSchedule/Schedule";
import { Auth } from "./pages/Auth";
import { Requisitions } from "./pages/Requisitions";
import { Staffmembers } from "./pages/Staffmembers";
import { StaffRegistration } from "./Staffmembers/StaffRegistration";
import { RequisitionForm } from "./PaymentSchedule/RequisitionForm";
import { Leave } from "./pages/Leave";
import { LeaveForm } from "./Staffmembers/LeaveForm";
import TaskHome from "./pages/TaskHome";
import { RequisitionsAdmin } from "./pages/RequisitionsAdmin";
import { LeaveApproval } from "./pages/LeaveApproval";
import { StaffTaskUpdate } from "./Task Pane/StaffTaskUpdate";
import { CauseList } from "./pages/CauseList";
import { TodayCauselist } from "./causelistStyle/TodayCauselist";
import { Help } from "./Help/Help";
import { LawFirms } from "./LawFirms/LawFirms";
import UsageManuals from "./Help/UsageManuals";
import SingleRequisition from "./PaymentSchedule/SingleRequisition";
import SeeTask from "./SeeTask/SeeTask";
import { SuggestionBox } from "./pages/SuggestionBox";
import { Suggestions } from "./pages/Suggestions";
import './pages/home.scss'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

import UpdateUser from "./Staffmembers/UpdateUser";
import { DarkModeContext } from "./context/darkModeContext";
import './Styles/dark.scss'
import Profile from "./Profile/Profile";
import { Monday } from "./causelistStyle/Monday";
import { Thursday } from "./causelistStyle/Thursday";
import { Friday } from "./causelistStyle/Friday";
import Message from "./Profile/Message";
import LandingPage from "./pages/LandingPage";
import { Price } from "./LandingPage/Pricing/Price";
import PrivacyPolicy from "./LandingPage/Legal/PrivacyPolicy";
import Ceo from "./LandingPage/Profiles/Ceo";
import MatterNotifications from "./Components/Matters/MatterNotifications";
import { Notifications } from "./Components/Notifications/Notifications";
import { RequisitionTabs } from "./Components/RequisitionTabs/RequisitionTabs";
import BotPage from "./LandingPage/CounselGenie/BotPage";
import LFHCare from "./LandingPage/LFHCare/LFHCare";
import CalendarPage from "./Calender/CalenderSelf/Calender";
import CaseForm from "./TrialReg/CaseForm";
import MainAccounts from "./MainComponents/AccountsBilling/MainAccounts"; 
import Appointments from "./MainComponents/Appointments/Appointments";
import CaseView from "./users/CaseView";
import CasesPage from "./NormalUser/UserCaseView/CasesPage";
import TripsSummary from "./NormalUser/UserView/TripsSummary";
import Expenses from "./NormalUser/Invoices/Expenses";
import BringUps from "./NormalUser/BringUps/BringUps";
import Communications from "./NormalUser/Communications/Communications";
import { Settings } from "./NormalUser/UserSettings/UserDetails/Settings";
import { UserLeave } from "./NormalUser/UserSettings/UserLeave/UserLeave";

function RoutesApp() { 

    const { currentUser } = useContext(AuthContext);
    const { darkMode } = useContext(DarkModeContext);


    const RequireAuth = ({ children }) => {
      return currentUser.role ? children : <Navigate to="/Auth" />;
    };


  return (
    <div className={darkMode ? "app dark" : "app"}>

        <ToastContainer position="top-center" />
        <BrowserRouter>
            <Routes>
                <Route>

                    <Route path="Main" element={<LandingPage />} />
                    <Route path="Auth" element={<Auth />}/>
                    <Route path='Docs' element={<UsageManuals />} />
                    <Route path='Help' element={<Help />}/>
                    <Route path='pricing' element={<Price />} />
                    <Route path='privacy_policy' element={<PrivacyPolicy />} />
                    <Route path="register_account" element={<LawFirms />}/>
                    <Route path="ceo_profile" element={<Ceo />}/>
                    <Route path="Bot" element={<BotPage />}/>
                    <Route path="LFHCare" element={<LFHCare />}/>
                
                    <Route
                        path="/"
                        element={
                            
                                <LandingPage />
                           
                                }
                    />

                    <Route 
                        path='/index'
                        element={
                            <RequireAuth>
                                <Home />
                            </RequireAuth>
                                }
                    />
                    <Route>
                        <Route 
                            path='/NewCases'
                            element={
                                <RequireAuth>
                                    <NewCases />
                                </RequireAuth>
                            }
                        />

                        <Route 
                        path='/caseReg'
                        element={
                            <RequireAuth>
                                <CaseForm />
                            </RequireAuth>
                        }
                    />


                        <Route 
                            path="/ExistingCases"
                            element={
                                <RequireAuth>
                                    <ExistingCases />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/update/:id"
                            element={
                                <RequireAuth>
                                    <NewCasesUpdate />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/users/:id"
                            element={
                                <RequireAuth>
                                    <CaseView />
                                </RequireAuth>
                            }
                        /> 
                    </Route>

                    <Route>
                        <Route 
                            path='/members'
                            element={
                                <RequireAuth>
                                    <Staffmembers />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/StaffRegistration"
                            element={
                                <RequireAuth>
                                    <StaffRegistration />
                                </RequireAuth>
                            }
                        />
                        <Route 
                        path='/update_user/:id'
                        element={
                            <RequireAuth>
                                <Settings />
                            </RequireAuth>
                        }
                        />
                    </Route>

                    <Route>
                        <Route 
                            path="/accounts"
                            element={
                                <RequireAuth>
                                    <MainAccounts />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/Requisitions"
                            element={
                                <RequireAuth>
                                    <RequisitionTabs />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/RequisitionForm"
                            element={
                                <RequireAuth>
                                    <RequisitionForm />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/Updaterequisition/:id"
                            element={
                                <RequireAuth>
                                    <RequisitionForm />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path='/requisition/:id'
                            element={
                                <RequireAuth>
                                    <SingleRequisition />
                                </RequireAuth>
                            }
                        />
                    </Route>

                    <Route>
                        <Route
                            path="/InstructionsForm"
                            element={
                                <RequireAuth>
                                    <InstructionsForm/>
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/schedule"
                            element={
                                <RequireAuth>
                                    <Schedule/>
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path='/updateSchedule/:id'
                            element={
                                <RequireAuth>
                                    <Schedule/>
                                </RequireAuth>
                            }
                        />
                    </Route>

                    <Route>
                        <Route 
                            path="/ViewTasks"
                            element={
                                <RequireAuth>
                                    <ViewTasks/>
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/IssueTask"
                            element={
                                <RequireAuth>
                                    <IssueTask/>
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path='/updateTask/:id'
                            element={
                                <RequireAuth>
                                    <IssueTask />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path='/taskview/:id'
                            element={
                                <RequireAuth>
                                    <SeeTask/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path='/TaskHome'
                            element={
                                <RequireAuth>
                                    <TaskHome/>
                                </RequireAuth>
                            }
                        />
                    </Route>

                    <Route>
                        <Route 
                            path="/Calender"
                            element={
                                <RequireAuth>
                                    <CalendarPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path='/causelist'
                            element={
                                <RequireAuth>
                                    <CauseList />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path='/TodayCauselist'
                            element={
                                <RequireAuth>
                                    <TodayCauselist/>
                                </RequireAuth>
                            }
                        />

                        <Route 
                            path="/Monday"
                            element={
                                <RequireAuth>
                                    <Monday />
                                </RequireAuth>
                            }
                        />

                        <Route 
                            path="/Thursday"
                            element={
                                <RequireAuth>
                                    <Thursday />
                                </RequireAuth>
                            }
                        />

                        <Route 
                            path="/Friday"
                            element={
                                <RequireAuth>
                                    <Friday />
                                </RequireAuth>
                            }
                        />

                    </Route>

                    <Route>
                        <Route 
                        path='/Leave'
                        element={
                            <RequireAuth>
                                <Leave/>
                            </RequireAuth>
                        }
                        />
                        <Route 
                            path="/LeaveForm"
                            element={
                                <RequireAuth>
                                    <LeaveForm/>
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/LeaveApproval"
                            element={
                                <RequireAuth>
                                    <LeaveApproval />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/messages"
                            element={
                                <RequireAuth>
                                    <Suggestions />
                                </RequireAuth>
                            }
                        />
                        <Route 
                            path="/SuggestionBox"
                            element={
                                <RequireAuth>
                                    <SuggestionBox />
                                </RequireAuth>
                            }
                        />
                        <Route 
                        path='/view_message/:id'
                        element={
                            <RequireAuth>
                                <Message />
                            </RequireAuth>
                        }
                    />

                    <Route 
                            path="/MesssageUpdate/:id"
                            element={
                                <RequireAuth>
                                    <Message />
                                </RequireAuth>
                            }
                        />
                        
                        <Route 
                            path="/UpdateLeave/:id"
                            element={
                                <RequireAuth>
                                    <LeaveForm />
                                </RequireAuth>
                            }
                        />

                        <Route 
                            path="/Profile"
                            element={
                                <RequireAuth>
                                    <Profile />
                                </RequireAuth>
                            }
                           
                        />

                        <Route 
                        path="/alerts"
                        element={
                            <RequireAuth>
                                <Notifications />
                            </RequireAuth>
                        }

                        />

                        <Route 
                            path="/PendingFiles"
                            element={
                                <RequireAuth>
                                    <MatterNotifications/>
                                </RequireAuth>
                            }
                        />

                        <Route 
                        path="/appointments"
                        element={
                            <RequireAuth>
                                <Appointments />
                            </RequireAuth>
                        }
                    />

                    <Route 
                    path="/cases/:stationName"
                    element={
                        <RequireAuth>
                            <CasesPage />
                        </RequireAuth>
                    }
                />

                <Route 
                path="/reports"
                element={
                    <RequireAuth>
                        <TripsSummary />
                    </RequireAuth>
                }
                />

                <Route 
                path="/invoices"
                element={
                    <RequireAuth>
                        <Expenses />
                    </RequireAuth>
                }
                />

                <Route 
                path="/bring_ups"
                element={
                    <RequireAuth>
                        <BringUps />
                    </RequireAuth>
                }
                />

                <Route 
                path="/communications"
                element={
                    <RequireAuth>
                        <Communications />
                    </RequireAuth>
                }
                />

                <Route 
                path="/settings"
                element={
                    <RequireAuth>
                        <Settings />
                    </RequireAuth>
                }
                />

                <Route 
                path="/user_leave"
                element={
                    <RequireAuth>
                        <UserLeave />
                    </RequireAuth>
                }
                />

                    </Route>

                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default RoutesApp;