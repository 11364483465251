import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Axios from 'axios';
import './calender.css'
import { useParams } from 'react-router-dom';
import Navbar from '../../Navigation/Navbar';
import { AuthContext } from '../../context/AuthContext';
import Navigation from '../../Components/NavOfficial/Navigation';

export default function CalendarPage() {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const { id } = useParams();
  const { currentUser, currentFirm } = useContext(AuthContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_API_ADDRESS}api/EventsFile`, {
          params: { lawfirmaccount: currentFirm?.firmname || currentUser?.firmname }
        });
        setEvents(
          response.data.map((val) => ({
            id: val.fileid,
            title: `${val.fileid}: ${val.eventFile}`,
            start: new Date(val.upcomingdate),
            end: new Date(val.upcomingdate),
            allDay: true,
          }))
        );
      } catch (error) {
        console.error('Error fetching events:', error);
        // Handle error and display error message to the user
      }
    };

    loadData();
  }, [currentFirm?.firmname, currentUser?.firmname]);
  return (
    <>
      <div className="home">
        <Navigation />
        <div className="homeContainer">
          <Navbar />
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor='start'
            endAccessor='end'
            views={['month', 'day', 'week']}
            style={{ height: 500, paddingLeft: '50px', paddingRight: '50px', paddingBottom: '15px', marginTop: '50px' }}
          />
        </div>
      </div>
    </>
  );
}
