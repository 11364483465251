import React from 'react'
import NavigationOne from '../NavigationOne'
import './Price.scss'

export const Price = () => {
  return (
    <div  className='Price'> 
        <NavigationOne />

        <div className='PriceMain'>

        <div className='PriceHead'>
            <h1>Flexible plans with live support, free <br /> training, and worry-free data migration</h1>
            <span>Get started in minutes. Try it free for 7 days.</span>
        </div>

        <div className='PriceBody'>
            <div className='editions'>
                <div className='editionshead'>
                    <h3>Bronze</h3>
                    <p>Track your time and get paid.</p>
                </div>
                <hr />
                <div className='Outline'>
                    <h2>$49.9 </h2> <h4>USD/month</h4>
                </div>
                
            </div>
            <div className='editions'>
                <div className='editionshead'>
                    <h3>Silver</h3>
                    <p>Streamline your client 
                    relations, case management, 
                    documents, and more.</p>
                </div>
                <hr />
                <div  className='Outline'> 
                    <h2>$99.9</h2> <h4>USD/month</h4>
                </div>
            </div>
            <div className='editions'>
                <div className='editionshead'>
                    <h3>Gold</h3>
                    <p>Maximize your productivity 
                    and improve your business intelligence.</p>
                </div>
                <hr />
                <div className='Outline'>
                    <h2>$129.9</h2> <h4>USD/month</h4>
                </div>
            </div>
        </div>

        </div>

    </div>
  )
}
