import React from 'react'
import './LaeveStyles/UserLeave.scss'
import UserNavigation from '../../UserNavigation/UserNavigation'

export const UserLeave = () => {
  return (
    <div className='UserLeave'>
        <UserNavigation />
    </div>
  )
}
