import React, { useContext, useEffect, useState } from 'react'
import { FinalStep } from './FinalStep'
import { StepOne } from './StepOne'
import { StepThree } from './StepThree'
import { StepTwo } from './StepTwo'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import Axios from 'axios'
import { StepFour } from './StepFour'
import { AuthContext } from '../context/AuthContext'
import moment from 'moment'


export const PageMain = () => {

  const { currentUser } = useContext(AuthContext);


  const [steps, setSteps] = useState(1)

  const nextStep = () =>{
    setSteps(steps + 1)
  }
  const perviousStep = () =>{
    setSteps(steps - 1)
  }

  
  const history = useNavigate();

  const [FullName,setFullName] = useState('');
  const [Gender,setGender] = useState('');
  const [IdNumber,setIdNumber] = useState('');
  const [Nationality,setNationality] = useState('');
  const [Mobile1,setMobile1] = useState('');
  const [Mobile2,setMobile2] = useState('');
  const [ChaserName,setChaserName] = useState('');
  const [ChaserMobile,setChaserMobile] = useState('');
  const [residence,setResidence] = useState('');
  const [dob,setDod] = useState('');
  const [policeabstract,setPoliceabsract] = useState('');
  const [medicalsummary,setMedicalsummary] = useState('');
  const [xray,setXray] = useState('');
  const [receipts,setReceipts] = useState('');
  const [idcopy,setIdcopy] = useState('');
  const [p3,setP3] = useState('');
  const [burialpermit,setBurialpermit] = useState('');
  const [marriagecert,setMarriagecert] = useState('');
  const [birthcertificate,setBirthcertificate] = useState('');
  const [Terminationletter,setTerminationletter] = useState('');
  const [summary,setSummary] = useState('');
  const [regdate,setRegdate] =  [moment().format("YYYY-MM-DD hh:mm:ss")];
  const [fileref,setFileref] = useState('');

  const [regby,setRegby] =  [currentUser.fullname || currentUser.firmname];

  const [status,setStatus] = useState('');
  const [defenceappearance,setDefenceappearance] = useState('');
  const [defencefirm,setDefencefirm] = useState('');
  const [defenceemail,setDefenceemail] = useState('');
  const [defendant,setDefendant] = useState('');
  const [parent,setParent] = useState('');
  const [firstadmin,setFirstadmin] = useState('');
  const [secondadmin,setSecondadmin] = useState('');
  const [accidentarea,setAccidentarea] = useState('');
  const [motor,setMotor] = useState('');
  const [secondddefendant,setSeconddefendant] = useState('');
  const [parentminor,setParentminor] = useState('');
  const [motorOne,setMotorOne] = useState('');
  const [Location,setLocation] = useState('');
  const [Street,setStreet] = useState('');
  const [caseNumber,setCaseNumber] = useState(''); 
  const [caseYear,setCaseYear] = useState('');
  const [Division,setDivision] = useState('')
  const [courtRank,setCourtRank] = useState('')


  const [lawfirmaccount,setLawFirmAccount] =  [currentUser.firmname]
  const [purpose,setPurpose] = useState('');
  const [Station,setStation] = useState('');
  const [CaseType,setCaseType] = useState('');
  const [ClientStatus,setClientStatus] = useState('');
  const [clientcategory,setClientCategory] = useState('');
  const [deathCert,setDeathCert] = useState('')
  const [chiefsletter,setChiefsletter] = useState('')
  const {id} = useParams();
  
  
  const SubmitEvent = () =>{

    if(!Station || !CaseType || !ClientStatus || !status || !dob ){
      toast.error("All values are required")
    }else{
      if(!id){
    Axios.post(process.env.REACT_APP_API_ADDRESS + 'api/newcases', 
    {lawfirmaccount:lawfirmaccount,purpose:purpose,Station:Station,
      CaseType:CaseType,ClientStatus:ClientStatus,clientcategory:clientcategory,
    FullName:FullName,parent:parent,firstadmin:firstadmin,secondadmin:secondadmin,parentminor:parentminor,
  Gender:Gender,IdNumber:IdNumber,dob:dob,accidentarea:accidentarea,Nationality:Nationality,
residence:residence,ChaserName:ChaserName,Mobile1:Mobile1,Mobile2:Mobile2,ChaserMobile:ChaserMobile,
policeabstract:policeabstract,
medicalsummary:medicalsummary,xray:xray,receipts:receipts,idcopy:idcopy,p3:p3,
burialpermit:burialpermit,marriagecert:marriagecert,
birthcertificate:birthcertificate,Terminationletter:Terminationletter,summary:summary,
regdate:regdate,fileref:fileref,
defendant:defendant,secondddefendant:secondddefendant,motorOne:motorOne,motor:motor,
defenceappearance:defenceappearance,defencefirm:defencefirm,Location:Location,Street:Street,
defenceemail:defenceemail,caseNumber:caseNumber,
caseYear:caseYear,regby:regby,status:status,deathCert:deathCert,chiefsletter:chiefsletter}).then(() => {
});

toast.success("Case Details added successfully")
setTimeout(() => history('/Index'),700); 


} 
    }
  }


  const multiStepForm = () =>{
    switch(steps){
      case 1:
        return (
          <StepOne 
        lawfirmaccount = {lawfirmaccount}
        purpose = {purpose}
        Station = {Station}
        CaseType = {CaseType} 
        ClientStatus = {ClientStatus}
        clientcategory = {clientcategory}
        Division={Division}
        courtRank={courtRank}
        setCourtRank={setCourtRank}
        setDivision={setDivision}
        setLawFirmAccount={setLawFirmAccount}
        setPurpose = {setPurpose}
        setStation = {setStation}
        setCaseType = {setCaseType}
        setClientStatus = {setClientStatus}
        setClientCategory = {setClientCategory} 
        policeabstract={policeabstract}
        setPoliceabsract={setPoliceabsract}
        medicalsummary={medicalsummary}
        setMedicalsummary={setMedicalsummary}
        xray={xray}
        setXray={setXray}
        receipts={receipts}
        setReceipts={setReceipts}
        idcopy={idcopy}
        setIdcopy={setIdcopy}
        p3={p3}
        setP3={setP3}
        burialpermit={burialpermit}
        setBurialpermit={setBurialpermit}
        marriagecert={marriagecert}
        setMarriagecert={setMarriagecert}
        birthcertificate={birthcertificate}
        setBirthcertificate={setBirthcertificate}
        Terminationletter={Terminationletter}
        setTerminationletter={setTerminationletter}
        setDeathCert = {setDeathCert}
        deathCert = {deathCert}
        chiefsletter = {chiefsletter}
        setChiefsletter = {setChiefsletter}
        nextStep={nextStep}
        /> 
        )
      case 2:
        return(
          <StepTwo 
          CaseType = {CaseType} 
          ClientStatus = {ClientStatus}
          FullName={FullName}
          setFullName={setFullName}
          parent={parent}
          setParent={setParent}
          firstadmin={firstadmin}
          setFirstadmin={setFirstadmin}
          secondadmin={secondadmin}
          setSecondadmin={setSecondadmin}
          parentminor={parentminor}
          setParentminor={setParentminor}
          Gender={Gender}
          setGender={setGender}
          IdNumber={IdNumber}
          setIdNumber={setIdNumber}
          dob={dob}
          setDod={setDod}
          accidentarea={accidentarea}
          setAccidentarea={setAccidentarea}
          Nationality={Nationality}
          setNationality={setNationality}
          residence={residence}
          setResidence={setResidence}
          ChaserName={ChaserName}
          setChaserName={setChaserName} 
          nextStep={nextStep}
          perviousStep={perviousStep}
          />
        )

      case 3:
        return(
          <StepThree 
          CaseType = {CaseType} 
          ClientStatus = {ClientStatus}
          Mobile1={Mobile1}
          setMobile1={setMobile1}
          Mobile2={Mobile2}
          setMobile2={setMobile2}
          ChaserMobile={ChaserMobile}
          setChaserMobile={setChaserMobile}
          summary={summary}
          setSummary={setSummary}
          regdate={regdate}
          setRegdate={setRegdate}
          fileref={fileref}
          setFileref={setFileref}

          perviousStep={perviousStep}
          nextStep={nextStep}
          />
        )

      case 4:
        return(
          <StepFour 
          CaseType = {CaseType} 
          ClientStatus = {ClientStatus}
          clientcategory={clientcategory}
          defendant={defendant}
          setDefendant={setDefendant}
          secondddefendant={secondddefendant}
          setSeconddefendant={setSeconddefendant}
          motorOne={motorOne}
          setMotorOne={setMotorOne}
          motor={motor}
          setMotor={setMotor}
          defenceappearance={defenceappearance}
          setDefenceappearance={setDefenceappearance}
          defencefirm={defencefirm}
          setDefencefirm={setDefencefirm}
          Location={Location}
          setLocation={setLocation}
          Street={Street}
          setStreet={setStreet}
          defenceemail={defenceemail}
          setDefenceemail={setDefenceemail}
          caseNumber={caseNumber}
          setCaseNumber={setCaseNumber}
          caseYear={caseYear}
          setCaseYear={setCaseYear}
          regby={regby}
          setRegby={setRegby}
          status={status}
          setStatus={setStatus}

          perviousStep={perviousStep}
          handleSubmit={SubmitEvent}
          />
        )

     
      default:
        return null
    }
  }


  return (
    <div >
      <div className='row my-5 justify-content-md-center'>
        <div className='col col-lg-1'></div>
        <div className='col-lg-10 col-lg-auto'>
          {multiStepForm()}
        </div>
        <div className='col col-lg-1'></div>
      </div>
    </div>
  )
}
