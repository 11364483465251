import "./navbar.scss";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import moment from "moment";
import Axios from "axios"; 
import { Link } from "react-router-dom";
import { DarkModeContext } from "../context/darkModeContext";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CampaignIcon from '@mui/icons-material/Campaign';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const Navbar = () => {

  const { dispatch } = useContext(DarkModeContext);

const { currentUser } = useContext(AuthContext); 
const current_time = moment().format("hh:mm a,Do MMMM")

const [data, setData] = useState([]);
const loadData = async () => {
  const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
  setData(response.data);
}; 

useEffect(() =>{
  loadData();
}, []); 

const [suggestions,setSuggestions] = useState([]);
const [totalDormant,SetTotalDormant] = useState([]);

const [query, setQuery] = useState("");

useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions/messages?q=${query}`);
      setSuggestions(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);



  useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/EventsFile/totalDormant?q=${query}`);
      SetTotalDormant(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);


  const [name] = [currentUser.fullname || currentUser.firmname];
  const initials = name
  .split(" ")
  .map(word => word.charAt(0))
  .join("");
  
  

  return (
    <div className="navbarMain"> 
      <div className="wrapper">

      <div></div>
      
        <div className="items">
        
        {/** 
        <div className="item" id="my-element1" data-tooltip-content="Messages">
        <a style={{textDecoration:'none'}} href='/alerts'>
        <div>
          {totalDormant.map((val) =>{
            return(
              <div className="counter2">
              {currentUser.firmname === val.lawfirmaccount &&
                <span>
                 {!val.dormant === false &&  <Alert severity="info" style={{backgroundColor:'rgb(17, 43, 75)',color:'white'}}>Office Notifications</Alert>}
                 </span>
              }
                 
              </div>
            )
          })}
        </div> 
        </a>

      </div> */}
         
          <div className="item" id="my-element" data-tooltip-content="Messages">
            <a style={{textDecoration:'none'}} href='/Suggestions'>
            <ChatBubbleOutlineOutlinedIcon className="icon" style={{color:'white'}}/>
            <ReactTooltip anchorId="my-element" style={{color:'black',backgroundColor:'white'}}/>
            <div className="counter">
              {suggestions.map((val) =>{
                return(
                  <div>
                    <span>
                    {currentUser.fullname === val.Receiver && 
                    <span> {val.messages} </span>
                    }
                    {currentUser.firmname === val.Receiver && 
                      <span> {val.messages} </span>
                      }
                    
                     </span>
                    
                  </div>
                )
              })}
            </div>
            </a>

          </div>
            

          
          <div className="item">
          <span>  

          {data.map((val,index) =>{
            return(
              <div  key={val.id}>             
              <div>
              {currentUser.fullname === val.fullname && 
                <Link to={`/updateUser/${val.id}`} style={{display:'flex',gap:'3px'}}> 
                <div>
                <button className='buttonlog2' 
                style={{fontFamily:'sans-serif',borderRadius:'50%',border:'2px solid white',padding:'10px 10px',backgroundColor:'transparent',color:'white',fontWeight:'bold'}}>
                {initials}
                </button> 
                </div>
                
                </Link>
                }
              </div>
             
              </div>
            )
          })}

          
          </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;