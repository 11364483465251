import React from 'react'
import Navbar from '../Navigation/Navbar'
import Sidebar from '../Navigation/Sidebar'
import { TaskForm } from '../Task Pane/TaskForm'

export const ViewTasks = () => {
  return (
    <>
    <div>
    <div className="home">
    <Sidebar />
    
    <div className="homeContainer">
      <Navbar />
        <TaskForm />
    </div>
    </div>
    </div>
    </>
  )
}
