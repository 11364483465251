import React from 'react'
import './StylesHere/StepOne.scss'
import './StylesHere/AnimatedText.scss'


export const StepOne = ({lawfirmaccount,PartType,setPartType,courtRank,Station,Division,CaseType,ClientStatus,clientcategory,setCourtRank,setLawFirmAccount,setDivision,setStation,setCaseType,setClientStatus,setClientCategory,deathCert,policeabstract,setPoliceabsract,setChiefsletter,medicalsummary,setMedicalsummary,xray,setXray,receipts,setReceipts,idcopy,chiefsletter,setIdcopy,p3,setP3,burialpermit,setBurialpermit,marriagecert,setMarriagecert,birthcertificate,setBirthcertificate,Terminationletter,setTerminationletter,setDeathCert,nextStep,handleOnChange,id}) => {

  return ( 
      <> 
      
     <div className='MainStations'>

      <div className="animated-text" style={{
        display:'flex',
        flexDirection:'row',
        gap:'50px',
        alignItems:'center',
        marginBottom:'20px'
      }}> 
      <h2 className='circle'> Step 1 </h2>
      <div>
      <h1>Embrace the digital leap: Step 1, where paper trails transform!</h1>
      </div>
      </div>

      <div style={{display:'none'}}>
        <label htmlFor='lawfirmaccount'>Law Firm Account</label>
        <input 
        className='form-control' 
        name='lawfirmaccount'
        id='lawfirmaccount' 
        value={lawfirmaccount} 
        onChange={(e) =>{
          setLawFirmAccount(e.target.value);
      }}
        />

      </div>

      <div className='mb-3' style={{display:"flex",justifyContent:'center',alignItems:'center',gap:'25px',marginTop:'30px'}}>
      <label htmlFor='courtRank' style={{fontWeight:'bold'}}> Select Court Rank: </label>
      <select
      className='form-control' 
      id="courtRank"
      name="courtRank"
      value={courtRank || ""}
      onChange={(e) =>{
        setCourtRank(e.target.value);
    }}
      style={{textTransform:'uppercase',width:'600px',display:'flex',flexDirection:'column',gap:'10px'}}
    >
      <option>Select rank</option>
      <option>Supreme Court</option>
      <option>High Court</option>
      <option>Employment and Labour Relations Court</option>
      <option>Environment and Land Court</option>
      <option>Magistrate Court</option>
      <option>Kadhi Court</option>
      <option>Small Claims Court</option>
      <option>Tribunal</option>
    </select>
    </div>

    <hr />
    {(courtRank !== "" &&
    <div>

    <div className='CourtStation'>
      <div className='Selections'>
        <label htmlFor='Station'>Court Station</label>
        <select
        className='form-control' 
        id="Station"
        name="Station"
        value={Station || ""}
        onChange={(e) =>{
          setStation(e.target.value);
      }}
        
      >
        <option>Select...</option>
        <option>MILIMANI Commercial Magistrate Court</option>
        <option>MILIMANI Magistrate Court</option>
        <option>Mavoko Magistrate Court</option>
        <option>Kajiado Magistrate Court</option>
        <option>RUIRU Magistrate Court</option>
        <option>Kilungu Magistrate Court</option>
        <option>Ngong Magistrate Court</option>
        <option>Murang'a Magistrate Court</option>
        <option>KANGEMA Magistrate Court</option>
        <option>Kikuyu Magistrate Court</option>
        <option>ENGINEER Magistrate Court</option>
        <option>THIKA Magistrate Court</option>
        <option>NAROK Magistrate Court</option>
        <option>OTHAYA Magistrate Court</option>
        <option>WANGURU Magistrate Court</option>
        <option>KERUGOYA Magistrate Court</option>
        <option>BARICHO Magistrate Court</option>
        <option>TAWA Magistrate Court</option>
        <option>MACHAKOS Magistrate Court</option>
        <option>Nyeri Magistrate Court</option>
        <option>Kitui Magistrate Court</option>
        <option>NYAHURURU Magistrate Court</option>
        <option>NANYUKI Magistrate Court</option>
        <option>LIMURU Magistrate Court</option>
        <option>EMBU Magistrate Court</option>
        <option>MWINGI Magistrate Court</option>
        <option>GATUNDU Magistrate Court</option>
        <option>KARATINA Magistrate Court</option>
        <option>Mutomo Magistrate Court</option>
        <option>Naivasha Magistrate Court</option>
        <option>Kiambu Magistrate Court</option>
        <option>Githunguri Magistrate Court</option>
        <option>Kandara Magistrate Court</option>
        <option>Kenol Magistrate Court</option>
        <option>Molo Magistrate Court</option>
        <option>Nakuru Magistrate Court</option>
        <option>Kithimani Magistrate Court</option>
        <option>Kyuso Magistrate Court</option>
        <option>MAKUENI Magistrate Court</option>
        <option>MAKINDU Magistrate Court</option>
        <option>KANGUNDO Magistrate Court</option>
        <option>WUNDANYI Magistrate Court</option>
        <option>MASENO Magistrate Court</option>
        <option>MANDERA Magistrate Court</option>
        <option>KALOLENI Magistrate Court</option>
        <option>MOMBASA Magistrate Court</option>
        <option>VIHIGA Magistrate Court</option>
      </select>
      </div>

      <div className='Selections'>
      <label htmlFor='Division'> Court Division </label>
      <select
      className='form-control' 
      name='Division' 
      id='Division' 
      value={Division || ""}
      onChange={(e) =>{
        setDivision(e.target.value);
    }}
    >
      <option>Select...</option>
      <option>MAGISTRATE COURT COMMERCIAL</option>
      <option>Commercial Suit Division</option>
      <option>Magistrate Court Family</option>
    </select>
     
    </div>

      <div className='Selections'>
      <label htmlFor='Division'>Your Party</label>
      <select
      className='form-control' 
      name='Division' 
      id='Division' 
      value={PartType || ""}
      onChange={(e) =>{
        setPartType(e.target.value);
    }}
    >
      <option>Select...</option>
      <option>Plaintiff</option>
      <option>Claimant</option>
      <option>Responsent</option>
      <option>Defendant</option>
    </select>
    
    </div>


    </div>


    <div className='Popoups'>

    
    {(Division !== "" &&
    <div className='PopoupsA'>
      <label htmlFor='ClientStatus'> Client Status </label>
      <select
      className='form-control' 
      name='ClientStatus' 
      id='ClientStatus' 
      value={ClientStatus} 
      onChange={(e) =>{
        setClientStatus(e.target.value);
    }}
    >
      <option>Select...</option>
      <option>Minor</option>
      <option>Adult</option>
      <option>Deceased</option>
      
    </select>
      
    </div>
)}

    {(ClientStatus !== "" &&
      <div className='PopoupsA'>
        <label htmlFor='CaseType'> Select Case Type </label>
        <select
        className='form-control' 
        name='CaseType' 
        id='CaseType' 
        value={CaseType || ""}
        onChange={(e) =>{
          setCaseType(e.target.value);
      }}
      >
        <option>Select...</option>
        <option>Personal Injury (Running Down)</option>
        <option>Personal Injury (Work Injury)</option>
        <option>Environment and Land Cases</option>
        <option>Employment and Labour Cases</option>
        <option>Election Petitions</option>
        <option>Defamation (Libel / Slander)</option>
        <option>Medical Negligence</option>
        <option>Debt Collection Cases</option>
        <option>Divorce and Separation</option>
        <option>Succession or Inheritance Cases</option>
      </select>
       
      </div>
  )}
  
  

  </div>

  {CaseType !== "" && 
  <div>
  <hr />
  <div style={{marginTop:'20px', backgroundColor:'#1e1e2c',padding:'10px',borderRadius:'5px'}}>
   <h6 style={{textAlign:'center',textTransform:'uppercase',color:'white'}}>Kindly Tick the necessary documents as provided.</h6>
  </div> 

   <div className="row">
   <div className='mb-3' style={{display:'flex',flexDirection:'column'}}>

   {CaseType === 'Personal Injury (Running Down)' &&
    <div className="col-md-5">
    <div className="form-check m-3">
    <input
      className="form-check-input"
      type="checkbox"
      id="policeabstract"
      name="policeabstract"
      value="Police Abstract"
      checked={policeabstract}
      onChange={(e) => {
        setPoliceabsract(e.target.checked);
      }}
    />
    <label htmlFor="policeabstract">Police Abstract</label>
  </div>
   </div>
  }

  {CaseType === 'Personal Injury (Running Down)' &&
   <div className="col-md-5">
   {ClientStatus !== 'Deceased' &&
   <div className="form-check m-3">
   <input
   className="form-check-input"
   type="checkbox"
   id="medicalsummary"
   name="medicalsummary"
   value="Medical Summary"
   checked={medicalsummary}
   onChange={(e) =>{
    setMedicalsummary(e.target.checked);
  }}
   />
   <label htmlFor='medicalsummary' > Copy of Medical Summary </label>
   </div>
}
  </div>
  }

  {CaseType === 'Personal Injury (Running Down)' &&
  <div className="col-md-5">
  {ClientStatus !== 'Deceased' &&
  <div className="form-check m-3">
  <input
  className="form-check-input"
  type="checkbox"
  id="xray"
  name="xray"
  value="Client Xrays"
  checked={xray}
  onChange={(e) =>{
    setXray(e.target.checked);
  }}
  />
  <label htmlFor='xray'> Xrays </label>
  </div>
}
 </div>
  }

{CaseType === 'Personal Injury (Running Down)' &&
 <div className="col-md-5">
 <div className="form-check m-3">
 <input
 className="form-check-input"
 type="checkbox"
 id="receipts"
 name="receipts"
 value="Medical Receipts"
 checked={receipts}
 onChange={(e) =>{
  setReceipts(e.target.checked);
}}
 />
 <label htmlFor='receipts' > Copies of Receipts </label>
 </div>
</div>
  }
  

<div className="col-md-5">
 <div className="form-check m-3">
 <input
 className="form-check-input"
 type="checkbox"
 id="idcopy"
 name="idcopy"
 value="Copy of Id"
 checked={idcopy}
 onChange={(e) =>{
  setIdcopy(e.target.checked);
}}
 />
 <label htmlFor='idcopy' > Copy(s) of National Identity Card </label>
 </div>
</div>

{CaseType === 'Personal Injury (Running Down)' &&
<div className="col-md-2">
{ClientStatus !== 'Deceased' &&
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="p3"
name="p3"
value="Verified P3"
checked={p3}
onChange={(e) =>{
  setP3(e.target.checked);
}}
/>
<label htmlFor='p3' >Copy of p3</label>
</div>
}
</div>
}

{ClientStatus === 'Deceased' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="burialpermit"
name="burialpermit"
value="Burial Permit"
checked={burialpermit}
onChange={(e) =>{
  setBurialpermit(e.target.checked);
}}
/>
<label htmlFor='burialpermit' > Burial Permit </label>
</div>
</div>

}

{ClientStatus === 'Deceased' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="deathCert"
name="deathCert"
value="Death Certificate"
checked={deathCert}
onChange={(e) =>{
  setDeathCert(e.target.checked);
}}
/>
<label htmlFor='deathCert' > Death Certificate </label>
</div>
</div>
}

{ClientStatus === 'Deceased' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="chiefsletter"
name="chiefsletter"
value="Chiefs Letter"
checked={chiefsletter}
onChange={(e) =>{
  setChiefsletter(e.target.checked);
}}
/>
<label htmlFor='chiefsletter' > Chief's letter </label>
</div>
</div>

}

{CaseType === 'Divorce' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="marriagecert"
name="marriagecert"
value="Marriage certificate"
checked={marriagecert}
onChange={(e) =>{
  setMarriagecert(e.target.checked);
}}
/>
<label htmlFor='marriagecert' > Marriage certificate </label>
</div>
</div>
}

{ClientStatus === 'Minor' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="birthcertificate" 
name="birthcertificate"
value="Birth Certificate of Minor"
checked = {birthcertificate}
onChange={(e) =>{
  setBirthcertificate(e.target.checked);
}}
/>
<label htmlFor='birthcertificate' > Birth Certificate </label>
</div>
</div>

}


{CaseType === 'Employment' &&
<div className="col-md-4">
<div className="form-check m-3">
<input
className="form-check-input"
type="checkbox"
id="Terminationletter"
name="Terminationletter"
value="Termination letter"
checked={Terminationletter}
onChange={(e) =>{
  setTerminationletter(e.target.checked);
}}
/>
<label htmlFor='Terminationletter'> Termination letter </label>
</div>
</div>
}

</div>

<div>

{(CaseType === 'Personal Injury (Running Down)' &&
<div className='PopoupsA'>
<label htmlFor='clientcategory'> Client Category </label>
<select
className='form-control' 
name='clientcategory' 
id='clientcategory' 
value={clientcategory} 
onChange={(e) =>{
  setClientCategory(e.target.value);
}}
>
<option>Select...</option>
<option>Pedestrian</option>
<option>Passenger</option>
<option>Pillion Passenger</option>
<option>Driver</option>
<option>Rider</option>
<option>Cart Pusher</option>

</select>

</div> 
)}
</div>

</div>

</div>
  }


  <div className='mt-4' style={{display:'flex',justifyContent:'center'}}>
    <button 
    type='button' 
    className='btn btn-primary' 
    onClick={() => nextStep()}
    style={{width:'fit-content',backgroundColor:'red',outline:'none',border:'none'}}
    >
      Proceed
    </button>
  </div>
 
  </div>
    )}
  </div>
  </>
  )
}
