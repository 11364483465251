import React, { useContext, useEffect, useState } from 'react';
import './BringUps.scss';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import UserNavigation from '../UserNavigation/UserNavigation';
import { AuthContext } from '../../context/AuthContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const BringUps = () => {
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expenses, setExpenses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useContext(AuthContext);
  const Branch = user?.Branch;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
    /* Get the data using useEffect */
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/expenses/${Branch}?q=${searchQuery}`)
          .then((response) => {
            setExpenses(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, [searchQuery, Branch]);
      

  return (
    <div>
      <UserNavigation />
      <div className='Expenses'>
      
   
        <div>
        <div style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>
        <input 
        placeholder='search ...' 
        style={{borderBottom:'1px solid grey',fontWeight:'bold'}}
        onChange={handleInputChange}
        value={searchQuery}
        />
        </div>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" id="table-to-export-mpesa">
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>date_received</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((expense,index) =>{
                    return(
                      
                      <TableRow>
                        <TableCell style={{textTransform:'uppercase'}}>{expense.unique_code}</TableCell>
                        <TableCell>
                            {expense.expense_amount} 
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap'}}>{expense.description}</TableCell>
                        <TableCell>{expense.added_by}</TableCell>
                        <TableCell>{moment(expense.date_added).format('MMMM D, YYYY')}</TableCell>
                      
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={expenses.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default BringUps;
