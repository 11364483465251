import React, { useContext, useEffect, useState } from 'react'
import './Styles/Cards.css'
import Axios from 'axios';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { AuthContext } from '../context/AuthContext';


export const Divorce = () => {

    const [data, setData] = useState([]);
    const { currentUser } = useContext(AuthContext);

    const loadData = async () => {
      const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/statistics/matterstatistics");
      setData(response.data);
    }; 
  
    useEffect(() =>{
      loadData();
    }, []);


  return (
    <>
    <div className='widget'>
    <div className='left'>
    <span className='title'>Active Files</span> 

    {data.map((val) =>{
        return(  
        <span> 
        {currentUser?.firmname === val.lawfirmaccount && 
        <span>
        
        <span  className='counter'>
         {val.activePercentage.toFixed(2) + '%'} 
         </span>
        
        </span>
      }

        </span>
       
        )
      })}
    
    </div>

    </div>
    </>
  )
}
