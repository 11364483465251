import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from "react-router-dom";
import './Sidebar.scss'
import { useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import { DarkModeContext } from "../context/darkModeContext";
import MailIcon from '@mui/icons-material/Mail';


const Sidebar = () => {

const { currentUser,logout,currentFirm,logout2 } = useContext(AuthContext);
const { dispatch } = useContext(DarkModeContext);
const [firmname] =[currentUser.firmname || currentFirm.firmname];
const firmAccountName = firmname.slice(0, firmname.indexOf(" ", firmname.indexOf(" ") + 1));


  return ( 
    <div className="sidebar">

      <div className="top">

      <button>
        {firmAccountName}
      </button> 
      
      </div>
       
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <a href="/index" style={{ textDecoration: "none" }}>
          <li>
            <DashboardIcon className="icon" />
            <span>Dashboard</span>
          </li>
          </a>
          <p className="title">CASES</p>
          <a href="/NewCases" style={{ textDecoration: "none" }}>
            <li>
              <SettingsSystemDaydreamOutlinedIcon className="icon" />
              <span>File New Case</span>
            </li>
          </a>
          <a href="/ExistingCases" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Existing Cases</span>
            </li>
          </a>

          <p className="title">PAYMENTS</p>
          
          {currentFirm.role === 'Admin' &&
          <Link to="/RequisitionsAdmin" style={{ textDecoration: "none" }}>
            <li>
                <AttachMoneyIcon className="icon"/>
                <span>Accounts</span>
            </li>
          </Link>
          }

          {currentUser.role === 'Normal User' && 
          <Link to="/Requisitions" style={{ textDecoration: "none" }}>
            <li>
                <AttachMoneyIcon className="icon"/>
                <span>Accounts</span>
            </li>
          </Link>
          }

          {/* 
          <Link to="/InstructionsForm" style={{ textDecoration: "none" }}>
          <li>
              <AttachMoneyIcon className="icon"/>
              <span>Payment Schedule</span>
          </li>
        </Link>
        */}
        
         {/*  <p className="title">USEFUL</p>

           {currentFirm.role === 'Admin' &&
           <Link to="/ViewTasks" style={{ textDecoration: "none" }}>
             <li>
                 <InsertChartIcon className="icon" />
                 <span> Task Tracker</span>
             </li>
           </Link>
          }

          {currentUser.role === 'Normal User' &&
          <Link to="/TaskHome" style={{ textDecoration: "none" }}>
            <li>
                <InsertChartIcon className="icon" />
                <span> Tasks </span>
            </li>
          </Link>
         }
        */}


          <p className="title">SCHEDULES</p>
          <Link to="/Calender" style={{ textDecoration: "none" }}>
            <li>
                <InsertChartIcon className="icon" />
                <span>Calender</span>
            </li>
          </Link>
          <Link to="/causelist" style={{ textDecoration: "none" }}>
            <li>
                <InsertChartIcon className="icon" />
                <span> List</span>
            </li>
          </Link>
        
          <p className="title">SERVICE</p>
          {currentFirm.role === 'Admin' &&
          <Link to="/Staffmembers" style={{ textDecoration: "none" }}>
          <li>
            <PersonOutlineIcon className="icon" />
            <span>Members</span>
          </li>
          </Link>
          }

          {currentUser.role === 'Normal User' && 
          <Link to="/Leave" style={{ textDecoration: "none" }}>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Leave</span>
          </li>
          </Link>
         }
         
       
         {currentFirm.role === 'Admin' &&
          <Link to="/LeaveApproval" style={{ textDecoration: "none" }}>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Leave Request</span>
          </li>
          </Link>
          }
         

          
          <Link to="/Suggestions" style={{ textDecoration: "none" }}>
          <li>
            <MailIcon className="icon" />
            <span>Hmails</span>
          </li>
          </Link>
          

          <p className="title">USER</p>
          <Link to="/Profile" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>


          <li>
            <ExitToAppIcon className="icon" />
             
            {currentUser.role === 'Normal User' && 
            <Link className='link' to='/Auth'>
            {currentUser && currentUser.username ? (
              <span onClick={logout}>Logout</span>
            ) : (
              <Link className='link' to="/Auth">
              Login
              </Link>
            )}
           
            </Link>
            }

            {currentFirm.role === 'Admin' && 
            <Link to='/Auth' style={{ textDecoration: "none" }}>
            {currentFirm && currentFirm.id ? (
              <span onClick={logout2}>Logout</span>
            ) : (
                <Link className='link' to="/Auth">  
                  Login
                </Link>
              )}

              
              </Link>
              }


          </li>
          <p className="title">THEME</p>
        </ul>
        
      </div>
      
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
      
    </div>
  );
};

export default Sidebar;