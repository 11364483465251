import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import moment from 'moment';
import { AuthContext } from '../context/AuthContext';
import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export const Leave = () => {

    const [leaveData,setLeaveData] = useState([]);

    const { currentUser } = useContext(AuthContext);

    const [query, setQuery] = useState("");



    useEffect(() => {
      const fetchData = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/leave?q=${query}`);
        setLeaveData(res.data);
      };
      if (query.length === 0 || query.length > 2) fetchData();
    }, [query]);


      const getText = (html) =>{
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }

      const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leaveData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }; 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <>
  
    <div className='staff'>

    <div style={{marginTop:'30px'}}>
        <input 
        className='serachInput'
        type='search'
        placeholder='Search...'
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
        
        />
       <Link to='/LeaveForm'>
        <button  className='buttonlink' style={{float:'right',padding:'10px'}}>
        Request Leave
        </button>
        </Link>
    </div>

    <TableContainer component={Paper} >
    <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
        <TableHead className='head'>
          <TableRow className='rows'>
                <TableCell className="tableCell rows">Citation</TableCell>
                <TableCell className="tableCell rows">Reaquest date</TableCell>
                <TableCell className="tableCell rows">Return Date</TableCell>
                <TableCell className="tableCell rows">Reasons for Leave</TableCell>
                <TableCell className="tableCell rows">Status</TableCell>
         </TableRow>
        </TableHead>
        <TableBody> 
          {(rowsPerPage > 0
            ? leaveData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : leaveData
          ).map((val,index) =>{
              return(
                  <TableRow key={val.id} className='rows'>
                  {currentUser.fullname === val.fullname &&   <TableCell className="tableCell rows">{val.fullname}</TableCell> }
                  {currentUser.fullname === val.fullname &&   <TableCell className="tableCell rows">{moment(val.reqdate).format('Do MMMM YYYY')}</TableCell>}
                  {currentUser.fullname === val.fullname &&   <TableCell className="tableCell rows">{moment(val.returndate).format('Do MMMM YYYY')}</TableCell>}
                  {currentUser.fullname === val.fullname &&     <TableCell className="tableCell rows">{getText(val.reasons)}</TableCell> }
                  {currentUser.fullname === val.fullname &&     
                    <TableCell className="tableCell rows" 
                      
                      style={{
                            backgroundColor: 
                          ((val.status === 'Accepted' && 'green')  ||
                          (val.status === 'Denied' && 'red'))
                      }}
                      > {val.status} </TableCell> }
                
                  </TableRow> 
              )
          })}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}


        </TableBody>
        <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={4}
            count={leaveData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
        </Table>
      </TableContainer>
            
        </div>
      
    </>
  )
}
