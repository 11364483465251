import React, { useState } from 'react'
import { useContext } from 'react'
import './StylesHere/AnimatedText.scss'
import './StylesHere/Step2.scss'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AuthContext } from '../context/AuthContext';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export const StepTwo = ({ clientInfo,Insurance,setInsurance,CaseType,ClientStatus,FullName,setFullName,parent,setParent,firstadmin,setFirstadmin,secondadmin,setSecondadmin,parentminor,setParentminor,Gender,setGender,IdNumber,setIdNumber,dob,setDod,accidentarea,setAccidentarea,Nationality,setNationality,residence,setResidence,ChaserName,setChaserName,nextStep,perviousStep,handleOnChange}) => {
 

  const [error,setError] = useState(null) 

  const { checkuser } = useContext(AuthContext);

  const handleCheck = async (e) => { 
    e.preventDefault();
    try {
      await checkuser(clientInfo);
    } catch (err) {
      setError(err.response.data); 
    }
  };

  const [open, setOpen] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div className="animated-text" style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            gap:'50px',
                                            alignItems:'center',
                                            marginBottom:'20px'
                                          }}> 
      <h2 className='circle'> Step 2 </h2>
      <div>
      <h1>Client Chronicles: Unveil their stories, empower their legal journey!</h1>
      </div>
    </div>
    <div style={{
      display:'flex',
      justifyContent:'flex-end'
    }}>
      <button onClick={handleClickOpen} style={{
        backgroundColor:'#241842',
        padding:'10px 10px',
        border:'none',
        borderRadius:'5px',
        color:'white'
      }}>
        Add Case Party
      </button>
    </div>
    <BootstrapDialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={open}
    TransitionComponent={Transition}
    style={{width:'auto'}}
    >
    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
    Add Case Party
  </BootstrapDialogTitle>
  <DialogContent dividers>

  <Typography gutterBottom style={{width:'570px'}}>
      
    <div className='mb-3'>
      <label htmlFor='IdNumber'> National Identity Number  </label>
      <input 
        type='number' 
        className='form-control' 
        name='IdNumber' 
        id='IdNumber' 
        placeholder='Enter IdNumber'
        value={IdNumber || ""} 
        onChange={(e) =>{
          setIdNumber(e.target.value);
        }}
      />
      <button className='buttonlogin' onClick={handleCheck}>CHECK</button>
      {error && <p style={{fontWeight:'bold'}} className='pAuth'>{error} </p>}
    </div>
  </Typography>

    <Typography gutterBottom>
      {error === 'Client not registered. Continue with registration' &&  
        <div className='mb-3'>
          <label htmlFor='FullName'> Full Name </label>
          <input 
            type='text' 
            className='form-control' 
            name='FullName' 
            id='FullName' 
            placeholder="Enter client's full name"
            value={FullName || ""} 
            onChange={(e) =>{
              setFullName(e.target.value);
            }}
          />
        </div>
      }
    </Typography>

    {error === 'Client not registered. Continue with registration' &&  
    <Typography gutterBottom style={{display:'flex',justifyContent:'space-between',gap:'20px'}}>
        {ClientStatus === 'Minor' && 
          <div className='mb-3' style={{width:'300px'}}>
            <label htmlFor='parent'> Full Name of Next Friend </label>
            <input 
              type='text' 
              className='form-control' 
              name='parent' 
              id='parent' 
              placeholder="Enter parent's name"
              value={parent || ""} 
              onChange={(e) =>{
                setParent(e.target.value);
              }}
            />
        </div>
      }
      {ClientStatus === 'Minor' && 
        <div className='mb-3' style={{width:'300px'}}>
          <label htmlFor='parentminor'> Relationship to minor </label>
          <select
            className='form-control' 
            id="parentminor"
            name="parentminor"
            value={parentminor || ""} 
            onChange={(e) =>{
              setParentminor(e.target.value);
          }}
          >
            <option>Select...</option>
            <option>Mother</option>
            <option>Father</option>
            <option>Brother</option>
            <option>Sister</option>
            <option>Grand Mother</option>
            <option>Grand Father</option>
          </select>
        </div>
      }
    </Typography>
    }

    {error === 'Client not registered. Continue with registration' &&  
    <Typography gutterBottom style={{display:'flex',justifyContent:'space-between',gap:'20px'}}>
      
      {ClientStatus === 'Deceased' && 
          <div className='mb-3' style={{width:'300px'}}>
            <label htmlFor='firstadmin'> 1st Admin Full Name </label>
            <input 
              type='text' 
              className='form-control' 
              name='firstadmin' 
              id='firstadmin' 
              placeholder="Enter 1st Admin name"
              value={firstadmin || ""} 
              onChange={(e) =>{
                setFirstadmin(e.target.value);
              }}
            />
          </div>
        }
        
        {ClientStatus === 'Deceased' && 
          <div className='mb-3' style={{width:'300px'}}>
            <label htmlFor='secondadmin'> 2nd Admin Full Name </label>
            <input 
              type='text' 
              className='form-control' 
              name='secondadmin' 
              id='secondadmin' 
              placeholder="Enter 1st Admin name"
              value={secondadmin || ""} 
                onChange={(e) =>{
                  setSecondadmin(e.target.value);
              }}
            />
          </div>
        }
    </Typography>
    }

    <Typography gutterBottom>
      {error === 'Client not registered. Continue with registration' &&  
        <div className='mb-3'>
          <label htmlFor='Gender'> Gender </label>
          <select
            className='form-control' 
            id="Gender"
            name="Gender"
            value={Gender || ""} 
            onChange={(e) =>{
              setGender(e.target.value);
            }}
          >
            <option>Select...</option>
            <option>Male</option>
            <option>Female</option>
            <option>Nonbinary</option>
          </select>
        </div>
      }
    </Typography>

    <Typography gutterBottom style={{display:'flex',justifyContent:'space-between',gap:'20px'}}>

        {error === 'Client not registered. Continue with registration' &&  
          <div className='mb-2' style={{width:'300px'}}>
            <label htmlFor='residence'>Residence</label>
            <input 
              type='text' 
              className='form-control' 
              name='residence' 
              id='residence' 
              value={residence || ""} 
              placeholder='Enter area of residence'
              onChange={(e) =>{
                setResidence(e.target.value);
              }}
            />
          </div>
        }

        {error === 'Client not registered. Continue with registration' &&  
          <div className='mb-3' style={{width:'300px'}}>
              {CaseType === 'Personal Injury (Running Down)' &&
                <div>
                  <label htmlFor='Insurance'> Insurance (Blamed).  </label>
                  <input 
                  type='text' 
                  className='form-control' 
                  name='Insurance' 
                  id='Insurance' 
                  value={Insurance || ""} 
                  placeholder='Enter the insurance'
                  onChange={(e) =>{
                    setInsurance(e.target.value);
                  }}
                  />
                  </div>
              }
          </div> 
        }
      
        {error === 'Client not registered. Continue with registration' &&  
          <div className='mb-2' style={{width:'300px'}}>
            {CaseType === 'Personal Injury (Running Down)' &&
            <div>
              <label htmlFor='ChaserName'>Chaser</label>
              <input 
                type='text' 
                className='form-control' 
                name='ChaserName' 
                id='ChaserName' 
                value={ChaserName || ""} 
                placeholder='Enter Chaser name'
                onChange={(e) =>{
                  setChaserName(e.target.value);
                }}
              />
              </div>
            }
          </div>
        
        }
    </Typography>

  </DialogContent>
  <DialogActions>
    <Button autoFocus onClick={handleClose}>
      Save changes
    </Button>
  </DialogActions>
  </BootstrapDialog>

  <div style={{
    display:"flex",
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    fontWeight:'500'
  }}>
  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenTable(!openTable)}
      >
        {openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
    <TableCell component="th" scope="row">
    {}
    </TableCell>
    <TableCell align="right">Samuel Wanjiru</TableCell>
    <TableCell align="right">32188768</TableCell>
    <TableCell align="right">Adult</TableCell>
    <TableCell align="right">Male</TableCell>
  </TableRow>
  <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={openTable} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography variant="h6" gutterBottom component="div">
            Other Details
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>Residence</TableCell>
                <TableCell>Chaser</TableCell>
                <TableCell align="right">Insurance</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nairobi
                  </TableCell>
                  <TableCell>Cheye</TableCell>
                  <TableCell align="right">Directline</TableCell>
                  <TableCell align="right">
                    {}
                  </TableCell>
                </TableRow>
            
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  </TableRow>
  </div>

      <div style={{marginTop:"50px"}}>
        {error === 'Client not registered. Continue with registration' && 
          <div className='float-end'>
            <button 
            type='button' 
            className='btn btn-primary' 
            onClick={() => nextStep()}
            style={{width:'200px'}}
            >Next</button>
          </div>
        }
          <div className='float-start'  >
              <button 
              type='button' 
              className='btn btn-dark' 
              onClick={() => perviousStep()}
              style={{width:'200px'}}
              >Back</button>
          </div>
      </div>
    </>
  )
}
