import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import '../Task Pane/IssueTask.css' 
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';

const Schedule = () => {

    const [schedule, setSchedule] = useState({
        filereference:'',
        decretal:'',
        terms:'',
        agreementDate:'',
        registeredby:'',
        scheduleStatus:''
    })

    const {filereference,decretal,terms,agreementDate,registeredby,scheduleStatus} = schedule;

    const handleOnChange = (e) =>{ 
        const {name, value} = e.target
        setSchedule({...schedule,[name]:value})
      } 

      const {id} = useParams();
      const history = useNavigate();

      const handleSubmit = (e) =>{

        if(!filereference || !decretal || !terms || !agreementDate || !registeredby || !scheduleStatus ){
          toast.error("Kindly fill all the fields")
        }else{
          if(!id){
            axios.post(process.env.REACT_APP_API_ADDRESS + "api/schedules", 
          schedule).then(() =>{
            setSchedule({filereference:"",decretal:"",terms:"",agreementDate :"",registeredby:'',scheduleStatus:''})
          }).catch((err) => toast.err(err.response.data));
          toast.success("Agreement added successfully")
          }else{
            axios.put(process.env.REACT_APP_API_ADDRESS + `api/schedules/${id}`, 
          schedule).then(() =>{
            setSchedule({filereference:"",decretal:"",terms:"",registeredby:'',scheduleStatus:''})
          }).catch((err) => toast.err(err.response.data));
          toast.success("Agreement updated successfully")
          }
          
          setTimeout(() => history('/InstructionsForm'),700);
        }
      }
    
      useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `api/schedules/${id}`).then((resp) => setSchedule({...resp.data[0]}));
      }, [id]);

  return (
    <>
    <div className="home">
    <Sidebar />
    
    <div className="homeContainer">
      <Navbar />
        <div className='main'>
            <div className='file-card'>
                <div className='mb-3' style={{marginTop:'10px'}}>
                    <label htmlFor='filereference'> File Reference </label>
                    <input 
                        className='form-control'
                        id='filereference'
                        name='filereference'
                        required
                        value={schedule.filereference || ""}
                        onChange={handleOnChange}
                    />
                </div>

                <div className='mb-3' style={{marginTop:'10px'}}>
                    <label htmlFor='decretal'> Decretal Sum </label>
                    <input 
                        className='form-control'
                        id='decretal'
                        name='decretal'
                        required
                        value={schedule.decretal || ""}
                        onChange={handleOnChange}
                    />
                </div>

                <div className='mb-3'>
                    <label htmlFor='terms'>Payment Terms</label>
                    <textarea 
                        className='form-control'
                        id='terms'
                        name='terms'
                        value={schedule.terms || ""}
                        onChange={handleOnChange}
                    />
                </div>

                <div className='mb-3'>
                    <label htmlFor='agreementDate'>Commencement Date</label>
                    <input 
                        className='form-control'
                        type='datetime-local'
                        id='agreementDate'
                        name='agreementDate'
                        value={schedule.agreementDate || ""}
                        onChange={handleOnChange}
                    />
                </div>

                <div className='mb-3'>
                    <label htmlFor='scheduleStatus'> Status </label>
                    <select
                        className='form-control'
                        id='scheduleStatus'
                        name='scheduleStatus'
                        value={schedule.scheduleStatus || ""}
                        onChange={handleOnChange}
                    >
                    <option>Select...</option>
                    <option>Active</option>
                    <option>Settled</option>
                    <option>Terminated</option>
                    </select>
                </div>

                <div className='mb-5'>
                <label htmlFor='registeredby'> Registered By: </label>
                <select
                    className='form-control'
                    id='registeredby'
                    name='registeredby'
                    value={schedule.registeredby || ""}
                    onChange={handleOnChange}
                >
                <option>Select...</option>
                <option>James Ichaura</option>
                <option>Patrick Waiganjo</option>
                <option>Lawrence Kiptanui</option>
                <option>Idah Kisiangani</option>
                </select>
            </div>

                <div className='mb-3'>
                    <input type='submit' onClick={() => handleSubmit()} className='btn btn-primary btn-block' value={id? "Edit Agreement": "Submit Agreement"}/>
                </div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}

export default Schedule