import React, { useEffect, useState } from 'react'
import './Task.css'
import { Link, useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import Axios from 'axios';
import moment from 'moment';
import '../Styles/Search.css'
import VisibilityIcon from '@mui/icons-material/Visibility';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import '../ExistingCaseTables/table.scss'




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




export const TaskForm = () => {

  const [taskdata, setTaskData] = useState([]);
  const [query, setQuery] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks?q=${query}`);
      setTaskData(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - taskdata.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  return (
    <div style={{paddingLeft:'50px',paddingRight:'50px'}}>

    <input 
    className='serachInput'
    type='search'
    placeholder='Search...'
    onChange={(e) => setQuery(e.target.value.toLowerCase())}
    />

   


    <Link to={'/IssueTask'}>
    <button 
    style={{float:'right',padding:'5px',marginTop:'10px'}} 
    className='buttonlink' 
    >
      Issue Task
    </button>
    </Link>

  <TableContainer component={Paper} >
   <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
    <TableHead className='head'>
      <TableRow className='rows'>
        <TableCell className="tableCell rows">#</TableCell>
        <TableCell className="tableCell rows">Employee Name</TableCell>
        <TableCell className="tableCell rows">Task</TableCell>
        <TableCell className="tableCell rows">Decsription</TableCell>
        <TableCell className="tableCell rows">Date Issued</TableCell>
        <TableCell className="tableCell rows">Task Age</TableCell>
        <TableCell className="tableCell rows">Status</TableCell>
        <TableCell className="tableCell rows">Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
    {(rowsPerPage > 0
      ? taskdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : taskdata
    ).map((val,index) =>{
      return(
        <TableRow key={val.id} className='rows'> 
        <TableCell className="tableCell rows">{index + 1}</TableCell>
        <TableCell className="tableCell rows">{val.name}</TableCell>
        <TableCell className="tableCell rows">{val.taskname}</TableCell>
        <TableCell className="tableCell rows">{val.description}</TableCell>
        <TableCell className="tableCell rows">{moment(val.dateissued).format('DD-MM-YY')}</TableCell>
        <TableCell className="tableCell rows">{moment(val.dateissued).fromNow()}</TableCell>
        <TableCell className="tableCell rows">
        <span className={`status2 ${val.status}`}>{val.status} </span>
        </TableCell>
        <TableCell className="tableCell rows">
        <Link to={`/updateTask/${val.id}`}>
          <button className='btnone btn-edit'><EditIcon /></button> 
        </Link>
        <Link to={`/taskview/${val.id}`}>
            <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
        </Link> 
        </TableCell>
      </TableRow>
      )
    })}

    {emptyRows > 0 && (
      <TableRow style={{ height: 53 * emptyRows }}>
        <TableCell colSpan={6} />
      </TableRow>
    )}

</TableBody>
<TableFooter>
<TableRow>
  <TablePagination
    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
    colSpan={4}
    count={taskdata.length}
    rowsPerPage={rowsPerPage}
    page={page}
    SelectProps={{
      inputProps: {
        'aria-label': 'rows per page',
      },
      native: true,
    }}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    ActionsComponent={TablePaginationActions}
  />
</TableRow>
</TableFooter>
</Table>
</TableContainer>
</div>
  )
}
