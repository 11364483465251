import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {useReactToPrint} from 'react-to-print'
import axios from "axios";
import './Users.css';
import '../ExistingCaseTables/table.scss' 
import './Styletable.css' 
import './Documents.scss'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { toast } from 'react-toastify'; 
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './Notices.scss'
import signature from './SIGNATURE.PNG'
import AddIcon from '@mui/icons-material/Add';
import pdfsvg from './pdfimg.png'
import CreateIcon from '@mui/icons-material/Create';

// Doc upload 
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";

// import { ref, uploadBytes,listAll,getDownloadURL } from 'firebase/storage'

import { v4 } from 'uuid'
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";
import '../pages/home.scss'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import './DocUpload.scss'
import './DetailsTable.scss'
import Navigation from "../Components/NavOfficial/Navigation";




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};








function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




const User = () => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const current_time = new Date()

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [clientInfo, setClientInfo] = useState({});
  const [activity, setActivity] = useState({});
  const [demand,setDemmand] = useState(false);
  const [statutory,setStatutory] = useState(false);
  const [pretrial,setPretrial] = useState(false);
  const [plaint, setPlaint] = useState(false)

  const [open, setOpen] = React.useState(false);
  const [openDiarize, setOpenDiarize] = useState(false)
  const [openHmail,setOpenHmail] = useState(false)
  const handleOpenDiarize = () => setOpenDiarize(true)
 const handleOpenHmail = () => setOpenHmail(true)
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseDiarize = () => setOpenDiarize(false)
  const handleCloseHmail = () =>setOpenHmail(false)


  const {id} = useParams();
  const {FullName} = useParams();

  const { currentUser } = useContext(AuthContext);

  /* Event posting and getting */ 

  /* Setting variables */
  const [FileEvents, setFileEvents] = useState([]);
  const [specificEvents, setSpecificEvens] = useState([]);


  const [fullname,setFullName] =  [currentUser.fullname || currentUser.firmname]
  const [lawfirmaccount,setLawFirmAccount] =  [currentUser.firmname || currentUser.firmname]
  const [EventsId,setEventsId] =  [clientInfo.id]
  const [currentdate,setCurrentDate] = useState('');
  const [upcomingdate,setUpcomingDate] = useState('');
  const [applicationDate, setApplicationDate] = useState('');
  const [actionTo,setActionTo] = useState('');
  const [testifiedBy,setTestifiedBy] = useState('');
  const [outcome,setOutcome] = useState('');
  const [eventFile,setEventFile] = useState('');
  const [fileid,setfileid] = [clientInfo.fileref]
  const [Defendant,setDefendant] = [clientInfo.defendant]
  const [clientname,setClientname] = [clientInfo.FullName]
  const [documentlabel, setDocumentLabel] = useState([]);
  const [url, setUrl] = useState([]);
  const [filingdate,setFilingDate] = [moment().format("YYYY-MM-DD hh:mm:ss")];

  const [advancementDate,setAdvancementDate] = useState('');
  const [advancedAmount,setAdvancedAmount] = useState('');



  
 


  /* Getting the events */
  const loadEvents = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/EventsFile/getEvents/${id}`);
    setFileEvents(response.data);
  }; 

  useEffect(() =>{
    loadEvents();
  });


  /* Getting the specific event for notice generation */
  const loadSpecific = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/EventsFile/getspecific/${id}`);
    setSpecificEvens(response.data);
  }; 

  useEffect(() =>{
    loadSpecific(); 
  });




  const [docsData, setDocsData] = useState([])

  
  /* Getting the documents */
  const Docs = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/Documents/getDocs/${id}`);
    setDocsData(response.data);
  }; 

  useEffect(() =>{
    Docs();
  });

  /* Posting events*/
  const SubmitEvent = (e) =>{
    e.preventDefault();

    axios.post(process.env.REACT_APP_API_ADDRESS + 'api/EventsFile/add', 
    {eventFile:eventFile,currentdate:currentdate,
      upcomingdate:upcomingdate,outcome:outcome,
      fullname:fullname,EventsId:EventsId,fileid:fileid,
      Defendant:Defendant,clientname:clientname,applicationDate:applicationDate,
      actionTo:actionTo,testifiedBy:testifiedBy,
      lawfirmaccount:lawfirmaccount
}).then(() => {
    alert('successful insert');
    
});
toast.success("Activity Added Successfuly")
};

const submitFile = (e) =>{
  e.preventDefault();

  axios.post(process.env.REACT_APP_API_ADDRESS + 'api/Documents/Add', 
  {documentlabel:documentlabel,url:url,
    filingdate:filingdate,
    fullname:fullname,EventsId:EventsId,clientname:clientname
}).then(() => {
  alert('successful insert');
  
});
toast.success("Document uploaded successfuly")
};
 

const submitAmount = (e) =>{
  e.preventDefault();

  axios.post(process.env.REACT_APP_API_ADDRESS + 'api/Advancement/Add', 
  {advancementDate:advancementDate,advancedAmount:advancedAmount,
    fullname:fullname,EventsId:EventsId
}).then(() => {
  alert('successful insert');
  
});
toast.success("Document uploaded successfuly")
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/newcases/${id}${FullName}`);
        setClientInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  const loadData = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + "api/newcases");
    setActivity(response.data);
  }; 

  useEffect(() =>{
    loadData(); 
  }, []);

  const [dataUsers, setDataUsers] = useState([]);

  const loadDataUsers = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
    setDataUsers(response.data);
  }; 

  useEffect(() =>{
    loadDataUsers();
  }, []); 

  
 

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Document'
  });



  const inputRef = useRef(null);


  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
    });
  };

 

  const [file, setFileUpload] = useState("");
  const [perc,setPerc] = useState(null)

  useEffect(() =>{
      const uploadFile= () =>{
        const name = new Date().getTime() + file.name
        console.log(name)
        const storageRef = ref(storage, file.name);

        const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on('state_changed', 
  (snapshot) => {
 
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    setPerc(progress)
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
        default:
          break;
    }
  }, 
  (error) => {
    
  }, 
  () => {

    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      setUrl(downloadURL)
    });
  }
);


      };
      file && uploadFile();
  },[file])






  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - FileEvents.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleChangeCheck = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };

  const [messageReceivers, setMessageReceivers] = useState([]);

  const Receivers = async () => {
    const response = await axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
    setMessageReceivers(response.data);
  }; 

  useEffect(() =>{
    Receivers();
  }, []); 

  {/* Message data */}

  const [sender,setSender] =  [currentUser.fullname || currentUser.firmname];
  const [senderId,setSenderId] = [currentUser.id || currentUser.id];
  const [DateSent,setDateSent] =  [moment().format("YYYY-MM-DD hh:mm:ss")];
  const [Receiver,SetReceiver] = useState('');
  const [Subject,setSubject] = useState('');
  const [Message,setMessage] = useState('');
  const [fileId,setFileId] = [clientInfo.id];
  const [fileref,setFileref] = [clientInfo.fileref];


  const SubmitMessage = () =>{
    axios.post(process.env.REACT_APP_API_ADDRESS + 'api/suggestions/insertsuggestion', 
    {sender:sender,senderId:senderId,DateSent:DateSent,
      Receiver:Receiver,Subject:Subject,Message:Message,fileId:fileId,fileref:fileref}).then(() => {
});

toast.success("Your message has been sent successfully.") 
}

const [str] =[currentUser.firmname || currentUser.firmname];
const [firstTwoWords] = str.slice(0, str.indexOf(" ", str.indexOf(" ") + 1));
const initials = firstTwoWords.slice(0, 1) + firstTwoWords.slice(firstTwoWords.indexOf(" ") + 1, firstTwoWords.indexOf(" ") + 2);


{/* Getting the messages */}

const [messageFile, setMessageFile] = useState([]);
const loadMessages = async () => {
  const response = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions/fileMessages/${id}`);
  setMessageFile(response.data);
}; 

useEffect(() =>{
  loadMessages();
});
 
  return (

    <>
    <div className="home">
    
    <Navigation />
    
    <div className="homeContainer">
      <Navbar />
    <div style={{marginTop:'30px'}}>
    <h4 style={{textAlign:'center'}} >Tracking Id: <span style={{textDecoration:'none',textAlign:'center',fontWeight:'700',fontSize:'18px'}}>{clientInfo.IdNumber}</span> </h4>
    <h4 style={{textAlign:'center'}} >Case Number: <span style={{textDecoration:'none',textAlign:'center',fontWeight:'700',fontSize:'18px'}}>MCCC/{clientInfo.caseNumber}/{clientInfo.caseYear}</span> </h4>
    <h3 style={{textAlign:'center'}} >Citation: <span style={{textDecoration:'none',textAlign:'center',fontWeight:'700',fontSize:'18px'}}>{clientInfo.FullName} vs {clientInfo.defendant}</span> </h3>
    </div>
  
    <div className="container"> 
    <div className="bloc-tabs">
    
      <button
        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
        onClick={() => toggleTab(1)}
      >
      Case Summary
      </button>
      <button
        className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
        onClick={() => toggleTab(2)}
      >
        Client Details
      </button>
      <button
        className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
        onClick={() => toggleTab(3)}
      >
        Case Activities
      </button>

    <button
    className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
    onClick={() => toggleTab(5)}
  >
    Documents
  </button>
  <button
  className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
  onClick={() => toggleTab(6)}
>
  Instructions
</button>

<button
className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
onClick={() => toggleTab(7)}
>
Miscellaneous
</button>

<button
className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
onClick={() => toggleTab(8)}
>
Drafts
</button>

<button
className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
onClick={() => toggleTab(9)}
>
Hmails
</button>

</div>

    <div className="content-tabs">
      <div
        className={toggleState === 1 ? "content  active-content" : "content"}
      >

<table className="CaseDetails">

  <div className="PartADetails">
            <tr>
              <td>Clients Name</td> <span>{clientInfo.FullName}</span>
            </tr>
            <tr> 
              <td>Case Type</td> <span>{clientInfo.CaseType}</span>
            </tr>
            <tr>
            <td>File Reference</td> <span>{clientInfo.fileref}</span>
          </tr>
          <tr>
          <td>Registration Date</td> <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}</span>
        </tr>
        <tr>
        <td>Description</td> <span>{clientInfo.summary}</span>
      </tr>
      <tr>
      <td>File Activity</td> <span>
      {specificEvents.map((val) =>{
        return(
          <div>
            {(current_time > new Date(val.upcomingdate) &&  
              <span style={{color:'red',fontWeight:'bold'}}>
              Attention!! File has been dormant for  {moment(val.upcomingdate).fromNow(true)} 
              </span>) 
            } 

          {!val.upcomingdate === true && 'File has never been fixed before any court'}
          </div>
        )
      })}
      </span>
      </tr>
  </div>


  <div className="PartBDetails">
        <tr>
          <td>Station</td> <span>{clientInfo.Station}</span>
        </tr>
        <tr>
          <td>Registered By</td> <span>{clientInfo.regby}</span>
        </tr>
        <tr>
          <td>Defence Appearance</td> <span>{clientInfo.defenceappearance}</span>
        </tr>
        <tr>
          <td>Defence Firm Name</td> <span>{clientInfo.defencefirm}</span>
        </tr>
        <tr>
          <td>Defence Email</td> <span><a style={{textDecoration:'none'}} href={`mailto:${clientInfo.defenceemail}`}> {clientInfo.defenceemail}</a></span>
        </tr>
      </div>
    </table>
    
  </div>

  <div className={toggleState === 2 ? "content  active-content" : "content"} >  
      
  <table className="CaseDetails">

      <div className="PartADetails">
        <tr>
          <td>Client Status</td> <span>{clientInfo.ClientStatus}</span>
        </tr>
        <tr>
          <td>Insurance</td> <span>{clientInfo.Nationality}</span>
        </tr>
        <tr>
          <td>Gender</td> <span>{clientInfo.Gender}</span>
        </tr>
        <tr>
          <td>Mobile Contact</td> <span>{clientInfo.Mobile1}</span>
        </tr>
        <tr>
          <td>Alternative Mobile</td> <span>{clientInfo.Mobile2}</span>
        </tr>

  <div>
    <table  className="CaseDetails">
    <div className="PartADetails">
        <thead>
          <th style={{textTransform:'uppercase',textDecoration:'underline'}}>List OF Documents</th>  
        </thead>
        <tr><td>{clientInfo.selectedDocuments}</td> <span>{!clientInfo.selectedDocuments === false && <span>Submitted</span>} </span> </tr>
        <tr><td>{clientInfo.medicalsummary}</td> <span>{!clientInfo.medicalsummary === false && <span>Submitted</span>} </span></tr>
        <tr><td>{clientInfo.xray}</td> <span>{!clientInfo.xray === false && <span>Submitted</span>} </span></tr>
        <tr><td>{clientInfo.receipts}</td> <span>{!clientInfo.receipts === false && <span>Submitted</span>} </span></tr>
        <tr><td>{clientInfo.idcopy}</td> <span>{!clientInfo.idcopy === false && <span>Submitted</span>} </span></tr>
        <tr><td>{clientInfo.p3}</td> <span>{!clientInfo.p3 === false && <span>Submitted</span>} </span></tr>
        <tr><td>{clientInfo.burialpermit}</td> <span>{!clientInfo.burialpermit === false && <span>Submitted</span>} </span></tr> 
        </div>
    </table>   
  </div>
</div>




  <div className="PartBDetails">
        {clientInfo.CaseType === "Running Down" && 
        <tr>
          <td>Chaser Name</td> <span>{clientInfo.ChaserName}</span>
        </tr>
      }
      {clientInfo.CaseType === "Running Down" &&
        <tr>
          <td>Chaser Mobile</td> <span>{clientInfo.ChaserMobile}</span>
        </tr>
      }
        <tr>
        <td>Defendant (s)s</td> <span>{clientInfo.defendant}</span>
      </tr>
      {clientInfo.ClientStatus === "Minor" && 
      <tr>
      <td>Parent's Name (Minor)</td> <span>{clientInfo.parent}</span>
      </tr>
        }
      {clientInfo.ClientStatus === "Deceased" &&
      <tr>
      <td>1st Administrator</td> <span>{clientInfo.firstadmin}</span>
      </tr>
        }
      {clientInfo.ClientStatus === "Deceased" &&
      <tr>
      <td>2nd Administrator</td> <span>{clientInfo.secondadmin}</span>
      </tr>
      }

  </div>
  </table>
</div>

      <div
        className={toggleState === 3 ? "content  active-content" : "content"}
      > 
      <div style={{display:'flex',justifyContent:'space-between'}}>

        <div>
          <input 
          className="activitysearch"
          type='search'
          />
        </div>
      
      <div>  

        <button onClick={handleOpenDiarize} style={{backgroundColor:'#02b159',color:'white',padding:'10px 25px',borderRadius:'5px',fontWeight:'500'}}><AddIcon />Diarize</button>
        <Modal
        open={openDiarize}
        onClose={handleCloseDiarize}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
                
          
            <form style={{padding:'10px',paddingLeft:'30px',paddingRight:'30px',width:'100%',border:'none'}}>
              <div className='mb-3'>
              <label htmlFor="eventFile">Activity</label>
              <select 
              className="form-control"
              name="eventFile"
              id="eventFile"
              value={eventFile || ""}
              onChange={(e) =>{
                setEventFile(e.target.value);
            }}
            >
            <option>Select...</option>
            <option>Case Filing</option>
            <option>Service</option>
            <option>1st Medical Examination</option>
            <option>2nd Medical Examination</option>
            <option>Pre-trial mention</option>
            <option>Mention</option>
            <option>Hearing of Application</option>
            <option>Hearing</option>
            <option>Defence Hearing</option>
            <option>Judgement</option>
            <option>Extraction of Decree</option>
            <option>Extraction of warrants</option>
            <option>Settled / paid</option>
            </select>
          </div>  

          {eventFile === "Hearing of Application" && 
          <div className='mb-3'>
            <label htmlFor="currentdate"> Application Date </label>
            <input 
                type='date'
                className='form-control'
                name="currentdate"
                id="currentdate"
                value={applicationDate || " "}
                onChange={(e) =>{
                  setApplicationDate(e.target.value);
              }}
            />
        </div>
        }


          <div className='mb-3'>
            <label htmlFor="currentdate"> Current Date </label>
            <input 
              type='date'
              className='form-control'
              name="currentdate"
              id="currentdate"
              value={currentdate || " "}
              onChange={(e) =>{
                setCurrentDate(e.target.value);
            }}
            />
          </div>

          <div className='mb-3'>
            <label htmlFor="upcomingdate"> Upcoming Date </label>
            <input 
              type='date'
              className='form-control'
              name="upcomingdate"
              id="upcomingdate"
              value={upcomingdate}
              onChange={(e) =>{
                setUpcomingDate(e.target.value);
            }}
            />
          </div>

          <div className='mb-3'>
          <label htmlFor="actionTo">Actioned To</label>
          <select
          className="form-control"
          name="actionTo"
          id="actionTo"
          value={actionTo || ""}
          onChange={(e) =>{
            setActionTo(e.target.value);
        }}
          >
          <option>Select..</option>
          <option>Iddah Kisiangani</option>
          <option>Lawrence Kiptanui</option>
          </select>
          </div> 

          <div className='mb-3'>

              <label htmlFor="outcome"> Outcome </label>
              <textarea 
                className="form-control"
                name="outcome"
                id="outcomme"
                rows={3}
                value={outcome || ""}
                onChange={(e) =>{
                  setOutcome(e.target.value);
              }}
              />
          </div>

        {eventFile === "Hearing" && 

          <div className='mb-3' style={{marginTop:'10px'}}>
          <label htmlFor='testifiedBy'> Testified By </label>
          <select
          className='form-control' 
          id="testifiedBy"
          name="testifiedBy"
          value={testifiedBy || ""}
          onChange={(e) =>{
              setTestifiedBy(e.target.value);
              }}
        >
          <option>select...</option>
          <option>{clientInfo.FullName}</option>
          {dataUsers.map((val) =>{
            return( 
                <option>{val.fullname}</option>
            )
          })}
          
        </select>
        </div> 

        }

          <div className='mb-3' style={{display:'none'}}>
          <label htmlFor="auctionedby">Auctioned By</label>
          <input 
          className="form-control"
          name="auctionedby"
          id="auctionedby"
          value={fullname || ""}
          onChange={(e) =>{
            setFullName(e.target.value);
        }}
          />
          </div>  

          <div style={{display:'none'}}>
          <label htmlFor='lawfirmaccount'>Law Firm Account</label>
          <input 
          className='form-control'
          name='lawfirmaccount'
          id='lawfirmaccount' 
          value={lawfirmaccount} 
          onChange={(e) =>{
            setLawFirmAccount(e.target.value);
        }}
          />
        </div>

        


          <div className='mb-3' style={{display:'none'}}>
          <label htmlFor="eventsId">Events Id</label>
          <input 
          className="form-control"
          name="eventsId"
          id="eventsId"
          value={EventsId || ""}
          onChange={(e) =>{
            setEventsId(e.target.value);
        }}
          />
          </div>  

          
          <div className='mb-3' style={{display:'none'}}>
          <label htmlFor="fileid">File Id</label>
          <input 
          className="form-control"
          name="fileid"
          id="fileid"
          value={fileid || ""}
          onChange={(e) =>{
            setfileid(e.target.value);
        }}
          />
          </div>  

          <div className='mb-3' style={{display:'none'}}>
          <label htmlFor="Defendant">Defendant</label>
          <input 
          className="form-control"
          name="Defendant"
          id="Defendant"
          value={Defendant || ""}
          onChange={(e) =>{
            setDefendant(e.target.value);
        }}
          />
          </div> 

          <div className='mb-3' style={{display:'none'}}>
          <label htmlFor="clientname">Client name</label>
          <input 
          className="form-control"
          name="clientname"
          id="clientname"
          value={clientname || ""}
          onChange={(e) =>{
            setClientname(e.target.value);
        }}
          />
          </div> 
          
          <div className='mb-3'>
              <input type='button' onClick={SubmitEvent} className='btn btn-primary btn-block' value={'Submit'}/>
          </div>

          </form>



        </Box>
      </Modal>
      </div>
    
    </div>
    
      
      <TableContainer component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
        <TableHead className='head'>
          <TableRow className='rows'>
            <TableCell className="tableCell rows">Activity</TableCell>
            <TableCell className="tableCell rows">Activity Date</TableCell>
            <TableCell className="tableCell rows">Actioned By</TableCell>
            <TableCell className="tableCell rows">Actioned To</TableCell>
            <TableCell className="tableCell rows">Outcome</TableCell>
            <TableCell className="tableCell rows">Testified by</TableCell>
            <TableCell className="tableCell rows">Status</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
     {(rowsPerPage > 0
      ? FileEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : FileEvents
    ).map((event,index) =>{
      return(
        <TableRow key={event.id} className='rows'>
          <TableCell className="tableCell rows">{event.eventFile}</TableCell>
          <TableCell className="tableCell rows">{moment(event.upcomingdate).format('Do MMMM YY')}</TableCell>
          <TableCell className="tableCell rows">{event.auctionBy}</TableCell>
          <TableCell className="tableCell rows">{event.actionTo}</TableCell>
          <TableCell className="tableCell rows">{event.outcome}</TableCell>
          <TableCell className="tableCell rows">{event.testifiedBy}</TableCell>

          <TableCell className="tableCell rows" style={{color:'red',fontWeight:'bold'}}>       
           {(current_time > new Date(event.upcomingdate) && "Completed") || (current_time < new Date(event.upcomingdate) && "Active") }
          </TableCell>

        </TableRow>
      )
     })}

     {emptyRows > 0 && (
      <TableRow>
        <TableCell colSpan={4} />
      </TableRow>
    )}
    
      </TableBody>
      <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={4}
          count={FileEvents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
      </TableFooter>
      </Table>
      </TableContainer>
      
      </div>
 


      <div
        className={toggleState === 5 ? "content  active-content" : "content"}
      >

      <div className="upload">

      {/* Uploading files */}
      <div>  

      <div>
      <div style={{display:'flex',justifyContent:'right'}}>
      <Button onClick={handleOpen} style={{backgroundColor:'#02b159',color:'white',display:'flex',gap:'3px',padding:'10px 15PX',borderRadius:'5px',fontWeight:'500'}}><CloudUploadIcon /> Upload documents</Button>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
         
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          <div className="mb-3 mt-3">
              <lable htmlFor='doclabel'>Document Name</lable>
              <input 
              type='text'
              className="form-control"
              name="doclabel"
              value={documentlabel}
              onChange={(e) =>{
                setDocumentLabel(e.target.value);
            }}
              />
          </div>
          <div className='mb-3'>
          <label htmlFor="fileUpload">Select Document</label>
          <input 
          type='file'
          className="form-control"
          name="fileUpload"
          onChange={(event) => {setFileUpload(event.target.files[0])}}
          />
        </div>
      
        <div className="mb-3 mt-3" >
        <lable htmlFor='filingdate'>Filing Date</lable>
        <input 
        className="form-control"
        name="filingdate"
        value={filingdate}
        onChange={(e) =>{
          setFilingDate(e.target.value);
      }}
        />
        </div>

        <div className="mb-3 mt-3"  style={{display:'none'}}>
        <lable htmlFor='filingdate'>File Link</lable>

        <input 
        className="form-control"
        name="filingdate"
        value={url}
        onChange={(e) =>{
          setUrl(e.target.value);
      }}
        />
        </div>

        <div className="mb-3 mt-3"  style={{display:'none'}}>
        <lable htmlFor='filedby'>Filed By</lable>
        <input 
        type='text' 
        className="form-control"
        name="filedby"
        id="filedby"
        value={fullname || ""}
        onChange={(e) =>{
          setFullName(e.target.value);
      }}
        />
        
        </div>

        <div className='mb-3 mt-3'  style={{display:'none'}}>
        <label htmlFor="clientname">Client name</label>
        <input 
        className="form-control"
        name="clientname"
        id="clientname"
        value={clientname || ""}
        onChange={(e) =>{
          setClientname(e.target.value);
      }}
        />
        </div> 

        <div className="mb-3 mt-3" style={{display:'none'}}>
        <lable htmlFor='docID'>Document ID</lable>
        <input 
        className="form-control"
        name="docID"
        id="docID"
        value={EventsId || ""}
        onChange={(e) =>{
          setEventsId(e.target.value);
      }}
        />
        </div>
        
        
        <div className='mb-3 mt-3' style={{display:'flex',justifyContent:'center'}}>
          
        <button className="uploadbutton" disabled={perc !== null && perc < 100} onClick={submitFile} 
        style={{backgroundColor:'#02b159',color:'white',
        display:'flex',gap:'3px',padding:'3px 10px',
        justifyContent:'flex-end',borderRadius:'5px'}}
        > 
            Upload
        </button>
        </div>

          </Typography>
        </Box>
      </Modal>
    </div>

      <div>
      
      <TableContainer component={Paper} >
      <Table sx={{ minWidth: 750 }} aria-label="custom pagination table" className='table'>
        <TableHead className='head'>
          <TableRow className='rows'>
            <TableCell className="tableCell rows">#</TableCell>
            <TableCell className="tableCell rows">Filing Date</TableCell>
            <TableCell className="tableCell rows">Filed By</TableCell>
            <TableCell className="tableCell rows">Files</TableCell>

            <TableCell className="tableCell rows">File Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
        {docsData.map((val,index) =>{
          return(
          <TableRow key={val.id} className='rows'> 
          <TableCell className="tableCell rows">{index+1}</TableCell> 
          <TableCell className="tableCell rows">{moment(val.filingdate).format('DD-MM-YYYY, hh:mm')}</TableCell>  
          <TableCell className="tableCell rows">{val.filedBy}</TableCell>  
          <TableCell className="tableCell rows">
            <a style={{textDecoration:'none'}} href={val.url}>
              <button 
                style={{
                  textDecoration:'none',
                  color:'white',padding:'5px 10px',borderRadius:'5px'
                }}
              >
              <img style={{height:'25px',width:'25px'}} src={pdfsvg} alt="" />

              </button>
            </a>
          </TableCell>  
          <TableCell className="tableCell rows">{val.documentlabel}</TableCell> 
        </TableRow> 
        )})}

      {emptyRows > 0 && (
        <TableRow>
          <TableCell colSpan={3} />
        </TableRow>
      )}

        </TableBody>
    <TableFooter className="TableFooter">
    <TableRow className="tableRow">
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        colSpan={4}
        count={docsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  </TableFooter>
      </Table>
      </TableContainer>



      </div>

      </div>

       


      </div>

      </div>


      <div
      className={toggleState === 6 ? "content  active-content" : "content"}
    >
      
      

    
      <div ref={componentRef} style={{width:'100%',height:'100%'}}> 

      <div className="card">
      
      <div className="splitscreen2">

      <div className="left2">
      
      <h2 style={{paddingBottom:'10px',paddingLeft:'5px',fontWeight:'bold'}}><u>Instruction Note</u></h2>
      <h6>Full Name:<span> {clientInfo.FullName} </span></h6> 
      <h6>Residence:<span> {clientInfo.residence} </span></h6> 
      <h6>Mobile One:<span> {clientInfo.Mobile1} </span></h6> 
      <h6>Mobile Two:<span> {clientInfo.Mobile2} </span></h6> 
     
      <h6>Case Category:<span> {clientInfo.CaseType} </span></h6> 
      <h6>Date of Instructions: <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}</span></h6>
    
      </div>

      <div className="right2">
      <span style={{paddingTop:'10px'}}>{currentUser?.firmname} </span> <br />
      
      <span>  
      P.O Box  {currentUser?.postal}
        {currentUser?.postal} - 00100
      </span> 
      <br />
      <span>
      Mobile: {currentUser?.phone1} {currentUser?.phone1}
      </span>
      </div>

      </div>
      <div className="agree">
      <h4><b>In the Chief Magistrate Court at {clientInfo.Station}</b></h4>
      <br />
      <span>
      I/WE hereby appoint you <span style={{fontWeight:'bold'}}>{currentUser?.firmname} {currentUser?.firmname}</span> 
      <b>{currentUser?.location} {currentUser?.location},</b> <b>P.O Box {currentUser?.postal} {currentUser?.postal}-00100 Nairobi</b> to represent me/us in the above case. You are at liberty 
      to engage another advocate to assist you or to represent you should you not be available on any day
       that the case comes up for hearing. I/we also agree that the fees payable herein is not refundable
        but is recoverable as costs awarded to yourselves by the court. I/we hereby agree to pay the sum
         of Kshs……NIL……….     being my initial deposit towards your agreed fees Upon receipt of the above
          deposit, I /we agree that the firm of <b style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</b> SHALL BE ENTITLED TO 30% 
          OF THE DECRETAL AMOUNT, upon determination of this case.
          </span>
      <br />
      <div style={{fontFamily:'Georgia, Times, "Times New Roman", serif',fontStyle:'normal',fontVariant:'normal'}}>
      <p>Your Faithfully</p>
      <br /><br />
      <p>ID NO: <span>{clientInfo.IdNumber}</span></p>
      <br />
      <p>Chaser Name: <span>{clientInfo.ChaserName}</span></p>
      <br/>
      <p>Chaser Mobile: <span>{clientInfo.ChaserMobile}</span></p>
      <p>Registred By: <span>{clientInfo.regby}</span></p>
      <br/>
      {/** <p>Office Number: <span>{currentUser?.phone1} {currentUser?.phone1}</span></p> */}
      <br/>
      <p>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span> Signature & Stamp.</p>
      </div>
      </div>
      </div>
      </div>
      <div style={{alignItems:'center',paddingLeft:'45%'}}>
      <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
      Print Instruction <LocalPrintshopIcon style={{color:'red'}}/></button>
      </div>
    </div>

    <div
    className={toggleState === 7 ? "content  active-content" : "content"}
    >
    <div>
    <p style={{background:'rgb(223, 240, 216)',padding:'10px',paddingLeft:'30px',paddingRight:'30px',textAlign:'center',fontWeight:'bold'}}>
      Amount advanced to a cliet i.e bus fare or any other type of advancement.
    </p>
    </div>
    <form  style={{background:'rgb(243, 235, 235)',padding:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
    <div className='mb-3'>


    <label htmlFor="advancement">Date of advancement</label>
    <input 
    className="form-control"
    type='date'
    name="advancement"
    id="advancement"
    value={advancementDate}
    onChange={(e) =>{
      setAdvancementDate(e.target.value);
      }}
    />
    </div>  

    <div className='mb-3'>
    <label htmlFor="Amount">Amount</label>
    <input 
    className="form-control"
    type='number'
    name="Amount"
    id="Amount"
    value={advancedAmount}
    onChange={(e) =>{
      setAdvancedAmount(e.target.value);
      }}
    />
    </div>

    
    <div className="mb-3 mt-3"  style={{display:'none'}}>
    <lable htmlFor='filedby'>Filed By</lable>
    <input 
    type='text'
    className="form-control"
    name="filedby"
    id="filedby"
    value={fullname || ""}
    onChange={(e) =>{
      setFullName(e.target.value);
  }}
    />
    
    </div>

    <div className="mb-3 mt-3" style={{display:'none'}}>
    <lable htmlFor='docID'>Document ID</lable>
    <input 
    className="form-control"
    name="docID"
    id="docID"
    value={EventsId || ""}
    onChange={(e) =>{
      setEventsId(e.target.value);
  }}
    />
    </div>
    
    <div className='mb-3 mt-3' style={{display:'flex',justifyContent:'center'}}>

    <button className="uploadbutton" disabled={perc !== null && perc < 100} onClick={submitAmount} 
    style={{backgroundColor:'#241842',border:
    '1px solid black',color:'white',
    display:'flex',gap:'3px',padding:'3px 10px',
    justifyContent:'flex-end',borderRadius:'5px'}}
    > 
        Submit
    </button>
    </div>

   
    </form>

    <table className='clients, table table-bordered'>
      <thead style={{background:'#effeae',color:'black'}}>
        <th>#</th>
        <th>Date</th>
        <th>Amount</th>
      </thead>
      <tbody>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
    </div>


    {/* System documents draft */}

    <div
    className={toggleState === 8 ? "content  active-content" : "content"}
    > 

    <div>
      <hr style={{width:'100%'}}/> 


    <div className="buttonItems">
    {/* Demand letter */}
    <div>
    
    {!demand && 
      <div className="mb-3">
        <button 
          className="buttonDoc"
          onClick={() =>setDemmand(!demand)}
          id='demandletter'
          name='demandletter'
        >
        Demand Letter
        </button>
       
      </div>
    }

    {clientInfo.clientcategory === "Passenger" && 
    <div>

    {demand && 
      <div>
      <h4 style={{textAlign:'center'}}>Demand letter: <br /> {clientInfo.FullName}</h4>
      <div className="drafts" ref={componentRef}>

        <div className="demand">

          <div className="travel">

            <h4> YOUR REF: <br /> 
              <span style={{fontSize:'17px'}}>TBA</span>
            </h4>

            <h4> Our REF: <br /> 
              <span style={{fontSize:'17px'}}> {initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span>
            </h4>

          <h4> Date: <br /> 
            <span style={{fontSize:'17px'}}>{moment(clientInfo.regdate).format('MMMM, Do YYYY')}</span>
          </h4>

          </div>
          
          <div className="mb-3">
            <h5>{clientInfo.defendant}</h5>
            <h5 style={{fontWeight:'bold',textDecoration:'underline'}}> Nairobi </h5>
          </div>

          <div> 
          <h5 style={{display:'flex',flexDirection:'column'}}>
            <span style={{display:'flex', gap:'20px',fontSize:'18px'}}> <b>RE:</b>  <b><u>DEMAND LETTER AND NOTICE OF INTENTION TO SUE</u></b>  </span>
            <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ACCIDENT ON <span style={{fontSize:'18px'}}>{moment(clientInfo.dob).format('Do MMMM YYYY')}</span></u></b>  </span>
            <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INVOLVING YOUR MOTOR VEHICLE REGISTRATION NUMBER {clientInfo.motor} </u></b>  </span>
            <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ALONG {clientInfo.accidentarea}  </u></b>  </span>
            <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INSURANCE: {clientInfo.Nationality}</u></b>  </span>
            <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>OUR CLIENT: {clientInfo.FullName} </u></b> </span>
            
            </h5>
          </div>

          <div className="body mt-2">
            <h4> <span style={{fontSize:'16PX'}}>We act for the above named client who has instructed us to address you as hereunder;</span></h4>
            <h4>
            <span style={{fontSize:'16PX'}}>
            On or about the <b>{moment(clientInfo.dob).format('Do MMMM YYYY')}</b> our client was a passenger aboard motor vehicle 
            registration number <b style={{fontSize:'17PX'}}>{clientInfo.motorOne}</b> when you, your driver, employee and or servant managed, 
            controlled and or drove the motor vehicle registration number <b style={{fontSize:'17PX'}}>{clientInfo.motor}</b> so carelessly 
            and/or negligently at a very high speed that he lost control and caused the same to 
            veer off its lawful lane and caused the said accident whereby the Plaintiff sustained 
            serious injuries, of which we hold you liable. 
            </span>

            </h4>

            <h4> 
            <span style={{fontSize:'16PX'}}>
            Our instructions are to demand which we hereby do, your immediate admission of liability 
            within the next seven (7) days from the date hereof, after which we shall deliberate on quantum.
            </span>
         
            </h4>

            <h4>
            <span style={{fontSize:'16PX'}}>
            <b style={{fontSize:'16px'}}>TAKE NOTICE</b> that unless you comply as demanded we have mandatory instructions to institute relevant 
            legal action against yourself at your own risk as to cost and other consequences without any further reference to you whatsoever.
            </span>
            </h4>

            <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Yours faithfully,</span></h4>
            {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
              <img style={{width:'150px',height:'40px'}} src={signature} alt="signature" />
            }
            <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Waiganjo Wachira <br />
              <b style={{fontSize:'17PX'}}><u>{currentUser?.firmname} {currentUser?.firmname}</u></b>  <br />
              <b>CC: {clientInfo.Nationality}</b>
              <br />
              <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span></p>
            </span>
            </h4>

          </div>
        </div>
      </div>
      <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
      <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
      Print Demand Letter <LocalPrintshopIcon style={{color:'red'}}/></button>
      </div>
      </div>
      
    }
    </div>
  }



  {clientInfo.clientcategory === "Rider" && 
  <div>

  {demand && 
    <div>
    <h4 style={{textAlign:'center'}}>Demand letter: <br /> {clientInfo.FullName}</h4>
    <div className="drafts" ref={componentRef}>

      <div className="demand">

        <div className="travel">

          <h4> YOUR REF: <br /> 
            <span style={{fontSize:'17px'}}>TBA</span>
          </h4>

          <h4> Our REF: <br /> 
            <span style={{fontSize:'17px'}}>{initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span>
          </h4>

        <h4> Date: <br /> 
          <span style={{fontSize:'17px'}}>{moment(clientInfo.regdate).format('MMMM, Do YYYY')}</span>
        </h4>

        </div>
        
        <div className="mb-3">
          <h5>{clientInfo.defendant}</h5>
          <h5 style={{fontWeight:'bold',textDecoration:'underline'}}> Nairobi </h5>
        </div>

        <div> 
        <h5 style={{display:'flex',flexDirection:'column'}}>
          <span style={{display:'flex', gap:'20px',fontSize:'18px'}}> <b>RE:</b>  <b><u>DEMAND LETTER AND NOTICE OF INTENTION TO SUE</u></b>  </span>
          <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ACCIDENT ON <span style={{fontSize:'17px'}}>{moment(clientInfo.dob).format('Do MMMM YYYY')}</span></u></b>  </span>
          <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INVOLVING YOUR MOTOR VEHICLE REGISTRATION NUMBER {clientInfo.motor} </u></b>  </span>
          <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ALONG {clientInfo.accidentarea}  </u></b>  </span>
          <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INSURANCE: {clientInfo.Nationality}</u></b>  </span>
          <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>OUR CLIENT: {clientInfo.FullName} </u></b> </span>
          
          </h5>
        </div>

        <div className="body mt-2">
          <h4> <span style={{fontSize:'16PX'}}>We act for the above named client who has instructed us to address you as hereunder;</span></h4>
          <h4>
          <span style={{fontSize:'16X'}}>
          On or about the <b>{moment(clientInfo.dob).format('Do MMMM YYYY')}</b> our client was lawfully riding motor cycle registration
          number <b style={{fontSize:'17PX'}}>{clientInfo.motorOne}</b> when you, your driver, employee and or servant managed, 
          controlled and or drove the motor vehicle registration number <b style={{fontSize:'17PX'}}>{clientInfo.motor}</b> so carelessly 
          and/or negligently at a very high speed that he lost control causing
          the said motor vehicle to veer off its lawful lane and collide onto our client’s motor cycle thereby
          causing him serious bodily injuries of which we hold you liable.
          </span>

          </h4>

          <h4> 
          <span style={{fontSize:'16PX'}}>
          Our instructions are to demand which we hereby do, your immediate admission of liability 
          within the next seven (7) days from the date hereof, after which we shall deliberate on quantum.
          </span>
       
          </h4>

          <h4>
          <span style={{fontSize:'16PX'}}>
          <b style={{fontSize:'17px'}}>TAKE NOTICE</b> that unless you comply as demanded we have mandatory instructions to institute relevant 
          legal action against yourself at your own risk as to cost and other consequences without any further reference to you whatsoever.
          </span>
          </h4>

          <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Yours faithfully,</span></h4>
          {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
            <img style={{width:'150px',height:'40px'}} src={signature} alt="signature" />
          }
          <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Waiganjo Wachira <br />
          <b style={{fontSize:'17PX'}}><u>{currentUser?.firmname} {currentUser?.firmname}</u></b>  <br />
          <b>CC: {clientInfo.Nationality}</b>
          <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span></p>
          </span>
          </h4>

        </div>
      </div>
    </div>
    <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
    <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
    Print Demand Letter <LocalPrintshopIcon style={{color:'red'}}/></button>
    </div>
    </div>
    
  }
  </div>
}



{clientInfo.clientcategory === "Driver" && 
<div>

{demand && 
  <div>
  <h4 style={{textAlign:'center'}}>Demand letter: <br /> {clientInfo.FullName}</h4>
  <div className="drafts" ref={componentRef}>

    <div className="demand">

      <div className="travel">

        <h4> YOUR REF: <br /> 
          <span style={{fontSize:'17px'}}>TBA</span>
        </h4>

        <h4> Our REF: <br /> 
          <span style={{fontSize:'17px'}}>{initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span>
        </h4>

      <h4> Date: <br /> 
        <span style={{fontSize:'17px'}}>{moment(clientInfo.regdate).format('MMMM, Do YYYY')}</span>
      </h4>

      </div>
      
      <div className="mb-3">
        <h5>{clientInfo.defendant}</h5>
        <h5 style={{fontWeight:'bold',textDecoration:'underline'}}> Nairobi </h5>
      </div>

      <div> 
      <h5 style={{display:'flex',flexDirection:'column'}}>
        <span style={{display:'flex', gap:'20px',fontSize:'18px'}}> <b>RE:</b>  <b><u>DEMAND LETTER AND NOTICE OF INTENTION TO SUE</u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ACCIDENT ON <span style={{fontSize:'17px'}}>{moment(clientInfo.dob).format('Do MMMM YYYY')}</span></u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INVOLVING YOUR MOTOR VEHICLE REGISTRATION NUMBER {clientInfo.motor} </u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ALONG {clientInfo.accidentarea}  </u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INSURANCE: {clientInfo.Nationality}</u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>OUR CLIENT: {clientInfo.FullName} </u></b> </span>
        
        </h5>
      </div>

      <div className="body mt-2">
        <h4> <span style={{fontSize:'17PX'}}>We act for the above named client who has instructed us to address you as hereunder;</span></h4>
        <h4>
        <span style={{fontSize:'16PX'}}>
        On or about the <b>{moment(clientInfo.dob).format('Do MMMM YYYY')}</b> our client was lawfully driving along <span style={{fontSize:'16PX',textTransform:'capitalize'}}>{clientInfo.accidentarea}</span> when your
        insured and/ his driver and/employee and or servant managed, 
        controlled and or drove the motor vehicle registration number <b style={{fontSize:'17PX'}}>{clientInfo.motor}</b> so carelessly 
        and/or negligently at a very high speed that he lost control causing the said motor vehicle to veer its lawful lane and hit the motor vehicle being
        driven by our client thereby occasioning him serious bodily injuries of which we hold you liable.
        </span>

        </h4>

        <h4> 
        <span style={{fontSize:'16PX'}}>
        Our instructions are to demand which we hereby do, your immediate admission of liability 
        within the next seven (7) days from the date hereof, after which we shall deliberate on quantum.
        </span>
     
        </h4>

        <h4>
        <span style={{fontSize:'16PX'}}>
        <b style={{fontSize:'17px'}}>TAKE NOTICE</b> that unless you comply as demanded we have mandatory instructions to institute relevant 
        legal action against yourself at your own risk as to cost and other consequences without any further reference to you whatsoever.
        </span>
        </h4>

        <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Yours faithfully,</span></h4>
        {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
          <img style={{width:'150px',height:'40px'}} src={signature} alt="signature" />
        }
        <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Waiganjo Wachira <br />
        <b style={{fontSize:'17PX'}}><u>{currentUser?.firmname} {currentUser?.firmname}.</u></b>  <br />
        <b>CC: {clientInfo.Nationality}</b>
        <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span></p>
        </span>
        </h4>

      </div>
    </div>
  </div>
  <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
  <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
  Print Demand Letter <LocalPrintshopIcon style={{color:'red'}}/></button>
  </div>
  </div>
  
}
</div>
}


{clientInfo.clientcategory === "Pedestrian" && 
<div>

{demand && 
  <div>
  <h4 style={{textAlign:'center'}}>Demand letter: <br /> {clientInfo.FullName}</h4>
  <div className="drafts" ref={componentRef}>

    <div className="demand">

      <div className="travel">

        <h4> YOUR REF: <br /> 
          <span style={{fontSize:'17px'}}>TBA</span>
        </h4>

        <h4> Our REF: <br /> 
          <span style={{fontSize:'17px'}}>{initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span>
        </h4>

      <h4> Date: <br /> 
        <span style={{fontSize:'17px'}}>{moment(clientInfo.regdate).format('MMMM, Do YYYY')}</span>
      </h4>

      </div>
      
      <div className="mb-3">
        <h5>{clientInfo.defendant}</h5>
        <h5 style={{fontWeight:'bold',textDecoration:'underline'}}> Nairobi </h5>
      </div>

      <div> 
      <h5 style={{display:'flex',flexDirection:'column'}}>
        <span style={{display:'flex', gap:'20px',fontSize:'18px'}}> <b>RE:</b>  <b><u>DEMAND LETTER AND NOTICE OF INTENTION TO SUE</u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ACCIDENT ON <span style={{fontSize:'17px'}}>{moment(clientInfo.dob).format('Do MMMM YYYY')}</span></u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INVOLVING YOUR MOTOR VEHICLE REGISTRATION NUMBER {clientInfo.motor} </u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>ALONG {clientInfo.accidentarea}  </u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>INSURANCE: {clientInfo.Nationality}</u></b>  </span>
        <span style={{paddingLeft:'44px',fontSize:'18px'}}> <b><u>OUR CLIENT: {clientInfo.FullName} </u></b> </span>
        
        </h5>
      </div>

      <div className="body mt-2">
        <h4> <span style={{fontSize:'16PX'}}>We act for the above named client who has instructed us to address you as hereunder;</span></h4>
        <h4>
        <span style={{fontSize:'16PX'}}>
        On or about the <b>{moment(clientInfo.dob).format('Do MMMM YYYY')}</b> our client was lawfully walking along <span style={{fontSize:'16PX',textTransform:'uppercase'}}>{clientInfo.accidentarea}</span> when your
        insured and/ his driver and/employee and or servant managed, 
        controlled and or drove the motor vehicle registration number <b style={{fontSize:'17PX'}}>{clientInfo.motor}</b> so carelessly 
        and/or negligently at a very high speed that he lost control causing the said motor vehicle to ram into our
        client thereby occasioning him serious bodily injuries of which we hold you liable.
        </span>

        </h4>

        <h4> 
        <span style={{fontSize:'16PX'}}>
        Our instructions are to demand which we hereby do, your immediate admission of liability 
        within the next seven (7) days from the date hereof, after which we shall deliberate on quantum.
        </span>
     
        </h4>

        <h4>
        <span style={{fontSize:'16PX'}}>
        <b style={{fontSize:'16px'}}>TAKE NOTICE</b> that unless you comply as demanded we have mandatory instructions to institute relevant 
        legal action against yourself at your own risk as to cost and other consequences without any further reference to you whatsoever.
        </span>
        </h4>

        <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Yours faithfully,</span></h4>
        {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
          <img style={{width:'150px',height:'40px'}} src={signature} alt="signature" />
        }
        <h4 className="mt-1"> <span style={{fontSize:'17PX'}}>Waiganjo Wachira <br />
        <b style={{fontSize:'17PX'}}><u>{currentUser?.firmname} {currentUser?.firmname}</u></b>  <br />
        <b>CC: {clientInfo.Nationality}</b>
        <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span></p>
        </span>
        </h4>

      </div>
    </div>
  </div>
  <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
  <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
  Print Demand Letter <LocalPrintshopIcon style={{color:'red'}}/></button>
  </div>
  </div>
  
}
</div>
}

</div>




    

    <div>
    {!statutory && 
      <div className="mb-3">
        <button 
        className="buttonDoc"
        onClick={() =>setStatutory(!statutory)}
      >
      Statutory Notice
      </button>
      </div>
    }
    {statutory && 
      
      <div>
      <div className="drafts" id="drafts" ref={componentRef}>

        <div className="statutory">
          <div className="stathead">
            <h4>REPUBLIC OF KENYA</h4>
            <h4>IN THE CHIEF MAGISTRATES COURT AT {clientInfo.Station}</h4>
            <h4>CIVIL SUIT NO. {clientInfo.caseNumber} of {clientInfo.caseYear}</h4>
          </div>

          <div className="mt-3 clientssection">
            <h5>{clientInfo.FullName} {clientInfo.ClientStatus === "Minor" &&  
            <>(A minor suing through the {clientInfo.parentrelationship} <br /> And next friend {clientInfo.parent})</> }
              </h5>

            <h6>VERSUS</h6>

            <h5>{clientInfo.defendant} AND {clientInfo.secondddefendant}</h5>
          </div>

          <div className="noticehead mt-5">
            <h5>NOTICE UNDER SECTION 10 OF THE INSURANCE</h5>
            <h5>Motor Vehicle Third Party Risks Act (Chapter 405 Laws of Kenya)</h5>
          </div>

          <div className="mt-3" style={{fontWeight:'600'}}>
            <div className="bodyStat">
              <span>
              As advocates for and on behalf of the above named {clientInfo.FullName} we have on <span style={{fontWeight:'bold'}}>{moment(clientInfo.filingdate).format("Do MMMM YYYY")}</span> filed suit at the Chief Magistrates Court 
              of Kenya at {clientInfo.Station} in respect of motor vehicle registration number 
              <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> insurance whereof has been effected by you for compensation 
              arising out of the road traffic accident on <span style={{fontWeight:'bold'}}>{moment(clientInfo.dob).format("Do MMMM YYYY")}</span>, caused by 
              the negligence of your insured agent, his driver, servant or agent.
              <br /> 
              
              {clientInfo.Nationality.split(' ')[0] !== 'DIRECTLINE' && <span>A copy of the plaint and summons are attached to enable you enter appearance.</span>}
              
              </span>
            </div>
        
            <br /> 
           

            <div className="mt-5">
              <h5 style={{textAlign:'left',fontFamily:'Georgia, Times, "Times New Roman", serif',fontSize:'15px'}}>Dated at NAIROBI this……………<span>{moment(clientInfo.regdate).format("Do")}</span>………………day of ………………...<span>{moment(clientInfo.regdate).format("MMMM")}</span>....……………. {moment(clientInfo.regdate).format('YYYY')}.</h5>
            </div>


            <div className="firmdetails">
            <div className="signature">
            {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
              <img src={signature} alt="signature"/>
            }
            </div>
              <span style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</span>
              <span>ADVOCATES FOR THE PLAINTIFF</span>
            </div>
  
            <div className="drawnby">
            {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
              <img src={signature} alt="signature"/>
              }
              <span style={{textTransform:'uppercase',fontWeight:'bold'}}>DRAWN & FILED BY</span>
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <span style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</span>
              <h5><span>{initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span></h5>
              </div>
              <span style={{textTransform:'uppercase'}}>{currentUser?.location} {currentUser?.location}</span>
  
              <span style={{textTransform:'uppercase'}}>P.O.BOX {currentUser?.postal} {currentUser?.postal}-00100</span>
  
              <span style={{textTransform:'uppercase',fontWeight:'bold'}}>NAIROBI</span>
              <span>Tel: {currentUser?.phone1} {currentUser?.phone1}</span>
            </div>

            <div className="mt-5" style={{textAlign:'left',textDecoration:'underline'}}>
              <h5 style={{fontWeight:'bold',fontFamily:'Georgia, Times, "Times New Roman", serif',fontSize:'15px'}}>TO BE SERVED UPON</h5>
            </div>

            <div>
              <h5 style={{textTransform:'uppercase',fontFamily:'Georgia, Times, "Times New Roman", serif',fontSize:'15px'}}>{clientInfo.Nationality}</h5>
            </div>



            <br  /> 
            <div>
            <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span></p>
            </div>
            
          </div>

        </div>
      </div>

      <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
      <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
      Print Statutory Notice <LocalPrintshopIcon style={{color:'red'}}/></button>
      </div>
      
      </div>
    }
    </div>

      {clientInfo.ClientStatus === "Deceased" && 
      <div className="mb-3">
        <button 
          className="buttonDoc"
        >
        Ad litem
        </button>
        
      </div>
      }

      {/* Plaint Generation */}


      <div>
      {!plaint && 
        <div className="mb-3">
        <button className="buttonDoc" onClick={() =>setPlaint(!plaint)}>Plaint</button>
        </div>
      }
      
      {plaint && 

    <div>

        <div  className="drafts"  ref={componentRef}>
        <div className="plaint">

        <div className="plainthead">
          <h4>REPUBLIC OF KENYA</h4>
          <h4>IN THE CHIEF MAGISTRATES COURT AT {clientInfo.Station}</h4>
          <h4> {clientInfo.Station} LAW COURTS</h4>
          <h4>CIVIL CASE NO. {clientInfo.caseNumber} OF {clientInfo.caseYear}</h4>
        </div>

        <div className="plaintParties">
          <span>{clientInfo.FullName}……………………………........................................................PLAINTIFF</span> <br />
          <div className="mt-3" style={{textAlign:'center'}}>
          <span>VERSUS</span>
          </div>
          <br />
          <span>{clientInfo.defendant}……………………………........................................................{!clientInfo.secondddefendant === false && <span>1ST </span>}DEFENDANT</span> <br />
          {!clientInfo.secondddefendant === false && 
          <span>{clientInfo.secondddefendant}……………........................................................2ND DEFENDANT</span> 
          }
        </div>

        <div className="noticeTitle">
          <span>PLAINT</span> 
          <br />
          <span>FAST TRACK</span>
        </div>

        <div className="bodyPlaint">

          <div className="paragraphs">
          <ol type="1">
            <li>
              <span>
                The Plaintiff is a <span style={{textTransform:'lowercase'}}>{clientInfo.Gender}</span> adult of sound mind residing and working for gain within the Republic of Kenya whose address of service for the purpose of this suit shall be care of
                <span style={{fontWeight:'bold',padding:'5px'}}>M/S {currentUser?.firmname} {currentUser?.firmname}, {currentUser?.location} {currentUser?.location} and of P.O. Box {currentUser?.postal} {currentUser?.postal}-00100, Nairobi.</span>
              </span>
            </li>

            <li>
              <span> 
                The {!clientInfo.secondddefendant === false && <span> Defendants are adults </span>} 
                {!clientInfo.secondddefendant === true && <span> Defendant is an adult </span>} of sound mind residing and working for gain within the Republic of Kenya. (Service of summons shall be effected through the Plaintiff’s Advocate offices)  
              </span>
            </li>

            <li>
              <span>
              That at all material times relevant to this suit the {!clientInfo.secondddefendant === false && <span>1ST </span>} defendant was the registered owner,
              insured owner, owner in possession and or beneficial owner of Motor Vehicle Registration
              Number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> which was at the material times under the control the 
              {!clientInfo.secondddefendant === false && <span> 2nd </span>} defendant
              as its authorized driver, agent, servant and or employee.
              </span>
            </li>
          {/*Paragraph 4 is bound to change based on the client category*/}

            <li>
              
              {/* Pedestrian */}
              {clientInfo.clientcategory === "Pedestrian" && 

              <span>
              On or about {moment(clientInfo.dob).format('Do MMMM YYYY')}, the Plaintiff was lawfully walking along {clientInfo.accidentarea} when the defendant’s driver managed, 
              controlled and or drove motor vehicle
              registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> so carelessly and or negligently at a very high speed that he
              lost control causing the said motor vehicle to veer off the road and collide onto the plaintiff,
              thereby causing the plaintiff serious bodily injuries, endured and continues to endure pain and
              has suffered loss and damages.
              </span>

              }

              {/* passenger */}

              {clientInfo.clientcategory === "Passenger" && 
              
              <span>
              On or about {moment(clientInfo.dob).format('Do MMMM YYYY')}, the Plaintiff was lawfully travelling as a passenger in motor
              vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motorOne}</span> along {clientInfo.accidentarea} when the
              defendant’s driver managed, controlled and or drove motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> so carelessly and/ or negligently at very high speed that he lost control causing the said
              motor vehicle to collide onto motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motorOne}</span> thereby causing
              the plaintiff serious bodily injuries from which he endured and continues to endure pain and
              has suffered loss and damages.
              </span>

              }

              {clientInfo.clientcategory === "Rider" && 
              
              <span>
              On or about {moment(clientInfo.dob).format('Do MMMM YYYY')}, the Plaintiff was lawfully riding motor cycle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motorOne}</span> along {clientInfo.accidentarea} when the
              defendant’s driver managed, controlled and or drove motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> so carelessly and or negligently at a very high speed that he lost control causing the
              said motor vehicle to collide onto the motor cycle thereby causing the plaintiff serious bodily
              injuries, endured and continues to endure pain and has suffered loss and damages.
              </span>

              }

              {clientInfo.clientcategory === "Driver" && 
              
              <span>
              On or about {moment(clientInfo.dob).format('Do MMMM YYYY')}, the Plaintiff was lawfully driving motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motorOne}</span> along {clientInfo.accidentarea} when the
              defendant’s driver managed, controlled and or drove motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> 
              so carelessly and/ or negligently at very high speed that he lost control
              causing the said motor vehicle to collide onto motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motorOne}</span>
              thereby causing the plaintiff serious bodily injuries from which he endured and continues to
              endure pain and has suffered loss and damages.
              </span>

              }

            </li>



          </ol>
          
          </div>

          <div className="perticulars">

          <ul style={{listStyleType:'none'}}>
            <li>
              <span>PARTICULARS OF NEGLIGENCE ON THE PART OF DEFENDANT, HIS
              AUTHORIZED DRIVER, SERVANT AND/OR AGENT</span>
            </li>
          </ul>
        </div>

        <div className="listparticulars">   
            <ol type="a">
              <li>Failing to keep any or any proper outlook.</li>
              
              <li>Drove motor vehicle registration <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> at a speed that was too fast in the
              circumstances</li>

              <li>Failed to have any or any proper control of the motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span></li>

              <li>Drove without any due regard and attention.</li>

              <li>Failed to have any or any sufficient regard for the safety of other road users and in
              particular the plaintiff herein.</li>

              <li>Failed to brake, stop, swerve, slow down or in any other manner manage or control the
              said motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> so as to avoid the accident subject
              matter.</li>
              <br /> <br />
              <li>Drove recklessly, carelessly and dangerously.</li>

              <li>Failed to heed the presence of the plaintiff so as to avoid the accident the subject matter.</li>

              <li>Caused motor vehicle registration number <span style={{fontWeight:'bold'}}>{clientInfo.motor}</span> to run down the plaintiff.</li>

            </ol>
          </div>

          <div className="paragraphsB">
            <ol start="5">
              <li>
              The plaintiff will further rely and pleads the doctrine of <span style={{fontWeight:'bold',fontStyle:'italic'}}>Res Ipsa Loquitor</span> on the true facts
              and circumstances of the accident subject matter.
              </li>

              <li>
              By reasons of the matters aforestated, the Plaintiff herein sustained severe injuries and has
              suffered loss and damage and he claims both general and special damages against the
              Defendant.
              </li>


            </ol>
          </div>

          <div >
            <div className="perticulars">
              <ul style={{listStyleType:'none'}}>
                <li><span>PARTICULARS OF INJURIES</span></li>
              </ul>
            </div>
           
            <ol type="a">
              <li></li>
              <li></li>
            </ol>
            <ul style={{listStyleType:'none',paddingLeft:'20px'}}>
              <li>
                <span style={{fontFamily:'Georgia, Times, "Times New Roman", serif',
                fontSize:'16px',fontStyle:'normal',fontVariant:'normal'}}>
                  Detailed particulars of injuries are set out in the medical report which the plaintiff shall seek
                  leave of court to refer to at the hearing hereof.
                </span>
              </li>
            </ul>
            
          </div>

          <div>
            <div className="perticulars">
              <ul style={{listStyleType:'none'}}>
                <li><span>PARTICULARS OF SPECIAL DAMAGES</span></li>
              </ul>
            </div>

            <div  className="perticulars">
            <ol type="i">
              <li>Medical Report/expenses Kshs {clientInfo.MedicalExpenses} /= </li>
              <li>Copy of Records Kshs {550} /= </li>
            </ol>
            <span style={{textDecoration:'none',paddingLeft:'100px'}}>Total Kshs {550 + clientInfo.MedicalExpenses} /=</span>

            <ul style={{listStyleType:'none'}}>
              {!clientInfo.FutureMedical === false && 
                <li><span style={{textDecoration:'none',fontWeight:'500',fontSize:'16px'}}> <b>AND</b> the plaintiff claims damages and future medical expenses of <b>Kshs {clientInfo.FutureMedical}.00/=.</b></span></li>
              }
            </ul>


          </div>

          <div className="perticulars">
            <ol start="7">
              <li>
                The plaintiff avers that the defendant is vicariously liable for the negligent acts of his driver,
                agent, servant and employee and the same is pleaded.
              </li>

              <li>
                Despite demand having been made and notice of intention to sue having been duly given the
                Defendant has refused and/or neglected to honour the Plaintiff’s claim.
              </li>

              <li>
                There is no other suit pending and there have been no previous proceedings in any court
                between the Plaintiff and the defendant over the same subject matter and the cause of action
                relates to the plaintiff herein.
              </li>

              <li>
                The cause of action arose within the jurisdiction of this Honorable Court.
              </li>

              </ol>

            
          </div>

          <div className="perticulars">
          <span style={{textDecoration:'none',fontFamily:'Georgia, Times, "Times New Roman", serif',
          fontSize:'16px',fontStyle:'normal',fontVariant:'normal',paddingTop:'15px'}}>REASONS WHEREFORE<span style={{textDecoration:'none',fontWeight:'500'}}> the plaintiff prays for judgment against the Defendant for:</span></span>


          <ol type="a">
              <li>General damages for pain, suffering, and loss of amenities.</li>
              <li>Special damages of <span style={{fontWeight:'bold',textDecoration:'none'}}>Kshs {550 + clientInfo.MedicalExpenses} /=</span> </li>
              {!clientInfo.FutureMedical === false && 
              <li>Future medical expenses of <span style={{fontWeight:'bold',textDecoration:'none'}}>Kshs {clientInfo.FutureMedical}.00/=.</span> </li>
              }
              <li>Costs and interests.</li>
          </ol>

        </div>

          <div style={{paddingTop:'10px'}}>
            <span>Dated at NAIROBI this .....……{moment(clientInfo.regdate).format('Do')} .........….day of …........……{moment(clientInfo.regdate).format('MMMM')}…….….....…….{moment(clientInfo.regdate).format('YYYY')}.</span>
          </div>

          <div className="PlaintDrawer">
              <span>{currentUser?.firmname} {currentUser?.firmname}</span>
              <span>ADVOCATES FOR THE PLAINTIFF</span>
              <span>PRACTICE NO: LSK/2020/06455</span>
              <span>ADM NO:P.105/6660/07</span>
          </div>

          <div className="PlaintDrawnBy">
          <br />

              <span style={{fontWeight:'bold',textDecoration:'underline'}}>DRAWN &amp; FILED BY:</span>
              <span>{currentUser?.firmname} {currentUser?.firmname}</span>
              <span>{currentUser?.location} {currentUser?.location}</span>
              <span>P.O. BOX {currentUser?.postal} {currentUser?.postal}-00100</span>
              <span style={{fontWeight:'bold',textDecoration:'underline'}}>Nairobi</span>
              <span>Email: <a href="mailto:waiganjowachiraadv@gmail.com"> <span style={{textTransform:'lowercase'}}>waiganjowachiraadv@gmail.com</span></a> </span>
              <span>Tel: {currentUser?.phone1} {currentUser?.phone1}</span>
          </div>

          <div className="servedTo">
              <h3>TO BE SERVED UPON</h3>
              <span>{clientInfo.defendant}</span>  <br />
              {!clientInfo.secondddefendant === false && 
                <span>{clientInfo.secondddefendant}</span> 
              }
              <br />
              <span style={{fontStyle:'italic',textTransform:'none',fontWeight:'bold'}}>(Service of summons will be affected through the plaintiff’s advocate office)</span>
          </div>
        
        </div>

      </div>
      </div>
    </div>

    <div style={{alignItems:'center',paddingLeft:'45%',marginBottom:'20px'}}>
    <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
    Print Plaint <LocalPrintshopIcon style={{color:'red'}}/></button>
    </div>


    </div>

  }
  </div>




    {clientInfo.defenceappearance === "None" && 
    <div className="mb-3">
      <button className="buttonDoc" > Request for judgement </button>
    </div>
    }


    <div>
    {!pretrial && 
      <div className="mb-3">
      <button className="buttonDoc" onClick={() =>setPretrial(!pretrial)}> Notice </button>
      </div>
    }
    
    {pretrial && 
  <div>
  {specificEvents.map((val) =>{
    return(
      <div>
      <div ref={componentRef}>
      <div className="Notices">
        <div className="noticeHead">
          
            <h4>REPUBLIC OF KENYA</h4>
            <h4>IN THE CHIEF MAGISTRATES COURT AT {clientInfo.Station}</h4>
            <h4> {clientInfo.Station} LAW COURTS</h4>
            <h4>CIVIL CASE NO. {clientInfo.caseNumber} OF {clientInfo.caseYear}</h4>
         
        </div>

        <div className="NoticeParties">
          <span>{clientInfo.FullName}</span> <br /> 

          <span>VERSUS</span> <br /> 

          <span>{clientInfo.defendant} AND {clientInfo.secondddefendant}</span> 

          </div>

          <div className="noticeTitle">
            <span>{val.eventFile} NOTICE</span>
          </div>

          <div className="DefenceFirmdetails">
            <span>{clientInfo.defencefirm}</span>
            <span>{clientInfo.Location}</span> 
            <span>{clientInfo.Street}</span>
            <span style={{textDecoration:'underline',fontWeight:'bold'}}>Nairobi</span>
            <span style={{textTransform:'lowercase'}}><i>{clientInfo.defenceemail}</i></span>
          </div>

          <div className="noticebody">
          
           <span> <span style={{textTransform:'uppercase',fontWeight:'bold'}}> TAKE NOTICE </span> that the above matter has been listed for <span style={{textTransform:'uppercase',fontWeight:'bold'}}>{val.eventFile}</span> on <span style={{textTransform:'uppercase',fontWeight:'bold'}}>{moment(val.upcomingdate).format('Do MMMM YYYY')} </span> at 9.00 AM O’clock in the forenoon or soon thereafter.</span>
        
          <span><span style={{textTransform:'uppercase',fontWeight:'bold'}}>TAKE FURTHER NOTICE </span> that should you fail to attend by yourself or authorized representative, the matter shall proceed to be heard your absence notwithstanding.  </span>
          
          </div>

          <div className="documentDate">

            <span>Dated at NAIROBI this .....……{moment(val.currentdate).format('Do')} .........….day of …........……{moment(val.currentdate).format('MMMM')}…….….....…….{moment(val.currentdate).format('YYYY')}.</span>

          </div>

          <div className="firmdetails">
          <div className="signature">
          {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
            <img src={signature} alt="signature"/>
          }
          </div>

            <span style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</span>

            <span>ADVOCATES FOR THE PLAINTIFF</span>

          </div>

          <div className="drawnby">
          {currentUser.firmname === "Waiganjo Wachira & Co Advocates" && 
            <img src={signature} alt="signature"/>
          }
            <span style={{textTransform:'uppercase',fontWeight:'bold'}}>DRAWN & FILED BY</span>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <span style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</span>
            <h5><span>{initials}/{clientInfo.fileref}/{moment(clientInfo.regdate).format('YYYY')}</span></h5>
            </div>
            <span style={{textTransform:'uppercase'}}>{currentUser?.location} {currentUser?.location}</span>

            <span style={{textTransform:'uppercase'}}>P.O.BOX {currentUser?.postal} {currentUser?.postal}-00100</span>

            <span style={{textTransform:'uppercase',fontWeight:'bold'}}>NAIROBI</span>
            <span>Tel: {currentUser?.phone1} {currentUser?.phone1}</span>
          </div>

          <br  /> <br  />
          <div>
          <p style={{textAlign:'center'}}>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.upcomingdate).format('Do-MMMM-YYYY')}.</span></p>
          </div>
        
      </div>
      </div>

      <div style={{display:'flex',justifyContent:'center',marginBottom:'20px'}}>
      <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
      Print Notice <LocalPrintshopIcon style={{color:'red'}}/></button>
      </div>

      </div>
    )
  })}
  </div>
}
</div>

   
  <div className="mb-3">
    <button className="buttonDoc"> Affidavit of Service </button>
  </div>

  <div className="mb-3">
    <button className="buttonDoc"> Notice of Motion </button>
  </div>

  



  </div>


</div>

    </div>

    <div className={toggleState === 9 ? "content  active-content" : "content"}>


    <div style={{display:'flex',justifyContent:'space-between'}}>
      <div style={{borderRadius:'5px',paddingLeft:'5px'}}>
        <input 
          placeholder="Search Message"
          type="search"
        /> 
      </div>

      <div>
        <button onClick={handleOpenHmail} style={{padding:'5px 20px', color:'white',backgroundColor:'#02b159',borderRadius:'5px'}}>
        <CreateIcon /> <span style={{paddingLeft:'10px'}}>Compose</span>
        </button>

        <Modal 
        open={openHmail}
        onClose={handleCloseHmail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

       
        <Box sx={style}>
            <div>
            <div className='mb-3 mt-3'>
            <label htmlFor='Receivers' style={{marginTop:'10px'}}> To: </label>
            <select
            className='form-control' 
            id="Receivers"
            name="Receivers"
            required
            value={Receiver} 
            onChange={(e) =>{
              SetReceiver(e.target.value);
          }}
          >
            <option>select...</option>
            <option>{currentUser?.firmname} {currentUser?.firmname}</option>
            
            {messageReceivers.map((val) =>{
              return( 
                
                <option>
                {currentUser.firmname === val.lawfirmaccount &&
                  <span>{val.fullname}</span>
                }

                {currentUser.firmname === val.lawfirmaccount &&
                  <span>{val.fullname}</span> 
                }

                </option>
                  
              )
            })}
            
            
          </select>
          </div>

          <div className='mb-3' style={{marginTop:'10px'}}>
            <label htmlFor='subject'> Subject: </label>
            <input
            className='form-control' 
            id="subject"
            name="subject"
            required
            value={Subject} 
            onChange={(e) =>{
              setSubject(e.target.value);
          }}
            />
          </div>

          <div className='mb-3' style={{marginTop:'10px'}}>
            <label htmlFor='Message'> Message </label>
            <textarea
            rows={5}
            className='form-control' 
            id="Message"
            name="Message"
            required
            value={Message} 
            onChange={(e) =>{
              setMessage(e.target.value);
          }}
            />
          </div>

          <div className="mb-3 mt-3" style={{display:'none'}}>
            <lable htmlFor='Sender'>Sender</lable>
            <input 
              type='text'
              className="form-control"
              name="sender"
              id="sender"
              value={sender || ""}
              onChange={(e) =>{
                setSender(e.target.value);
              }}
            />
          </div>

          <div className="mb-3 mt-3" style={{display:'none'}}>
          <lable htmlFor='Sender'>SenderId</lable>
          <input 
            type='text'
            className="form-control"
            name="sender"
            id="sender"
            value={senderId || ""}
            onChange={(e) =>{
              setSenderId(e.target.value);
            }}
          />
        </div>

        <div className="mb-3 mt-3" style={{display:'none'}}>
        <lable htmlFor='fileId'>File Id</lable>
        <input 
          type='text'
          className="form-control"
          name="fileId"
          id="fileId"
          value={fileId || ""}
          onChange={(e) =>{
            setFileId(e.target.value);
          }}
        />
      </div>

      <div className="mb-3 mt-3" style={{display:'none'}}>
      <lable htmlFor='fileref'>FilE Ref</lable>
      <input 
        type='text'
        className="form-control"
        name="fileref"
        id="fileref"
        value={fileref || ""}
        onChange={(e) =>{
          setFileref(e.target.value);
        }}
      />
    </div>

          <div className="mb-3 mt-3" style={{display:'none'}}>
            <lable htmlFor='DateSent'>Sending Date</lable>
            <input 
            className="form-control"
            name="DateSent"
            value={DateSent}
            onChange={(e) =>{
              setDateSent(e.target.value);
            }}
          />
          </div>

          
          <div>
          <label htmlFor='attachfile' style={{display:'flex',gap:'10px'}}>
          <input 
            type='checkbox'
            value={isSubscribed}
            onChange={handleChangeCheck}
            id='attachfile'
            name='attachfile'
          />
          Attach File (Optional)
          </label>

          {isSubscribed && 
            <div style={{marginTop:'10px'}}>
              <input 
                className='form-control'
                type='file'
              />
            </div>
          
          }


          </div>

          <div style={{display:'flex',justifyContent:'center'}}>
            <button onClick={SubmitMessage} style={{padding:'5px 20px', color:'white',backgroundColor:'#02b159',borderRadius:'5px'}}>Send</button>
          </div>
          
                  
        
          </div>
        </Box>


      </Modal>



      </div>
    </div>
      
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='table'>
        <TableHead className='head'>
          <TableRow className='rows'>
            <TableCell className="tableCell rows">#</TableCell>
            <TableCell className="tableCell rows">Date</TableCell>
            <TableCell className="tableCell rows">Subject</TableCell>
            <TableCell className="tableCell rows">Sender</TableCell>
            <TableCell className="tableCell rows">Sent To</TableCell>
            <TableCell className="tableCell rows">View</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
            {messageFile.map((val,index) =>{
              return(
                <TableRow key={val.id} className="rows">
                <TableCell className="tableCell rows">{index + 1}</TableCell>
                <TableCell className="tableCell rows">{moment(val.DateSent).format("Do MMMM YYYY")}</TableCell>
                <TableCell className="tableCell rows">{val.Subject}</TableCell>
                <TableCell className="tableCell rows">{val.sender}</TableCell>
                <TableCell className="tableCell rows">{val.Receiver}</TableCell>
                <TableCell className="tableCell rows"> 
                <Link to={`/view_message/${val.id}`}>
                  <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                </Link>
              </TableCell>
                
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>

    </div>



    </div>
  </div>
  </div>
  </div>
</>
    
  );
};

export default User;