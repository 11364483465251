import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import Navbar from '../Navigation/Navbar'
import '../pages/home.scss'
import './Profile.scss'
import Axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Navigation from '../Components/NavOfficial/Navigation'
import { Leave } from '../pages/Leave'
import { LeaveApproval } from '../pages/LeaveApproval'


function Profile() {

    const { currentUser } = useContext(AuthContext);

    const {id} = useParams();
    const [total, setTotal] = useState([]);
    const [data, setData] = useState([]);

    const [dataUsers, setDataUsers] = useState([]);
    const loadDataUsers = async () => {
      const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
      setDataUsers(response.data);
    }; 

    useEffect(() =>{
      loadDataUsers();
    }, []); 

    const [name] = [currentUser.fullname || currentUser.firmname];
    const initials = name
    .split(" ")
    .map(word => word.charAt(0))
    .join("");

    useEffect (() =>{
        Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Total').then((response) =>{
            setTotal(response.data)
        });
      },[id]);
      const loadData = async () => {
        const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/statistics/matterstatistics");
        setData(response.data);
      }; 
    
      useEffect(() =>{
        loadData();
      }, []);

      const [highestCaseType, setHighestCaseType] = useState('');

      useEffect(() => {
        const fetchHighestCaseType = async () => {
          try {
            const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'api/statistics/CaseCategory');
            const data = await response.json();
            setHighestCaseType(data.highestCaseType);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchHighestCaseType();
      }, []);

      const getPercentageStyle = (percentage) => {
        if (percentage > 50) {
          return { color: 'green', transform: 'rotate(45deg)' };
        } else {
          return { color: 'red', transform: 'rotate(-45deg)' };
        }
      };


  return (

    <div className="home">
        <Navigation />
    
        <div className="homeContainer">
        <Navbar />
            <div className='profile'>

                <div className='ProfileName'>
                {currentUser.role === "Normal User" && 
                    <span> {currentUser?.fullname} </span> 
                }
                {currentUser.role === "Admin" && 
                  <span>{currentUser?.firmname}</span>
              }

              <div>
              <span> 

              {dataUsers.map((val) =>{
                return(
                  <div  key={val.id}>             
                  <div>
                  {currentUser.fullname === val.fullname && 
                    <Link to={`/updateUser/${val.id}`} style={{display:'flex',gap:'3px'}}> 
                    <div>
                    <button
                      style={{
                        backgroundColor:'white',
                        border:'1px solid white',
                        color:'rgba(2, 2, 48, 0.842)',
                        padding:'5px 5px',
                        fontWeight:'bold',
                        borderRadius:'5px'
                      }}
                    >
                      Reset your Password
                    </button> 
                    </div>
                    
                    </Link>
                    }
                  </div>
                 
                  </div>
                )
              })}
    
              
              </span>
              </div>

              <div>
              {currentUser.role === 'Admin' &&
              <div>
              <a href='/Staffmembers'>
              <button style={{backgroundColor:'white',color:'rgba(2, 2, 48, 0.842)',border:'1px solid white',padding:'5px 5px',borderRadius:'5px'}}>
                Members
              </button>
              </a>
              </div>
              }
              </div>
                </div>

              

                <div className='Performance'>
                    
                    {currentUser.role === "Normal User" && 
                    <div>
                        <div className='performanceHeaders'>

                            <div className='item'>
                                <span>Total Messages</span> 
                            </div>

                            <div className='item'>
                                <span>Trips made</span>
                            </div>

                            <div className='item'>
                                <span>Files registered</span>
                            </div>

                            <div className='item'>
                                <span>Total leave days</span>
                            </div>

                            <div className='item'>
                                <span>Total money spent</span>
                               
                            </div>

                        
                      
                        </div> 
                          <div>
                            <Leave />
                          </div>
                        </div>
                        
                    }

                    {currentUser.role === "Admin" && 
                        <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                        <div className='performanceHeaders'>

                            <div className='item'>
                                <span>File opening rate</span>
                                <div className='FileClosure'>
                                {data.map((val) =>{
                                    return(  
                                    <span> 
                                    {currentUser?.firmname === val.lawfirmaccount && 
                                    <span >
                                    <span  className='counter' style={getPercentageStyle(val.activePercentage)}>
                                     {val.activePercentage.toFixed(2) + '%'} 
                                     </span>
                                    
                                    </span>
                                  }
                                  
                                    </span>
                                   
                                    )
                                  })}
                                </div>
                            </div>

                            <div className='item'>
                                <span>File closure rate</span>
                                <div className='FileClosure'>
                                {data.map((val) =>{
                                    return(  
                                    <span> 
                                    {currentUser?.firmname === val.lawfirmaccount && 
                                    <span>
                                    <span  className='counter'>
                                     {val.closedPercentage.toFixed(2) + '%'} 
                                     </span>
                                    
                                    </span>
                                  }
                                  
                                    </span>
                                   
                                    )
                                  })}
                                </div>
                            </div>

                            <div className='item'>
                                <span>High case category</span>
                                <h5 style={{
                                    color:'green',
                                    fontWeight:'500'
                                }}>{highestCaseType}</h5>
                            </div>

                            <div className='item'>
                                <span>Total money spent</span>
                                <div className='AmountCounter'>
                                {total.map((val) =>{
                                    return(   
                                      <div>
                                      {currentUser?.firmname === val.lawfirmaccount && 
                                        <span className='counter'>
                                      Kshs.  {val.Totalamount} /=
                                        </span>
                                      }
                            
                                      </div>
                                    )
                                  })}
                                  </div>
                            </div>

                            <div className='item'>
                                <span>Total Messages</span>
                            </div>

                            <div className='item'>
                                <span>Total staff members</span>
                            </div>
                               
                        </div>

                        <div style={{width:'auto'}}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
                              <h5 style={{fontWeight:'bold',color:'rgba(2, 2, 48, 0.842)'}}>Leave Approval</h5>
                            </div>
                            <LeaveApproval />
                        </div>
                        </div>
                    }
                    

                </div>

            </div>

        </div>
    </div>

  )
}

export default Profile