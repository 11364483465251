import React, { useContext, useEffect, useState } from 'react'
import './Notifications.scss'
import './Announcements.scss'
import Navbar from '../../Navigation/Navbar'
import Alert from '@mui/material/Alert';
import Axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Navigation from '../NavOfficial/Navigation';
import CampaignIcon from '@mui/icons-material/Campaign';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const Notifications = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    }


    
    const { currentUser } = useContext(AuthContext);
    const [query, setQuery] = useState("");

    {/* Dormant files number */}

    const [dormant, setDormant] = useState([]);
    const loadData = async () => {
      const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/EventsFile/dormantTotal");
      setDormant(response.data);
    }; 
    
    useEffect(() =>{
      loadData();
    }, []); 


      {/* Requisitions */}

      const [pendingRequisition, setPendingRequisition] = useState([]);

      const loadrequisitions = async () => {
      const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/requisition/pendingrequisitions");
      setPendingRequisition(response.data);
      }; 
      
      useEffect(() =>{
        loadrequisitions();
      }, []);

    {/* Leave Requests */}

    const [leave, setLeave] = useState([]);

    const loadleave = async () => {
    const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/leave/pendingLeave");
    setLeave(response.data);
    }; 
    
    useEffect(() =>{
        loadleave();
    }, []);

    const [announcement, setAnnouncement] = useState({
        title: '',
        content: '',
        recipients: [],
      });

      const staffMembers = [
        { id: 1, name: 'Staff Member 1' },
        { id: 2, name: 'Staff Member 2' },
        { id: 3, name: 'Staff Member 3' },
        // Add more staff members as needed
      ];

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAnnouncement({
          ...announcement,
          [name]: value,
        });
      };
    
      const handleRecipientChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
          setAnnouncement({
            ...announcement,
            recipients: [...announcement.recipients, value],
          });
        } else {
          setAnnouncement({
            ...announcement,
            recipients: announcement.recipients.filter((id) => id !== value),
          });
        }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Prepare the announcement data to send to the server
        const data = {
          title: announcement.title,
          content: announcement.content,
          recipients: announcement.recipients,
        };
    
        // Send the data to the server
        fetch('/api/announcements', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response); // Handle the response from the server
            // Optionally, reset the form fields or show a success message here
            setAnnouncement({
              title: '',
              content: '',
              recipients: [],
            });
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      };

  return (

    <div className="home">
    <Navigation />
    
    <div className="homeContainer">
      <Navbar />
    <div className='Centre'>
        <Alert severity="success" className='NotificationHead'>
            <h1>Notifications Centre</h1>
            You have pending notifications. <strong>Check them out!</strong>
        </Alert>
    
    <div className='NotificationsCentre'>
        <div className='Matters'>
            <span className='elements'>
                <span> <a href='/PendingFiles'>Files Notifications</a> </span>

                <span className='Counter'>
                    {dormant.map((val) =>{
                        return(
                            <div>
                            {currentUser.firmname === val.lawfirmaccount &&
                            <span>Dormant: <span>{val.dormant}</span></span>
                            }
                         
                            </div>
                        )
                    })}
                </span>
            </span>
        </div>
        {currentUser.role === 'Admin' && 
        <div className='Matters'>
        <span className='elements'>
            <span> <a href='/RequisitionsAdmin'>Payment Notifications</a></span>
            <span className='Counter'>
                    {pendingRequisition.map((val) =>{
                        return(
                            <div>
                            {currentUser.firmname === val.lawfirmaccount &&
                            <span>Pending: <span>{val.pending}</span></span>
                            }
                            </div>
                        )
                    })}
                </span>
            </span>
        </div>
        }
        {currentUser.role === 'Admin' &&
        <div className='Matters'>
            <span className='elements'>
                <span> <a href='/LeaveApproval'>Leave Notifications</a></span>
                    <span className='Counter'>
                        {leave.map((val) =>{
                            return(
                                <div>
                                {currentUser.firmname === val.lawfirmaccount &&
                                <span>{val.pendingleave}</span>
                                }
                                </div>
                            )
                        })}
                </span>
            </span>
        </div>
        }
        {currentUser.role === 'Admin' &&
        <div className='Matters' style={{backgroundColor:'white',color:'black'}}>
            <span className='elements'>
                <span> <a href='/LeaveApproval' style={{fontSize:'20px',fontWeight:'400'}}><CampaignIcon style={{color:'red',fontSize:'20px',fontWeight:'400'}}/> <span style={{color:'black',fontSize:'20px',fontWeight:'400'}}>Staff Alerts</span> <CampaignIcon style={{color:'red',fontSize:'20px',fontWeight:'400'}}/></a></span>
                    <span className='Counter' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                       <button  onClick={handleClickOpen}>Issue Alert</button>
                </span>
            </span>
        </div>
        }
    </div>

    <div>
    <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{"Issue an announcement to your staff members"}</DialogTitle>

    <DialogContent>

      <DialogContentText id="alert-dialog-slide-description">
      <form onSubmit={handleSubmit} className='Announcement'>
      <div className='entries'>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          value={announcement.title}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className='entries'>
        <label htmlFor="content">Content:</label>
        <textarea
          id="content"
          name="content"
          value={announcement.content}
          onChange={handleInputChange}
          required
          rows={4}
        />
      </div>
      <div className='entries2'>
        <p>Select Recipients:</p>
        {staffMembers.map((staff) => (
          <label key={staff.id}>
            <input
              type="checkbox"
              name="recipients"
              value={staff.id.toString()}
              checked={announcement.recipients.includes(staff.id.toString())}
              onChange={handleRecipientChange}
            />
           <span>{staff.name}</span> 
          </label>
        ))}
      </div>
      <button type="submit">Send Announcement</button>
    </form>
      </DialogContentText>

    </DialogContent>

    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
    </div>

    </div>
    </div>
    </div>

  )
}
