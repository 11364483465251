import React, { useContext, useEffect, useState } from 'react'
import ArticleIcon from '@mui/icons-material/Article';
import './Styles/Cards.css'
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { AuthContext } from '../context/AuthContext';

export const General = () => {

    const { currentUser } = useContext(AuthContext);
    const {id} = useParams();
    const [general, setGeneral] = useState([]);

    useEffect (() =>{
        Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/statistics/General').then((response) =>{
            setGeneral(response.data)
        });
      },[id]);

  return (
    <>
    <div className='widget'>
    <div className='left'>
        <span className='title'>General cases</span>
        
        
        {general.map((val) =>{
            return(  
              <>
              {currentUser?.firmname === val.lawfirmaccount && 
              <span className='counter'> 
              {val.General.toLocaleString()}
              </span>
              }

              </>
           
           
            )
          })} 

    </div>
 
    </div>

    </>
  )
}
