import React, { useState, useEffect } from 'react';
import './WordsAnimation.scss'

const words = ['Large Law Firms', 'Small Law Firms', 'Legal Hr Managers', 'Firm Accountants', 'Justice Seekers', 'Legal Researchers'];

function WordAnimation() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);

  useEffect(() => {
    const word = words[currentWordIndex];
    if (currentLetterIndex < word.length) {
      const timeout = setTimeout(() => {
        setCurrentLetterIndex(currentLetterIndex + 1);
      }, 100); // Adjust the duration as needed
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setCurrentLetterIndex(0);
        setCurrentWordIndex((currentWordIndex + 1) % words.length);
      }, 1000); // Adjust the duration between words as needed
      return () => clearTimeout(timeout);
    }
  }, [currentWordIndex, currentLetterIndex]);

  return (
    <div className="word-animation">
      {words.map((word, index) => (
        <span
          key={index}
          className={index === currentWordIndex ? 'active' : ''}
        >
          {index === currentWordIndex ? word.slice(0, currentLetterIndex + 1) : ''}
        </span>
      ))}
    </div>
  );
}

export default WordAnimation;
