import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import axios from 'axios'
import Axios from 'axios'
import { AuthContext } from '../context/AuthContext'
import moment from 'moment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../ExistingCaseTables/table.scss'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:'auto',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius:'5px',
  boxShadow: 24,
  p: 4,
};


export const Requisitions = () => {

  const [requisition, setRequisition] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [query, setQuery] = useState(""); 


  useEffect(() => { 
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/requisition?q=${query}`);
      setRequisition(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);


  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requisition.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  
  const {id} = useParams(); 
  const history = useNavigate(); 



    const [name,setName] = [currentUser.fullname]
    const [destination,setDestination] = useState('');
    const [travelpurpose,setTravelpurpose] = useState(''); 
    const [documents,setDocuments] = useState('');
    const [PlaintA, setPlaintA] = useState('');
    const [PlaintB, setPlaintB] = useState('');
    const [PlaintC, setPlaintC] = useState('');
    const [PlaintD, setPlaintD] = useState('');

    const [PlaintE, setPlaintE] = useState('');
    const [PlaintF, setPlaintF] = useState('');
    const [PlaintG, setPlaintG] = useState('');
    const [PlaintH, setPlaintH] = useState('');
    const [PlaintI, setPlaintI] = useState('');
    const [PlaintJ, setPlaintJ] = useState('');
    const [adlitem2, setAdlitem2] = useState('');
    const [application2, setApplication2] = useState('');
    const [certificate1, setCertificate1] = useState('');
    const [certificate2, setCertificate2] = useState('');
    const [transaction, setTransaction] = useState('');
    const [credit,setCredit] = useState('');
    const [adlitem, setAdlitem] = useState('');
    const [application, setApplication] = useState('');
    const [decree, setDecree] = useState('');
    const [FollowUp, setFollowUp] = useState('');
    const [travel, setTravel] = useState('');
    const [traveldate,setTravelDate] = useState('');
    const [paymentstatus,setPaymentStatus] = useState('');
    const [fileref, setFileRef] = useState('');
    const [memorandum, setMemorandum] = useState('');

    const A = PlaintA * 2000;
    const B = PlaintB * 5000;
    const C = PlaintC * 10000;
    const D = PlaintD * 20000;
    const E = adlitem * 1000;
    const F = application * 1000;
    const G = decree * 500;
    const H = FollowUp * 1;
    const I = travel * 1;
    const J = PlaintE * 30000;
    const K = PlaintF * 40000;
    const L = PlaintG * 50000;
    const M = PlaintH * 60000;
    const N = PlaintI * 70000;
    const O = PlaintJ * 71000;
    const P = adlitem2 * 2000;
    const Q = application2 * 1500;
    const R = certificate1 * 1500;
    const S = certificate2 * 2250;
    const T = transaction * 1;
    const U = credit * 1;
    const V = memorandum * 1550;

    const [Totalvalue,setTotal] = [A+B+C+D+E+F+G+H+I+J+K+L+M+N+O+P+Q+R+S+T+U+V]
    const [lawfirmaccount,setLawFirmAccount] =  [currentUser.firmname]
    

    const SubmitEvent = () =>{
      axios.post(process.env.REACT_APP_API_ADDRESS + 'api/requisition', 
      {name:name,destination:destination,travelpurpose:travelpurpose,documents:documents,
      PlaintA:PlaintA,PlaintB:PlaintB,PlaintC:PlaintC,PlaintD:PlaintD,PlaintE:PlaintE,
      PlaintF:PlaintF,PlaintG:PlaintG,PlaintH:PlaintH,PlaintI:PlaintI,PlaintJ:PlaintJ,
    adlitem:adlitem,application:application,decree:decree,FollowUp:FollowUp,
    travel:travel,traveldate:traveldate,
    Totalvalue:Totalvalue,paymentstatus:paymentstatus,fileref:fileref,adlitem2:adlitem2,
    application2:application2,certificate1:certificate1,certificate2:certificate2,transaction:transaction,
    credit:credit,memorandum:memorandum,lawfirmaccount:lawfirmaccount,
  }).then(() => {
      alert('successful insert');
  });
  toast.success("Invoice successful. Exit")
  setTimeout(() => history('/Requisitions'),700); 
  };

  const UpdateEvent = () =>{
    Axios.put(process.env.REACT_APP_API_ADDRESS + `api/requisition/${id}`, 
    {paymentstatus:paymentstatus}).then(() => {
   
});
toast.success("Status response sent.")
setTimeout(() => history('/RequisitionsAdmin'),700);
}



  return (
    <>
    <div style={{
      paddingLeft:'30px',
      paddingRight:'30px'
    }}>
    
    <div style={{
      display:'flex',
      justifyContent:'space-between',
      paddingTop:'20px',
      paddingBottom:'20px'
    }}>

        <input 
        style={{
          border:'none',
          outline:'none',
          background:'rgba(194, 187, 187, 0.404)',
          borderRadius:'10px',
          padding:'10px 15px',
          fontweight:'500'
        }}
        type='search'
        placeholder='Search...'
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
    
        <div>
        <button onClick={handleOpen} style={{
          backgroundColor:'#02b159',
          padding:'5px 30px',
          border:'none',
          borderRadius:'5px',
          color:'white',
          fontweight:'500'
      }}>
      Requisite
        </button>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>

          <h3 style={{textAlign:'center'}}>System Invoicing</h3>

          <form style={{
            border:'none',
            width:'fitContent',
            overflow:'scroll',
            height:'60vh'
            }}>

            {currentUser.role === 'Normal User' && 
            <div className='mb-3'  style={{display:'none'}}>
              <label htmlFor='name'>Name</label>
              <input 
                className='form-control'
                type='text'
                placeholder='John Doe'
                id='name'
                name='name'
                value={name || ""}
                onChange={(e) =>{
                  setName(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div style={{display:'none'}}>
              <label htmlFor='lawfirmaccount'>Law Firm Account</label>
              <input 
              className='form-control'
              name='lawfirmaccount'
              id='lawfirmaccount' 
              value={lawfirmaccount} 
              onChange={(e) =>{
                setLawFirmAccount(e.target.value);
            }}
              />
            </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
              <label htmlFor='fileref'>File Reference</label>
              <input 
              style={{width:'400px'}}
                className='form-control'
                type='text'
                placeholder='RD1570'
                id='fileref'
                name='fileref'
                value={fileref || ""}
                onChange={(e) =>{
                  setFileRef(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
              <label htmlFor='destination'>Destination</label>
              <input 
                className='form-control'
                type='text'
                placeholder='eg. Kajiado, Kilungu'
                id='destination'
                name='destination'
                onChange={(e) =>{
                  setDestination(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='travelpurpose'>Purpose of Travel</label>
              <input 
                className='form-control'
                type='text'
                placeholder='eg. To follow up on dates'
                id='travelpurpose'
                name='travelpurpose'
                onChange={(e) =>{
                  setTravelpurpose(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='documents'>Documents to file</label>
              <input
                className='form-control'
                type='text'
                placeholder='eg. Plaints, Affidavits...'
                id='documents'
                name='documents'
                onChange={(e) =>{
                  setDocuments(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='plaintA'>Plaint(s) of value: Kshs 0 - Kshs 203,550/=</label>
              <input 
                className='form-control'
                placeholder='Enter number of plaints eg. 5,10'
                id='plaintA'
                name='plaintA'
                value={PlaintA}
                onChange={(e) =>{
                  setPlaintA(e.target.value);
              }}
                
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='plaintB'>Plaint(s) of value: Kshs 204,000 - Kshs 500,000/=</label>
              <input 
                className='form-control'
                placeholder='Enter number of plaints eg. 5,10'
                id='plaintB'
                name='plaintB'
                value={PlaintB} 
                onChange={(e) =>{
                  setPlaintB(e.target.value);
              }}
                
              />
              </div>
            }
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='plaintC'>Plaint(s) of value: Kshs 501,000 - Kshs 1,050,000/=</label>
              <input 
                className='form-control'
                placeholder='Enter number of plaints eg. 5,10'
                id='plaintC'
                name='plaintC'
                value={PlaintC}
                onChange={(e) =>{
                  setPlaintC(e.target.value);
              }}
                
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='plaintD'>Plaint(s) of value: Kshs 1,060,000 - Kshs 1,100,000</label>
              <input 
                className='form-control'
                placeholder='Enter number of plaints eg. 5,10'
                id='plaintD'
                name='plaintD'
                value={PlaintD}
                onChange={(e) =>{
                  setPlaintD(e.target.value);
              }}
                
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
            <label htmlFor='plaintE'>Plaint(s) of value: Kshs 1,101,000 - Kshs 1,150,000</label>
            <input 
              className='form-control'
              placeholder='Enter number of plaints eg. 5,10'
              id='plaintE'
              name='plaintE'
              value={PlaintE}
              onChange={(e) =>{
                setPlaintE(e.target.value);
            }}
              
            />
            </div>
          }
      
          {currentUser.role === 'Normal User' && 
          <div className='mb-3'>
          <label htmlFor='plaintF'>Plaint(s) of value: Kshs 1,160,000 - Kshs 1,200,000</label>
          <input 
            className='form-control'
            placeholder='Enter number of plaints eg. 5,10'
            id='plaintF'
            name='plaintF'
            value={PlaintF}
            onChange={(e) =>{
              setPlaintF(e.target.value);
          }}
            
          />
          </div>
        }
      
        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='plaintG'>Plaint(s) of value: Kshs 1,201,000 - Kshs 1,250,000</label>
        <input 
          className='form-control'
          placeholder='Enter number of plaints eg. 5,10'
          id='plaintG'
          name='plaintG'
          value={PlaintG}
          onChange={(e) =>{
            setPlaintG(e.target.value);
        }}
          
        />
        </div>
      }
      
      {currentUser.role === 'Normal User' && 
      <div className='mb-3'>
      <label htmlFor='plaintH'>Plaint(s) of value: Kshs 1,260,000 - Kshs 1,300,000</label>
      <input 
        className='form-control'
        placeholder='Enter number of plaints eg. 5,10'
        id='plaintH'
        name='plaintH'
        value={PlaintH}
        onChange={(e) =>{
          setPlaintH(e.target.value);
      }}
        
      />
      </div>
      }
      
      {currentUser.role === 'Normal User' && 
      <div className='mb-3'>
      <label htmlFor='plaintI'>Plaint(s) of value: Kshs 1,301,000 - Kshs 1,400,000</label>
      <input 
        className='form-control'
        placeholder='Enter number of plaints eg. 5,10'
        id='plaintI'
        name='plaintI'
        value={PlaintI}
        onChange={(e) =>{
          setPlaintI(e.target.value);
      }}
        
      />
      </div>
      }
      
      {currentUser.role === 'Normal User' && 
      <div className='mb-3'>
      <label htmlFor='plaintJ'>Plaint(s) of value: Kshs 1,401,000 & above</label>
      <input 
        className='form-control'
        placeholder='Enter number of plaints eg. 5,10'
        id='plaintJ'
        name='plaintJ'
        value={PlaintJ}
        onChange={(e) =>{
          setPlaintJ(e.target.value);
      }}
        
      />
      </div>
      }
      
      
        {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='adlitem'>Adlitem(s) </label>
              <input 
                className='form-control'
                placeholder='Enter number of plaints eg. 5,10'
                id='adlitem'
                name='adlitem'
                value={adlitem}
                onChange={(e) =>{
                  setAdlitem(e.target.value);
              }}
              />
              </div>
        }
      
        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='adlitem2'>Adlitem(s) high court </label>
        <input 
          className='form-control'
          placeholder='Enter number of plaints eg. 5,10'
          id='adlitem2'
          name='adlitem2'
          value={adlitem2}
          onChange={(e) =>{
            setAdlitem2(e.target.value);
        }}
        />
        </div>
      }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='application'>Application(s) </label>
              <input 
                className='form-control'
                placeholder='Enter number of applications eg. 5,10'
                id='application'
                name='application'
                value={application}
                onChange={(e) =>{
                  setApplication(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
            <label htmlFor='application2'>Application(s) high court</label>
            <input 
              className='form-control'
              placeholder='Enter number of applications eg. 5,10'
              id='application2'
              name='application2'
              value={application2}
              onChange={(e) =>{
                setApplication2(e.target.value);
            }}
            />
            </div>
          }
      
          {currentUser.role === 'Normal User' && 
          <div className='mb-3'>
          <label htmlFor='certificate1'>Certificate of urgency</label>
          <input 
            className='form-control'
            placeholder='Enter number of applications eg. 5,10'
            id='certificate1'
            name='certificate1'
            value={certificate1}
            onChange={(e) =>{
              setCertificate1(e.target.value);
          }}
          />
          </div>
        }
      
        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='certificate2'>Certificate of urgency high court</label>
        <input 
          className='form-control'
          placeholder='Enter number of applications eg. 5,10'
          id='certificate2'
          name='certificate2'
          value={certificate2}
          onChange={(e) =>{
            setCertificate2(e.target.value);
        }}
        />
        </div>
      }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='decree'>Decrees,order,bill of costs, certificate of costs </label>
              <input 
                className='form-control'
                placeholder='Enter number of decrees eg. 5,10'
                id='decree'
                name='decree'
                value={decree}
                onChange={(e) =>{
                  setDecree(e.target.value);
              }}
              />
              </div>
            }
      
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
            <label htmlFor='memorandum'>Memorandum of appeal</label>
            <input 
              className='form-control'
              placeholder='Enter number of decrees eg. 5,10'
              id='memorandum'
              name='memorandum'
              value={memorandum}
              onChange={(e) =>{
                setMemorandum(e.target.value);
            }}
            />
            </div>
          }
      
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='FollowUp'>Registry followUp token </label>
              <input 
                className='form-control'
                placeholder='Enter amount provided'
                id='FollowUp'
                name='FollowUp'
                value={FollowUp}
                onChange={(e) =>{
                  setFollowUp(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
            <label htmlFor='transaction'>Filing transaction fee</label>
            <input 
              className='form-control'
              placeholder='Enter amount provided'
              id='transaction'
              name='transaction'
              value={transaction}
              onChange={(e) =>{
                setTransaction(e.target.value);
            }}
            />
            </div>
          }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='travel'>Travel Allowance </label>
              <input 
                className='form-control'
                placeholder='Enter amount given'
                id='travel'
                name='travel'
                value={travel}
                onChange={(e) =>{
                  setTravel(e.target.value);
              }}
              />
              </div>
            }
      
            {currentUser.role === 'Normal User' && 
            <div className='mb-3'>
            <label htmlFor='credit'>Office phone credit</label>
            <input 
              className='form-control'
              placeholder='Enter amount given'
              id='credit'
              name='credit'
              value={credit}
              onChange={(e) =>{
                setCredit(e.target.value);
            }}
            />
            </div>
          }
      
            {currentUser.role === 'Normal User' && 
              <div className='mb-3'>
              <label htmlFor='traveldate'>Date of Travel </label>
              <input 
                className='form-control'
                type='date'
                id='traveldate'
                name='traveldate'
                value={traveldate}
                onChange={(e) =>{
                  setTravelDate(e.target.value);
              }}
              />
              </div>
            }
            
            {currentUser.role === 'Normal User' && 
              <div className='mb-3' >
              <input 
                className='form-control'
                id='Totalvalue'
                name='Totalvalue'
                value={Totalvalue}
                onChange={(e) =>{
                  setTotal(e.target.value);
              }}
              />
              </div>
            }
            {currentUser.role === 'Admin' && 
              <div className='mb-5'>
              <label htmlFor='paymentstatus'> Status </label>
              <select
              className='form-control' 
              id="paymentstatus"
              name="paymentstatus"
              value={paymentstatus || ""}
              onChange={(e) =>{
                setPaymentStatus(e.target.value);
            }}
            >
              <option>Select...</option>
              <option>Declined</option>
              <option>Paid</option>
              
            </select>
            </div>
          }
      
          {currentUser.role === 'Normal User' && 
              <div className='mb-3' style={{
                display:'flex',
                justifyContent:'space-evenly'
                
            }}>
                <button autoFocus onClick={handleClose} style={{
                  backgroundColor:'red',
                  padding:'5px 30px',
                  border:'none',
                  borderRadius:'5px',
                  color:'white',
                  fontweight:'500'
                  }}>
                  Exit
                  </button>

                  <button 
                  type='button' 
                  onClick={SubmitEvent}  
                  style={{
                    backgroundColor:'#02b159',
                    padding:'5px 30px',
                    border:'none',
                    borderRadius:'5px',
                    color:'white',
                    fontweight:'600'
                }}>
                  Submit
                </button>
                  
              </div>
            }
      
              {currentUser.role === 'Admin' && 
              <div className='mb-3'>
                  <input type='button' onClick={UpdateEvent} className='btn btn-primary btn-block' value={'Submit Status'}/>
              </div>
          }
        
          </form>

        </Box>
        </Modal>
        </div>
        

      </div>

      <TableContainer component={Paper} >
      <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className='table'>
      <TableHead className='head'>
      <TableRow className='rows'>
        <TableCell className="tableCell rows">Citation</TableCell>
        <TableCell className="tableCell rows">Travel Location</TableCell>
        <TableCell className="tableCell rows">Travel Date</TableCell>
        <TableCell className="tableCell rows">Travel Reason</TableCell>
        <TableCell className="tableCell rows">Total Amount Given</TableCell>
        <TableCell className="tableCell rows">Status</TableCell>
        <TableCell className="tableCell rows">Action</TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
      {(rowsPerPage > 0
        ? requisition.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : requisition
      ).map((val,index) =>{
        return(
            <TableRow key={val.id} className='rows'> 
            
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.name}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.destination}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{moment(val.traveldate).fromNow()}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">{val.travelpurpose}</TableCell>  }
            {currentUser.fullname === val.name &&    <TableCell className="tableCell rows">Kshs.{val.Totalvalue}/=</TableCell>  }
            {currentUser.fullname === val.name &&    
            <TableCell className="tableCell rows">
            <span className={`status2 ${val.paymentstatus}`}>{val.paymentstatus}</span></TableCell>  }

              {currentUser.fullname === val.name && 
              <TableCell className="tableCell rows">
               <Link to={`/requisition/${val.id}_${val.name}`}>
                  <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                </Link>
              </TableCell> 
              }
            </TableRow>
        )
      })}

      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
 
    </TableBody>
    <TableFooter>
    <TableRow>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        colSpan={4}
        count={requisition.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  </TableFooter>
    </Table>
    </TableContainer>
    </div>
  
    </>
  )
}
