import React from 'react';
import './Communications.scss';
import UserNavigation from '../UserNavigation/UserNavigation';

const Communications = () => {
  return (
    <div>
        <UserNavigation />
    <div className="communications-container">
      <div className="message-list">
        {/* Placeholder for the list of conversations/messages */}
        <div className="conversation">
          <div className="profile-picture">👤</div>
          <div className="message-preview">
            <div className="sender-name">John Doe</div>
            <div className="latest-message">Hello there!</div>
          </div>
          <div className="timestamp">12:30 PM</div>
        </div>
        {/* Add more conversation elements as needed */}
      </div>

      <div className="message-thread">
        {/* Placeholder for the conversation/message thread */}
        <div className="message">
          <div className="profile-picture">👤</div>
          <div className="message-content">This is a sample message.</div>
          <div className="timestamp">12:32 PM</div>
        </div>
        {/* Add more message elements as needed */}
      </div>

      <div className="message-composer">
        {/* Placeholder for the message composition area */}
        <input type="text" placeholder="Type your message..." />
        <button className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'>Send</button>
      </div>
    </div>
    </div>
  );
};

export default Communications;
