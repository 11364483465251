import React, { useEffect, useState } from 'react'
import Navbar from '../Navigation/Navbar'
import Sidebar from '../Navigation/Sidebar'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import moment from 'moment';
import InboxIcon from '@mui/icons-material/Inbox';
import Axios from 'axios'
import SendIcon from '@mui/icons-material/Send';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import '../Profile/SuggestionsMessage.css'
import Navigation from '../Components/NavOfficial/Navigation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
 
export const Suggestions = () => {

    const [suggestions,setSuggestions] = useState([]);
    const [messages,setMessages] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions?q=${query}`);
          setSuggestions(res.data);
        };
        if (query.length === 0 || query.length > 2) fetchData();
      }, [query]);

      useEffect(() => {
        const fetchData = async () => {
          const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/suggestions/messages?q=${query}`);
          setMessages(res.data);
        };
        if (query.length === 0 || query.length > 2) fetchData();
      }, [query]);


      const getText = (html) =>{
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - suggestions.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { currentUser } = useContext(AuthContext);

  const [taskInfo,setTaskInfo] = useState({
    name:'',
    taskname:'',
    description:'',
    dateissued:'',
    status:'', 
    sender:''
  })

  const {status} = taskInfo;

  const handleOnChange = (e) =>{
    const {name, value} = e.target
    setTaskInfo({...taskInfo,[name]:value}) 
  }

  const history = useNavigate();
  const {id} = useParams();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleChangeCheck = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };




  const [filingdate,setFilingDate] = [moment().format("YYYY-MM-DD hh:mm:ss")];
  const [fullname,setFullName] =  [currentUser.fullname || currentUser.firmname]

  const handleSubmit = (e) =>{

    if(!status ){
      toast.error("All values are required")
    }else{
      if(!id){
        axios.post(process.env.REACT_APP_API_ADDRESS + "api/tasks", 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",dateissued:"",status:"",sender:''})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task added successfully")
      }else{
        axios.put(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`, 
      taskInfo).then(() =>{
        setTaskInfo({name:"",taskname:"",description:"",dateissued:"",status:"",sender:""})
      }).catch((err) => toast.err(err.response.data));
      toast.success("Task updated successfully")
      }
      
      setTimeout(() => history('/index'),700); 
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`);
        setTaskInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  


  const [data, setData] = useState([]);

  const loadData = async () => {
    const response = await Axios.get(process.env.REACT_APP_API_ADDRESS + "api/Auth/getUsers");
    setData(response.data);
  }; 

  useEffect(() =>{
    loadData();
  }, []); 




  return (
    
    <div className="home"> 
    <Navigation />

    <div className="homeContainer">
    <Navbar />
        <div style={{marginLeft:'50px',marginRight:'50px',marginTop:'50px',display:'flex',justifyContent:'space-between'}}>

        <div className='Messagebar'>
          <span style={{fontSize:'25px'}}>Hmail</span>

          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',gap:'1rem'}}>
            <button className='MailButtonInbox'>
            <InboxIcon />
            <div style={{display:'flex',gap:'10px'}}>
            <span>Inbox</span> 
            <span>
              <div className="counter">
              {messages.map((val) =>{
                return(
                  <div>
                    <span style={{fontWeight:'bold'}}>
                    {currentUser.fullname === val.Receiver && 
                      <span>{val.messages}</span>
                    }
                    {currentUser.firmname === val.Receiver && 
                      <span> {val.messages} </span>
                      }
                    </span>
                    
                  </div>
                )
              })}
              </div>
            </span>
            </div>
            </button>
            
            <button className='MailButton'><SendIcon /><span style={{paddingLeft:'10px'}}>Sent messages</span></button>
          </div>
         
        </div>
        
            <TableContainer component={Paper}>
                <div className='Inboxbar'>
                  <span className='inboxBarText'></span>
                  <div>
                    <input 
                    style={{borderRadius:'5px',height:'30px',padding:'5px 10px',border:'none',outline:'none'}}
                    type="search"
                    placeholder='Search message'
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  />
                  
                </div>
                 
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
                    <TableHead className='head'>
                        <TableRow className='rows'>
                            <TableCell className="tableCell rows">
                            <div>
                              <input 
                                type="checkbox"
                              /> All
                              </div>
                            </TableCell>
                            <TableCell className="tableCell rows"></TableCell>
                            <TableCell className="tableCell rows"></TableCell>
                            <TableCell className="tableCell rows"></TableCell>
                            <TableCell className="tableCell rows"></TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(rowsPerPage > 0
                            ? suggestions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : suggestions
                          ).map((val,index) =>{
                            return(
                                <TableRow key={val.id} className='rows'>
                                
                                {currentUser.fullname === val.Receiver && 
                                <TableCell className="tableCell rows">
                                    <input 
                                    type="checkbox"
                                    />
                                </TableCell>
                                }
                                {currentUser.firmname === val.Receiver && 
                                  <TableCell className="tableCell rows">
                                    <input 
                                    type="checkbox"
                                    />
                                </TableCell>
                                }
                                {currentUser.fullname === val.Receiver && 
                                <TableCell className="tableCell rows">
                                    {val.fileref}
                                </TableCell>
                                }

                                {currentUser.firmname === val.Receiver && 
                                  <TableCell className="tableCell rows">
                                    {val.fileref}
                                  </TableCell>
                                }

                                {currentUser.fullname === val.Receiver && 
                                <TableCell className="tableCell rows">
                                    {val.Subject}
                                </TableCell>
                                }

                                {currentUser.firmname === val.Receiver && 
                                  <TableCell className="tableCell rows">
                                    {val.Subject}
                                  </TableCell>
                                }


                                {currentUser.fullname === val.Receiver && 
                                <TableCell className='tablecell rows'>{moment(val.DateSent).format("D-MMM-YYYY")}</TableCell>
                                }

                                {currentUser.firmname === val.Receiver && 
                                  <TableCell className='tablecell rows'>{moment(val.DateSent).format("D-MMM-YYYY")}</TableCell>
                                }

                                {currentUser.fullname === val.Receiver && 
                                <TableCell className="tableCell rows"> 
                                  <Link to={`/view_message/${val.id}`}>
                                    <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                                  </Link>
                                </TableCell>
                              }

                              {currentUser.firmname === val.Receiver && 
                                <TableCell className="tableCell rows"> 
                                  <Link to={`/view_message/${val.id}`}>
                                    <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                                  </Link>
                                </TableCell>
                              }

                            </TableRow>
                            )
                          })}

                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                      
                    </TableBody>

                    <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={4}
                    count={suggestions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>


                </Table>
            </TableContainer>
        </div>
    </div>
    </div>
    
  )
}
