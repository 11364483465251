import React, { useContext, useEffect, useState } from 'react'
import './UserSummary.scss'
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

export const UserSummary = () => {
    
  const { currentUser,logout } = useContext(AuthContext);

    const [salesDash, setSalesDash] = useState([]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/performance/${currentUser.fullname}`)
          .then(response => {
            setSalesDash(response.data); 
          })
          .catch(error => {
            console.error('Error fetching sales data:', error);
          });
      }, [currentUser]); 

    const renderData = (data, property) => {
        return data.map(val => ( 
          <div key={val.id}>
            <span>{val[property].toLocaleString()}</span>
          </div>
        ));
      };


  return (
    <div className='UserSummary'>


        <div className='grid grid-cols-2 sm:grid-cols-5 md:grid-cols-3 lg:grid-cols-5 gap-4 overflow-x-scroll'>
            <div 
                className='bg-blue-600 p-4 text-white rounded-md hidden lg:block sm:block md:block' 
             
            >
                <span className='text-lg font-bold'>Total Cases opened</span>
                <small className='font-bold text-lg'>{renderData(salesDash, 'total_individual_cases')}</small>
            </div>
    
            <div 
                className='bg-green-600 p-4 text-white rounded-md hidden lg:block sm:block md:block'
              
            >
                <span className='text-lg font-bold'>Total trips</span>
                <small className='font-bold text-lg'>{renderData(salesDash, 'total_trips')}</small>
            </div>
    

            <div 
                className='bg-yellow-600 p-4 text-white rounded-md'
                
            >
                <span className='text-lg font-bold'>Total expenses</span>
                <small className='font-bold text-lg'>{renderData(salesDash, 'individual_total')}</small>
            </div>

            <div 
              className='bg-purple-600 p-4 text-white rounded-md'
             
              >
              <span className='text-lg font-bold'>Appointments</span>
              <small className='font-bold text-lg'>{renderData(salesDash, 'total_booked_appointments')}</small>
            </div>

            <div 
            className='bg-red-600 p-4 text-white rounded-md hidden lg:block sm:block md:block'
         
            >
                <span className='text-lg font-bold'>Bonus points</span>
                <small className='font-bold text-lg'>{renderData(salesDash, 'total_bonus')}</small>
            </div>
            
        </div>
    </div>
  )
}
