import React, { useState } from 'react';
import './CaseForm.scss';
import { Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import './MainStyles/PartiesPage.scss'
import './StylesHere/AnimatedText.scss'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CaseForm = () => {
  const [step, setStep] = useState(1);
  const [parties, setParties] = useState([]);
  const [courtStation, setCourtStation] = useState('');
  const [courtDivision, setCourtDivision] = useState('');
  const [partyType, setPartyType] = useState('');
  const [caseType, setCaseType] = useState('');
  const [firmName, setFirmName] = useState('');
  const [otherDetails, setOtherDetails] = useState('');
  const [documents, setDocuments] = useState({
    policeAbstract: false,
    medicalSummary: false,
    xrays: false,
    receipts: false,
    nationalIdentityCard: false,
    p3Copy: false,
  });
  const [isAddPartyModalOpen, setAddPartyModalOpen] = useState(false);
  const [newParty, setNewParty] = useState({
    partyType: '',
    number: '',
    name: '',
    mobile: '',
    residence: '',
  });

  const handlePartyTypeChange = (event) => {
    setPartyType(event.target.value);
  };

  const handleInputChange = (event, key) => {
    setNewParty((prevParty) => ({
      ...prevParty,
      [key]: event.target.value,
    }));
  };

  const handleDocumentChange = (event) => {
    const { name, checked } = event.target;
    setDocuments((prevDocuments) => ({
      ...prevDocuments,
      [name]: checked,
    }));
  };

  const addParty = () => {
    const newPartyWithPartyType = { ...newParty, partyType };
    setParties((prevParties) => [...prevParties, newPartyWithPartyType]);
    setNewParty({
      partyType: '',
      number: '',
      name: '',
      mobile: '',
      residence: '',
    });
    setAddPartyModalOpen(false);
  };

  const editParty = (index) => {
    const party = parties[index];
    setPartyType(party.partyType);
    setParties((prevParties) => {
      const updatedParties = [...prevParties];
      updatedParties.splice(index, 1);
      return updatedParties;
    });
  };

  const deleteParty = (index) => {
    setParties((prevParties) => {
      const updatedParties = [...prevParties];
      updatedParties.splice(index, 1);
      return updatedParties;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the data to be submitted
    const data = {
      parties,
      courtStation,
      courtDivision,
      partyType,
      caseType,
      firmName,
      otherDetails,
      documents,
    };

    // Send the case creation request to the backend
    fetch('/cases', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response from backend
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const [isRegistered, setIsRegistered] = useState(false);

  const checkRegistrationStatus = () => {
    // Make an API call to check the registration status based on the ID number
    // Replace the `API_ENDPOINT` with your actual API endpoint
    fetch(`/checkRegistrationStatus/${newParty.number}`)
      .then((response) => response.json())
      .then((data) => {
        setIsRegistered(data.isRegistered);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffixIndex = number % 100 > 10 && number % 100 < 20 ? 0 : number % 10;
    return suffixes[suffixIndex] || suffixes[0];
  };

  const renderPartiesTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Residence</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parties.map((party, index) => (
            <TableRow key={index}>
              <TableCell>{party.partyType}</TableCell>
              <TableCell>{`${party.number}${getOrdinalSuffix(party.number)}`}</TableCell>
              <TableCell>{party.name}</TableCell>
              <TableCell>{party.mobile}</TableCell>
              <TableCell>{party.residence}</TableCell>
              <TableCell>
                <Button variant="outlined" color="primary" onClick={() => editParty(index)}>
                  Edit
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => deleteParty(index)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <div className='PartiesPage'>
              <div className="animated-text" style={{
                                                      display:'flex',
                                                      flexDirection:'row',
                                                      gap:'50px',
                                                      alignItems:'center',
                                                      marginBottom:'20px'
                                                    }}
                > 
                <h2 className='circle'> Step 1 </h2>
                <div>
                  <h1>Embrace the digital leap: Step 1, where paper trails transform!</h1>
                </div>
              </div>

            <div className='AddParty'>
            
              <button onClick={() => setAddPartyModalOpen(true)}>
                Add Party
              </button>

              <Modal 
              open={isAddPartyModalOpen} 
              onClose={() => setAddPartyModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
               
              }}

              >
              <div style={{
                backgroundColor:'white',
                paddingTop:'30px',
                paddingLeft:'30px',
                paddingRight:'30px',
                paddingBottom:'30px',
                border:'none',
                borderRadius:'5px'
              }}>


              <h3 style={{fontWeight:'500',color:'grey'}}>Add Party</h3>
              <hr />

                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    marginTop:'20px',
                    justifyContent:"center",
                    alignItems:'center'
                  }}>
                  <div style={{display:'flex',gap:"40px",justifyContent:'center',alignItems:'center'}}>

                  <div style={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'15px',
                    justifyContent:'center',
                    alignItems:'flex-start'
                  }}>
                  <div style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:"5px"
                  }}>
                    <label>Party Level:</label>
                    <select value={newParty.number} onChange={(event) => handleInputChange(event, 'number')} className='form-control' style={{width:'200px',borderRadius:'5px'}}>
                      <option value="">Select Number</option>
                      {Array.from({ length: 99 }, (_, i) => i + 1).map((number) => (
                        <option value={number} key={number}>
                          {number}{getOrdinalSuffix(number)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:"5px"
                  }}>
                  <label>Party Type:</label>
                  <select value={partyType} onChange={handlePartyTypeChange} style={{width:'200px',borderRadius:'5px'}} className='form-control'>
                    <option>Select Party Type</option>
                    <option>Plaintiff</option>
                    <option>Defendant</option>
                  </select>
                </div>

                  <div style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:"5px"
                  }}>
                    <label htmlFor='idNumber'>National Identity Number</label>
                    <input 
                    className='form-control'
                    style={{width:'200px',borderRadius:'5px'}}
                    value={newParty.number}
                    onChange={(event) => setNewParty({ ...newParty, number: event.target.value })}
                    />
                  </div>

                  <Button variant="contained" color="primary" onClick={checkRegistrationStatus}>
                  Check
                  </Button>

                 


                  </div>

                  {isRegistered ? (
                    <p>ID number is already registered.</p>
                  ) : (
                    <>
                  <div style={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'15px',
                    justifyContent:'center',
                    alignItems:'flex-start'
                  }}>

                  
                  <div style={{
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column',
                    alignItems:'flex-start',
                    gap:"5px"
                  }}>
                    <label>Full Name:</label>
                    <input
                      value={newParty.name}
                      onChange={(event) => handleInputChange(event, 'name')}
                      style={{width:'200px',borderRadius:'5px'}}
                      className='form-control'
                    />
                  </div>

                  
                  <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    flexDirection:'column',
                    gap:"5px"
                  }}>
                    <label>Mobile:</label>
                    <input
                      value={newParty.mobile}
                      onChange={(event) => handleInputChange(event, 'mobile')}
                      style={{width:'200px',borderRadius:'5px'}}
                      className='form-control'
                    />
                  </div>

                  <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    flexDirection:'column',
                    gap:"5px"
                  }}>
                    <label>Mobile 2:</label>
                    <input
                      value={newParty.mobile}
                      onChange={(event) => handleInputChange(event, 'mobile')}
                      style={{width:'200px',borderRadius:'5px'}}
                      className='form-control'
                    />
                  </div>


                  <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    flexDirection:'column',
                    gap:"5px"
                  }}>
                    <label>Residence:</label>
                    <input
                      value={newParty.residence}
                      onChange={(event) => handleInputChange(event, 'residence')}
                      style={{width:'200px',borderRadius:'5px'}}
                      className='form-control'
                    />
                  </div>
                  </div>
                  </>
                  )}

                </div>

                  <div>
                    <Button variant="contained" color="primary" onClick={addParty}>
                      Add
                    </Button>
                  </div>
                  
                </div>
                </div>
              </Modal>
            </div>
            {renderPartiesTable()}
            <Button variant="contained" color="primary" onClick={() => setStep(2)}>
              Next
            </Button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Step 2: Case Details</h2>
            {/* Render case details form */}
            <Button variant="contained" color="primary" onClick={() => setStep(3)}>
              Next
            </Button>
          </div>
        );

        case 3:
          return (
            <div>
              <h2>Step 3: Case Details</h2>
              {/* Render case details form */}
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          );

      default:
        return null;
    }
  };

  return (
    <div className="case-form">
      {renderForm()}
    </div>
  );
};

export default CaseForm;
