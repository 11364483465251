import React,{useEffect, useState} from 'react'
import './header1.scss'
import LOGO from '../ASSETS/LFH.svg';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';


const Header = ()=>{
const [open,setOpen] = useState(false);


const handleClick = (e)=>{
  e.preventDefault();
  setOpen(!open);
}

const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <div className='header'>

          <div className={classNames('navbar', { 'scrolled': isScrolled })}>

            <div className='logo'>
              <img src={LOGO} alt="logo" />
            </div>

            <div
              className={open ? "nav-items active" : "nav-items"}
            >
              <span>
                <a href='/' className={isScrolled ? 'scrolled' : ''}>Home</a>
              </span>
              <span>
                <a href='/' className={isScrolled ? 'scrolled' : ''}>About us</a>
              </span>
              <span>
                <a href='/' className={isScrolled ? 'scrolled' : ''}>Products</a>
              </span>
              <span>
                <a href='/' className={isScrolled ? 'scrolled' : ''}>Services</a>
              </span>
              
              
              <span>
                <a href='/' className={isScrolled ? 'scrolled' : ''}>LFH_CSR</a>
              </span>
              

              <span> 
                <button>
                <a href='/Auth'>Sign In</a>
                </button>
              </span>

              <span>
                <button>
                  <a href='/Auth'>Request a Demo</a>
                </button>
              </span>
            </div>
            <div className="hamburger">
                  <span><MenuIcon style={{fontSize:'25px'}} onClick={handleClick}/></span>
              </div>
          </div>
      </div>
  );
}

export default Header;
