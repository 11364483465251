import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios'
import { AuthContext } from '../context/AuthContext';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';
 

const UpdateUser = () => {

    const { currentFirm } = useContext(AuthContext);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const history = useNavigate();
    const {id} = useParams();
  
    const UpdateEvent = () =>{
        axios.put(process.env.REACT_APP_API_ADDRESS + `api/Auth/updateUser/${id}`, 
        {username:username,password:password,}).then(() => { 
    }); 
    toast.success("Status response sent.")
    setTimeout(() => history('/index'),700);
    }

    
  return (
    <>

    <div className="home">
    <Navigation />

    <div className="homeContainer">
    <Navbar />
    <div className='main'>
    <div className='file-card'>
    <h5 style={{textAlign:'center', color:'red'}}>Reset password account password</h5>
   
    <div className='mb-3' style={{marginTop:'10px'}}>
    <label htmlFor='username'>Enter your username</label>
    <input
    className='form-control' 
    type='email'
    id="username"
    name="username"
    required
    value={username || ""}
    onChange={(e) =>{
    setUserName(e.target.value);
    }}
    />

    </div>

    <div className='mb-3' style={{marginTop:'10px'}}>
    <label htmlFor='password'>Enter new password</label>
    <input
    type='password'
    className='form-control' 
    id="password"
    name="password"
    value={password || ""}
    onChange={(e) =>{
    setPassword(e.target.value);
    }}
    />
    </div>


<br />

<div className='float-center'>
    <input type="submit" onClick={UpdateEvent} className="btn btn-primary btn-block" value={"Submit"} />
</div>

  </div>
  </div>
  </div>
  </div>

    </>
  )
}

export default UpdateUser