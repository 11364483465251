import React, { useContext, useEffect, useRef, useState } from 'react'
import {useReactToPrint} from 'react-to-print'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import '../causelistStyle/causelist.css'
import '../ExistingCaseTables/TableClient1.css'
import axios from 'axios';
import moment from 'moment';

import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';
import { AuthContext } from '../context/AuthContext';


export const Friday = () => {

    const Friday  = moment().add(3,'days').format('dddd, Do MMMM YYYY');
    const { currentUser,currentFirm } = useContext(AuthContext);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Cause List'
    });

    /* Importing the data from mysql */
    const [Activity,setActivity] = useState([]);
    
    const loadData = async () => {
        const response = await axios.get(process.env.REACT_APP_API_ADDRESS + "api/EventsFile/Day5");
        setActivity(response.data);
      }; 
    
      useEffect(() =>{
        loadData();
      }, []);

  return (
        <>
        <div className="home">
        <Sidebar />
    
        <div className="homeContainer">
        <Navbar />
        <div style={{display:'flex',flexDirection:'row',gap:'2rem',marginBottom:'10px',marginTop:'50px'}}>
        <div style={{paddingLeft:'45%'}}>
        <button type="button" className='causelistButton'  onClick={handlePrint}>
        <LocalPrintshopIcon style={{color:'white'}}/> Print
         </button>
        </div>
      
        </div>
        <div ref={componentRef} className="card">
        <div className='causelist'>
            <div>
                <h4>Magistrate Court Commercial</h4>
                <h5>Cause List</h5>
                <p>..................</p>
                <h5>Matters on {Friday} </h5>
            </div>
            <div style={{paddingLeft:'50px',paddingRight:'50px'}}>
            <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="custom pagination table" className='table'>
                <TableHead className='head'>
                    <TableRow className='rows'>
                        <TableCell className="tableCell rows">#</TableCell>
                        <TableCell className="tableCell rows">File Reference</TableCell>
                        <TableCell className="tableCell rows">Activity</TableCell>
                        <TableCell className="tableCell rows">Actioned To</TableCell>
                        <TableCell className="tableCell rows">Citation</TableCell>
                    </TableRow>
                </TableHead>
                    
                     
                   
                    <tbody>
                    {Activity.length === 0 ? (
                        <TableRow className='rows'>
                            <td colSpan={4}>No matters available today</td>
                        </TableRow>
                    ): (
                        Activity.map((val,index) =>{
                            return(
                            <TableRow key={val.id} className='rows'>
                            {currentFirm.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{index + 1}</TableCell>
                            }
                            {currentUser.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{index + 1}</TableCell>
                            }
                            {currentFirm.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.fileid}</TableCell>
                            }
                            {currentUser.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.fileid}</TableCell>
                            }
                            {currentFirm.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.eventFile}</TableCell>
                            }
                            {currentUser.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.eventFile}</TableCell>
                            }

                            {currentFirm.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.actionTo}</TableCell>
                            }
                            {currentUser.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.actionTo}</TableCell>
                            }

                            {currentFirm.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.clientname} vs {val.Defendant}</TableCell>   
                            }
                            {currentUser.firmname === val.lawfirmaccount &&
                                <TableCell className="tableCell rows">{val.clientname} vs {val.Defendant}</TableCell>   
                            }    
                            </TableRow>
                                
                            )
                        })
                    )}
                    </tbody>
                </Table>
                </TableContainer>
            </div>
    
        </div>
        </div>
        </div>
        </div>
        
        
        </>
  )
}
