import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import UserNavigation from '../UserNavigation/UserNavigation';
import './CasesPage.scss'

const CasesPage = () => {
  const { stationName } = useParams();
  const [cases, setCases] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/${stationName}?q=${searchQuery}`)
      .then((response) => {
        setCases(response.data);   
      })
      .catch((error) => {
        console.error('Error fetching cases:', error);
      });
  }, [searchQuery,stationName]);

  return (
    <div>
        <UserNavigation />
    <div className='CasesPage'>

      <h4>{stationName}</h4>

        <Paper sx={{ width: '100%', overflow: 'scroll' }}>
          <div className='StationSearch'>
            <input 
              placeholder='Search case...'
              onChange={handleInputChange}
              value={searchQuery}
            />
          </div>
        <TableContainer  sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead> 
              <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>File reference</TableCell>
                  <TableCell>Opening Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {cases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((caseItem,index) =>{
                return(
                <TableRow>
                    <TableCell style={{whiteSpace:'nowrap'}}>{index + 1}</TableCell>
                    <TableCell>{caseItem.fileref} </TableCell>
                    <TableCell>{moment(caseItem.regdate).format('Do-MMMM-YYYY')}</TableCell>
                    <TableCell>{caseItem.status}</TableCell>
                    <TableCell>
                        <Link to={`/update/${caseItem.id}`}>
                            <button className="btnone btn-edit"><EditIcon style={{ fontSize: '16px' }} /></button>
                        </Link>

                        <Link to={`/users/${caseItem.id}_${caseItem.FullName}`}>
                            <button className="btnone btn-view"><VisibilityIcon style={{ fontSize: '16px' }} /></button>
                        </Link>
                    </TableCell>
                    
                </TableRow>
                )
            })}
            </TableBody>
        </Table>
        
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Paper>
    </div>
    </div>
  );
};

export default CasesPage;
