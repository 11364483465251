import React, { useContext, useEffect, useRef, useState } from 'react';
import './Expenses.scss';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'; 
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import UserNavigation from '../UserNavigation/UserNavigation';
import { AuthContext } from '../../context/AuthContext';
import './InvoiceShow.scss'
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const InvoiceShow = ({ invoice }) => {


  const { currentUser } = useContext(AuthContext);
  const sender_namePdf = currentUser?.fullname;
  const sender_idPdf = currentUser?.id;
  const today = moment().format('MMMM D, YYYY'); 
  const contentRef = useRef(null);

  const downloadInvoice = () => {
    const content = contentRef.current;

    // Create a canvas from the content
      html2canvas(content, { scale: 10 }).then((canvas) => {
      // Convert canvas to PDF using jsPDF with higher DPI
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imageWidth = 150; // Adjusted width of the image
      const imageHeight = 210; // Adjusted height of the image

        // Calculate the X and Y coordinates to center the image
        const xCoordinate = (pageWidth - imageWidth) / 2;
        const yCoordinate = (pageHeight - imageHeight) / 2;
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          xCoordinate,
          yCoordinate,
          imageWidth,
          imageHeight,
          undefined,
          'FAST'
        );
      pdf.save(`${sender_namePdf}_${sender_idPdf}_${today}.pdf`);
    });
  };

  
  const [showInvoice, setShowInvoice] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInvoice(true);
    }, 3000);

    // Cleanup the timer to avoid side effects
    return () => clearTimeout(timer);
  }, [invoice]);

  if (!invoice || !invoice[0] || !showInvoice) {
    // Handle the case when no invoice is selected, data is still being fetched, or waiting for 1 second
    return <div style={{ backgroundColor: '#1F9D55', color: 'white', padding: '10px 15px' }}>Processing the invoice...</div>;
  }


  const {
    invoice_code,
    sender_name,
    amount,
    case_date,
    clients_name,
    description,
    destination,
    expenseOptions,
    file_reference,
    id,
    purchase_date,
    purpose,
    sender_id,
    travel_date,
    utility_type,
    created_date,
  } = invoice[0] || {};


  return( 
      <div className='InvoiceShow'>
        <DialogContent className="DialogContent" ref={contentRef}>
        <DialogContentText className='ContextText' id="alert-dialog-slide-description">
          <div className='InvoiceTitle'>
            <h3>LBMS</h3>
            <h3>{moment(created_date).format('MMMM D, YYYY')}</h3>
          </div>
          <div className='InvoiceCode'>
            <h5> Invoice Code: {invoice_code}</h5>
          </div>

          <div className='InvoiceSubTitle'>
            <div className='subTitle'>
              <h4>Category</h4>
              <span>{expenseOptions}</span>
            </div>
          
          </div>

          <div className='NameSender'>
            <div className='SendReceive'>
              <h5>From:</h5>
              <span>{sender_name}</span>
            </div>
          
          </div>

          <div className='Purpose'>
            <div className='SenderDetails'>
              <h5>Payment details</h5>
              <span>Mobile</span>
            </div>
            <div className='SenderDetails'>
            <h5>Intention date</h5>
            <span>
              {expenseOptions === 'Client transport & medical allowance' && moment(case_date).format('MMMM D, YYYY')}
              {expenseOptions === 'Travel & Filing allowance' && moment(travel_date).format('MMMM D, YYYY')}
              {expenseOptions === 'Office utility facilitation' && moment(purchase_date).format('MMMM D, YYYY')}
            </span>
          </div>
          
          
          </div>

          
          <div className='Summary'>
            <div className='SummaryHead'>
              <h4>Invoice Summary</h4>
            </div> 
            <div className='SummaryBody'>

                  <div className='summaryview'>
                  <div className='amount'> 
                    <span>Amount</span>
                    <small>{amount}</small>
                  </div>
                </div>

                <div className='summaryview'>
                 <div className='description'>
                  <span>Description</span>
                  <small>{description}</small>
                 </div>
                </div>
             
            </div>
          </div>


          <div className='InvoiceFooter'>
            <span>Generated by <small>LBMS</small></span>
            <span>Powered by <small>Iconic Africa</small></span>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}>
          <button
            className='bg-green-500 text-white font-[Poppins] py-2 px-6 rounded md:ml-8 hover:bg-indigo-400 duration-500'
            onClick={downloadInvoice}
          >
            Download
          </button>

        </DialogActions>
      </div>
  );
};


const ExpenseForm = ({ handleClose }) => {
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expenseOptions, setExpenseOptions] = useState('')
    const [destination,setDestination] = useState('');
    const [purpose,setPurpose] = useState('');
    const [travel_date,setTravelDate] = useState('');
    const [clients_name,setClientsName] = useState('');
    const [file_reference,setFileReference] = useState('');
    const [case_date,setCaseDate] = useState('');
    const [utility_type,setUtilityType] = useState('');
    const [purchase_date,setPurchaseDate] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState(0);
    const { currentUser } = useContext(AuthContext);
    const sender_name = currentUser?.fullname;
    const sender_id = currentUser?.id;
 

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
  
      // Check if either expenseAmount or stockAmount is provided
      if (
        !(amount) || // Check if amount is provided
        (amount && isNaN(amount)) || // Check if amount is provided and a number
        !description.trim()
      ) {
        setError('Please enter valid amount and description.');
        return;
      }
  
      const response = await axios.post(
        process.env.REACT_APP_API_ADDRESS + 'api/userpage/expenses',
        {
          expenseOptions,
          destination,
          purpose,
          travel_date: travel_date || null,
          clients_name,
          file_reference,
          case_date: case_date || null,
          utility_type,
          purchase_date: purchase_date || null,
          description,
          amount,
          sender_name,
          sender_id,
        }
      );
  
      console.log('API Response:', response.data);
  
      if (response.data.success) {
        toast.success('Expense added successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          // Reload the page after the delay
          window.location.reload();
        }, 3000);
      } else {
        setError('An error occurred while submitting the expense.');
      }
    } catch (error) {
      setError('An error occurred while submitting the expense.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <DialogTitle>{"Use this form to record and send an invoice"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">

          <div style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor='expenseOptions' className="block text-sm font-medium text-gray-700">Select invoice type</label>
            <select 
              name='expenseOptions'
              id='expenseOptions'
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              value={expenseOptions}
              onChange={(e) => setExpenseOptions(e.target.value)}
            >
              <option value=''>Select..</option>
              <option>Travel & Filing allowance</option>
              <option>Client transport & medical allowance</option>
              <option>Office utility facilitation</option>
            </select>
          </div>

          {/** Travel and filing form */}

          
            {expenseOptions === 'Travel & Filing allowance' &&(
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor='destination' className="block text-sm font-medium text-gray-700">Travel destination</label>
              <input
                className="mt-1 block w-full p-2 border border-gray-800 rounded-md focus:outline-none focus:border-blue-500"
                style={{border:'1px solid grey'}}
                type='text'
                name='destination'
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              />
            </div>
            )}

                      
            {expenseOptions === 'Travel & Filing allowance' &&(
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor='purpose' className="block text-sm font-medium text-gray-700">Purpose</label>
              <input
                className="mt-1 block w-full p-2 border border-gray-800 rounded-md focus:outline-none focus:border-blue-500"
                style={{border:'1px solid grey'}}
                type='text'
                name='purpose'
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              />
            </div>
            )}

                      
            {expenseOptions === 'Travel & Filing allowance' &&(
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor='travel_date' className="block text-sm font-medium text-gray-700">Travel date</label>
              <input
                className="mt-1 block w-full p-2 border border-gray-800 rounded-md focus:outline-none focus:border-blue-500"
                style={{border:'1px solid grey'}}
                type='date'
                name='travel_date'
                value={travel_date}
                onChange={(e) => setTravelDate(e.target.value)}
              />
            </div>
            )}


            {/** Client travel allowance form */}
           
            {expenseOptions === 'Client transport & medical allowance' &&(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor='clients_name' className="block text-sm font-medium text-gray-700">Client's name</label>
              <input
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                style={{border:'1px solid grey'}}
                type='text'
                name='clients_name'
                value={clients_name}
                onChange={(e) => setClientsName(e.target.value)}
              />
            </div>
            )}

            {expenseOptions === 'Client transport & medical allowance' &&(
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor='file_refence' className="block text-sm font-medium text-gray-700">File Reference</label>
                <input
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  style={{border:'1px solid grey'}}
                  type='text'
                  name='file_refence'
                  value={file_reference}
                  onChange={(e) => setFileReference(e.target.value)}
                />
              </div>
              )}

              {expenseOptions === 'Client transport & medical allowance' &&(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor='case_date' className="block text-sm font-medium text-gray-700">Case date</label>
                <input
                  className="mt-1 block w-full p-2 border border-gray-800 rounded-md focus:outline-none focus:border-blue-500"
                  style={{border:'1px solid grey'}}
                  type='date'
                  name='case_date'
                  value={case_date}
                  onChange={(e) => setCaseDate(e.target.value)}
                />
              </div>
              )}
              

              {/** Utility facilitation */}

              {expenseOptions === 'Office utility facilitation' &&(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor='utility_type' className="block text-sm font-medium text-gray-700">Utility type</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    style={{border:'1px solid grey'}}
                    type='text'
                    name='utility_type'
                    value={utility_type}
                    onChange={(e) => setUtilityType(e.target.value)}
                  />
                </div>
                )}

                {expenseOptions === 'Office utility facilitation' &&(
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor='purchase_date' className="block text-sm font-medium text-gray-700">Case date</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-800 rounded-md focus:outline-none focus:border-blue-500"
                    style={{border:'1px solid grey'}}
                    type='date'
                    name='purchase_date'
                    value={purchase_date}
                    onChange={(e) => setPurchaseDate(e.target.value)}
                  />
                </div>
                )}

                {expenseOptions !== '' &&(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor='expense_amount' className="block text-sm font-medium text-gray-700">Amount</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    style={{border:'1px solid grey'}}
                    type='number'
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                )}

                {expenseOptions !== '' &&(
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor='description' className="block text-sm font-medium text-gray-700">Description</label>
                  <textarea
                    
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                )}

          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleCancel}
          className='bg-red-500 text-white font-[Poppins] py-2 px-6 rounded md:ml-8 hover:bg-pink-500 duration-500'
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className='bg-green-500 text-white font-[Poppins] py-2 px-6 rounded md:ml-8 hover:bg-indigo-400 duration-500'
        >
        {loading ? 'Submitting...' : 'Submit'}
        </button>
      </DialogActions>
    </div>
  );
};

const Expenses = () => {
  
  const [open, setOpen] = React.useState(false);
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const { currentUser } = useContext(AuthContext);
  const sender_name = currentUser?.fullname;
  const sender_id = currentUser?.id;

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [fetchedInvoice, setFetchedInvoice] = useState(null);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenInvoice = (id) => {
    setSelectedInvoiceId(id);
    setOpenInvoice(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleCloseInvoice = (id) => {
    setSelectedInvoiceId(id);
    setOpenInvoice(false);
  };


     /* Get the ivoice using useEffect */
     useEffect(() => {
      if (selectedInvoiceId) {
        // Fetch the invoice data based on the selectedInvoiceId
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/invoice/${selectedInvoiceId}`)
          .then((response) => {
            setFetchedInvoice(response.data);
          })
          .catch((error) => {
            console.error('Error fetching invoice data:', error);
          });
      }
    }, [selectedInvoiceId]);

  const [expenses, setExpenses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
    /* Get the data using useEffect */
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/expenses/${sender_id}?q=${searchQuery}`)
          .then((response) => {
            setExpenses(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, [searchQuery, sender_id]);
      

  return (
    <div>
      <UserNavigation />
      <div className='Expenses'>
        <div className='topExpense'>
          <button
            className='bg-green-500 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-400 duration-500 outline:none'
            onClick={handleClickOpen}
          >
            Record invoice
          </button>
        </div>
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <ExpenseForm handleClose={handleClose} />
          </Dialog>
        </div>

        <div>
          <Dialog
          open={openInvoice} 
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseInvoice}
          aria-describedby="alert-dialog-slide-description"
          >
          <InvoiceShow invoice={fetchedInvoice}/>
          </Dialog>
        </div>
        <div>
        <div style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>
        <input 
        placeholder='search ...' 
        style={{borderBottom:'1px solid grey',fontWeight:'bold',outline:'none',borderRadius:'5px',padding:'5px'}}
        onChange={handleInputChange}
        value={searchQuery}
        />
        </div>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" id="table-to-export-mpesa">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>date_added</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((expense,index) =>{
                    return(
                      
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell style={{textTransform:'uppercase'}}>{expense.invoice_code}</TableCell>
                        <TableCell>
                            {expense.amount} 
                        </TableCell>
                        <TableCell>{moment(expense.created_date).format('MMMM D, YYYY')}</TableCell>
                        <TableCell style={{
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          gap:'5px'
                        }}>
                          <button style={{
                            color:'white',
                            backgroundColor:"#1F9D55",
                            border:'none',
                            outline:'none',
                            borderRadius:'5px'
                          }}><EditIcon /></button>
                          <button 
                          onClick={() => handleClickOpenInvoice(expense.id)}
                          style={{
                            color:'white',
                            backgroundColor:"orange",
                            border:'none',
                            outline:'none',
                            borderRadius:'5px'
                          }}><VisibilityIcon /></button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={expenses.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
