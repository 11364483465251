import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views';
import Slide from '@mui/material/Slide';
import './Styles/MainAccounts.scss'
import Navigation from '../../Components/NavOfficial/Navigation';
import Navbar from '../../Navigation/Navbar';
import ExpenseAccounts from './ExpenseAccounts';
import AccountsPayable from './AccountsPayable';
import IncomeAccounts from './IncomeAccounts';
import OperatingAccounts from './OperatingAccounts';
import ReserveAccounts from './ReserveAccounts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainAccounts = () => {

  const [activeTab, setActiveTab] = useState('Add Stock');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  return (
    <div className="home">
    <Navigation />
     
    <div className="homeContainer">
      <Navbar />
    <div className="TabBar"> 
      <div className="TabButtons">
        <button
          className={activeTabIndex === 0 ? 'ActiveTab' : ''}
          onClick={() => handleTabChange(0)}
        >
          Expense Accounts
        </button>
        <button
          className={activeTabIndex === 1 ? 'ActiveTab' : ''}
          onClick={() => handleTabChange(1)}
        > 
          Payable Accounts
        </button>
        <button
          className={activeTabIndex === 2 ? 'ActiveTab' : ''}
          onClick={() => handleTabChange(2)}
        >
          Income Accounts
        </button>
        <button
        className={activeTabIndex === 3 ? 'ActiveTab' : ''}
        onClick={() => handleTabChange(3)}
        >
        Operating Accounts
        </button>
        <button
        className={activeTabIndex === 4 ? 'ActiveTab' : ''}
        onClick={() => handleTabChange(4)}
        >
        Reserve Accounts
        </button>
      </div>
      <SwipeableViews
      index={activeTabIndex}
      onChangeIndex={handleTabChange}
      enableMouseEvents
      >
      <div className="TabContent"> <ExpenseAccounts /> </div>
      <div className="TabContent"> <AccountsPayable /> </div>
      <div className="TabContent"> <IncomeAccounts /> </div>
      <div className="TabContent"> <OperatingAccounts /> </div>
      <div className="TabContent"> <ReserveAccounts /> </div>
      </SwipeableViews>
    </div>
    </div>
    </div>
  )
}

export default MainAccounts