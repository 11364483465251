import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend,Tooltip, ResponsiveContainer,LineChart, Line,LabelList  } from 'recharts';
import UserNavigation from '../UserNavigation/UserNavigation';
import './TripSummary.scss'



const TripBarchart = ({ TripPerfomance }) => {
    return ( 
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={TripPerfomance} margin={{ top: 40, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="months" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="monthly_expense" fill="#4f46e5">
             <LabelList dataKey="monthly_expense" position="top" offset={30} angle={90} style={{ fontWeight: 'bold',color:'black' }}/>
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const TripLinechart = ({ TripPerfomance }) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={TripPerfomance} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="months" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="trips" stroke="blue">
            <LabelList dataKey="trips" position="top" style={{ fontWeight: 'bold' }}/>
          </Line>
        </LineChart>
      </ResponsiveContainer>
    );
  };
  


const TripsSummary = () => {
    const { currentUser } = useContext(AuthContext);

    const [TripPerfomance, setTripPerformance] = useState([]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/api/userpage/trips/${currentUser.fullname}`)
          .then(response => {
            setTripPerformance(response.data); 
          })
          .catch(error => {
            console.error('Error fetching sales data:', error);
          });
      }, [currentUser]); 
  return (
    <div>
    <UserNavigation />

    

    <div className='TripSummary'>

        <div className = 'ReportGeneration'>
            <div className='grid-item'> 
                <button 
                    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
                >
                    Insurance Report
                </button>
            </div>
            <div className='grid-item'>
                <button 
                    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
                >
                    Financial Report
                </button>
            </div>
            <div className='grid-item'>
                <button 
                    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
                >
                    Leave Report
                </button>
            </div>
            <div className='grid-item'>
                <button 
                    className='bg-indigo-600 text-white font-[Poppins] py-2 px-6 rounded md:ml-4 hover:bg-indigo-500 duration-500'
                >
                    Case Report
                </button>
            </div>
        </div>

        <div className='flex flex-col md:flex-row'>
            <TripBarchart TripPerfomance={TripPerfomance}/>
            <TripLinechart TripPerfomance={TripPerfomance}/>
        </div>

    </div>
    </div>
  )
}

export default TripsSummary;