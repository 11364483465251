import React, { useState } from 'react';
import './CounselGenie.scss';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const CounselGenie = () => {
  const [showInputs, setShowInputs] = useState(false);

  const handleStart = () => {
    setShowInputs(true); 
  };

  return (
    <div className="legal-bot-interface">
      
      <SupportAgentIcon className="waving-icon" />
      <h1>Counsel Genie</h1>
      <p>Ask me any legal question and I'll provide you with an answer.</p>
      {!showInputs && <button onClick={handleStart} style={{
        backgroundColor:'red',
        padding:'5px 10px',
        marginTop:'10px',
        color:'white',
        borderRadius:'10px'
      }}>Start</button>}
      {showInputs && (
        <>
          <input type="text" placeholder="Enter your question" style={{
            padding:'15px 25px',
            outlineColor:'green',
            borderColor:'green',
            borderRadius:'10px'
          }}/>
          <button style={{
            backgroundColor:'red',
            padding:'5px 10px',
            marginTop:'10px',
            color:'white',
            borderRadius:'10px'
          }}>Submit</button>
        </>
      )}
    </div>
  );
};

export default CounselGenie;
