import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios'
import '../Task Pane/Task.css'
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';


export const LeaveForm = () => {



    const {id} = useParams();
    const history = useNavigate(); 
    const { currentUser } = useContext(AuthContext);

    const [fullname,setFullName] =  [currentUser.fullname]
    const [firmName,setFirmName] = [currentUser.firmname || currentUser.firmname]
    const [userId, setUserId] = [currentUser.id]
    const [value, setValue] = useState("");
    const [reqdate,setReqDate] = useState("");
    const [returndate,setReturnDate] = useState("");
    const [Status,setStatus] = useState("");
    const [ReasonsDenied,setReasonsDenied] = useState('')

    const SubmitEvent = () =>{
      Axios.post(process.env.REACT_APP_API_ADDRESS + 'api/leave/insertleave', 
      {fullname:fullname,userId:userId,reqdate:reqdate,returndate:returndate,
        reasons:value,Status:Status,firmName:firmName,ReasonsDenied:ReasonsDenied}).then(() => {
  });
  toast.success("Status response sent.")
setTimeout(() => history('/Leave'),700); 
  }

  const UpdateEvent = () =>{
    Axios.put(process.env.REACT_APP_API_ADDRESS + `api/leave/insertleave/${id}`, 
    {Status:Status,ReasonsDenied:ReasonsDenied}).then(() => {
   
});
toast.success("Status response sent.")
setTimeout(() => history('/LeaveApproval'),700);
}



  return (
    <>

    <div className="home">
    <Navigation />

    <div className="homeContainer">
    <Navbar />
    <div className='main'>
    <div className='file-card'>
      <form style={{marginTop:'10px'}}>

      {currentUser.role === 'Normal User' && 
        <div className='mb-3' style={{display:'none'}}>
        <label htmlFor='fullname'>Full Name</label>

        <input 
          className='form-control'
          type='text'
          placeholder='John Doe'
          id='fullname'
          name='fullname'
          value={fullname || ""}
          onChange={(e) =>{
            setFullName(e.target.value);
        }}
          
        />
        </div>
      }

      {currentUser.role === 'Normal User' && 
      <div className='mb-3' style={{display:'none'}}>
      <label htmlFor='firmName'>Firm Name</label>

      <input 
        className='form-control'
        type='text'
        placeholder='John Doe'
        id='firmName'
        name='firmName'
        value={firmName || ""}
        onChange={(e) =>{
          setFirmName(e.target.value);
      }}
        
      />
      </div>
    }

      
      {currentUser.role === 'Normal User' && 
        <div className='mb-3'  style={{display:'none'}}>
        <label htmlFor='userId'>User Id</label>

        <input 
          className='form-control'
          type='text'
          placeholder='userId'
          id='userId'
          name='userId'
          value={userId || ""}
          onChange={(e) =>{
            setUserId(e.target.value);
        }}
          
        />
        </div>
      }


        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='reqdate'>Request Date</label>
        <input 
          className='form-control'
          type='date'
          id='reqdate'
          name='reqdate'
          value={reqdate || ""}
          onChange={(e) =>{
            setReqDate(e.target.value);
        }}
        />
        </div>
      }

        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='returndate'>Expected Return Date</label>
        <input 
          className='form-control'
          type='date'
          id='returndate' 
          name='returndate'
          value={returndate || ""}
          onChange={(e) =>{
            setReturnDate(e.target.value);
        }}
        />
        </div>
      }
        {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
        <label htmlFor='reasons'>Reasons for leave</label>
        <div className='editorContainer2'>

        <ReactQuill 
        className='editor2'
        placeholder='Provide Reasons'
        id='reasons'
        name='reasons'
        theme="snow" 
        value={value || ""} 
        onChange={setValue}
        />
        </div>
        </div>
      }

        {currentUser.role === 'Admin' && 
        <div className='mb-3'>
          <label htmlFor='Status'> Status </label>
          <select
          className='form-control'
          name='Status'
          id='Status'
          value={Status || " "}
          onChange={(e) =>{
            setStatus(e.target.value);
        }}
          >
          <option>Select..</option>
          <option>Accepted</option>
          <option>Denied</option>
          </select> 
        </div> 
      }

      {currentUser.role === 'Admin' && 
        <div className='mb-3'>
        {Status === "Denied" &&
          <div>
          <label htmlFor='ReasonsDenied'> State Reason for Denial </label>
          <textarea
          className='form-control'
          name='ReasonsDenied'
          id='ReasonsDenied'
          value={ReasonsDenied || " "}
          onChange={(e) =>{
            setReasonsDenied(e.target.value);
        }}
          />
          </div>
      }
        </div> 
      }

      {currentUser.role === 'Normal User' && 
        <div className='mb-3'>
            <input type='button' onClick={SubmitEvent} className='btn btn-primary btn-block' value={'Submit Request'}/>
        </div>
    }
        {currentUser.role === 'Admin' && 
        <div className='mb-3'>
            <input type='button' onClick={UpdateEvent} className='btn btn-primary btn-block' value={'Submit Response'}/>
        </div>
    }
      </form>
    </div>
    </div>
    </div>
    </div>
    </>
  )
}
