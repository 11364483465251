import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import './SeeTask.scss'
import '../ExistingCaseTables/table.scss'


const SeeTask = () => {

    
    const [view, setView] = useState({});
    const {id} = useParams();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/tasks/${id}`);
        setView(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);


  return (
    <div className='seetask'> 

        <div>  <h3 style={{textAlign:'center'}}> {view.name}</h3>  </div> 

        <hr />

        <div> <h4> Task Name: <span>{view.taskname}</span></h4> </div>

        <div><h4>Date issued: <span>{moment(view.dateissued).format('MMMM Do YYYY')}</span></h4></div>

        <div><h4>Task description: <span>{view.description}</span></h4></div>

        <div><h4>Task Sender: <span>{view.sender}</span></h4></div>

        <div><h4>Task status: <span style={{
            fontWeight:'bold',color: 
            ((view.status === 'Recalled' && 'red') || 
            (view.status === 'Completed' && 'green') || 
            (view.status === 'Pending' && 'rgb(219, 25, 18)'))}} >{view.status}</span></h4></div>

        <div><h4>Time lapsed: <span>{moment(view.dateissued).fromNow()}</span></h4></div>
        

    </div>
  )
}

export default SeeTask