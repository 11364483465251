import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
   
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || "user"
  );

  const [IdNumber, setIdNumber] = useState(''); 

  const login = async (staff) => {
    const res = await axios.post(process.env.REACT_APP_API_ADDRESS + "api/Auth/login",staff);
    setCurrentUser(res.data);
  };

  const checkuser = async (clientId) => {
    const res = await axios.post(process.env.REACT_APP_API_ADDRESS + "api/newcases/checkuser",clientId);
    setIdNumber(res.data);
  }; 

  const logout = async (staff) => {
    await axios.post(process.env.REACT_APP_API_ADDRESS + "api/Auth/logout");
    setCurrentUser('user');
  };



  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);





  return (
    <AuthContext.Provider value={{ currentUser, login, logout,checkuser,IdNumber  }}>
      {children}
    </AuthContext.Provider>
  ); 
};