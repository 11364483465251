import React, { useEffect, useState } from 'react'
import './LandingApp.scss'
import Header from './NavigationOfficial/Header';
import WordAnimation from './WordsAnimations';
import Legal from './Images/Legal.jpg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const words = ['Mathematics', 'English', 'Kiswahili'];


const LandingApp = () => {
 
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentIndex < words.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }, 1000); 

    return () => clearTimeout(timeout);
  }, [currentIndex]);


    return (
      <div className='HomeView'>
            <Header />
        <div className='HomePage'>

        <div className="background-overlay">
          <div className='HomePartA'>
          <h1>LawFirms<strong>Hub</strong></h1>
          <h2>Infinite Possibilities for</h2>
          <WordAnimation />
          <h3>A management system for the most progressive law firms - like yours</h3>

          <h5>Simple. Compliant. Affordable</h5>


          <div className='Buttons'>
            <a href='https://calendly.com/samuelwanjiru69/30min' target="_blank" rel="noreferrer"><button>Get Started</button></a>
            <div className='BtVideo'><button> <PlayArrowIcon className='iconplay'/> Experience Law Firms Hub</button></div>
          </div>
          </div>

          <div className='HomePartB'>
            <div className='image-container'>

              <img src={Legal} alt='' />

        {/** 
              <div className='overlay-text'>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Register & follow up cases remotely</span></div>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Track & approve invoices,bank transfers and statement</span></div>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Track your finances and generate general management reports</span></div>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Onboard employees & track them seamlessly</span></div>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Promptly notify clients of their case progress</span></div>
                <div className='textIndividual'><span><CheckCircleIcon className='icon'/></span><span className='textSpan'>Assing and manage tasks and case flows</span></div>
              </div>
        */}

            </div>
          </div>
        
        </div>

      </div>
      </div>
    );
  };

export default LandingApp