import React, { useRef, useState } from 'react'
import './MainLnadingPage.scss'
import billing from './Images/billing.jpg'
import calender from './Images/calender.jpg'
import HR from './Images/HR.png'
import analytics from './Images/analytics.png'
import registry from './Images/registry.png'
import WorkIcon from '@mui/icons-material/Work';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import SyncIcon from '@mui/icons-material/Sync';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TuneIcon from '@mui/icons-material/Tune';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';



export const LandingHome = () => {


  return (
    <div className='LandingHome'>

      <h2>LawFirmsHub <strong>Features</strong></h2>
    <div className='imageScroll'>

      <div className='ImageDesc'>
      <img src={billing} alt='billing'/>
        <p>Invoicing: Expense Tracking</p>
      </div>

      <div className='ImageDesc'>
      <img src={calender} alt='calender'/>
      <p>Legal calender</p>
      </div>
      
      <div className='ImageDesc'>
      <img src={HR} alt='HR'/>
      <p>Human Resource</p>
      </div>

      <div className='ImageDesc'>
      <img src={analytics} alt='analytics'/>
      <p>Reports and Analytics</p>
      </div>

      <div className='ImageDesc'>
      <img src={registry} alt='registry'/>
      <p>Registry Automation</p>
      </div>

    </div>

    <h2>Why <strong>LawFirmsHub</strong></h2>

    <div className='WhyUs'>
      <div className='PartA'>

          <div className='features'>
            <WorkIcon className='Icon'/>
            <div className='featuresA'><h6>Remote File Access</h6>
            <span>LawFirmsHub allows users to access case files and documents from anywhere, 
            at any time.</span>
            </div>
          </div>

          <div className='features'>
            <SettingsSuggestIcon className='Icon'/>
            <div className='featuresA'><h6>Time and Cost Efficiency</h6>
            <span>By automating various tasks and providing efficient workflows, 
            LawFirmsHub helps law firms save time and reduce costs.</span>
            </div>
        </div>

        <div className='features'>
          <Diversity3Icon className='Icon'/>
          <div className='featuresA'><h6>Enhanced Collaboration</h6>
          <span>The software facilitates seamless collaboration within the law firm, enabling team members to work together efficiently.</span>
          </div>
      </div>

      <div className='features'>
        <GppMaybeIcon className='Icon'/>
        <div className='featuresA'><h6>Secure Data Management</h6>
        <span>LawFirmsHub prioritizes the security of sensitive client and case data.</span>
        </div>
      </div>

      <div className='features'>
        <SyncIcon className='Icon'/>
        <div className='featuresA'><h6>Streamlined Communication</h6>
        <span>LawFirmsHub offers built-in communication tools, such as secure messaging and document sharing.</span>
        </div>
      </div>

      </div>

      <div className='PartB'>
        <div className='features'>
          <LeaderboardIcon className='Icon'/>
          <div className='featuresA'><h6>Advanced Reporting and Analytics</h6>
          <span>With LawFirmsHub, clients gain access to comprehensive reporting and analytics features. </span>
          </div>
        </div>

        <div className='features'>
          <TuneIcon className='Icon'/>
          <div className='featuresA'><h6>Customization and Flexibility</h6>
          <span>LawFirmsHub understands that each law firm has unique requirements</span>
          </div>
        </div>

        <div className='features'>
          <WebAssetIcon className='Icon'/>
          <div className='featuresA'><h6>User-Friendly Interface</h6>
          <span>LawFirmsHub prioritizes user experience, providing an intuitive and user-friendly interface.</span>
          </div>
        </div>

        <div className='features'>
          <SupportAgentIcon className='Icon'/>
          <div className='featuresA'><h6>Dedicated Customer Support</h6>
          <span>LawFirmsHub is committed to client success. Clients can rely on a 
          responsive support team to address their questions, concerns, and technical issues promptly.</span>
          </div>
        </div>

     


      </div>
    </div>
   
    <div className='footerMain'>
        <div className='FooterA'>
          <h3>The Company</h3>
          <span><a href='/'>Home</a></span>
          <span><a href='/'>About Us</a></span>
          <span><a href='/'>Our Services</a></span>
          <span><a href='/'>Contact Us</a></span>
        </div>
        <div className='FooterA'><h3>Our Products</h3>
          <span><a href='/'>CounselGenie</a></span>
          <span><a href='/'>LFHCare</a></span>
        </div>
        <div className='FooterA'><h3>OUR address</h3>
          <span><LocationOnIcon /> Nairobi, Kenya</span>
          <span><CallIcon /> +254 742548359 | +254 111356555</span>
          <span><EmailIcon /> info@lawfirmshub.com</span>
         
        </div>
    </div>
    
    </div>
  )
}
