import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Axios from 'axios'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment'
import '../Styles/Search.css'
import '../PaymentSchedule/Requisition.css'
import Paper from '@mui/material/Paper';
import '../ExistingCaseTables/table.scss'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Sidebar from '../Navigation/Sidebar';
import Navbar from '../Navigation/Navbar';
import { AuthContext } from '../context/AuthContext';
import Navigation from '../Components/NavOfficial/Navigation';




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




export const RequisitionsAdmin = () => {


  const [requisition, setRequisition] = useState([]);
  const [query, setQuery] = useState(""); 
  const { currentUser,currentFirm } = useContext(AuthContext);


  useEffect(() => {
    const fetchData = async () => {
      const res = await Axios.get(process.env.REACT_APP_API_ADDRESS + `api/requisition?q=${query}`);
      setRequisition(res.data);
    };
    if (query.length === 0 || query.length > 2) fetchData();
  }, [query]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requisition.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };


  return (
    <>

    <div className='staff'>

   <div style={{display:'flex',gap:'10rem'}}>
 
    <div >
      <input  
      className='serachInput'
      type='search'
      placeholder='Search...'
      onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />
    </div>
    </div>

    <TableContainer component={Paper} >
    <Table stickyHeader aria-label="sticky table" className='table'>
      <TableHead>
        <TableRow>
          <TableCell>Citation</TableCell>
          <TableCell>Travel Date</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {(rowsPerPage > 0
        ? requisition.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : requisition
      ).map((val,index) =>{
        return(
            <TableRow key={val.id} className='rows'> 

        
           
            {currentFirm?.firmname === val.lawfirmaccount && 
              <TableCell>{val.name}</TableCell>  
            }
            {currentUser?.firmname === val.lawfirmaccount && 
              <TableCell>{val.name}</TableCell>  
            }
        
            {currentFirm?.firmname === val.lawfirmaccount &&
              <TableCell>{moment(val.traveldate).fromNow()}</TableCell>  
            }
            {currentUser?.firmname === val.lawfirmaccount &&
              <TableCell>{moment(val.traveldate).fromNow()}</TableCell>  
            }
         
            {currentFirm?.firmname === val.lawfirmaccount &&
              <TableCell>Kshs.{val.Totalvalue}/=</TableCell>  
            }
            {currentUser?.firmname === val.lawfirmaccount &&
              <TableCell>Kshs.{val.Totalvalue}/=</TableCell>  
            }
            {currentFirm?.firmname === val.lawfirmaccount &&
              <TableCell>
              <span className={`status2 ${val.paymentstatus}`}>{val.paymentstatus}</span>
              </TableCell>
            }
            {currentUser?.firmname === val.lawfirmaccount &&
              <TableCell className="tableCell rows">
              <span className={`status2 ${val.paymentstatus}`}>{val.paymentstatus}</span>
              </TableCell>
            }
            {currentFirm?.firmname === val.lawfirmaccount &&
              <TableCell className="tableCell rows">
                <Link to={`/Updaterequisition/${val.id}`}>
                    <button className='btnone btn-edit'><EditIcon style={{fontSize:'16px'}}/></button> 
                </Link>
                <Link to={`/requisition/${val.id}_${val.name}`}>
                    <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                </Link>
              </TableCell>
            }
            {currentUser?.firmname === val.lawfirmaccount &&
              <TableCell className="tableCell rows">
                <Link to={`/Updaterequisition/${val.id}`}>
                    <button className='btnone btn-edit'><EditIcon style={{fontSize:'16px'}}/></button> 
                </Link>
                <Link to={`/requisition/${val.id}_${val.name}`}>
                    <button className='btnone btn-view'><VisibilityIcon style={{fontSize:'16px'}}/></button> 
                </Link>
              </TableCell>
            }
            </TableRow> 
        )
      })}

      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}


      </TableBody>

      <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={4}
          count={requisition.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>


    </Table>
    </TableContainer>
    </div>

    </>
  )
}
