import React, { useRef, useState } from 'react'
import NavigationOne from '../NavigationOne'
import './BotPage.scss'
import './Modal.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './CounselGenie.scss';
import CounselGenie from './CounselGenie';
import html2pdf from "html2pdf.js";
import CouselGenie from './COUNSELGENIE.jpeg'
import Slide from '@material-ui/core/Slide';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid green',
    boxShadow: 24,
    height:'auto',
    p: 4,
  };

const BotPage = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const pdfRef = useRef();

    const handleDownloadPdf = () => {
      const element = pdfRef.current;
      const opt = {
        margin: 0.5,
        filename: "Bot.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
      };
  
      html2pdf().from(element).set(opt).save();
    };

  return (
    <div>
        <NavigationOne />
        <div className='Bot'>
            <div className='BotPage'>
                <div className='BotA'>
                    <div className='ABot'> 
                    <h2>CounselGenie</h2>
                    <h5>An AI providing legal counsel to you.</h5>

                    <div className='BotButton'>
                        <button onClick={handleOpen}>Talk To Genie Today</button>
                        <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" 
                        transition={Slide}
                        >
                        <Box sx={style} style={{borderRadius:'10px'}}>
                        
                            <CounselGenie />
                        </Box>
                        </Modal>
                    </div>
                    </div>
                    <div className='BBot'>
                        <img src={CouselGenie} alt=''/>
                    </div>
                </div>
                

             

            </div>
            
            <div className='Overview' ref={pdfRef}>
                <h3>Overview</h3>
                <div className='Details'>
                    <span>
                        CounselGenie is a legal bot that provides a 
                        range of functions to help individuals and businesses 
                        with their legal needs. At its core, CounselGenie utilizes
                        artificial intelligence and natural language processing to 
                        provide users with automated legal guidance.
                     </span>
                     <span>From a technical perspective, CounselGenie's functions 
                     can be broken down into several key areas:
                     </span>
                     <span>
                        <ol type="1">
                            <li>
                                Legal Document Generation: CounselGenie uses 
                                machine learning algorithms to generate legal documents 
                                such as contracts, agreements, and other legal forms. Users can 
                                input their specific requirements and the bot will generate a 
                                customized document based on their needs.
                            </li>
                            <li>
                                Legal Research: CounselGenie leverages natural language processing 
                                to conduct legal research and provide users with legal answers and guidance. 
                                Users can ask the bot questions and it will provide answers based on its vast 
                                database of legal information.
                            </li>
                            <li>
                                Contract Analysis: CounselGenie uses machine learning algorithms to analyze 
                                contracts and identify potential legal issues. This feature can help users 
                                identify potential problems with contracts before they sign them, helping to 
                                mitigate legal risk.
                            </li>
                            <li>
                                Compliance Monitoring: CounselGenie can monitor regulatory changes and legal 
                                developments in specific areas of law. Users can set up alerts to be notified 
                                when a change occurs, helping them stay on top of legal compliance requirements.
                            </li>
                        </ol>
                     </span>
                     <span>
                        From a more simple and non-technical perspective, CounselGenie provides users with 
                        a convenient and cost-effective way to access legal advice and services. Users can interact 
                        with the bot using natural language, making it easy to use even for those without legal training. 
                        The bot can generate legal documents quickly and accurately, and provide legal guidance on a wide 
                        range of topics. Overall, CounselGenie is a powerful tool that can help individuals and businesses 
                        navigate the complex world of legal regulations and requirements.
                     </span>
                </div>
            </div>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
            <button onClick={handleDownloadPdf} 
            style={{
                backgroundColor:'#02b159',
                borderRadius:'5PX',
                color:'white',
                padding:'10px 20px'
            }}>Download PDF</button>
            </div>
        </div>
    </div>
  )
}

export default BotPage