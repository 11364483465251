import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './Styles/Cards.css'
import { AuthContext } from '../context/AuthContext';
import './Styles/widgets.scss'

 
function Total() {

    
    const [TotalCase, setTotalCases] = useState([]);
    
    const {id} = useParams();
    const { currentUser } = useContext(AuthContext);

 
 
    useEffect (() =>{
        Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/statistics/Total').then((response) =>{
            setTotalCases(response.data)
        });
      },[id]);

  return (
    <>
    <div className='widget'>

    <div className='left'>

      {TotalCase.map((val) =>{
          return(  
          <div 
            className='bg-blue-600 p-2 text-white rounded-md hidden lg:block sm:block md:block' 
            style={{height:'100px'}}
          >
          <span className='title'>Total Cases</span>
          {currentUser?.firmname === val.lawfirmaccount && 
            <span className='counter'> {val.Total.toLocaleString()}</span>
          }
          </div>
          
          )
        })}

        </div>
 
    </div>

 
    </>
  )
}

export default Total;

