import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Tabs.scss'
import { Requisitions } from '../../pages/Requisitions';
import RequisitionTwo from './RequisitionTwo';
import Navigation from '../NavOfficial/Navigation';

export const RequisitionTabs = () => {

const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
   
    <div className='Tabs'>
    <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)} >
    <TabList style={{display:'flex',justifyContent:'space-evenly'}}>
      <Tab>System Invoice</Tab>
      <Tab>Manual Invoice</Tab>
    </TabList>
  
    <TabPanel>
      <Requisitions />
    </TabPanel>

    <TabPanel>
        <RequisitionTwo />
    </TabPanel>
 
  </Tabs>
    </div>
    </div>
 
  )
}
