import React, { useContext, useEffect, useState } from 'react'
import './Styles/Cards.css'
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { AuthContext } from '../context/AuthContext';

export const WorkInjury = () => {

    const {id} = useParams();
    const [total, setTotal] = useState([]);
    const [paid, setPaid] = useState([]);
    const [balance, setBalance] = useState([]);

    const { currentUser } = useContext(AuthContext);

    useEffect (() =>{
      Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Total').then((response) =>{
          setTotal(response.data)
      });
    },[id]);

    useEffect (() =>{
      Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Paid').then((response) =>{
          setPaid(response.data)
      });
    },[id]);

    useEffect (() =>{
      Axios.get(process.env.REACT_APP_API_ADDRESS + 'api/requisition/Balance').then((response) =>{
          setBalance(response.data)
      });
    },[id]);

  return ( 
    <>
    <div className='widget' style={{backgroundColor:'rgb(17, 43, 75)'}}>
    <div className='left'>
    <span className='title'>All payments</span>
    {total.map((val) =>{
        return(   
          <div>
          {currentUser?.firmname === val.lawfirmaccount && 
            <span className='counter'>
            {val.Totalamount.toLocaleString()}
            </span>
          }
          </div>
        )
      })} 

    </div>

  
    </div>
    </>
  )
}
