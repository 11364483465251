import { CssBaseline } from "@material-ui/core";
import Navbar from "../Navigation/Navbar";
import { PageMainUpdate } from "../CaseUpdate/CaseMain";
import Navigation from "../Components/NavOfficial/Navigation";
import { MainPage } from "../Components/CaseRegistrations/MainPage";



function NewCasesUpdate() {  
 
  return (
    <>
    <div className="home">
    <Navigation />
    <div className="homeContainer">
      <Navbar /> 
    <div>
    <MainPage />
    </div>
    <CssBaseline />
    </div>
    </div>
    </>
  );
}

export default NewCasesUpdate;