import React, { useContext, useState } from 'react'
import Navbar from '../Navigation/Navbar'
import Sidebar from '../Navigation/Sidebar'
import ReactQuill from 'react-quill';
import '../Styles/Suggestionbox.scss'
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import Axios from 'axios'
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useParams } from 'react-router-dom';

export const SuggestionBox = () => {

    const { currentUser } = useContext(AuthContext);
    const {id} = useParams();
    const history = useNavigate(); 

    const [fullname,setFullName] =  [currentUser.fullname]
    const [userId, setUserId] = [currentUser.id]
    const [value, setValue] = useState("");
    const [returndate,setReturnDate] = useState("");

    const SubmitEvent = () =>{
        Axios.post(process.env.REACT_APP_API_ADDRESS + 'api/suggestions/insertsuggestion', 
        {fullname:fullname,userId:userId,returndate:returndate,
          reasons:value}).then(() => {
    });
    
    toast.success("Your comments have been sent successfully.")
  setTimeout(() => history('/Index'),700); 
    }


  return (

    <div className="home">
    <Sidebar />

    <div className="homeContainer">
    <Navbar />


    <div className='suggestionbox'>
        <div className='suggestionboxHead'> 
            <h5>Do you have a suggestion or complaint that yud like to make to the boss? 
            Fear not. Fill the following form to make your recommendations or complaints.</h5>
            <p>NB: Your comments are well protected and your identity wont be 
            revealed to the bosses or your fellow colleagues</p>
        </div>

        <div className='suggestionForm'>
          <form>
          <div className='mb-3'>
          <label htmlFor='returndate'>Date</label>
          <input 
            className='form-control'
            type='date'
            id='returndate' 
            name='returndate'
            value={returndate || ""} 
            onChange={(e) =>{
              setReturnDate(e.target.value);
          }}
          />
          </div>
         
          <div className='mb-3'>
          <label htmlFor='reasons'>Suggestion, recommendation or complaint</label>
          <div className='editorContainer2'>
  
          <ReactQuill 
          className='editor2'
          placeholder='Provide Reasons'
          id='reasons'
          name='reasons'
          theme="snow" 
          value={value || ""} 
          onChange={setValue}
          />
          </div>
          </div>

            <div className='mb-3' style={{display:'none'}}>
            <label htmlFor='fullname'>Full Name</label>
    
            <input 
              className='form-control'
              type='text'
              placeholder='John Doe'
              id='fullname'
              name='fullname'
              value={fullname || ""}
              onChange={(e) =>{
                setFullName(e.target.value);
            }}
              
            />
            </div>
          
    
            <div className='mb-3'  style={{display:'none'}}>
            <label htmlFor='userId'>User Id</label>
    
            <input 
              className='form-control'
              type='text'
              placeholder='userId'
              id='userId'
              name='userId'
              value={userId || ""}
              onChange={(e) =>{
                setUserId(e.target.value);
            }}
              
            />
            </div>

            <div className='mb-3 buttonsuggestion'>
            
                <button onClick={SubmitEvent} className='suggestionButton'> <SendIcon />  </button>

            </div>
             
          </form>
        </div>
        </div>
    </div>
    </div>

  )
}
