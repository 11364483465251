import React, { useEffect, useState,useRef, useContext } from 'react'
import axios from 'axios'
import {useReactToPrint} from 'react-to-print'
import { useParams } from 'react-router-dom';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import moment from "moment";
import './Single.scss'
import { AuthContext } from '../context/AuthContext';
import Navbar from '../Navigation/Navbar';
import Navigation from '../Components/NavOfficial/Navigation';


const SingleRequisition = () => {

    const [view, setView] = useState([]);
    const {id} = useParams();
    const { currentUser,currentFirm } = useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [openDiarize, setOpenDiarize] = useState(false)
    const handleOpenDiarize = () => setOpenDiarize(true)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseDiarize = () => setOpenDiarize(false)



  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/requisition/OneRequisition/${id}`);
        setView(res.data);
      } catch (err) {
        console.log(err);
      }
    }; 
    fetchData();
  }, [id]);
  

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Requisition form'
  });



  return (
    <div>


    <div className="home">
    <Navigation />
    
    <div className="homeContainer">
      <Navbar />


    <div ref={componentRef}> 


    <div className='InvoiceMain'>
    
    <div className='invoice'>

      <div className='invoicehead'>
       
      </div>

      <div className='invoicebody1'>
      <h5 >Requisition by {view.name}</h5>
     <div>

          <div style={{display:'flex',justifyContent:'space-between'}}>
          <h4>Travel destination <br />
          <span>{view.destination}</span>
          </h4>

          <h4>Travel / Order date <br />
          <span> {moment(view.traveldate).format('Do MMMM YY')}</span>
          </h4>

          </div>
          <h4>Purpose of travel <br/>
          <span>{view.travelpurpose}</span>
          </h4>

        </div>
      </div>

      <div className='invoicebody2'>
        <h5 style={{fontWeight:'bold'}}>Requisition Summary</h5>

        <div className='perticulars'>
        <h4 style={{fontWeight:'bold'}}>Documents to file <br />
        <span style={{fontWeight:'bold'}}>{view.documents}</span>
        </h4>
        </div>

        <div className='perticulars'>
        <h4>File Reference (s) </h4>
          <p>{view.fileref}</p>
        </div>

        <div className='invoicebody2'>

          <div className='summarytotal'>
            <h5 style={{fontWeight:'bold'}}>Decsription</h5>
            <h5 style={{fontWeight:'bold'}}>Totals</h5>
          </div>

          <div>
            {view.PlaintA >= 1 && 
              <div className='summarytotal'>
              <h6>Plaint (0 - 203,550/= , <b>2000/= per copy</b>) </h6>
              <h6>{view.PlaintA}</h6>
              </div>
              }

              {view.PlaintB >= 1 && 
                <div className='summarytotal'>
                <h6>Plaint (204,000 - 500,000/=, <b>5000/= per copy</b>) </h6>
                <h6>{view.PlaintB}</h6>
                </div>
                }

                {view.PlaintC >= 1 && 
                  <div className='summarytotal'>
                  <h6>Plaint (501,000 - 1,050,000/=, <b>10,000/= per copy</b>)</h6>
                  <h6> {view.PlaintC}</h6>
                  </div>
                  }

                
                {view.PlaintD >= 1 && 
                  <div className='summarytotal'>
                  <h6>Plaint (1,060,000 - 1,100,000, <b>20,000/= per copy</b>)</h6>
                  <h6> {view.PlaintD}</h6>
                  </div>
                  }

                  {view.PlaintE >= 1 && 
                    <div className='summarytotal'>
                    <h6>Plaint (1,101,000 - 1,150,000, <b>30,000/= per copy</b>) </h6>
                    <h6>{view.PlaintE}</h6>
                    </div>
                    }

                  {view.PlaintF >= 1 && 
                    <div className='summarytotal'>
                    <h6>Plaint (1,160,000 - 1,200,000, <b>40,000/= per copy</b>) </h6>
                    <h6>{view.PlaintF}</h6>
                    </div>
                    }

                  {view.PlaintG >= 1 && 
                    <div className='summarytotal'>
                    <h6>Plaint (1,201,000 - 1,250,000, <b>50,000/= per copy</b>)</h6>
                    <h6>{view.PlaintG}</h6>
                    </div>
                    }

                    {view.PlaintH >= 1 && 
                      <div className='summarytotal'>
                      <h6>Plaint (1,260,000 - 1,300,000, <b>60,000/= per copy</b>)</h6> 
                      <h6>{view.PlaintH}</h6>
                      </div>
                      }

                    {view.PlaintI >= 1 && 
                      <div className='summarytotal'>
                      <h6>Plaint (1,301,000 - 1,400,000, <b>70,000/= per copy</b>)</h6>
                      <h6>{view.PlaintI}</h6>
                      </div>
                      }

                    {view.PlaintJ >= 1 && 
                      <div className='summarytotal'>
                      <h6>Plaint (1,401,000 & above, <b>71,000/= per copy</b>)</h6>
                      <h6>{view.PlaintJ}</h6>
                      </div>
                      }
                        
                           
                    {view.adlitem >= 1 && 
                    <div className='summarytotal'>
                    <h6>Adlitem (<b>1,000 /= subordinate court</b>)</h6>
                    <h6> {view.adlitem}</h6>
                    </div>
                    }

                    {view.adlitem2 >= 1 &&
                      <div className='summarytotal'>
                      <h6>Adlitem: High court (<b>2000/= High court</b>)</h6>
                      <h6> {view.adlitem2}</h6>
                      </div>
                      }

                    {view.application >= 1 && 
                      <div className='summarytotal'>
                      <h6>Applications: Surbordinate courts (<b>1000/= per application all stations</b>) :</h6>
                      <h6>{view.application}</h6>
                      </div>
                    }

                    {view.application2 >= 1 && 
                      <div className='summarytotal'>
                      <h6>Application: High court (<b>1500/= per application all stations</b>)</h6>
                      <h6> {view.application2}</h6>
                      </div>
                      }

                    {view.certificate1 >= 1 && 
                      <div className='summarytotal'>
                      <h6>Certificate of urgency (<b>1500/= per application subordinate courts</b>)</h6>
                      <h6>{view.certificate1}</h6>
                      </div>
                      }

                    {view.certificate2 >= 1 && 
                      <div className='summarytotal'>
                      <h6>Certificate of urgency: high court (<b>2250/= per application high courts</b>)</h6>
                      <h6> {view.certificate2} </h6>
                      </div>
                      }

                    {view.decree >= 1 &&
                      <div className='summarytotal'>
                      <h6>Decree,order,bill of costs, certificate of costs (<b>500/= per copy all stations</b>)</h6>
                      <h6>{view.decree}</h6>
                      </div>
                      }

                    {view.memorandum >= 1 && 
                      <div className='summarytotal'>
                      <h6>Memorandum of appeal (<b>1550/= per copy all high court stations</b>)</h6>
                      <h6> {view.memorandum} </h6>
                      </div>
                      }

                    {view.followUp >= 1 && 
                      <div className='summarytotal'>
                      <h6>Registry follow up fee </h6>
                      <h6>{view.followUp}</h6>
                      </div>
                      }

                    {view.transaction >= 1 && 
                      <div className='summarytotal'>
                      <h6>Filing transaction fee</h6>
                      <h6>{view.transaction}</h6>
                      </div>
                      }

                    {view.credit >= 1 && 
                      <div className='summarytotal'>
                      <h6>Office credit allowance</h6>
                      <h6> {view.credit} </h6>
                      </div>
                      }

                    {view.travel >= 1 &&
                      <div className='summarytotal'>
                      <h6>Travel allowance</h6>
                      <h6>{view.travel}</h6>
                      </div>
                      }

                    <hr style={{color:'white'}}/>

                    <div className='summarytotal'>
                      <h6 style={{fontWeight:'bold'}}>Total amount invoiced </h6>
                      <h4 style={{color:'black',fontWeight:'bold'}}>{view.Totalvalue}</h4>
                    </div>

                    <hr style={{color:'white'}}/>

                    <div className='summarytotal'>
                    <h6>Status </h6>
                      <h6 
                    style={{color: ((view.paymentstatus === 'Declined' && 'red') ||(view.paymentstatus === 'Paid' && '#02b159')) 
                                ,fontSize:'20px',fontWeight:'700'}}
                    >{view.paymentstatus} </h6>
                    
                    
             </div>
          </div>
        </div>
      </div>
    </div>
    </div>


    </div>
        <div style={{alignItems:'center',marginBottom:'70px',padding:'10px 45%'}}>
            <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
                Print Requisition <LocalPrintshopIcon style={{color:'red'}}
            />
            </button>
        </div>
    </div>
    </div>
    </div>
  )
}

export default SingleRequisition