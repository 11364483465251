import React, { useState } from 'react';
import { LandingHome } from '../LandingPage/LandingHome';
import LandingApp from '../LandingPage/LandingApp';
import './Styles/LandingPage.css'; 
import ForumIcon from '@mui/icons-material/Forum';

function LandingPage() {
  const [showMessage, setShowMessage] = useState(true);

  const handleCancel = () => {
    setShowMessage(false);
  };

  return (
    <>
      <LandingApp />
      <LandingHome />
      {showMessage && (
        <div className="suspended-message">
          <div className="message-box">
            <p>Hi there, how can we help?</p>
            <button onClick={handleCancel}>
              X
            </button>
          </div>
        </div>
      )}
      <div className="suspended-button">
        <button><ForumIcon className='iconSuspence'/></button>
      </div>
    </>
  );
}

export default LandingPage;
