import React from 'react'
import './MainNav.scss'
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom';

const MainNav = () => {
  return (
    <div className='MainNav'>
        <div className='Views'>  
            <div className='ViewsIcon'> <FolderIcon className='IconView'/> </div>
            <Link to={'/NewCases'} className='ViewsDetals' style={{textDecoration:'none'}}>
                <div className='MainDecs'>Case registration</div>
                <div className='PartDesc'>Add new cases, view case analytics,transfer to branch office and assess case value.</div>
            </Link>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <SettingsIcon className='IconView'/> </div>
            <Link to={'/ExistingCases'} className='ViewsDetals' style={{textDecoration:'none'}}>
                <div className='MainDecs'>Case manager</div>
                <div className='PartDesc'>Edit existing cases, delete cases, assign case tasks, submit file accounts and create case events.</div>
            </Link>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <CalculateIcon className='IconView'/> </div>
            <Link to={'/accounts'} className='ViewsDetals' style={{textDecoration:'none'}}>
                <div className='MainDecs'>Billing and Accounting</div>
                <div className='PartDesc'>Requisitions/invoices, client bank money transfers, Office paybill analysis (Mpesa) and cash spending analysis</div>
            </Link>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <BarChartIcon className='IconView'/> </div>
            <div className='ViewsDetals'>
                <div className='MainDecs'>Reports and Analytics</div>
                <div className='PartDesc'>Daily case registrations,monthly case completion report,monthly case dormancy report & case payments report</div>
            </div>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <BarChartIcon className='IconView'/> </div>
            <div className='ViewsDetals'>
                <div className='MainDecs'>Document Management & Generation</div>
                <div className='PartDesc'>Storing and organizing legal documents, contracts, and case-related files, document sharing, access control for collaboration</div>
            </div>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <BarChartIcon className='IconView'/> </div>
            <div className='ViewsDetals'>
                <div className='MainDecs'>Legal Research & Knowledge Management</div>
                <div className='PartDesc'>Centralizing legal research, templates, and best practices,Keyword searching, citation analysis, document retrieval and sharing internal knowledge resources</div>
            </div>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <CalendarMonthIcon className='IconView'/> </div>
            <Link to={'/Calender'} className='ViewsDetals' style={{textDecoration:'none'}}>
                <div className='MainDecs'>Scheduling and Calendaring</div>
                <div className='PartDesc'>Add new events, manage existing events, delete existsing events in office calender</div>
            </Link>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <CalendarMonthIcon className='IconView'/> </div>
            <Link to={'/messages'} className='ViewsDetals'  style={{textDecoration:'none'}}>
                <div className='MainDecs'>Task flow manager</div>
                <div className='PartDesc'>Assign new tasks, manage existsing tasks, delete existing tasks, in-office communications and task completion analysis</div>
            </Link>
        </div>

        <div className='Views'>  
            <div className='ViewsIcon'> <CalendarMonthIcon className='IconView'/> </div>
            <Link to={'/members'} className='ViewsDetals' style={{textDecoration:'none'}}>
                <div className='MainDecs'>Human Resources and Personnel Management:</div>
                <div className='PartDesc'>Add, manage and monitor your office staff logs,payroll, and benefits, track employee time off, performance evaluations, and training</div>
            </Link>
        </div>

    </div>
  )
}

export default MainNav