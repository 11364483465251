import React from 'react'
import { Link } from 'react-router-dom'
import './Help.css'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallIcon from '@mui/icons-material/Call';
import NavigationOne from '../LandingPage/NavigationOne';
import moment from 'moment';

export const Help = () => {
    const year = moment().format("YYYY")
  return (
    <>
   
        
        <div className='help'>

        <NavigationOne />


        <div className='partB'>
       
        <div className='column one'>
        <h1>Electronic Law Firm Management Hub</h1>
        <p>Automating routine tasks to increase the productivity and effectiveness of your Law Firm.</p>
        </div>

        <div className='column'>
        <h3>Contact Lines</h3>
        <h6><AlternateEmailIcon /><a href='mailto:info.lawfirmshubke@gmail.com'>info.lawfirmshubke@gmail.com</a></h6>
        <h6><CallIcon />+254742548359</h6>
        <h6><CallIcon />+254111356555</h6>
        </div>


        </div>

        <div className='services'>
            <h4>Services provided on the Law Firms Hub Portal</h4>
        </div>
        <div className='pop-out-card'>
            <h3 style={{background:'#2f6f4e',width:'100%',textAlign:'center',color:'white'}}>E-Case Registration</h3>

            <div style={{padding:'10px 10px',fontWeight:'bold'}}>
                <p>The law Firms Hub provides a platform for Lawyers and Law 
                staff members to initiate and complete the process of 
                registering a new suit as it arrives in the office.
                It facilitates the recording of files on a real time basis with the client in site 
                hence ensuring transparency.</p>
            </div>
        </div>
        <div className='pop-out-card'>
            <h3 style={{background:'#2f6f4e',width:'100%',textAlign:'center',color:'white'}}>Case activity tracking</h3>
            <div style={{padding:'10px 10px',fontWeight:'bold'}}>
            <p>Once the file is registered, the status of the file becomes active.
              The registration date is registered and the file is listed under existing files and here the administrator 
              has the authority to either edit or delete the record.
              The file details are also accessible by clicking the view icon.
              Inside the individual file, you can access the case update form, documents submitted and the client advocate agreement.
              The postal also provides a customised calender to help you plan and office cause list for the current date and next day.
            </p>
        </div>
        </div>

        <div className='pop-out-card'>
            <h3 style={{background:'#2f6f4e',width:'100%',textAlign:'center',color:'white'}}>Office Task Management</h3>

            <div style={{padding:'10px 10px',fontWeight:'bold'}}>
            <p>
            The portal has a customised personal task management and tracking system. Once the task is issued to an individual staff member,
            the task issuer can be in a position to track the amount of time taken on the task.
            He/she also has the authority to recall and re-issue the tasks as they so wish. The platform also provides a leave management avenue 
            where individual staff member can place a leave request in advance.
            </p>
        </div>
        </div>

        <div className='pop-out-card'>
        <h3 style={{background:'#2f6f4e',width:'100%',textAlign:'center',color:'white'}}>Case Statistics</h3>

        <div style={{padding:'10px 10px',fontWeight:'bold'}}>
        <p>
          The portal also provides a real time data analysis. It provides the total cases registered, the total cases per category 
          and activity summary.
          The system also calculates the percentage of office closed files versus the active files offering the file completion/closing rate.
          On monetary, the platform also provides the total sums invested in the completion of files on a day to day basis.
        </p>
    </div> 
    </div>
    <div className='partB'>
          <h3 style={{width:'100%',textAlign:'center'}}>How it works <h6 style={{color:'white'}}> <br />
          <h4>We have created several videos to guide you</h4>
          <Link style={{color:'white',textDecoration:'none',background:'#ff6f00',padding:'4px 30px',lineHeight:'40px',
        minWidth:'140px',display:'inline-block',fontSize:'18px',borderRadius:'5px'}}>
         Watch Videos Now
          </Link>
          <br />
          
        </h6> <Link to={'/Docs'}> <h5 style={{textDecorationLine:'underline',textDecorationThickness:'2px',color:'white'}}>or Read the Manual here</h5> </Link></h3>
        
    </div>
    <div>
          <h2 style={{width:'100%',textAlign:'center',marginTop:'20px'}}>Objectives of the E-Management Platform</h2>

          <div className='pop-out-card2' style={{display:'flex',gap:'2rem'}}>
              <h4>Operational Efficiency & Cost effectiveness <p style={{fontSize:'17px',fontWeight:'400'}}>Reduce time taken in task management and file follow up.
              The platform doesnt entertain file duplication hence minimising the risk of paying for a single file twice.
              
              </p></h4>
           
          </div>
          <div className='pop-out-card2' style={{display:'flex',gap:'2rem'}}>
         
          <h4>Convinience and flexibity <p style={{fontSize:'17px',fontWeight:'400'}}>Check the status or position of a 
          file from anywhere. Avoid date mistakes with our customised calender and office cause list.</p></h4>
         
      </div>
      <div className='pop-out-card2' style={{display:'flex',gap:'2rem'}}>
      <h4>Turn around time <p style={{fontSize:'17px',fontWeight:'400'}}>Resolve long turnaround time in file retrival and customer service.</p> </h4>
      
      </div>
      
      
</div>
<div className='footer'>
    <span>Get In Touch with us today</span>
    <button><a href='/#'>Lets Talk</a></button>
    <small> &copy; {year} <a href='/#'>S&M Software Inc.</a> All rights reserved.</small>
    </div>
</div>


       
    </>
  )
}
