import React from 'react'
import './RequisitionTwo.scss'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'fitContent',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius:'5px',
    boxShadow: 24,
    p: 4,
  };

const RequisitionTwo = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  return (
    <div className='RequisitionTwo'>
        <div className='TopSection'>
            <div className='InputSection'>

                <input 
                type='search'
                placeholder='search'
                />

            </div>
         
            <div className='FormSection'>
                <button onClick={handleOpen}>Requisite</button>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                <h3 style={{textAlign:'center'}}>Manual Invoicing</h3>
                
                <form style={{
                    border:'none',
                    width:'fitContent',
                    height:'60vh'
                }}>
                    <div className='mb-2 mt-3'>
                        <label htmlFor='reference'> File Reference / Gadget Name eg. Printer repair</label>
                        <input 
                            className='form-control'
                            placeholder='Input file reference'
                            id='reference'
                            name='reference'
                        />
                    </div>
    
                    <div className='mb-2'>
                        <label htmlFor='purpose'>Purpose of Funds</label>
                        <textarea
                            rows={4} 
                            className='form-control'
                            placeholder='Purpose...'
                            id='purpose'
                            name='purpose'
                        />
                    </div>
    
                    <div className='mb-3'>
                        <label htmlFor='amount'>Amount (Total / Balance. If balance, input the same file reference)</label>
                        <input 
                            className='form-control'
                            type='number'
                            placeholder='2000 ...'
                            id='amount'
                            name='amount'
                        />
                    </div>
    
                    <div className='mb-3' style={{display:'none'}}>
                        <label htmlFor='requestDate'>Date</label>
                        <input 
                            className='form-control'
                            type='date'
                            id='requestDate'
                            name='requestDate'
                        />
                    </div>
    
                    <div className='mb-3' style={{display:'none'}}>
                        <label htmlFor='username'>User Name</label>
                        <input 
                            className='form-control'
                            id='username'
                            name='username'
                        />
                    </div>
    
                    <div className='mb-3' style={{display:'none'}}>
                        <label htmlFor='firmname'>Firm Name</label>
                        <input 
                            className='form-control'
                            id='firmname'
                            name='firmname'
                        />
                    </div> 

                    <div className='mb-2' style={{
                        display:'flex',
                        justifyContent:'space-evenly'
                        
                    }}>

                        <button autoFocus onClick={handleClose} style={{
                            backgroundColor:'red',
                            padding:'5px 30px',
                            border:'none',
                            borderRadius:'5px',
                            color:'white',
                            fontweight:'500'
                        }}>Exit</button>

                        <button style={{
                            backgroundColor:'#02b159',
                            padding:'5px 30px',
                            border:'none',
                            borderRadius:'5px',
                            color:'white',
                            fontweight:'500'
                        }}>Submit</button>

                        
                    </div>
                
                </form>

            
            
            </Box>
            </Modal>

            </div>
           
        </div>

     


        <div className='Table'>
            <table>
                <thead>
                <tr>
                    <th>File Ref</th>
                    <th>Amount</th>
                    <th>User Name</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            
                </tbody>
            </table>
        </div>


    </div>
  )
}

export default RequisitionTwo