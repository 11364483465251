import React, { useContext, useEffect, useRef, useState } from 'react'
import './CaseView.scss'
import {useReactToPrint} from 'react-to-print'
import Navbar from '../Navigation/Navbar'
import Navigation from '../Components/NavOfficial/Navigation'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment'
import UserNavigation from '../NormalUser/UserNavigation/UserNavigation'

const CaseView = () => {

    const [clientInfo, setClientInfo] = useState({});
    const {id} = useParams();
    const {FullName} = useParams();
    const { currentUser } = useContext(AuthContext);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get(process.env.REACT_APP_API_ADDRESS + `api/newcases/${id}${FullName}`);
            setClientInfo(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [id,FullName]);
    
      const [activeTab, setActiveTab] = useState('Add Stock');
      const [activeTabIndex, setActiveTabIndex] = useState(0);
      
    
      const handleTabChange = (index) => {
        setActiveTabIndex(index);
      };
    
      const handleTabClick = (tabName) => {
        setActiveTab(tabName);
      };

      const componentRef = useRef();
      const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Document'
      });


  return (
    <div className="home">

    {currentUser.role === 'Admin' && (
        <Navigation />
        )}
    
    <div className="homeContainer">
    {currentUser.role === 'Admin' && (
        <Navbar />
      )}

      {currentUser.role === 'Normal User' && (
        <div>
        <UserNavigation />
        </div>
      )}
    <div className='CaseView'>
        <div className='IntroDetails'>
            <div className='NameIntro'><h4>{clientInfo.FullName}</h4> <span>{clientInfo.status}</span></div>
            <div className='NameIntro'><h5>ID Number</h5><p>{clientInfo.IdNumber}</p></div>
            <div className='NameIntro'><h5>Mobile Number</h5><p>{clientInfo.Mobile1}</p></div>
            <div className='NameIntro'><h5>Gender</h5><p>{clientInfo.Gender}</p></div>
            <div className='NameIntro'><h5>Case Category</h5><p>{clientInfo.CaseType}</p></div>
        </div>
        <div className='MinorDetails'>
            <div className="TabBar"> 
                <div className="TabButtons"> 
                    <button
                    className={activeTabIndex === 0 ? 'ActiveTab' : ''}
                    onClick={() => handleTabChange(0)}
                    >
                    Case Summary
                    </button>
                    <button
                    className={activeTabIndex === 1 ? 'ActiveTab' : ''}
                    onClick={() => handleTabChange(1)}
                    >
                    Case Tracking
                    </button>
                    <button
                    className={activeTabIndex === 2 ? 'ActiveTab' : ''}
                    onClick={() => handleTabChange(2)}
                    >
                    Client Communication
                    </button>
                    <button
                    className={activeTabIndex === 3 ? 'ActiveTab' : ''}
                    onClick={() => handleTabChange(3)}
                    >
                     Documents
                    </button>
                </div>

                <SwipeableViews
                index={activeTabIndex}
                onChangeIndex={handleTabChange}
                enableMouseEvents
                >
                <div className="TabContent" style={{display:'flex',flexDirection:'column',gap:'15px'}}> 
                    <div className='caseDetails'>
                        <div className='partOneCase'>
                            <h3>Case details</h3>

                            <div className='DetailView'>
                                <div className='detailAlign'>

                                    <div className='viewA'>
                                        <span>File Reference:</span><p>{clientInfo.fileref}</p>
                                    </div>
                                    <div className='viewA'>
                                        <span>Registration Date:</span><p>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}</p>
                                    </div>
                                    <div className='viewA'>
                                        <span>Client Status:</span><p>{clientInfo.ClientStatus}</p>
                                    </div>
                                    <div className='viewA'>
                                        <span>Alternative Mobile:</span><p>{clientInfo.Mobile2}</p>
                                    </div>
                                    <div className='viewA'>
                                        <span>Insurance:</span><p>{clientInfo.Insurance}</p>
                                    </div>
                                    <div className='viewA'>
                                        <span>Defence appearance:</span><p>{clientInfo.defenceappearance}</p>
                                    </div>
                                    {clientInfo.defenceappearance === 'Yes' &&(
                                        <div className='viewA'>
                                            <span>Defence firm:</span><p>{clientInfo.defencefirm}</p>
                                        </div>
                                    )}
                                    {clientInfo.defenceappearance === 'Yes' &&(
                                        <div className='viewA'>
                                            <span>Defence email:</span><p style={{color:'blue'}}>{clientInfo.defenceemail}</p>
                                        </div>
                                    )}
                                   

                                </div>

                                <div className='detailAlign'>

                                    <div className='viewA'>
                                        <span>Description:</span><p>{clientInfo.summary}</p>
                                    </div>

                                    <div className='viewA'>
                                        <span>Documents:</span><p>{clientInfo.selectedDocuments}</p>
                                    </div>

                                    <div className='viewA'>
                                        <button>Registered by</button><p>{clientInfo.regby}</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                 </div>
                <div className="TabContent" style={{display:'flex',flexDirection:'column',gap:'15px'}}> Deatails b </div>
                <div className="TabContent" style={{display:'flex',flexDirection:'column',gap:'15px'}}> 
                    <div className='Messages'>
                        <h2>SMS messaging</h2>
                        <div className='messageCompose'>

                            <div className='MessageInputs'>
                                <label>Subject</label>
                                <input />   
                            </div>  

                            <div className='MessageInputs'>
                                <label>Select Mobile</label>
                                <select>
                                    <option>Select...</option>
                                    <option>{clientInfo.Mobile1}</option>
                                    <option>{clientInfo.Mobile2}</option>
                                </select>   
                            </div>  

                            <div className='MessageInputs'>
                                <label>Appointment Date</label>
                                <input type='date'/>   
                            </div>  

                            <div className='MessageInputs'>
                                <label>Appointment time</label>
                                <input type='time'/>   
                            </div>  

                            <div className='MessageInputs'>
                                <label>Compose your Message</label>
                                <textarea 
                                    rows={4}
                                />   
                            </div>  

                            <div className='MessageInputs'>
                                <button>Send Message</button>
                            </div>

                        </div>
                    </div>
                 </div>
                <div className="TabContent" style={{display:'flex',flexDirection:'column',gap:'15px'}}> 

                <div style={{alignItems:'center',paddingLeft:'45%'}}>
                <button type="button" style={{alignSelf:'center'}} className="btnPrint" onClick={handlePrint}>
                Print Instruction</button>
                </div>

                <div ref={componentRef} style={{width:'100%',height:'100%'}}> 
                <div className="card" style={{height:'60vh'}}>
                <div className="splitscreen2">
                <div className="left2">
                <h2 style={{paddingBottom:'10px',paddingLeft:'5px',fontWeight:'bold'}}><u>Instruction Note</u></h2>
                <h6>Full Name:<span> {clientInfo.FullName} </span></h6> 
                <h6>Residence:<span> {clientInfo.residence} </span></h6> 
                <h6>Mobile One:<span> {clientInfo.Mobile1} </span></h6> 
                <h6>Mobile Two:<span> {clientInfo.Mobile2} </span></h6> 
               
                <h6>Case Category:<span> {clientInfo.CaseType} </span></h6> 
                <h6>Date of Instructions: <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}</span></h6>
              
                </div>
          
                <div className="right2">
                <span style={{paddingTop:'10px'}}>{currentUser?.firmname} </span> <br />
                
                <span>  
                P.O Box  {currentUser?.postal}
                  {currentUser?.postal} - 00100
                </span> 
                <br />
                <span>
                Mobile: {currentUser?.phone1} {currentUser?.phone1}
                </span>
                </div>
          
                </div>
                <div className="agree">
                <h4><b>In the Chief Magistrate Court at {clientInfo.Station}</b></h4>
                <br />
                <span>
                I/WE hereby appoint you <span style={{fontWeight:'bold'}}>{currentUser?.firmname} {currentUser?.firmname}</span> 
                <b>{currentUser?.location} {currentUser?.location},</b> <b>P.O Box {currentUser?.postal} {currentUser?.postal}-00100 Nairobi</b> to represent me/us in the above case. You are at liberty 
                to engage another advocate to assist you or to represent you should you not be available on any day
                 that the case comes up for hearing. I/we also agree that the fees payable herein is not refundable
                  but is recoverable as costs awarded to yourselves by the court. I/we hereby agree to pay the sum
                   of Kshs……NIL……….     being my initial deposit towards your agreed fees Upon receipt of the above
                    deposit, I /we agree that the firm of <b style={{textTransform:'uppercase'}}>{currentUser?.firmname} {currentUser?.firmname}</b> SHALL BE ENTITLED TO 30% 
                    OF THE DECRETAL AMOUNT, upon determination of this case.
                    </span>
                <br />
                <div style={{fontFamily:'Georgia, Times, "Times New Roman", serif',fontStyle:'normal',fontVariant:'normal'}}>
                <p>Your Faithfully</p>
                <br /><br />
                <p>ID NO: <span>{clientInfo.IdNumber}</span></p>
                <br />
                <p>Chaser Name: <span>{clientInfo.ChaserName}</span></p>
                <br/>
                <p>Chaser Mobile: <span>{clientInfo.ChaserMobile}</span></p>
                <p>Registred By: <span>{clientInfo.regby}</span></p>
                <br/>
                {/** <p>Office Number: <span>{currentUser?.phone1} {currentUser?.phone1}</span></p> */}
                <br/>
                <p>Generated by: <b>www.lawfirmshub.com</b> on <span>{moment(clientInfo.regdate).format('Do-MMMM-YYYY')}.</span> Signature & Stamp.</p>
                </div>
                </div>
                </div>
                </div>

                </div>
                </SwipeableViews>
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default CaseView