import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import RoutesApp from './Routes';
import { AuthContexProvider } from './context/AuthContext';
import { DarkModeContextProvider } from './context/darkModeContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    
      <DarkModeContextProvider>
        <AuthContexProvider>
          <RoutesApp />
        </AuthContexProvider>
      </DarkModeContextProvider>
    
);

